import {
	View,
	Button,
	StyleSheet,
	TouchableHighlight,
	Text,
} from "react-native";
import React, { useState } from "react";

const SubmitButtonDark = ({ onPress, title, style, fontStyle }) => (
	<TouchableHighlight
		activeOpacity={0.5}
		onPress={onPress}
		style={[styles.appButtonContainer, style]}>
		<Text
			style={[
				styles.appButtonText,
				fontStyle,
				{ fontFamily: "Gilroy-SemiBold" },
			]}>
			{title}
		</Text>
	</TouchableHighlight>
);

const styles = StyleSheet.create({
	appButtonContainer: {
		backgroundColor: "#001A45",
		borderRadius: 10,
		paddingVertical: 10,
		paddingHorizontal: 40,
	},
	appButtonText: {
		fontSize: 18,
		color: "#fff",
		alignSelf: "center",
	},
});

export default SubmitButtonDark;
