import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

/**
 * As of 2022 there are some peer deps conflict when using latest react 18 that causes errors in setup,
 * So we are currently using v17 react and react-dom, however you have to update this index.js file if you decide to upgrade v18 safely.
 */
// *****For react v17 use this*****
// import React from "react";
// import ReactDOM from "react-dom";
// import App from "./App";

// ReactDOM.render(
// 	<React.StrictMode>
// 		<App />
// 	</React.StrictMode>,
// 	document.getElementById("root")
// );

//*****For react v18 use this*****
// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
// 	<React.StrictMode>
// 		<App />
// 	</React.StrictMode>
// );
