import React, { useState } from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import Image from "../Common/MemoizedImage";

const ClickableIcon = ({ onPress, icon, iconHeight, iconWidth }) => {
	return (
		<View style={styles.buttonContainer}>
			<TouchableOpacity onPress={onPress}>
				<Image
					source={icon}
					style={{ width: iconWidth, height: iconHeight }}
					resizeMode='contain'
				/>
			</TouchableOpacity>
		</View>
	);
};

const styles = StyleSheet.create({
	buttonContainer: {
		alignContent: "center",
		justifyContent: "center",
	},
});

export default ClickableIcon;
