import React, { useState } from "react";
import { StyleSheet, Text, View, Dimensions, TextInput } from "react-native";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils.web";
import SubmitButton from "../../components/Buttons/SubmitButtonDark";
import PlainHeader from "../../components/WebOnly/PlainHeader";
import TopFeedbackBar from "../../components/WebOnly/TopFeedbackBar";

const ForgotPasswordScreen = ({ navigation }) => {
	const [emailValue, setEmailValue] = useState("");
	const window = Dimensions.get("window");
	const screen = Dimensions.get("screen");
	const [feedbackText, setFeedbackText] = useState();
	const [feedbackType, setFeedbackType] = useState("warn");
	const [showFeedback, setShowFeedback] = useState(false);

	const requestResetPassword = () => {
		axiosInstance({
			method: "post",
			url: "accounts/dj-rest-auth/password/reset/",
			data: {
				email: emailValue,
			},
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					console.log(response.data);
					navigation.navigate("resetpassword", {
						email: emailValue,
					});
				} else {
					setFeedbackText(
						"Error connecting to our servers. Try again later."
					);
					setFeedbackType("error");
					setShowFeedback(true);
				}
			})
			.catch((onrejected) => {
				Utils.handleFailedResponse(
					"postRequestPasswordReset",
					onrejected.response,
					onrejected.response.status,
					navigation,
					setFeedbackText
				);
				setFeedbackType("error");
				setShowFeedback(true);
			});
	};

	return (
		<View style={{ flex: 1 }}>
			<PlainHeader />
			<View style={{ flex: 1 }}>
				<TopFeedbackBar
					feedbackNature={feedbackType}
					setShow={setShowFeedback}
					show={showFeedback}
					text={feedbackText}
				/>

				<View style={styles.container}>
					<Text style={{ maxWidth: 380 }}>
						Please Enter the Email address of the account you want
						to reset password for
					</Text>
					<View
						style={{
							maxWidth: 390,
							alignSelf: "center",
							width: "100%",
						}}>
						<Text style={styles.textInputTitle}>Email</Text>
						<TextInput
							style={styles.textInput}
							placeholder={"Enter your email"}
							placeholderTextColor='#858189'
							color='#000'
							onChangeText={(text) => {
								setEmailValue(text);
							}}
							textContentType='emailAddress'
							autoCapitalize='none'
							value={emailValue}
							autoCorrect={false}
							spellCheck={false}
						/>
					</View>
					<SubmitButton
						style={{
							marginTop: 20,
							maxWidth: 330,
							alignSelf: "center",
						}}
						title={"Send Reset password email"}
						onPress={() => {
							if (
								emailValue.search(
									/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
								) != -1
							) {
								requestResetPassword();
							} else {
								setFeedbackText("Please enter a valid email.");
								setFeedbackType("warn");
								setShowFeedback(true);
								// navigation.navigate("ResetCredentialScreen", {
								// 	email: emailValue,
								// });
							}
						}}
					/>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
		alignItems: "center",
		paddingTop: 160,
	},
	backArrow: {
		marginTop: 35,
		marginStart: 40,
	},
	textInputTitle: {
		marginStart: 7,
		color: "#120632",
		fontSize: 16,
		marginTop: 20,
	},
	textInput: {
		borderRadius: 18,
		borderWidth: 2,
		borderColor: "#C4C4C4",
		paddingHorizontal: 15,
		height: 40,
		width: "100%",
		alignSelf: "center",
		outlineColor: "#7E98ED",
	},
});

export default ForgotPasswordScreen;
