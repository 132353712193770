import React, { useState, useEffect } from "react";
import {
	StyleSheet,
	Text,
	View,
	Dimensions,
	TouchableOpacity,
	Image,
} from "react-native";
import QRCode from "react-qr-code";

//components
import BackFab from "../../components/Common/BackFab";

const GatePassScreen = ({ route, navigation }) => {
	const window = Dimensions.get("window");
	const screen = Dimensions.get("screen");
	const [dimensions, setDimensions] = useState({ window, screen });

	// const { gatepassCode } = route.params;
	//handling for empty params
	const [gatepassCode, setGatepassCode] = useState(
		typeof route.params == "undefined" ? "" : route.params.next
	);
	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setDimensions({ window, screen });
			}
		);
		return () => subscription?.remove();
	}, []);

	return (
		<View style={styles.container}>
			{gatepassCode ? (
				<View
					style={{
						marginTop: 30,
						paddingTop: 20,
						alignItems: "center",
					}}>
					<Text
						style={{
							width: "90%",
							maxWidth: 400,
							fontSize: 16,
							textAlign: "center",
							fontFamily: "Gilroy-Regular",
						}}>
						Please get this QR code scanned at the Gate to receive
						your serviced vehicle.
					</Text>
					<View style={{ marginTop: 20 }}>
						<QRCode value={gatepassCode} size={200} />
					</View>
				</View>
			) : (
				<View
					style={{
						marginTop:
							screen.width > 400
								? (screen.height * 10) / 100
								: 70,
						paddingTop: 20,
						alignItems: "center",
					}}>
					<Text
						style={{
							width: "90%",
							maxWidth: 400,
							fontSize: 16,
							textAlign: "center",
							fontFamily: "Gilroy-Regular",
						}}>
						Unable to generate a gate pass, Try again later or
						contact support.
					</Text>
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
});

export default GatePassScreen;
