import React, { useState, useEffect, useRef } from "react";
import {
	StyleSheet,
	Text,
	View,
	TextInput,
	Image,
	ToastAndroid,
	Platform,
	Alert,
	Linking,
	Dimensions,
	TouchableOpacity,
} from "react-native";
import { CheckBox } from "react-native-web";
import axiosInstance from "../../Utils/axiosGlobal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Utils from "../../Utils/Utils";
import PlainHeader from "../../components/WebOnly/PlainHeader";

//components
import SubmitButtonDark from "../../components/Buttons/SubmitButtonDark";

//assets
import googleIc from "../../assets/Misc/google-48.png";
import BackFab from "../../components/Common/BackFab";
import eye_close_ic from "../../assets/Icons/DCS_eye_closed_ic.png";
import eye_open_ic from "../../assets/Icons/DCS_eye_open_ic.png";
import themes from "../../Styles/themes";
import { Link } from "@react-navigation/native";

const Register = ({ navigation }) => {
	//User inputs
	const [usernameValue, setUsernameValue] = useState("");
	const [emailValue, setEmailValue] = useState("");
	const [passValue, setPassValue] = useState("");
	const [repassValue, setRepassValue] = useState("");
	const [agreeToTandC, setAgreeToTandC] = useState(false);
	//input field refs
	const passFieldref = useRef();
	const rePassFieldref = useRef();
	const emailref = useRef();
	const usernameref = useRef();

	const [securePassword1, setSecurePassword1] = useState(true);
	const [securePassword2, setSecurePassword2] = useState(true);

	//errorText fields
	const [errorText, setErrorText] = useState({
		name: "",
		email: "",
		password: "",
		repassword: "",
	});
	//error text refs
	const errorPassFieldref = useRef();
	const errorRePassFieldref = useRef();
	const errorEmailref = useRef();
	const errorUsernameref = useRef();

	const window = Dimensions.get("window");
	const screen = Dimensions.get("screen");
	const [dimensions, setDimensions] = useState({ window, screen });

	const [box1IsFocus, setBox1Focus] = useState(false);
	const [box2IsFocus, setBox2Focus] = useState(false);
	const [box3IsFocus, setBox3Focus] = useState(false);

	const updateParam = (text, fieldName) => {
		switch (fieldName) {
			case "email":
				setEmailValue(text);
				break;

			case "password":
				setPassValue(text);
				break;

			case "repassword":
				setRepassValue(text);
				break;
		}
	};

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setDimensions({ window, screen });
			}
		);
		return () => subscription?.remove();
	}, []);

	const notifyMessage = (message) => {
		if (Platform.OS === "android") {
			ToastAndroid.show(message, ToastAndroid.SHORT);
		} else {
			Alert.alert(message);
		}
	};

	//key for user:
	// {
	// "username": "test1",
	// "password1": "asdfghjkl12123",
	// "password2": "asdfghjkl12123",
	// "email": "test1@gmail.com"
	// }
	// = af0787bfff2cda35d74a6f7f7ec3adbd60d7d34c

	const callRegisterAPI = async () => {
		axiosInstance({
			method: "post",
			url: "accounts/dj-rest-auth/registration/",
			data: {
				username: emailValue,
				password1: passValue,
				password2: repassValue,
				email: emailValue,
			},
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					try {
						// AsyncStorage.setItem("auth-key", response.data.key);
						// //set Authorization in header to fetched key
						// axiosInstance.defaults.headers.common["Authorization"] =
						// 	"Token " + response.data.key;
						// navigation.navigate("HomeScreen");
						// navigation.reset({
						// 	index: 0,
						// 	routes: [{ name: "HomeScreen" }],
						// });
						navigation.navigate("verifymail", {
							email: emailValue,
						});
					} catch (error) {
						console.log(error);
					}
				} else {
					notifyMessage(
						"Error connecting to our servers. Try again later."
					);
				}
			})
			.catch((onrejected) => {
				console.log(onrejected);
				handleErrorResponse(onrejected.response.data);
				Utils.handleFailedResponse(
					"postRegistration",
					onrejected.response,
					onrejected.response.status,
					navigation
				);
			});
	};

	const handleErrorResponse = (error) => {
		setErrorText({
			email: "email" in error ? error.email : "",
			password: "password1" in error ? error.password1 : "",
			repassword: "password2" in error ? error.password2 : "",
		});
		//update email field
		if ("email" in error) {
			emailref.current.setNativeProps({
				style: { borderColor: "#FF5151" },
			});

			errorEmailref.current.setNativeProps({
				style: { height: 20 },
			});
		} else {
			emailref.current.setNativeProps({
				style: { borderColor: "#D1FFA0" },
			});

			errorEmailref.current.setNativeProps({
				style: { height: 0 },
			});
		}

		//update username field
		// if ("username" in error) {
		// 	usernameref.current.setNativeProps({
		// 		style: { borderColor: "#FF5151" },
		// 	});

		// 	errorUsernameref.current.setNativeProps({
		// 		style: { height: 20 },
		// 	});
		// } else {
		// 	usernameref.current.setNativeProps({
		// 		style: { borderColor: "#D1FFA0" },
		// 	});

		// 	errorUsernameref.current.setNativeProps({
		// 		style: { height: 0 },
		// 	});
		// }

		//update password1 field
		if ("password1" in error) {
			passFieldref.current.setNativeProps({
				style: { borderColor: "#FF5151" },
			});
			errorPassFieldref.current.setNativeProps({
				style: { height: 20 },
			});
		} else {
			passFieldref.current.setNativeProps({
				style: { borderColor: "#D1FFA0" },
			});

			errorPassFieldref.current.setNativeProps({
				style: { height: 0 },
			});
		}

		//update password2 field
		if ("password2" in error) {
			rePassFieldref.current.setNativeProps({
				style: { borderColor: "#FF5151" },
			});
			errorRePassFieldref.current.setNativeProps({
				style: { height: 20 },
			});
		} else {
			rePassFieldref.current.setNativeProps({
				style: { borderColor: "#D1FFA0" },
			});

			errorRePassFieldref.current.setNativeProps({
				style: { height: 0 },
			});
		}

		if ("non_field_errors" in error) {
			// usernameref.current.setNativeProps({
			// 	style: { borderColor: "#FF5151" },
			// });
			if (
				error.non_field_errors ==
				"The two password fields didn't match."
			) {
				setErrorText({
					email: "email" in error ? error.email : "",
					password: "The two password fields didn't match.",
					repassword: "The two password fields didn't match.",
				});
				passFieldref.current.setNativeProps({
					style: { borderColor: "#FF5151" },
				});
				errorPassFieldref.current.setNativeProps({
					style: { height: 20 },
				});
				rePassFieldref.current.setNativeProps({
					style: { borderColor: "#FF5151" },
				});
				errorRePassFieldref.current.setNativeProps({
					style: { height: 20 },
				});
			} else {
				emailref.current.setNativeProps({
					style: { borderColor: "#FF5151" },
				});
				passFieldref.current.setNativeProps({
					style: { borderColor: "#FF5151" },
				});
				rePassFieldref.current.setNativeProps({
					style: { borderColor: "#FF5151" },
				});
				notifyMessage(error.non_field_errors[0]);
			}
		}
	};

	const InputField = ({
		title,
		placeHolder,
		innerRef,
		param,
		errorText,
		errorInnerRef,
	}) => {
		return (
			<View style={{ flexWrap: "wrap" }}>
				<Text style={styles.textInputTitle}>{title}</Text>
				<TextInput
					ref={innerRef}
					style={styles.textInput}
					placeholder={placeHolder}
					placeholderTextColor='#858189'
					color='#000'
					onChangeText={(text) => {
						updateParam(text, param);
					}}
					autoCorrect={false}
					spellCheck={false}
					secureTextEntry={param.endsWith("password")}
					keyboardType={
						param == "email" ? "email-address" : "default"
					}
				/>
				<Text
					style={{ color: "#FF5151", height: 0 }}
					ref={errorInnerRef}>
					{errorText}
				</Text>
			</View>
		);
	};

	const FormBody = () => {
		return (
			<View
				style={[
					styles.innerContainer,
					{ maxWidth: screen.width > 500 ? 400 : "80%" },
				]}>
				{/* title */}
				<Text style={styles.titleFont}>Register</Text>
				{/* Sign up with Google Button */}
				{/* <TouchableOpacity
					style={[styles.googleSignUpTextCont, styles.centerify]}
					onPress={() => {
						console.log("sign up with google pressed");
					}}>
					<Image
						source={googleIc}
						style={{
							height: 20,
							width: 20,
						}}
					/>
					<Text style={styles.googleSignUpText}>
						Sign Up with Google
					</Text>
				</TouchableOpacity> */}
				{/* partitioning title text */}
				{/* <View
					style={[
						styles.centerHorizontal,
						{
							marginTop: 10,
							flexDirection: "row",
							alignContent: "space-around",
						},
					]}>
					<Text
						style={{
							backgroundColor: "#858189",
							flex: 1,
							maxHeight: 2,
							opacity: 0.5,
							marginHorizontal: 5,
						}}></Text>
					<Text style={styles.signUpOptionText}>
						Or Sign up with email
					</Text>
					<Text
						style={{
							backgroundColor: "#858189",
							flex: 1,
							maxHeight: 2,
							opacity: 0.5,
							marginHorizontal: 5,
						}}></Text>
				</View> */}
				{/* Inputfields */}
				<View style={{ marginTop: 55 }}>
					{/* Email inputfield */}
					<View>
						<Text style={styles.textInputTitle}>Email</Text>
						<TextInput
							ref={emailref}
							style={[
								styles.textInput,
								{
									outlineColor: box1IsFocus
										? themes.primarySkyBlue
										: "#C4C4C4",
								},
							]}
							placeholder={"Enter your email"}
							placeholderTextColor='#858189'
							color='#000'
							onChangeText={(text) => {
								updateParam(text, "email");
							}}
							onFocus={() => setBox1Focus(true)}
							onBlur={() => setBox1Focus(false)}
							value={emailValue}
							autoCapitalize='none'
							autoCorrect={false}
							spellCheck={false}
							keyboardType='email-address'
						/>
						<Text
							style={{
								color: "#FF5151",
								height: 0,
								fontFamily: "Gilroy-Regular",
							}}
							ref={errorEmailref}>
							{errorText.email}
						</Text>
						{/* <InputField
							innerRef={emailref}
							errorInnerRef={errorEmailref}
							title={"Email"}
							placeHolder='Enter your email'
							errorText={errorText.email}
							param='email'
						/> */}
					</View>
					{/* Password inputfield */}
					<View style={{ marginTop: 20 }}>
						<Text style={styles.textInputTitle}>Password</Text>
						<TextInput
							ref={passFieldref}
							style={[
								styles.textInput,
								{
									outlineColor: box2IsFocus
										? themes.primarySkyBlue
										: "#C4C4C4",
								},
							]}
							placeholder={"Enter your password"}
							placeholderTextColor='#858189'
							color='#000'
							onChangeText={(text) => {
								updateParam(text, "password");
							}}
							onFocus={() => setBox2Focus(true)}
							onBlur={() => setBox2Focus(false)}
							value={passValue}
							autoCorrect={false}
							spellCheck={false}
							secureTextEntry={securePassword1}
						/>
						<TouchableOpacity
							style={{
								position: "absolute",
								right: 20,
								top: 25,
							}}
							onPress={() => setSecurePassword1((prev) => !prev)}>
							<Image
								source={
									securePassword1 ? eye_close_ic : eye_open_ic
								}
								style={{
									height: 25,
									width: 25,
									tintColor: !securePassword1
										? "#ccc"
										: "#7E98ED",
								}}
							/>
						</TouchableOpacity>
						<Text
							style={{
								color: "#FF5151",
								height: 0,
								fontFamily: "Gilroy-Regular",
							}}
							ref={errorPassFieldref}>
							{errorText.password}
						</Text>
						{/* <InputField
							innerRef={passFieldref}
							errorInnerRef={errorPassFieldref}
							title={"Password"}
							placeHolder='Enter your password'
							errorText={errorText.password}
							param='password'
						/> */}
					</View>
					{/* re-enter password inputfield */}
					<View style={{ marginTop: 20 }}>
						<Text style={styles.textInputTitle}>
							Re-enter Password
						</Text>
						<TextInput
							ref={rePassFieldref}
							style={[
								styles.textInput,
								{
									outlineColor: box3IsFocus
										? themes.primarySkyBlue
										: "#C4C4C4",
								},
							]}
							placeholder={"Re-enter your Password"}
							placeholderTextColor='#858189'
							color='#000'
							onChangeText={(text) => {
								updateParam(text, "repassword");
							}}
							onFocus={() => setBox3Focus(true)}
							onBlur={() => setBox3Focus(false)}
							value={repassValue}
							autoCapitalize='none'
							autoCorrect={false}
							spellCheck={false}
							secureTextEntry={securePassword2}
						/>
						<TouchableOpacity
							style={{ position: "absolute", right: 20, top: 25 }}
							onPress={() => setSecurePassword2((prev) => !prev)}>
							<Image
								source={
									securePassword2 ? eye_close_ic : eye_open_ic
								}
								style={{
									height: 25,
									width: 25,
									tintColor: !securePassword2
										? "#ccc"
										: "#7E98ED",
								}}
							/>
						</TouchableOpacity>
						<Text
							style={{
								color: "#FF5151",
								height: 0,
								fontFamily: "Gilroy-Regular",
							}}
							ref={errorRePassFieldref}>
							{errorText.repassword}
						</Text>
						{/* <InputField
							innerRef={rePassFieldref}
							errorInnerRef={errorRePassFieldref}
							title={"Re-enter Password"}
							placeHolder='Re-enter your password'
							errorText={errorText.repassword}
							param='repassword'
						/> */}
					</View>
				</View>
				{/* Terms and conditions */}
				<View
					style={{
						flexDirection: "row",
						marginTop: 40,
					}}>
					<View
						style={{ flexDirection: "row", alignItems: "center" }}>
						<CheckBox
							boxType='square'
							// onCheckColor='#fff'
							color='#001A45'
							// tintColors={{ true: "#001A45" }}
							value={agreeToTandC}
							onChange={() => {
								setAgreeToTandC((prev) => !prev);
							}}
						/>
						<View style={{ flexDirection: "row", marginLeft: 7 }}>
							<Text
								style={{
									fontSize: 12,
									fontFamily: "Gilroy-Regular",
								}}>
								I agree to all{" "}
							</Text>
							<Text
								onPress={() =>
									Linking.openURL(
										"https://accessai.co/terms_and_conditions/"
									)
								}
								style={{
									fontSize: 12,
									color: "blue",
									fontFamily: "Gilroy-SemiBold",
								}}>
								terms
							</Text>
							<Text
								style={{
									fontSize: 12,
									fontFamily: "Gilroy-Regular",
								}}>
								{" "}
								and{" "}
							</Text>
							<Text
								onPress={() =>
									Linking.openURL(
										"https://accessai.co/privacy_policy/"
									)
								}
								style={{
									fontSize: 12,
									color: "blue",
									fontFamily: "Gilroy-SemiBold",
								}}>
								privacy policies.
							</Text>
						</View>
					</View>
				</View>
				{/* Register/Submit Button */}
				<View style={{ marginTop: 10 }}>
					<SubmitButtonDark
						onPress={() => {
							if (agreeToTandC) {
								callRegisterAPI();
							} else {
								notifyMessage(
									"Please agree to all terms and conditions"
								);
							}
						}}
						title={"Register"}
						fontStyle={{ fontFamily: "Gilroy-SemiBold" }}
					/>
				</View>
				{/* Already have an account */}
				<Link to={"/login"}>
					<View
						style={{
							flexDirection: "row",
							marginTop: 45,
							justifyContent: "center",
							marginBottom: 60,
						}}>
						<Text style={{ fontFamily: "Gilroy-Regular" }}>
							Already have an account?
						</Text>
						<Text
							style={{
								color: "#00417C",
								marginStart: 5,
								fontFamily: "Gilroy-SemiBold",
							}}
							onPress={() => navigation.navigate("Login")}>
							Login here
						</Text>
					</View>
				</Link>
			</View>
		);
	};

	return (
		<View style={styles.container}>
			<PlainHeader />
			<View
				style={{
					alignItems: "center",
				}}>
				{FormBody()}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
	},
	innerContainer: {
		flex: 1,
		flexDirection: "column",
		paddingTop: 35,
	},
	titleFont: {
		fontSize: 25,
		color: "#120632",
		fontFamily: "AvenirNextLTPro-Bold",
	},
	googleSignInText: {
		fontSize: 15,
		color: "#001A45",
		marginStart: 20,
	},
	googleSignInTextCont: {
		height: 45,
		width: 300,
		maxWidth: "100%",
		flexDirection: "row",
		marginTop: 25,
		marginBottom: 16,
		borderWidth: 1,
		borderColor: "#001A45",
		borderRadius: 15,
		padding: 5,
	},
	centerify: {
		alignItems: "center",
		justifyContent: "center",
	},
	centerHorizontal: {
		alignItems: "center",
	},
	signInOptionText: {
		fontSize: 12,
		color: "#858189",
	},
	textInputTitle: {
		marginStart: 7,
		color: "#120632",
		fontSize: 16,
		fontFamily: "Gilroy-Regular",
	},
	textInput: {
		borderRadius: 18,
		borderWidth: 2,
		paddingHorizontal: 15,
		height: 40,
		width: "100%",
		borderColor: "#C4c4c4",
		fontFamily: "Gilroy-Regular",
	},
});

export default Register;
