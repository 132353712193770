import React, { useState, useEffect, useMemo } from "react";
import {
	StyleSheet,
	Text,
	View,
	ScrollView,
	Dimensions,
	TextInput,
	Image,
	FlatList,
	TouchableOpacity,
} from "react-native";
import { ActivityIndicator, Picker } from "react-native-web";

//components
import NonFilledButton from "../../components/Buttons/NonFilledButton";
import RatingFiveStar from "../../components/Utils/RatingFiveStar";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils.web";
import SubmitButton from "../../components/Buttons/SubmitButtonDark";

//assets
import no_image from "../../assets/Defaults/DCS_no_image.png";
import filled_star from "../../assets/Misc/DCS_star_filled_ic.png";
import empty_star from "../../assets/Misc/DCS_star_empty_ic.png";
import BottomPopUpModal from "../../components/Common/BottomPopUpModal.web";
import TopFeedbackBar from "../../components/WebOnly/TopFeedbackBar";

const MyOrdersScreen = ({ route, navigation }) => {
	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));
	const [dimensions, setDimensions] = useState({ window, screen });

	// const { filterCode } = route.params;
	const [loadWithFilter, setLoadWithFilter] = useState(
		typeof route.params == "undefined" ? null : route.params.filterCode
	);

	const data = [
		{ label: "No Filter", value: -99 },
		{ label: "Started", value: 0 },
		{ label: "Completed", value: 20 },
		{ label: "Canceled", value: -1 },
	];
	const ratingArray = [1, 2, 3, 4, 5];
	const [value, setValue] = useState(null);
	const [isFocus, setIsFocus] = useState(false);
	const [rateModalVisibility, setRateModalVisibility] = useState(false);
	const [serviceToBeRated, setServiceToBeRated] = useState(null);
	const [usersRating, setUsersRating] = useState(1);

	const [titleErrorText, setTitleErrorText] = useState("");
	const [reviewErrorText, setReviewErrorText] = useState("");

	const [fetchingData, setFetchingData] = useState(true);
	const [serviceOrdersData, setServiceOrdersData] = useState([]);
	const [orderCopy, setOrderCopy] = useState([]);
	const [centerSelected, setCenterSelected] = useState(null);
	const [reviewTitle, setReviewTitle] = useState("");
	const [reviewBody, setReviewBody] = useState("");

	const [postingReview, setPostingInProgress] = useState(false);

	const [showFeedback, setShowFeedback] = useState(false);
	const [feedbackText, setFeedbackText] = useState("");
	const [feedbackNature, setFeedbackNature] = useState("warn");

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	useEffect(() => {
		/**
		 * On web version we need to check for user session validity on page refresh as the axios default headers don't
		 * persist page resfresh, so this is an additional step and needs to be done on all pages.
		 */
		Utils.isGuestOrUser(navigation).then((result) => {
			console.log(result);
			if (
				result == null ||
				typeof result == "undefined" ||
				result == "guest"
			) {
				setGuestUserState(true);
				hitApi(false);
			} else {
				axiosInstance.defaults.headers.common["Authorization"] = result;
				hitApi(false);
			}
		});
	}, []);

	const hitApi = (append) => {
		axiosInstance({
			method: "get",
			url: append
				? serviceOrdersData.next.substring(
						serviceOrdersData.next.indexOf(
							"auto-care/service-bookings/"
						),
						serviceOrdersData.next.length
				  )
				: `auto-care/service-bookings/`,
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					handleOrderResponse(response.data, append);
				} else {
					Utils.notifyMessage(
						"Error connecting to our servers. Try again later."
					);
				}
			})
			.catch((onrejected) => {
				Utils.handleFailedResponse(
					"getBookings",
					onrejected.response,
					onrejected.response.status,
					navigation,
					setFeedbackText
				);
			});
	};

	const handleOrderResponse = (response, append) => {
		if (append) {
			let newObj = {
				count: serviceOrdersData.count,
				next: response.next,
				previous: serviceOrdersData.next,
				results: serviceOrdersData.results.concat(response.results),
			};
			//load with active filter -> load the list first time with filter applied.
			if (
				loadWithFilter == 0 ||
				loadWithFilter == 20 ||
				loadWithFilter == -1
			) {
				console.log("filtering with append");
				//set UI with filter applied
				setValue(loadWithFilter);
				setIsFocus(false);
				//filter and set in current list, where 'loadWithFilter' is the status code
				let filteredOrder = newObj.results.filter(
					(order) =>
						order.status != null &&
						order.status.status == loadWithFilter
				);
				setServiceOrdersData({
					...serviceOrdersData,
					results: filteredOrder,
				});
				// setOrderCopy(newObj);
			} else {
				setServiceOrdersData(newObj);
				setOrderCopy(serviceOrdersData);
			}
		} else {
			// load the list without filter applied on first time but also handle if filter was set later on
			// so if filter is set later on then
			if (
				loadWithFilter == 0 ||
				loadWithFilter == 20 ||
				loadWithFilter == -1
			) {
				//set dropdown UI with filter applied
				setValue(loadWithFilter);
				setIsFocus(false);
				//filter and set in current list, where 'loadWithFilter' is the status code
				let filteredOrder = response.results.filter(
					(order) =>
						order.status != null &&
						order.status.status == loadWithFilter
				);
				setServiceOrdersData({
					count: response.count,
					next: response.next,
					previous: response.previous,
					results: filteredOrder,
				});
			}
			//filter is not applied yet
			else {
				setServiceOrdersData(response);
			}
			setOrderCopy(response);
		}
		setFetchingData(false);
	};

	const postReview = () => {
		let requestBody = {
			service_booking: serviceToBeRated.id,
			service: serviceToBeRated.id,
			service_center: centerSelected,
			title: reviewTitle,
			comment: reviewBody,
			rating: usersRating,
		};
		try {
			axiosInstance({
				method: "post",
				url: `/feedback/reviews/`,
				data: requestBody,
			})
				.then((response) => {
					if (response.status >= 200 && response.status < 300) {
						setFeedbackNature("success");
						setFeedbackText("Your response has been recoded!");
						setShowFeedback(true);
						setPostingInProgress(false);
						setReviewBody("");
						setReviewTitle("");
						setRateModalVisibility(false);
					} else {
						setFeedbackText(
							"There was an error posting your review, Try again later."
						);
						setFeedbackNature("error");
						setShowFeedback(true);
						setPostingInProgress(false);
					}
				})
				.catch((onrejected) => {
					setFeedbackText(
						"There was an error posting your review, Try again later."
					);
					setFeedbackNature("error");
					setShowFeedback(true);
					setPostingInProgress(false);
					Utils.handleFailedResponse(
						"postReview",
						onrejected.response,
						onrejected.response.status,
						navigation,
						setFeedbackText
					);
				});
		} catch (error) {
			console.log(error);
			Utils.notifyMessage("Something went wrong, Try again later.");
		}
	};

	//Use this component to get user's rating, each star is clickable and sets the user rating to it's position in array.
	//ex: if 3rd star is clicked, user rated 3 star
	const TouchableStars = useMemo(() => {
		return (
			<View style={{ flexDirection: "row" }}>
				{ratingArray.map((currentRating) => {
					return (
						<TouchableOpacity
							key={currentRating}
							style={{
								height: 30,
								margin: 5,
								width: 30,
							}}
							onPress={() => {
								setUsersRating(currentRating);
							}}>
							<Image
								resizeMode='contain'
								style={{ height: "100%", width: "100%" }}
								source={
									currentRating <= usersRating
										? filled_star
										: empty_star
								}
							/>
						</TouchableOpacity>
					);
				})}
			</View>
		);
	}, [usersRating]);

	const renderItem = ({ item }) => {
		return (
			<View
				key={item.id}
				style={{
					backgroundColor: "#fff",
					paddingHorizontal: 20,
					paddingVertical: 15,
					marginVertical: 5,
					// borderWidth: 1,
					// borderColor: "#ddd",
					borderRadius: 9,
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						marginBottom: 8,
					}}>
					<View style={{ flexDirection: "row" }}>
						<View style={{ height: 30, width: 30 }}>
							<Image
								source={
									item.status == null
										? no_image
										: { uri: item.status.icon }
								}
								style={{ height: "100%", width: "100%" }}
								resizeMode={"contain"}
							/>
						</View>
						<View style={{ marginLeft: 10 }}>
							<Text
								style={{
									color: "#0E1B46",
									fontFamily: "Gilroy-SemiBold",
									fontSize: 16,
								}}>
								Service{" "}
								{item.status == null
									? "UNKNOWN"
									: item.status.status_str}
							</Text>
							<Text
								style={{
									color: "#52566C",
									fontSize: 12,
									fontFamily: "Gilroy-Regular",
								}}>
								{item.status != null && item.status.status == 20
									? "Completed on " +
									  Utils.getLocalTimeFromUTC(
											item.updated,
											"dddd Do MMM"
									  )
									: "Last updated " +
									  Utils.getLocalTimeFromUTC(
											item.updated,
											"Do MMM"
									  )}
							</Text>
						</View>
					</View>
					{item.status == null ? null : (
						<NonFilledButton
							title={"Track status"}
							onPress={() => {
								navigation.navigate("trackorder", {
									orderID: item.id,
								});
							}}
						/>
					)}
				</View>
				<View
					style={{
						borderWidth: 1,
						borderTopRightRadius: 7,
						borderTopLeftRadius: 7,
						borderColor: "#e5e5e5",
						flexDirection: "row",
					}}>
					<View style={{ flex: 5, padding: 10 }}>
						<Text style={{ fontFamily: "Gilroy-SemiBold" }}>
							{item.service == null
								? "UNKNOWN"
								: item.service.name}
						</Text>
						{item.service == null ||
						item.service.description == null
							? null
							: item.service.description
									.split(",")
									.map((item, i) => {
										return (
											<View
												key={i}
												style={{
													flexDirection: "row",
													alignItems: "center",
												}}>
												<Text
													style={{
														color: "#585C74",
														fontSize: 8,
														fontFamily:
															"Gilroy-Regular",
													}}>
													{"\u2B24"}
												</Text>
												<Text
													style={{
														color: "#585C74",
														marginStart: 5,
														fontSize: 12,
														fontFamily:
															"Gilroy-Regular",
													}}>
													{item}
												</Text>
											</View>
										);
									})}
					</View>
					<View
						style={{
							flex: 3,
							height: 150,
							maxHeight: 350,
							overflow: "hidden",
							borderTopRightRadius: 7,
						}}>
						<Image
							source={
								item.service == null
									? no_image
									: { uri: item.service.image }
							}
							style={{ height: "100%", width: "100%" }}
							resizeMode={"cover"}
						/>
					</View>
				</View>
				<View
					style={{
						borderBottomWidth: 1,
						borderRightWidth: 1,
						borderLeftWidth: 1,
						borderBottomLeftRadius: 7,
						borderBottomRightRadius: 7,
						borderColor: "#e5e5e5",
						padding: 10,
						flexDirection: "row",
						justifyContent: "center",
					}}>
					{item.status != null && item.status.status == 20 ? (
						item.user_review != null ? (
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
								}}>
								<Text
									style={{
										color: "#aaa",
										fontFamily: "Gilroy-Regular",
									}}>
									You Rated
								</Text>
								<RatingFiveStar
									rating={item.user_review.rating}
									styles={{ marginLeft: 10 }}
								/>
							</View>
						) : (
							<TouchableOpacity
								style={{ alignItems: "center" }}
								onPress={() => {
									setServiceToBeRated(item);
									setRateModalVisibility(true);
									setCenterSelected(item.service_center.id);
									setReviewBody("");
									setReviewTitle("");
								}}>
								<Text
									style={{
										color: "#7E98ED",
										fontFamily: "Gilroy-SemiBold",
									}}>
									Rate your Experience
								</Text>
							</TouchableOpacity>
						)
					) : (
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								width: 150,
							}}>
							<RatingFiveStar
								rating={
									item.service == null
										? 0
										: item.service.rating
								}
								styles={{ marginLeft: 10 }}
							/>
						</View>
					)}
				</View>
				{/* item.pickup && item.completed */}
				{item.completed && !item.pickup && item.payment_completed ? (
					<TouchableOpacity
						onPress={() => {
							navigation.navigate("GatePassScreen", {
								gatepass_code: item.gatepass_code,
							});
						}}
						style={{ alignSelf: "center", marginTop: 10 }}>
						<Text
							style={{
								color: "#7E98ED",
								fontFamily: "Gilroy-SemiBold",
							}}>
							Get your Gate Pass
						</Text>
					</TouchableOpacity>
				) : null}
			</View>
		);
	};

	const memoizedList = useMemo(() => {
		return (
			<FlatList
				contentContainerStyle={{
					paddingBottom:
						screen.width > 400 ? (screen.height * 10) / 100 : 120,
				}}
				scrollEnabled={true}
				data={serviceOrdersData.results}
				renderItem={renderItem}
				onEndReached={() => {
					serviceOrdersData.next != null &&
					serviceOrdersData.results.length < serviceOrdersData.count
						? hitApi(true)
						: null;
				}}
				onEndReachedThreshold={0.5}
			/>
		);
	}, [serviceOrdersData]);

	const filterOrders = (code) => {
		if (code != -99) {
			let filteredOrder = orderCopy.results.filter(
				(order) => order.status != null && order.status.status == code
			);
			setServiceOrdersData({
				...serviceOrdersData,
				results: filteredOrder,
			});
		}
	};

	const RowData = ({ title, value }) => {
		return (
			<View
				style={{
					flexDirection: "row",
					marginVertical: 2,
				}}>
				<Text style={{ fontSize: 16, fontFamily: "Gilroy-Regular" }}>
					{title}
				</Text>
				<Text style={{ fontSize: 16, fontFamily: "Gilroy-Regular" }}>
					{" "}
					:{" "}
				</Text>
				<Text style={{ fontSize: 16, fontFamily: "Gilroy-SemiBold" }}>
					{value}
				</Text>
			</View>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			<View
				style={{
					height: 50,
					width: windowSize.width < 800 ? "100%" : "70%",
					maxWidth: 900,
					backgroundColor: "#fff",
					flexDirection: "row",
					justifyContent: "space-between",
					alignSelf: "center",
					alignItems: "center",
					paddingStart: 20,
					marginVertical: 10,
					borderRadius: 20,
				}}>
				<View
					style={{
						height: 50,
						backgroundColor: "#fff",
						flexDirection: "row",
						alignItems: "center",
					}}>
					<Text
						style={{
							color: "#585C74",
							fontFamily: "Gilroy-Regular",
						}}>
						Showing
					</Text>
					<Picker
						style={[styles.dropdown]}
						onValueChange={(item) => {
							setValue(item.value);
							setIsFocus(false);
							filterOrders(item);
							setLoadWithFilter(item.value);
						}}
						selectedValue={value}>
						{data.map((item, i) => {
							return (
								<Picker.Item
									key={i}
									itemStyle={{
										maxWidth: 100,
									}}
									label={item.label}
									value={item.value}
								/>
							);
						})}
					</Picker>
					{/* <Dropdown
							style={[
								styles.dropdown,
								isFocus && { borderColor: "blue" },
							]}
							placeholderStyle={styles.placeholderStyle}
							selectedTextStyle={styles.selectedTextStyle}
							iconStyle={styles.iconStyle}
							data={data}
							maxHeight={300}
							labelField='label'
							valueField='value'
							placeholder={
								!isFocus ? "Showing all" : "Pick a filter"
							}
							searchPlaceholder='Search'
							value={value}
							onFocus={() => setIsFocus(true)}
							onBlur={() => setIsFocus(false)}
							onChange={(item) => {
								setValue(item.value);
								setIsFocus(false);
								filterOrders(item.value);
								setLoadWithFilter(item.value);
							}}
						/> */}
				</View>
				{value != null || value == -99 || value == null ? (
					<TouchableOpacity
						style={{ marginRight: 15 }}
						onPress={() => {
							setLoadWithFilter(null);
							setServiceOrdersData(orderCopy);
							setValue(null);
						}}>
						<Text
							style={{
								color: "#7E98ED",
								fontFamily: "Gilroy-SemiBold",
							}}>
							CLEAR
						</Text>
					</TouchableOpacity>
				) : null}
			</View>
			{/* Body */}
			<ScrollView
				contentContainerStyle={{
					flexGrow: 1,
					width: windowSize.width < 800 ? "100%" : "70%",
					maxWidth: 900,
					alignSelf: "center",
					// alignItems: "center",
					// justifyContent: "center",
					// backgroundColor: "#fff",
					paddingHorizontal: 20,
					paddingBottom: 120,
				}}>
				{fetchingData ? (
					<View
						style={{
							alignItems: "center",
							padding: 5,
							backgroundColor: "#fff",
							borderRadius: 20,
							width: 100,
							alignSelf: "center",
						}}>
						<Text
							style={{
								color: "#bbb",
								fontFamily: "Gilroy-Regular",
							}}>
							Loading...
						</Text>
					</View>
				) : (
					<View>
						<View>{memoizedList}</View>
					</View>
				)}
			</ScrollView>
			{serviceToBeRated !== null ? (
				<BottomPopUpModal
					isVisible={rateModalVisibility}
					height={"80%"}
					animation={"fadeInUp"}
					onClose={() => {
						setRateModalVisibility(false);
						// setServiceToBeRated(null);
						setUsersRating(1);
					}}
					children={
						<ScrollView
							contentContainerStyle={{
								alignItems: "center",
								paddingBottom: 50,
							}}
							showsVerticalScrollIndicator={false}>
							<View>
								<View
									style={{
										padding: 6,
										flex: 7,
										alignItems: "center",
									}}>
									<Image
										source={
											serviceToBeRated.service.image ==
											null
												? no_image
												: {
														uri: serviceToBeRated
															.service.image,
												  }
										}
										style={{
											height: 100,
											width: 150,
											borderRadius: 7,
										}}
										resizeMode='cover'
									/>
									<View style={{ marginTop: 20 }}>
										<RowData
											title='Service name'
											value={
												serviceToBeRated.service.name
											}
										/>
										<RowData
											title='Provider'
											value={
												serviceToBeRated.service_center
													.name
											}
										/>
										<RowData
											title='Price'
											value={
												serviceToBeRated.service
													.display_price
											}
										/>
									</View>
								</View>
							</View>
							<View
								style={{
									alignItems: "center",
									marginVertical: 20,
								}}>
								{/* <Text
										style={{ fontSize: 12, color: "#ccc" }}>
										Touch to rate
									</Text> */}
								{TouchableStars}
							</View>
							<View style={{ width: 290 }}>
								<Text
									style={{
										margin: 10,
										fontFamily: "Gilroy-Regular",
									}}>
									Title
								</Text>
								<TextInput
									placeholder='Give a single line title.'
									style={{
										borderWidth: 1,
										borderRadius: 7,
										borderColor: "#ccc",
										padding: 10,
										width: "100%",
										maxWidth: 400,
										height: 40,
										textAlignVertical: "top",
										color: "black",
										outlineColor: "#7E98ED",
										fontFamily: "Gilroy-Regular",
									}}
									placeholderTextColor='#ccc'
									multiline={true}
									maxLength={35}
									onChangeText={(t) => setReviewTitle(t)}
								/>
								<Text
									style={{
										color: "red",
										fontFamily: "Gilroy-Regular",
									}}>
									{titleErrorText}
								</Text>
							</View>
							<View style={{ width: 290 }}>
								<Text
									style={{
										margin: 10,
										fontFamily: "Gilroy-Regular",
									}}>
									Your Review
								</Text>
								<TextInput
									placeholder='Describe your Experience'
									style={{
										borderWidth: 1,
										borderRadius: 7,
										borderColor: "#ccc",
										padding: 10,
										width: "100%",
										maxWidth: 600,
										height: 140,
										textAlignVertical: "top",
										color: "black",
										outlineColor: "#7E98ED",
										fontFamily: "Gilroy-Regular",
									}}
									placeholderTextColor='#ccc'
									multiline={true}
									maxLength={250}
									onChangeText={(t) => setReviewBody(t)}
								/>
								<Text
									style={{
										color: "red",
										fontFamily: "Gilroy-Regular",
									}}>
									{reviewErrorText}
								</Text>
							</View>
							{postingReview ? (
								<ActivityIndicator
									style={{ marginTop: 50 }}
									color={"#7E98ED"}
									size='small'
								/>
							) : (
								<SubmitButton
									title={"Submit"}
									style={{
										marginTop: 50,
									}}
									onPress={() => {
										if (
											reviewTitle.length == 0 ||
											reviewBody.length == 0
										) {
											reviewTitle.length == 0
												? setTitleErrorText(
														"Your review needs a title"
												  )
												: setTitleErrorText("");
											reviewBody.length == 0
												? setReviewErrorText(
														"Your review cannot be empty"
												  )
												: setReviewErrorText("");
											return;
										}
										setPostingInProgress(true);
										postReview();
									}}
								/>
							)}
						</ScrollView>
					}
				/>
			) : null}
			<TopFeedbackBar
				feedbackNature={feedbackNature}
				show={showFeedback}
				setShow={setShowFeedback}
				text={feedbackText}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "white",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	textInput: {
		borderBottomWidth: 1,
		borderBottomColor: "#ddd",
		width: "90%",
		paddingBottom: 0,
		alignSelf: "center",
	},
	whiteBox: {
		backgroundColor: "#fff",
		paddingVertical: 20,
		marginVertical: 5,
		borderBottomWidth: 1,
		borderBottomColor: "#ddd",
	},
	container: {
		backgroundColor: "white",
		padding: 16,
	},
	dropdown: {
		height: 30,
		width: 150,
		borderColor: "#ddd",
		borderWidth: 1,
		borderRadius: 20,
		paddingHorizontal: 8,
		marginLeft: 10,
		overflow: "hidden",
		outlineColor: "#7E98ED",
	},
	icon: {
		marginRight: 5,
	},
	label: {
		position: "absolute",
		backgroundColor: "white",
		left: 22,
		top: 8,
		zIndex: 999,
		paddingHorizontal: 5,
		fontSize: 14,
	},
	placeholderStyle: {
		fontSize: 14,
		color: "#ccc",
	},
	selectedTextStyle: {
		fontSize: 14,
	},
	iconStyle: {
		width: 20,
		height: 20,
	},
	inputSearchStyle: {
		height: 40,
		fontSize: 16,
		borderRadius: 12,
	},
});
export default MyOrdersScreen;
