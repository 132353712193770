import React, { useState, useEffect, useRef } from "react";
import {
	StyleSheet,
	Text,
	View,
	Platform,
	ToastAndroid,
	Alert,
	ScrollView,
	Dimensions,
	Image,
	FlatList,
	Linking,
	TouchableOpacity,
	TouchableHighlight,
} from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils";
import Clipboard from "@react-native-clipboard/clipboard";
import BottomPopUpModal from "../../components/Common/BottomPopUpModal.web";
// import Share from "react-native-share";

//components
import BackFab from "../../components/Common/BackFab";
import IconButton from "../../components/ClickableIcons/ClickableIcon";
import FloatingServiceCard from "../../components/Cards/FloatingServiceCard";

//assets
import no_image from "../../assets/Defaults/DCS_no_image.png";
import call_ic from "../../assets/Icons/DCS_call_ic.png";
import help_ic from "../../assets/Icons/DCS_help_ic.png";
import location_ic from "../../assets/Icons/DCS_location_ic.png";
import cross_ic from "../../assets/Icons/DCS_add_ic.png";
import share_ic from "../../assets/Icons/DCS_share_ic.png";
import OptionComponent from "../../components/WebOnly/OptionComponent";
import PlaceHolderShimmer from "../../components/Common/PlaceHolderShimmer.web";
import FloatingModal from "../../components/Common/FloatingModal.web";
import themes from "../../Styles/themes";
import NavigatableTextPrompt from "../../components/Common/NavigatableTextPrompt.js";

const CompanyServiceScreen = ({ route, navigation }) => {
	const { companyID } = route.params;
	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));
	const Stack = createNativeStackNavigator();
	const [companyData, setCompanyData] = useState();
	const [showModal, setShowModal] = useState(false);
	const [showGallery, setShowGallery] = useState(false);
	const [displayingGalleryImgObj, setDisplayingGalleryImgObj] = useState({
		id: 0,
		image: "",
	});

	const [containerWidth, setContainerWidth] = useState(300);

	const [fetchingData, setFetchingData] = useState(true);

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	const notifyMessage = (message) => {
		if (Platform.OS === "android") {
			ToastAndroid.show(message, ToastAndroid.SHORT);
		} else {
			Alert.alert(message);
		}
	};

	useEffect(() => {
		axiosInstance({
			method: "get",
			url: `auto-care/service-centers/${companyID}/`,
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					handleCompanyDetailsResponse(response.data);
				} else {
					notifyMessage(
						"Error connecting to our servers. Try again later."
					);
				}
			})
			.catch((onrejected) => {
				Utils.handleFailedResponse(
					"getServiceCenterByID",
					onrejected.response,
					onrejected.response.status,
					navigation
				);
			});
	}, []);

	const handleCompanyDetailsResponse = (response) => {
		setCompanyData(response);
		setFetchingData(false);
	};

	const renderOption = ({ item, i }) => {
		return (
			<OptionComponent
				key={i}
				imageUrl={item.image}
				onCardPress={() => {
					navigation.navigate("providedservice", {
						serviceID: item.id,
						companyID: companyID,
					});
				}}
				enableButton={true}
				buttonText={"Add Service"}
				onButtonPress={() => {
					navigation.navigate("bookservice", {
						serviceID: item.id,
						companyID: companyID,
						opts: false,
					});
				}}
				tag={item.tag || null}
				children={
					<View style={{ maxHeight: 88, overflow: "hidden" }}>
						<Text
							style={{
								fontFamily: "Gilroy-SemiBold",
								fontSize: 16,
							}}>
							{item.name}
						</Text>
						{item.description.split("\n").map((text, i) => {
							return (
								<Text
									key={i}
									style={{
										color: "#2A292E",
										fontFamily: "Gilroy-Regular",
									}}>
									{"\u2022 " + text}
								</Text>
							);
						})}
					</View>
				}
			/>
		);
	};

	const gallerImageItem = ({ item }) => {
		const isDisplayed = displayingGalleryImgObj.id == item?.id;
		return (
			<TouchableHighlight
				onPress={() => {
					setDisplayingGalleryImgObj(item);
				}}
				underlayColor={themes.secondaryColor}
				style={{
					height: 100,
					width: "100%",
					borderWidth: isDisplayed ? 2 : 0,
					borderColor: isDisplayed ? themes.primarySkyBlue : "#fff",
				}}>
				<Image
					source={item.image != null ? item.image : no_image}
					style={{ height: "100%", width: "100%" }}
					resizeMode='contain'
				/>
			</TouchableHighlight>
		);
	};

	return (
		<View style={styles.container}>
			<ScrollView
				showsVerticalScrollIndicator={false}
				contentContainerStyle={{
					flexGrow: 1,
					width: windowSize.width < 800 ? "100%" : "70%",
					maxWidth: 900,
					alignSelf: "center",
					paddingHorizontal: 20,
					backgroundColor: "#fff",
					paddingBottom: 200,
				}}>
				{/* parent scrollable body */}
				<View>
					<View
						style={{
							flexDirection:
								windowSize.width < 800 ? "column" : "row",
							padding: 10,
						}}>
						<View
							style={[
								{
									height: 200,
									width: 350,
								},
							]}>
							<Image
								source={
									fetchingData || companyData.image == null
										? no_image
										: { uri: companyData.image }
								}
								resizeMode='cover'
								style={{
									height: "100%",
									width: "100%",
									borderRadius: 7,
								}}
							/>
						</View>
						<View
							style={{
								flex: 1,
								padding: 10,
							}}>
							<View
								style={{
									flexDirection: "row",
									flex: 1,
									padding: 5,
								}}>
								{/* Company icon */}
								<View
									style={{
										flex: 1,
										alignItems: "center",
									}}>
									<View
										style={{
											height: 100,
											width: 100,
											borderColor: "#ccc",
											borderWidth: 1,
											borderRadius: 50,
											overflow: "hidden",
										}}>
										<Image
											source={
												fetchingData ||
												companyData.dealer_brand == null
													? no_image
													: {
															uri: companyData
																.dealer_brand
																.logo,
													  }
											}
											style={{
												height: "100%",
												width: "100%",
												backgroundColor: "#fff",
											}}
											resizeMode='contain'
										/>
									</View>
								</View>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "flex-end",
										position: "absolute",
										right: 0,
									}}>
									<View>
										{/* Ratings view */}
										{fetchingData ||
										companyData.rating == null ? (
											<PlaceHolderShimmer
												height={50}
												width={70}
												radius={10}
											/>
										) : (
											<View
												style={{
													backgroundColor: "#258C2A",
													width: 70,
													padding: 5,
													paddingStart: 7,
													borderRadius: 9,
													marginVertical: 7,
												}}>
												<Text
													style={{
														color: "white",
														fontFamily:
															"Gilroy-Regular",
													}}>
													{companyData.rating}
												</Text>
												<Text
													style={{
														color: "white",
														fontFamily:
															"Gilroy-SemiBold",
													}}>
													Rating
												</Text>
											</View>
										)}
										{/* Showroom images view */}
										{fetchingData ||
										companyData.gallery == null ||
										companyData.gallery.length ==
											0 ? null : (
											<TouchableOpacity
												onPress={() => {
													setShowGallery(true);
													setDisplayingGalleryImgObj(
														companyData.gallery[0]
													);
												}}
												style={{
													height: 48,
													width: 70,
													padding: 5,
													paddingStart: 7,
													borderRadius: 9,
													overflow: "hidden",
													backgroundColor: "grey",
													marginVertical: 7,
												}}>
												{/* one of the showroom image in bg */}
												<Image
													style={[
														{
															height: 48,
															width: 70,
															backgroundColor:
																"#000",
															opacity: 0.7,
														},
														styles.fixed,
													]}
													source={
														companyData.gallery[0]
															.image != null
															? companyData
																	.gallery[0]
																	.image
															: no_image
													}
													resizeMode='contain'
												/>
												<Text
													style={{
														color: "white",
														fontFamily:
															"Gilroy-Regular",
														zIndex: 1,
													}}>
													{companyData.gallery.length}
												</Text>
												<Text
													style={{
														color: "white",
														fontFamily:
															"Gilroy-SemiBold",
														zIndex: 1,
													}}>
													Photos
												</Text>
											</TouchableOpacity>
										)}
									</View>
								</View>
							</View>
							{/* bottom half of header, contains Company name and address */}
							<View
								style={{
									alignItems: "center",
									marginTop: 20,
								}}>
								<View
									style={{
										flexDirection: "row",
										width: "100%",
									}}>
									<View
										style={{
											alignItems: "center",
											flex: 9,
										}}>
										<Text
											style={{
												fontSize: 18,
												fontFamily: "Gilroy-SemiBold",
											}}>
											{fetchingData ? (
												<View>
													<PlaceHolderShimmer
														height={20}
														width={80}
														radius={5}
													/>
													<PlaceHolderShimmer
														height={40}
														width={180}
														radius={5}
													/>
												</View>
											) : (
												companyData.name
											)}
										</Text>
									</View>
									{/* call showroom button */}
									{!fetchingData ? (
										<View
											style={{
												paddingStart: 7,
												flex: 1,
											}}>
											<IconButton
												icon={call_ic}
												iconHeight={20}
												iconWidth={20}
												onPress={() => {
													setShowModal(
														(prev) => !prev
													);
												}}
											/>
										</View>
									) : (
										<PlaceHolderShimmer
											height={40}
											width={40}
											radius={10}
										/>
									)}
								</View>
								{fetchingData ? null : (
									<TouchableOpacity
										onPress={() => {
											const url = `https://www.google.com/maps/search/?api=1&query=${companyData.location.latitude},${companyData.location.longitude}(${companyData.name})`;

											// window.open(url, "_blank");
											Linking.openURL(url);
										}}
										style={{
											flexDirection: "row",
											marginTop: 5,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Image
											style={{
												height: 20,
												width: 20,
											}}
											source={location_ic}
											resizeMode='contain'
										/>
										<Text
											style={{
												maxWidth: "70%",
												marginLeft: 5,
												marginTop: 5,
												fontSize: 12,
												color: "#2693ff",
												fontFamily: "Gilroy-Regular",
											}}>
											{fetchingData
												? "Loading..."
												: companyData.location.address}
										</Text>
									</TouchableOpacity>
								)}
							</View>
						</View>
					</View>
					{/* body */}
					<View style={{ padding: 10 }}>
						<View
							onLayout={(e) => {
								var { x, y, width, height } =
									e.nativeEvent.layout;
								setContainerWidth(width);
							}}
							style={{
								marginStart: 10,
								marginTop: 12,
								marginBottom: 16,
							}}>
							<Text
								style={{
									fontSize: 16,
									fontFamily: "Gilroy-Regular",
								}}>
								Available Services
							</Text>
						</View>
						{!fetchingData ? (
							<View
								style={{
									flex: 1,
								}}>
								{companyData.sections.map((section, i) => {
									return section.items.length > 0 ? (
										<View
											key={i}
											style={{
												marginVertical: 10,
											}}>
											<Text
												style={{
													fontFamily:
														"Gilroy-SemiBold",
												}}>
												{section.name}
											</Text>
											<FlatList
												key={
													containerWidth +
													Math.random() * 1000
												}
												contentContainerStyle={{
													alignItems:
														Math.floor(
															containerWidth / 200
														) > 1
															? "flex-start"
															: "center",
												}}
												data={section.items}
												renderItem={renderOption}
												numColumns={Math.floor(
													containerWidth / 200
												)}
											/>
										</View>
									) : null;
								})}
							</View>
						) : (
							<View>
								<PlaceHolderShimmer
									height={20}
									width={100}
									radius={5}
								/>
								<View style={{ flexDirection: "row" }}>
									<PlaceHolderShimmer
										height={300}
										width={200}
										radius={10}
									/>
									<PlaceHolderShimmer
										height={300}
										width={200}
										radius={10}
									/>
								</View>
							</View>
						)}
					</View>
				</View>
			</ScrollView>
			{companyData != null && typeof companyData !== "undefined" ? (
				<BottomPopUpModal
					isVisible={showModal}
					onClose={() => setShowModal(false)}>
					<View style={{ alignSelf: "center" }}>
						<View style={{ flexDirection: "row" }}>
							<Image
								source={help_ic}
								style={{ height: 30, width: 30 }}
								resizeMode='contain'
							/>
							<NavigatableTextPrompt
								value={companyData.email}
								typeOf={"phone"}
							/>
						</View>
						<View
							style={{
								flexDirection: "row",
								marginTop: 10,
							}}>
							<Image
								source={call_ic}
								style={{ height: 30, width: 30 }}
								resizeMode='contain'
							/>
							<NavigatableTextPrompt
								value={companyData.phone}
								typeOf={"phone"}
							/>
						</View>
					</View>
				</BottomPopUpModal>
			) : null}
			{showGallery ? (
				<FloatingModal
					isVisible={true}
					height={windowSize.width < 800 ? "98%" : "80%"}
					contentStyle={{
						flexDirection:
							windowSize.width < 800 ? "column" : "row",
					}}
					modalTitle={"Gallery"}
					onClose={() => setShowGallery(false)}>
					<View style={{ flex: windowSize.width < 800 ? 6 : 8 }}>
						<Image
							style={{
								height: "100%",
								width: "100%",
							}}
							source={
								companyData.gallery != null
									? displayingGalleryImgObj.image
									: no_image
							}
							resizeMode='contain'
						/>
					</View>
					<View style={{ flex: windowSize.width < 800 ? 4 : 2 }}>
						<FlatList
							data={companyData.gallery}
							renderItem={gallerImageItem}
							scrollEnabled={true}
							showsVerticalScrollIndicator={true}
						/>
					</View>
				</FloatingModal>
			) : null}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// backgroundColor: "#fff",
	},
	backArrow: {
		position: "absolute",
		left: 30,
		top: 25,
		zIndex: 1,
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
});
export default CompanyServiceScreen;
