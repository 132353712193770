import React, { useState, useEffect, useRef } from "react";
import { StyleSheet, Text, View, Image } from "react-native";

import star_filled from "../../assets/Misc/DCS_star_filled_ic.png";

const CommentBox = ({ rating, title, body, name, date, styles }) => {
	return (
		<View
			style={[
				{
					flexDirection: "row",
					width: "85%",
					maxWidth: 450,
					backgroundColor: "#f2f8ff",
					borderRadius: 7,
					padding: 8,
				},
				styles,
			]}>
			{/* Ratings view */}
			<View
				style={{
					flexDirection: "row",
					backgroundColor: "#68C3A8",
					borderRadius: 5,
					padding: 3,
					paddingHorizontal: 6,
					alignItems: "center",
					height: 25,
					width: 50,
					justifyContent: "center",
				}}>
				<Image
					source={star_filled}
					style={{ tintColor: "white", height: 14, width: 14 }}
				/>
				<Text
					style={{
						color: "white",
						marginStart: 4,
						fontFamily: "Gilroy-Regular",
					}}>
					{rating}
				</Text>
			</View>
			<View
				style={{
					paddingHorizontal: 7,
					flex: 1,
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						width: "100%",
					}}>
					<Text
						style={{ fontSize: 16, fontFamily: "Gilroy-SemiBold" }}>
						"{title}"
					</Text>
					<Text
						style={{
							fontSize: 14,
							color: "#757A89",
							fontFamily: "Gilroy-Regular",
						}}>
						{name} | {date}
					</Text>
				</View>
				<Text
					style={{
						fontSize: 14,
						marginTop: 8,
						fontFamily: "Gilroy-Regular",
					}}>
					{body}
				</Text>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({});
export default CommentBox;
