import React, { useState, useEffect } from "react";
import {
	StyleSheet,
	Text,
	View,
	Dimensions,
	TouchableOpacity,
	ActivityIndicator,
	Image,
} from "react-native";
import QRCode from "react-qr-code";
import axiosInstance from "../../Utils/axiosGlobal";
import FirebaseWebInstance from "../../Utils/FirebaseWebInstance.web";
import { getAnalytics, logEvent } from "firebase/analytics";

//components
import Utils from "../../Utils/Utils.web";
import TopFeedbackBar from "../../components/WebOnly/TopFeedbackBar";

const GatePassByTokenScreen = ({ route, navigation }) => {
	const window = Dimensions.get("window");
	const screen = Dimensions.get("screen");
	const [dimensions, setDimensions] = useState({ window, screen });

	const { token } = route?.params;

	const [bannerText, setBannerText] = useState("");
	const [bannerNature, setBannerNature] = useState("warn");
	const [showBanner, setShowBanner] = useState(false);
	const [loadingState, setLoadingState] = useState("loading");
	const [gatepassCode, setGatepassCode] = useState();

	const analytics = FirebaseWebInstance.getInstance();

	useEffect(() => {
		hitApis();
	}, []);

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setDimensions({ window, screen });
			}
		);
		return () => subscription?.remove();
	}, []);

	const hitApis = () => {
		axiosInstance({
			method: "get",
			url: `auto-care/gatepass/token/${token}`,
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					handleGatePassResponse(response.data);
					setLoadingState("done");
				} else {
					notifyMessage(
						"Error connecting to our servers. Try again later."
					);
					setLoadingState("error");
				}
			})
			.catch((onrejected) => {
				Utils.handleFailedResponse(
					"getGatepassByToken",
					onrejected.response,
					onrejected.response.status,
					navigation,
					setBannerText
				);
				setLoadingState("error");
			});
	};

	const notifyMessage = (mssg) => {
		setBannerText(mssg);
		setBannerNature("error");
		setShowBanner(true);
	};

	const handleGatePassResponse = (data) => {
		setGatepassCode(data.secret);
		logEvent(analytics, "gatepass_access", {
			screen_name: "gatepass",
			params: token,
		});
	};

	const errorUI = () => {
		return (
			<View
				style={{
					marginTop:
						screen.width > 400 ? (screen.height * 10) / 100 : 70,
					paddingTop: 20,
					alignItems: "center",
				}}>
				<Text
					style={{
						width: "90%",
						maxWidth: 400,
						fontSize: 16,
						fontFamily: "Gilroy-Regular",
					}}>
					Unable to generate a gate pass, Try again later or contact
					support.
				</Text>
			</View>
		);
	};

	return (
		<View style={styles.container}>
			{showBanner ? (
				<TopFeedbackBar
					feedbackNature={bannerNature}
					setShow={setShowBanner}
					show={showBanner}
					text={bannerText}
				/>
			) : null}
			{token ? (
				<View
					style={{
						marginTop: 30,
						paddingTop: 20,
						alignItems: "center",
					}}>
					{loadingState == "done" ? (
						<View style={{ flex: 1, alignItems: "center" }}>
							<Text
								style={{
									width: "90%",
									maxWidth: 400,
									fontSize: 16,
									textAlign: "center",
									fontFamily: "Gilroy-Regular",
								}}>
								Please get this QR code scanned at the Gate to
								receive your serviced vehicle.
							</Text>
							<View style={{ marginTop: 20 }}>
								<QRCode value={gatepassCode} size={200} />
							</View>
						</View>
					) : loadingState == "loading" ? (
						<ActivityIndicator
							style={{ marginTop: 100 }}
							size={"large"}
						/>
					) : (
						errorUI()
					)}
				</View>
			) : (
				errorUI()
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
});

export default GatePassByTokenScreen;
