import React, { useState, useEffect } from "react";
import {
	StyleSheet,
	Text,
	View,
	Dimensions,
	ToastAndroid,
	Platform,
	Alert,
} from "react-native";
import MapView, { Marker } from "@alamoweb/react-native-web-mapview";
import Geolocation from "@react-native-community/geolocation";
import Utils from "../../Utils/Utils.web";
import axiosInstance from "../../Utils/axiosGlobal";

//component
import BackFab from "../../components/Common/BackFab";
import typography from "../../Styles/typography";
import SearchBar from "../../components/Utils/SearchBar";
import EmptyButton from "../../components/Buttons/NonFilledButton";

const Map = ({ route, navigation }) => {
	const window = Dimensions.get("window");
	const screen = Dimensions.get("screen");
	const [dimensions, setDimensions] = useState({ window, screen });
	const { showMap } = route.params;

	const [currentLocationTxt, setCurrentLocationText] = useState(
		"fetching address..."
	);
	const [cityName, setCityName] = useState("fetching address...");
	const [searchResults, setSearchResults] = useState([]);
	const [selectedResult, setSelectedResult] = useState("");
	const [latestLocation, setLatestLocation] = useState({
		lat: 0,
		lng: 0,
		coordinates: [],
	});

	const [selectedResultObj, setSelectedResultObj] = useState();

	const geoCodeAPIKey = "AIzaSyCMJ7dNH9RMMcZSNLVeUzfpcO7iwUtRqGE";

	function getAddressFromCoordinates(latitude, longitude) {
		console.log(
			"https://maps.googleapis.com/maps/api/geocode/json?address=" +
				latitude +
				"," +
				longitude +
				"&key=" +
				geoCodeAPIKey
		);
		return new Promise((resolve, reject) => {
			let finalUrl =
				"https://maps.googleapis.com/maps/api/geocode/json?address=" +
				latitude +
				"," +
				longitude +
				"&key=" +
				geoCodeAPIKey;
			fetch(finalUrl)
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.status === "OK") {
						resolve(responseJson?.results?.[0]?.formatted_address);
						setCurrentLocationText(
							responseJson?.results?.[0]?.formatted_address
						);
						setCityName(
							responseJson?.results?.[
								responseJson?.results.length - 2
							].formatted_address
						);
						setSelectedResultObj({
							name: "Home",
							latitude:
								responseJson.results[0].geometry.location.lat,
							longitude:
								responseJson.results[0].geometry.location.lng,
							address: responseJson.results[0].formatted_address,
							pin_code:
								responseJson.results[0].address_components[
									responseJson.results[0].address_components
										.length - 1
								].long_name,
							city: responseJson.results[0].address_components[3]
								.long_name,
							is_service_center_location: false,
						});
					} else {
						console.log(responseJson);
						// reject("not found");
					}
				})
				.catch((error) => {
					// reject(error);
				});
		});
	}

	const notifyMessage = (message) => {
		if (Platform.OS === "android") {
			ToastAndroid.show(message, ToastAndroid.SHORT);
		} else {
			Alert.alert(message);
		}
	};

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setDimensions({ window, screen });
			}
		);
		return () => subscription?.remove();
	}, []);

	useEffect(() => {
		Geolocation.getCurrentPosition(
			(position) => {
				setLatestLocation({
					lat: position.coords.latitude,
					lng: position.coords.longitude,
					coordinates: latestLocation.coordinates.concat({
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
					}),
				});
				getAddressFromCoordinates(
					position.coords.latitude,
					position.coords.longitude
				);
			},
			(error) => {
				console.log(error.message);
				if (
					error.message.includes(
						"User denied access to location services."
					)
				) {
					navigateBack();
					notifyMessage(
						"Location access has been denied.\nPlease Enable from Settings to make use of this feature."
					);
				} else {
					navigateBack();
					notifyMessage(
						"Timed out fetching location, Try again in some time."
					);
				}
			},
			{
				showLocationDialog: true,
				enableHighAccuracy: false,
				timeout: 10000,
			}
		);
	}, []);

	const searchLocationFrom = (text) => {
		if (text != null && text.length != 0) {
			new Promise((resolve, reject) => {
				let finalUrl =
					"https://maps.googleapis.com/maps/api/place/textsearch/json?query=" +
					text +
					"&key=AIzaSyAl2_vLktWygd-4r61fPZ7EHbHe-6uwV9k";
				fetch(finalUrl)
					.then((response) => response.json())
					.then((responseJson) => {
						if (responseJson.status === "OK") {
							resolve(
								responseJson?.results?.[0]?.formatted_address
							);
							setSearchResults(responseJson?.results);
						} else {
							reject("not found");
						}
					})
					.catch((error) => {
						reject(error);
					});
			});
		} else {
			setSearchResults([]);
		}
	};

	const navigateBack = () => {
		navigation.goBack();
	};

	const postNewAddress = () => {
		axiosInstance({
			method: "post",
			url: `/auto-care/locations/`,
			data: selectedResultObj,
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					showMap(false);
				} else {
					Utils.notifyMessage(
						"Error connecting to our servers. Try again later."
					);
				}
			})
			.catch((onrejected) => {
				showMap(false);
				Utils.handleFailedResponse(
					"postLocations",
					onrejected.response,
					onrejected.response.status,
					navigation
				);
			});
	};

	return (
		<View style={{ flex: 1 }}>
			<SearchBar
				placeholderText={"Search for location"}
				extraStyles={{
					marginTop: 0,
					marginBottom: 0,
					position: "absolute",
					top: 10,
					zIndex: 1,
					width: "70%",
					maxWidth: 360,
					alignSelf: "center",
				}}
				onChangeText={(t) => searchLocationFrom(t)}
				results={searchResults}
				setSelectedResult={setSelectedResult}
				setSelectedResultCoords={setLatestLocation}
				setSelectedResultObj={setSelectedResultObj}
				selectedCoords={{}}
			/>
			<MapView
				style={{ flex: 1 }}
				region={{
					latitude: latestLocation.lat,
					longitude: latestLocation.lng,
					latitudeDelta: 0.0922,
					longitudeDelta: 0.0421,
				}}>
				<Marker
					coordinate={{
						latitude: latestLocation.lat,
						longitude: latestLocation.lng,
					}}></Marker>
			</MapView>
			<View
				style={{
					backgroundColor: "#1D368899",
					padding: 10,
					borderRadius: 20,
					position: "absolute",
					bottom: 10,
					width: "90%",
					alignSelf: "center",
				}}>
				<View
					style={{
						width: screen.width < 350 ? 260 : 340,
						alignSelf: "center",
						paddingTop: 20,
					}}>
					<Text
						style={{
							fontSize: 12,
							color: "#fff",
							marginBottom: 10,
						}}>
						{selectedResult == ""
							? "Current location according to GPS"
							: "You selected..."}
					</Text>
					<Text
						style={{
							fontSize: 22,
							color: "#fff",
							fontWeight: "500",
							marginBottom: 10,
						}}>
						{selectedResult == "" ? cityName : selectedResult}
					</Text>
					<Text
						style={{
							fontSize: 12,
							color: "#fff",
							fontWeight: "500",
							marginBottom: 30,
						}}>
						{selectedResult == "" ? currentLocationTxt : ""}
					</Text>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "center",
						}}>
						<EmptyButton
							title={"Confirm location"}
							style={{
								backgroundColor: "#fff",
								justifyContent: "center",
								borderWidth: 0,
							}}
							onPress={() => {
								postNewAddress();
							}}
							fontStyle={{
								fontSize: 14,
								fontWeight: "500",
							}}
						/>
						{/* <EmptyButton
							title={"Search location"}
							style={{
								justifyContent: "center",
							}}
							onPress={() => {}}
							fontStyle={{
								fontSize: 14,
								fontWeight: "500",
								color: "#fff",
							}}
						/> */}
					</View>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	backArrow: {
		marginLeft: 10,
	},
});

export default Map;
