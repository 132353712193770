import React, { useState, useEffect, useRef, useMemo } from "react";
import {
	StyleSheet,
	Text,
	View,
	ScrollView,
	Image,
	Dimensions,
	TouchableOpacity,
	TouchableHighlight,
	Platform,
	FlatList,
	ActivityIndicator,
} from "react-native";
// import {
// 	NavigationContainer,
// 	useNavigationContainerRef,
// } from "@react-navigation/native";
// import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
// import { FlatList } from "react-native-gesture-handler";
import fontStyle from "../../Styles/typography";
import MemoImage from "../../components/Common/MemoizedImage";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils.web";
// import Spinner from "react-native-spinkit";
// import analytics from "@react-native-firebase/analytics";
import TopFeedbackBar from "../../components/WebOnly/TopFeedbackBar";

import { useLinkTo } from "@react-navigation/native";

//components
// import ClickableCard from "../../components/Cards/ClickableCard";
// import FloatingCard from "../../components/Cards/FloatingCard";
import ClickableCard2 from "../../components/Cards/ClickableCard2";
// import NonFloatingCard from "../../components/Cards/NonFloatingCard";
// import MainScreenAppBar from "../../components/Common/MainScreenAppBar";
import ButtonDark from "../../components/Buttons/SubmitButtonDark";
import EmptyButton from "../../components/Buttons/NonFilledButton";
import GenericDealershipComponent from "../../components/Common/GenericDealershipComponent";
import GenericPromoComponent from "../../components/Common/GenericPromoComponent";
import GenericServiceComponent from "../../components/Common/GenericServiceComponent";
import GenericServiceBookingComponent from "../../components/Common/GenericServiceBookingComponent";
import GenericServiceCenterComponent from "../../components/Common/GenericServiceCenterComponent";
import GenericYTVideoComponent from "../../components/Common/GenericYTVideoComponent";

//assets
import no_image_ic from "../../assets/Defaults/DCS_no_image.png";
import home_ic from "../../assets/Icons/DCS_home_tab_ic.png";
import arrows_ic from "../../assets/Icons/DCS_arrow_tab_ic.png";
import gears_ic from "../../assets/Icons/DCS_gears_tab_ic.png";
import car_ic from "../../assets/Icons/DCS_car_tab_ic.png";
import centre_tab_ic from "../../assets/Icons/DCS_tab_bar_ic.png";
import arrow_head_ic from "../../assets/Icons/DCS_arrow_head_ic.png";
import person_ic from "../../assets/Icons/DCS_person_with_bg_ic.png";

//screens
// import SettingsScreen from "./SettingsScreen";
// import OptedShowroomScreen from "./OptedShowroomScreen";
// import FastImage from "react-native-fast-image";
import BackFab from "../../components/Common/BackFab";
import NonFilledButton from "../../components/Buttons/NonFilledButton";
import GenericSearchBar from "../../components/Common/GenericSearchBar";
import GenericHeroComponent from "../../components/Common/GenericHeroComponent.web";

const HomeScreen = ({ navigation, route }) => {
	const [windowDim, setWindow] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));
	const tabIconArray = [home_ic, centre_tab_ic, gears_ic];
	// [home_ic, car_ic, centre_tab_ic, arrows_ic, gears_ic];
	const [currentIndex, setCurrentIndex] = useState(0);
	// const [dimensions, setDimensions] = useState({ window, screen });

	const [popularServiceData, setPopularServiceData] = useState([]);

	const [homeData, setHomeData] = useState([]);
	const [brandedHomeData, setBrandedHomeData] = useState([]);
	const [brandingInfo, setBrandingInfo] = useState({});

	const [ongoingServicesData, setOngoingServicesData] = useState([]);

	const [isBranded, setBranded] = useState(false);

	//possible states are: loading, done, error, fetching
	const [loadingState, setLoadingState] = useState("loading");

	const [locationData, setLocationData] = useState([]);
	const [userData, setUserData] = useState({});
	const [profileData, setProfileData] = useState({});

	const [completedServiceArr, setCompletedServiceArr] = useState([]);
	const [isGuestUserState, setGuestUserState] = useState(true);

	const rootNavigation = navigation;

	const ongoingServiceSVRef = useRef();
	const [searchStr, setSearchStr] = useState("");

	const [selectedAddress, setSelectedAddress] = useState("");

	const [updatingOngoingServices, setUpdatingOngoingServices] =
		useState(false);

	const [errorText, setErrorText] = useState(null);
	const [showError, setShowError] = useState(false);

	const [q, setQ] = useState(
		typeof route.params == "undefined" ? "" : route.params.q
	);

	const linkTo = useLinkTo();

	// const Tab = createBottomTabNavigator();
	// const navigationRef = useNavigationContainerRef();
	const routeNameRef = useRef();
	const webRef = useRef();

	const handleOngoingServicesUpdate = (response) => {
		let tempArr = [];

		//get completed services
		response.forEach((service) => {
			if (service.completed) {
				tempArr.push(service);
			}
		});
		//if more than one services completed
		if (tempArr.length > 0) {
			setOngoingServicesData(tempArr);
			setCompletedServiceArr(tempArr);
		} else {
			setOngoingServicesData(response);
			setCompletedServiceArr([]);
		}
		setUpdatingOngoingServices(false);
	};

	const updateOngoingServices = () => {
		setUpdatingOngoingServices(true);
		axiosInstance({
			method: "get",
			url: "auto-care/service-bookings/",
		})
			.then((response) => {
				try {
					if (response.status >= 200 && response.status < 300) {
						handleOngoingServicesUpdate(response.data.results);
					} else {
						Utils.notifyMessage(
							"Error connecting to our servers. Try again later."
						);
					}
				} catch (error) {
					console.log(error);
					setUpdatingOngoingServices(false);
				}
			})
			.catch((onrejected) => {
				setUpdatingOngoingServices(false);
				Utils.handleFailedResponse(
					"getServices",
					onrejected.response,
					onrejected.response.status,
					navigation
				);
			});
	};

	const hitApis = () => {
		setLoadingState("loading");
		axiosInstance({
			method: "get",
			url: `auto-care/home${q ? "?q=" + q : ""}`,
		})
			.then((response) => {
				try {
					if (response.status >= 200 && response.status < 300) {
						console.log(response.data);
						// if (homeData.length == 0) {
						handleHomeResponse(response.data);
						// }
					} else {
						setLoadingState("error");
						setShowError(true);
						Utils.handleFailedResponse(
							"getHome",
							response,
							response.status,
							navigation,
							setErrorText
						);
					}
				} catch (error) {
					console.log(error);
					setLoadingState("error");
					setShowError(true);
					Utils.handleFailedResponse(
						"getHome",
						response,
						response.status,
						navigation,
						setErrorText
					);
				}
			})
			.catch((onrejected) => {
				setLoadingState("error");
				setShowError(true);
				Utils.handleFailedResponse(
					"getHome",
					onrejected.response,
					onrejected.response.status,
					navigation,
					setErrorText
				);
			});

		if (!isGuestUserState) {
			axiosInstance({
				method: "get",
				url: `auto-care/locations/`,
			})
				.then((response) => {
					if (response.status >= 200 && response.status < 300) {
						try {
							handleLocationResponse(response.data);
						} catch (error) {
							console.log(error);
						}
					} else {
						Utils.notifyMessage(
							"Error connecting to our servers. Try again later."
						);
					}
				})
				.catch((onrejected) => {
					Utils.handleFailedResponse(
						"getLocations",
						onrejected.response,
						onrejected.response.status,
						navigation
					);
				});

			axiosInstance({
				method: "get",
				url: "accounts/dj-rest-auth/user/",
			})
				.then((response) => {
					if (response.status >= 200 && response.status < 300) {
						try {
							setUserData(response.data);
						} catch (error) {
							console.log(error);
						}
					} else {
						Utils.notifyMessage(
							"Error connecting to our servers. Try again later."
						);
					}
				})
				.catch((onrejected) => {
					Utils.handleFailedResponse(
						"getUser",
						onrejected.response,
						onrejected.response.status,
						navigation
					);
				});

			axiosInstance({
				method: "get",
				url: "accounts/user/profile/",
			})
				.then((response) => {
					try {
						if (response.status >= 200 && response.status < 300) {
							setProfileData(response.data[0]);
						} else {
							Utils.notifyMessage(
								"Error connecting to our servers. Try again later."
							);
						}
					} catch (error) {
						console.log(error);
					}
				})
				.catch((onrejected) => {
					Utils.handleFailedResponse(
						"getProfile",
						onrejected.response,
						onrejected.response.status,
						navigation
					);
				});
		} else {
			setLocationData("guest");
			setProfileData("guest");
			setUserData("guest");
		}
	};

	useEffect(() => {
		/**
		 * On web version we need to check for user session validity on page refresh as the axios default headers don't
		 * persist page resfresh, so this is an additional step and needs to be done on all pages.
		 */
		Utils.isGuestOrUser(navigation).then((result) => {
			console.log(result);
			if (
				result == null ||
				typeof result == "undefined" ||
				result == "guest"
			) {
				setGuestUserState(true);
				hitApis();
			} else {
				axiosInstance.defaults.headers.common["Authorization"] = result;
				hitApis();
			}
		});
	}, []);

	const handleLocationResponse = (response) => {
		if (response.length > 0) {
			let defaultAddress = "";
			response.forEach((addressObj) => {
				if (addressObj.is_default) {
					defaultAddress = addressObj.address;
				}
			});
			setSelectedAddress(defaultAddress);
			setLocationData(response);
		} else {
			setSelectedAddress("");
		}
	};

	/**
	 *
	 * @param {*} addressObj
	 * @param {*} action
	 */
	const updateAddress = (addressObj) => {
		setLocationData(addressObj);

		let defaultAddress = "";
		addressObj.forEach((obj) => {
			if (obj.is_default) {
				defaultAddress = obj.address;
			}
		});
		setSelectedAddress(defaultAddress);
	};

	const updateProfileOrUser = (type, obj) => {
		if (type == "user") {
			setUserData({ ...userData, email: obj });
		} else {
			setProfileData({ ...profileData, ...obj });
		}
	};

	const GenericCustomServices = ({ data }) => {
		return (
			<View>
				{data.items.length > 0 ? (
					<View>
						<Text
							style={[
								fontStyle.fontTitle,
								{ marginLeft: 20, marginTop: 10 },
							]}>
							{data.name}
						</Text>
						<View style={{ padding: 10 }}>
							<ClickableCard2
								borderColor='#7E98ED'
								icon={no_image_ic}
								onPress={() => console.log("type 1 service")}
								titleText='type 1'
								additionalText='100 others chose this'
							/>
						</View>
					</View>
				) : null}
			</View>
		);
	};

	const GenericAdBannerComponent = ({ data }) => {
		return (
			<View>
				{data.items.length > 0 ? (
					<View
						style={{
							flexDirection: "row",
							height: 70,
							width: screen.width - 40,
							padding: 10,
							borderColor: "#888",
							borderWidth: 0.6,
							borderRadius: 12,
							alignSelf: "center",
						}}>
						<MemoImage
							source={no_image_ic}
							style={{
								height: 50,
								width: "40%",
							}}
							resizeMode='contain'
						/>
						<View
							style={{
								height: 45,
								width: 1,
								backgroundColor: "#7E98ED",
								opacity: 0.8,
								marginHorizontal: 15,
								alignSelf: "center",
							}}
						/>
						<View>
							<Text style={fontStyle.fontSmallRegularTitle}>
								advert title
							</Text>
							<Text style={fontStyle.fontSmallRegular}>
								advert description text
							</Text>
						</View>
					</View>
				) : null}
			</View>
		);
	};

	const handleHomeResponse = (data) => {
		try {
			data.forEach((obj) => {
				if (obj["type"] == "service" && obj.items.length > 0) {
					obj.items.push({ name: "extra" });
				}
				if (obj["type"] == "service_center" && obj.items.length > 0) {
					obj.items.push({
						name: "extra",
						filter: obj.items[0].filter,
					});
				}
				if (obj["type"] == "service_booking" && obj.items.length > 0) {
					handleOngoingServicesUpdate(obj.items);
				}
				return;
			});
		} catch (error) {
			console.log(error);
		}
		setHomeData(data);
		setLoadingState("done");
	};

	const launchSpecialScreen = () => {
		navigation.navigate("OptionListScreen", {
			typeOfList: "services",
			setSelectedService: null,
			extraData: {
				locationData: locationData,
				updateAddress: updateAddress,
			},
		});
		// : navigation.navigate("ServiceBookingScreen", {
		// 		serviceObj: null,
		// 		locationData: locationData,
		// 		updateAddress: updateAddress,
		// 		companyID: null,
		// 		isFastOrder: true,
		//   });
	};

	const MyTabBar = ({ state, descriptors, navigation }) => {
		return (
			<View style={[styles.bottomTab, styles.iosShadowUp]}>
				<View
					style={{
						width: "100%",
						maxWidth: 350,
						flexDirection: "row",
					}}>
					{state.routes.map((route, index) => {
						const { options } = descriptors[route.key];

						const isFocused = state.index === index;

						const onPress = () => {
							const event = navigation.emit({
								type: "tabPress",
								target: route.key,
							});

							if (!isFocused && !event.preventDefault) {
								setCurrentIndex(index);
								if (route.name == "Cars") {
									navigation.navigate(route.name, {
										updateAddress: updateAddress,
										locationData: locationData,
										selectedAddress: selectedAddress,
										setSelectedAddress: setSelectedAddress,
										userData: userData,
										profileData: profileData,
										updateUserProfile: updateProfileOrUser,
										nav: rootNavigation,
									});
								} else if (route.name == "Settings") {
									navigation.navigate(route.name, {
										userData: userData,
										profileData: profileData,
										updateUserProfile: updateProfileOrUser,
										nav: rootNavigation,
									});
								} else {
									navigation.navigate(route.name, {
										nav: rootNavigation,
									});
								}
							}
						};

						let centerTabIndex = 1; //2

						return index != centerTabIndex ? (
							<TouchableOpacity
								key={index}
								onPress={onPress}
								style={{
									flex: 1,
									alignItems: "center",
									justifyContent: "center",
								}}>
								<Image
									source={tabIconArray[index]}
									style={{
										tintColor: isFocused
											? "#0E1B46"
											: "#c2cfff",
										height: "40%",
										width: "40%",
									}}
									resizeMode='contain'
								/>
							</TouchableOpacity>
						) : (
							//Empty space below special floating button, otherwise the icons don't look even
							<View
								key={index}
								style={{ height: 45, width: 45 }}
							/>
						);
					})}
				</View>
				<View
					style={[
						{
							height: 60,
							width: 60,
							position: "absolute",
							borderRadius: 50,
							zIndex: 99,
							elevation: 2,
							top: -20,
							overflow: "hidden",
						},
						styles.iosShadowDown,
					]}>
					<TouchableHighlight
						onPress={() => {
							launchSpecialScreen();
						}}
						underlayColor='#fff'
						style={{
							flex: 1,
							alignItems: "center",
							justifyContent: "center",
						}}>
						<Image
							source={centre_tab_ic}
							style={{
								height: "100%",
								width: "100%",
							}}
							resizeMode='contain'
						/>
					</TouchableHighlight>
				</View>
			</View>
		);
	};

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setWindow(window);
				// setDimensions({ window, screen });
			}
		);
		return () => subscription?.remove();
	}, []);

	const goToDealerScreen = (code) => {
		linkTo(`/${code}`);
	};

	const getHomeScreenData = (item) => {
		return item.map((view) => {
			switch (view.type) {
				case "hero":
					return (
						<GenericHeroComponent
							data={view}
							props={{ screen, navigation }}
						/>
					);
				case "promo":
					return (
						<GenericPromoComponent
							data={view}
							props={{ screen, navigation }}
						/>
					);
				case "service_booking":
					return (
						<GenericServiceBookingComponent
							data={ongoingServicesData}
							props={{
								name: view.name,
								screen,
								navigation,
								ongoingServiceSVRef,
								onRefreshClick: () => {
									updateOngoingServices();
								},
								isLoading: updatingOngoingServices,
							}}
						/>
					);
				case "service":
					return (
						<GenericServiceComponent
							data={view}
							props={{
								windowDim,
								navigation,
							}}
						/>
					);
				case "service_center":
					return (
						<GenericServiceCenterComponent
							data={view}
							props={{
								navigation,
							}}
						/>
					);
				case "ad":
					return <GenericAdBannerComponent data={view} />;
				case "dealership":
					return (
						<GenericDealershipComponent
							data={view}
							props={{
								navigation,
								onPress: goToDealerScreen,
							}}
						/>
					);
				case "video":
					return (
						<GenericYTVideoComponent
							data={view}
							props={{ windowDim }}
						/>
					);

				default:
					return null;
			}
		});
	};

	const HomeScreen = ({}) => {
		const renderHomeView = ({ item }) => {
			return <View key={Math.random() * 100}>{item}</View>;
		};

		const renderErrorScreen = (errorText) => {
			return (
				<View
					style={{
						height: windowDim.height,
						justifyContent: "center",
						alignItems: "center",
						paddingBottom: 0,
					}}>
					<Text>{errorText}</Text>
					<EmptyButton
						title={"Retry"}
						onPress={() => {
							hitApis();
						}}
						style={{ width: 80, marginTop: 10 }}
					/>
				</View>
			);
		};

		return (
			<View
				style={{
					flex: 1,
					backgroundColor: "white",
					width: screen.width < 800 ? screen.width : "70%",
					maxWidth: 900,
					alignSelf: "center",
					paddingHorizontal: 10,
				}}>
				{loadingState == "error" ? (
					renderErrorScreen("Something went wrong, Try again later")
				) : loadingState == "loading" ? (
					<View
						style={{
							height: windowDim.height,
							justifyContent: "center",
							alignItems: "center",
							paddingBottom: 0,
						}}>
						<ActivityIndicator
							animating={loadingState != "done"}
							size={"large"}
							color={"#7E98ED"}
						/>
						<Text style={{ marginVertical: 10, color: "#bbb" }}>
							Loading, please wait...
						</Text>
					</View>
				) : (
					<View>
						<FlatList
							scrollEnabled={true}
							contentContainerStyle={{
								backgroundColor: "white",
								paddingBottom: 150,
							}}
							showsVerticalScrollIndicator={false}
							data={getHomeScreenData(homeData)}
							renderItem={renderHomeView}
						/>
					</View>
				)}
			</View>
		);
	};

	return (
		<ScrollView scrollEnabled={true}>
			<HomeScreen />
			<TopFeedbackBar
				feedbackNature={"error"}
				show={showError}
				setShow={setShowError}
				text={errorText}
			/>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	appBar: {
		flexDirection: "row",
		padding: 5,
		height: 50,
		justifyContent: "space-between",
		backgroundColor: "white",
		borderBottomWidth: 1,
		borderBottomColor: "#ddd",
		elevation: 5,
	},
	bottomTab: {
		flexDirection: "row",
		backgroundColor: "white",
		position: "absolute",
		bottom: 0,
		width: "100%",
		height: Platform.OS == "ios" ? 85 : 70,
		justifyContent: "center",
		elevation: 10,
		borderTopWidth: 1,
		borderTopColor: "#eee",
		paddingBottom: Platform.OS == "ios" ? 20 : 0,
	},
	iosShadowDown: {
		shadowColor: "#222",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.17,
		shadowRadius: 2,
	},
	iosShadowUp: {
		shadowColor: "#222",
		shadowOffset: {
			width: 0,
			height: -2,
		},
		shadowOpacity: 0.1,
		shadowRadius: 2,
	},
	searchBar: {
		height: 40,
		width: "100%",
		maxWidth: 500,
		marginVertical: 10,
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		alignSelf: "center",
		borderWidth: 2,
		borderColor: "#E5E5E5",
		paddingHorizontal: 15,
		borderRadius: 15,
	},
});

export default HomeScreen;
