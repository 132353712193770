import React from "react";
import { Platform, ToastAndroid, Alert } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axiosInstance from "./axiosGlobal";
import moment from "moment";
import { useLinkTo } from "@react-navigation/native";

const Utils = (function () {
	let utilityFunctions = {};

	const weekJson = [
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
		"Sunday",
	];
	const monthJson = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	const millisInDay = 86400000;
	const millisInMinute = 1000 * 60;
	const millisInHour = millisInMinute * 60;
	const millisInWeek = millisInDay * 7;

	utilityFunctions.getHooks = function (ofType) {
		const linkTo = useLinkTo();
		switch (ofType) {
			case "link":
				return linkTo;

			default:
				break;
		}
	};

	utilityFunctions.getReadableDate = function (longTime) {
		const date = new Date(longTime);
		return (
			weekJson[date.getDay() - 1] +
			", " +
			date.getDate() +
			" " +
			monthJson[date.getMonth()]
		);
	};

	utilityFunctions.getLocalTimeFromUTC = function (utcTime, format) {
		let year = utcTime.substring(0, 10);
		let yearArr = year.split("-");
		year = yearArr[1] + "/" + yearArr[2] + "/" + yearArr[0];
		utcTime = year + " " + utcTime.substring(11);
		return moment(utcTime).format(format);
	};

	utilityFunctions.timeAgotext = function (millis) {
		let currentTimeMillis = new Date().getTime();
		let timeDifference = currentTimeMillis - millis;
		if (timeDifference > millisInWeek) {
			return "More than a Week ago";
		} else if (
			timeDifference < millisInWeek &&
			timeDifference > millisInDay
		) {
			return `${Math.floor(timeDifference / 7 / 1000)} days ago`;
		} else if (
			timeDifference < millisInDay &&
			timeDifference > millisInHour
		) {
			return `${Math.floor(timeDifference / 24 / 1000)} hours ago`;
		} else if (timeDifference < millisInHour && timeDifference > 60000) {
			return `${Math.floor(timeDifference / 60 / 1000)} minutes ago`;
		} else {
			return `just now`;
		}
	};

	utilityFunctions.convert12Hto24HFormat = function (input) {
		let hour = parseInt(input.substring(0, 2));
		if (input.includes("PM")) {
			hour = hour + 12;
		}
		hour = hour < 10 ? "0" + hour : hour;
		return hour + input.substring(2).replace(" AM", "").replace(" PM", "");
	};

	utilityFunctions.getNumToMonth = function (num) {
		num = parseInt(num);
		return monthJson[num];
	};

	utilityFunctions.notifyMessage = function (message) {
		if (Platform.OS === "android") {
			ToastAndroid.show(message, ToastAndroid.SHORT);
		} else {
			Alert.alert(message);
		}
	};

	utilityFunctions.handleLogOut = function (navigation, logOutCallback) {
		navigation.reset({
			index: 0,
			routes: [{ name: "login" }],
		});
		axiosInstance.defaults.headers.common["Authorization"] = "";
		AsyncStorage.removeItem("auth-key");
		logOutCallback();
	};

	utilityFunctions.getConstructedUrl = function (path, queries, keywords) {
		// console.log("-----------START---------");
		// console.log("input = ", path, queries, keywords);
		let finalUrl = path + "";
		if (
			typeof queries !== "undefined" &&
			queries != null &&
			queries.length != 0
		) {
			finalUrl += "?";

			queries.forEach((query, i) => {
				if (
					typeof query !== "undefined" &&
					query != null &&
					query != ""
				) {
					if (i == 0) {
						finalUrl += "q=" + query;
					} else if (i == queries.length - 1) {
						finalUrl += query;
					} else {
						finalUrl += query + "&";
					}
				}
			});
		}

		if (
			typeof keywords !== "undefined" &&
			keywords != null &&
			keywords.length != 0
		) {
			if (!finalUrl.includes("?")) {
				finalUrl += "?";
			} else if (finalUrl.includes("?q=")) {
				finalUrl += "&";
			}
			keywords.forEach((keyword, i) => {
				if (
					typeof keyword !== "undefined" &&
					keyword != null &&
					keyword != ""
				) {
					keyword = keyword.replace("_as_", "=");
					if (i == keywords.length - 1) {
						finalUrl += keyword;
					} else {
						finalUrl += keyword + "&";
					}
				}
			});
		}

		// console.log(finalUrl);
		// console.log("xxxxxxxxxENDxxxxxxxxx");

		return finalUrl;
	};

	utilityFunctions.convertBase64 = function (file) {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				resolve(fileReader.result);
			};
			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	};

	utilityFunctions.handleFailedResponse = function (
		apiName,
		response,
		status,
		navigation,
		setError
	) {
		console.log("-----------------START OF ERROR-----------------");
		console.log("Error hitting " + apiName);
		console.log(response);
		console.log("-----------------END OF ERROR-----------------");
		if (status == 401) {
			AsyncStorage.getItem("guestUser").then((key) => {
				if (key == null || key == "false") {
					// setError("Your session has expired \n Login again");
					// console.log(navigation);
					// this.handleLogOutWithNext(
					// 	navigation,
					// 	navigation.getState().routes[0].path,
					// 	"Your session has expired \n Login again"
					// );
				} else {
					console.log(
						"fix " +
							apiName +
							", no need to hit when logged as guest"
					);
				}
			});
		} else if (status == 503) {
			setError(
				`Could not connect to our servers, Make sure you have an internet access or Try again later [${status}]`
			);
		} else if (status > 401 && status < 500) {
			setError(
				`We could not process your request, Try again later [${status}]`
			);
		} else if (status == 408) {
			setError(
				`It's taking too long to process your request, Try again later [${status}]`
			);
		} else {
			setError(
				`Unable to process your request, Try again later [${status}]`
			);
			console.log(response.data);
		}
	};

	utilityFunctions.isGuestOrUser = async function (navigation) {
		return AsyncStorage.getItem("guestUser").then((key) => {
			//if the choice to proceed as guest has been made
			if (key != null && typeof key !== "undefined") {
				//if it was true
				if (key == "true") {
					console.log("as guest key:" + key);
					//go to home as guest
					return "guest";
				} else {
					console.log("not as guest key:" + key);
					return AsyncStorage.getItem("auth-key")
						.then((key) => {
							console.log("saved token =" + key);
							if (key !== null) {
								axiosInstance.defaults.headers.common[
									"Authorization"
								] = "Token " + key;

								//Check if session expired
								return axiosInstance({
									method: "get",
									url: `accounts/dj-rest-auth/user/`,
								})
									.then((response) => {
										return "Token " + key;
									})
									.catch((onrejected) => {
										console.log(onrejected);

										Utils.handleLogOut(navigation);

										Utils.notifyMessage(
											"There was an error logging in to your account. Please Login again"
										);
									});
							} else {
								//update user state to guest because we have lost token, so use needs to login back
								AsyncStorage.setItem("guestUser", "true");
								if (
									!window.location.pathname.startsWith(
										"/home"
									)
								) {
									navigation.reset({
										index: 0,
										routes: [{ name: "home" }],
									});
								}
							}
						})
						.catch((reject) => {
							console.log(reject);
							if (!window.location.pathname.startsWith("/home")) {
								navigation.reset({
									index: 0,
									routes: [{ name: "home" }],
								});
							}
						});
				}
			} else {
				console.log("no key:" + key);
				//if the choice wasn't made proceed to setup
				// navigation.navigate("setup");
				AsyncStorage.setItem("guestUser", "true").then(() => {
					axiosInstance.defaults.headers.common["Authorization"] = "";
					AsyncStorage.removeItem("auth-key");
					navigation.reset({
						index: 0,
						routes: [{ name: "home" }],
					});
				});
			}
		});
	};

	// utilityFunctions.sendAnalyticsEvent = function (eventName, eventObj) {
	// 	analytics().logEvent(eventName, eventObj);
	// };

	return utilityFunctions;
})();

export default Utils;
