import React from "react";
import axios from "axios";

// const axiosGlobal = (function () {
// 	let axiosFunctions = {};
// 	//Update baseUrl here
// 	let baseURL = "https://api.accessai.co:5001";
// 	let Authorization = "c8dada704e2d16bfd393feacc8859eaf7b0a2db5";

// 	const axiosInstance = axios.create({ baseURL: baseURL });

// 	axiosFunctions.postRequestNoKey = function (
// 		url,
// 		method,
// 		requestBody,
// 		handleResponse,
// 		handleError
// 	) {
// 		axios({
// 			method: method,
// 			url: url,
// 			data: requestBody,
// 			baseURL: baseURL,
// 		})
// 			.then(handleResponse)
// 			.catch(handleError);
// 	};

// 	axiosFunctions.postRequestWithKey = function (
// 		url,
// 		method,
// 		requestBody,
// 		handleResponse,
// 		handleError
// 	) {
// 		axios({
// 			method: method,
// 			url: url,
// 			data: requestBody,
// 			baseURL: baseURL,
// 			headers: {
// 				Authorization: key,
// 			},
// 		})
// 			.then(handleResponse)
// 			.catch(handleError);
// 	};

// 	return axiosFunctions;
// })();

//prod url:https://api.accessai.co:5002/
//dev url: https://api.accessai.co:5001/
const axiosInstance = axios.create({
	baseURL: "https://api.accessai.co:5002/",
});

export default axiosInstance;
