import React, { useMemo } from "react";
import { View, Image } from "react-native";

const MemoizedImage = ({ source, style, resizeMode }) => {
	const memoImage = useMemo(() => {
		return <Image source={source} style={style} resizeMode={resizeMode} />;
	}, []);
	return <View>{memoImage}</View>;
};

export default MemoizedImage;
