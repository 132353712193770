import React, { useEffect, useRef } from "react";
import { StyleSheet, View, Text } from "react-native";
import { Animated } from "react-native-web";

const TopFeedbackBar = ({ feedbackNature, text, show, setShow }) => {
	const animOpacity = useRef(new Animated.Value(0)).current;
	useEffect(() => {
		if (show) {
			Animated.timing(animOpacity, {
				toValue: 1,
				duration: 250,
				useNativeDriver: false,
			}).start();
			setTimeout(() => {
				setTimeout(() => setShow(false), 250);
				Animated.timing(animOpacity, {
					toValue: 0,
					duration: 250,
					useNativeDriver: false,
				}).start();
			}, 5000);
		}
	}, [show]);

	return (
		<Animated.View
			style={[
				styles.top,
				{
					opacity: animOpacity,
					backgroundColor:
						feedbackNature == "error"
							? "#ed3737"
							: feedbackNature == "success"
							? "#2bc253"
							: "#d6ac22",
					zIndex: 999,
				},
			]}>
			<Text
				style={{
					color: "#fff",
					maxWidth: 800,
					fontFamily: "Gilroy-Regular",
				}}>
				{text}
			</Text>
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	top: {
		flexDirection: "row",
		paddingHorizontal: 10,
		top: 0,
		position: "absolute",
		paddingVertical: 10,
		width: "100%",
		alignItems: "center",
		justifyContent: "center",
	},
});

export default TopFeedbackBar;
