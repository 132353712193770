import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, FlatList, Dimensions } from "react-native";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils.web";

//components
import BandButton from "../../components/Buttons/BandButton";
import AddressBox from "../../components/Utils/AddressBox";
import PopupDialogBox from "../../components/Common/PopupDialogBox.web";
import BottomPopUpModal from "../../components/Common/BottomPopUpModal.web";
import EditAddressScreen from "./EditAddressScreen.web";
import TopFeedbackBar from "../../components/WebOnly/TopFeedbackBar";

//assets
import home_ic from "../../assets/Icons/DCS_home_tab_ic.png";
import office_ic from "../../assets/Icons/DCS_work_ic.png";

const SavedAddressScreen = ({ route, navigation }) => {
	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));

	const [fetchingData, setFetchingData] = useState(true);
	const [addressData, setAddressData] = useState([]);
	const [dialogVisiblity, setDialogVisibility] = useState(false);
	const [deleteID, setDeleteID] = useState(-1);
	const [isGuestUserState, setGuestUserState] = useState(false);
	const [showEditAddressModal, setShowEditAddressModal] = useState(false);
	const [selectedAddress, setSelectedAddress] = useState(null);

	const [feedbackNature, setFeedbackNature] = useState("warn");
	const [feedbackText, setFeedbackText] = useState("");
	const [showFeedback, setShowFeedback] = useState(false);

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	useEffect(() => {
		/**
		 * On web version we need to check for user session validity on page refresh as the axios default headers don't
		 * persist page resfresh, so this is an additional step and needs to be done on all pages.
		 */
		Utils.isGuestOrUser(navigation).then((result) => {
			if (
				result == null ||
				typeof result == "undefined" ||
				result == "guest"
			) {
				setGuestUserState(true);
			} else {
				axiosInstance.defaults.headers.common["Authorization"] = result;
				hitApis(false);
				setGuestUserState(false);
			}
		});
	}, []);

	const hitApis = () => {
		axiosInstance({
			method: "get",
			url: `auto-care/locations/`,
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					handleLocationResponse(response.data);
				} else {
					setFeedbackNature("error");
					setFeedbackText("Error connecting to our server.");
					setShowFeedback(true);
				}
			})
			.catch((onrejected) => {
				Utils.handleFailedResponse(
					"getLocations",
					onrejected.response,
					onrejected.response.status,
					navigation,
					setShowFeedback
				);
			});
	};

	const updateAddress = () => {};

	const handleLocationResponse = (response) => {
		setAddressData(response);
		setFetchingData(false);
	};

	const renderAddress = ({ item }) => {
		return !item.is_default ? (
			<AddressBox
				key={item.id}
				addTypeIcon={item.location_type == "HOME" ? home_ic : office_ic}
				name={item.name}
				id={item.id}
				address={item.address}
				onPressDelete={() => {
					setDeleteID(item.id);
					setDialogVisibility(true);
				}}
				onPressEdit={() => {
					setSelectedAddress(item);
					setShowEditAddressModal(true);
				}}
			/>
		) : null;
	};

	const handleDeleteAddress = () => {
		const filteredData = addressData.filter((item) => item.id != deleteID);
		console.log("deleting addr id - " + deleteID);
		axiosInstance({
			method: "delete",
			url: `auto-care/locations/${deleteID}/`,
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					updateAddress(filteredData);
					setAddressData(filteredData);
					setDialogVisibility(false);
					setFeedbackNature("success");
					setFeedbackText("Successfully deleted the address.");
					setShowFeedback(true);
				} else {
					setFeedbackNature("error");
					setFeedbackText(
						"There was an error processing your request."
					);
					setShowFeedback(true);
				}
			})
			.catch((onrejected) => {
				Utils.handleFailedResponse(
					"deleteLocation",
					onrejected.response,
					onrejected.response.status,
					navigation,
					setShowFeedback
				);
				setDialogVisibility(false);
			});
	};

	const ErrorComponent = (text) => {
		return (
			<View
				style={{
					backgroundColor: "#eee",
					height: 40,
					justifyContent: "center",
					alignItems: "center",
					paddingHorizontal: 10,
					borderBottomWidth: 1,
					borderBottomColor: "#ddd",
				}}>
				<Text
					style={{ color: "#a9a9a9", fontFamily: "Gilroy-Regular" }}>
					{text}
				</Text>
			</View>
		);
	};

	const DefaultAddress = () => {
		var defaultAddress = addressData.map((obj, i) => {
			return obj.is_default ? (
				<AddressBox
					key={i}
					addTypeIcon={home_ic}
					address={obj.address}
					name={obj.name}
					id={obj.id}
					onPressDelete={() => {
						setDeleteID(obj.id);
						setDialogVisibility(true);
					}}
					onPressEdit={() => {
						setSelectedAddress(obj);
						setShowEditAddressModal(true);
					}}
				/>
			) : null;
		});

		return defaultAddress == null || defaultAddress.length == 0
			? ErrorComponent("No Default Address set")
			: defaultAddress;
	};

	return (
		<View style={{ flex: 1, flexDirection: "row" }}>
			{/* Body */}
			<View
				style={{
					backgroundColor: "#f0ecfc",
					flex: 1,
				}}>
				<BandButton
					onPress={() => {
						setSelectedAddress(null);
						setShowEditAddressModal(true);
					}}
					text={"Add New Address"}
					style={{
						marginTop: 10,
						fontFamily: "Gilroy-SemiBold",
						width: windowSize.width < 800 ? "100%" : "70%",
						maxWidth: 900,
						alignSelf: "center",
						borderRadius: 7,
					}}
				/>
				<FlatList
					contentContainerStyle={{
						flexGrow: 1,
						width: windowSize.width < 800 ? "100%" : "70%",
						maxWidth: 900,
						alignSelf: "center",
						backgroundColor: "#fff",
						paddingHorizontal: 20,
						paddingBottom: 220,
						marginTop: 7,
						borderTopRightRadius: 7,
						borderTopLeftRadius: 7,
					}}
					ListHeaderComponent={
						<View>
							<View>
								<View
									style={{
										backgroundColor: "#fff",
										height: 40,
										justifyContent: "center",
										paddingHorizontal: 10,
										borderBottomWidth: 1,
										borderBottomColor: "#ddd",
										marginTop: 10,
									}}>
									<Text
										style={{
											color: "#585C74",
											fontFamily: "Gilroy-Regular",
										}}>
										Default Address
									</Text>
								</View>

								{DefaultAddress()}

								<View
									style={{
										backgroundColor: "#fff",
										height: 40,
										justifyContent: "center",
										paddingHorizontal: 10,
										borderBottomWidth: 1,
										borderBottomColor: "#ddd",
										marginTop: 10,
									}}>
									<Text
										style={{
											color: "#585C74",
											fontFamily: "Gilroy-Regular",
										}}>
										Other Addresses
									</Text>
								</View>
							</View>
						</View>
					}
					ListFooterComponent={
						<FlatList
							data={addressData}
							renderItem={renderAddress}
							keyExtractor={(item) => item.id}
							scrollEnabled={false}
						/>
					}
				/>
			</View>
			<PopupDialogBox
				titleText={"Delete Address"}
				messageText={"Do you want to delete this address?"}
				positiveOptionText={"Yes"}
				negativeOptionText={"No"}
				onPositiveSelection={() => handleDeleteAddress()}
				onNegativeSelection={() => setDialogVisibility(false)}
				visibility={dialogVisiblity}
				showButtons={true}
			/>
			<BottomPopUpModal
				isVisible={showEditAddressModal}
				height={"90%"}
				children={
					<EditAddressScreen
						navigation={navigation}
						route={{
							params: {
								address: selectedAddress,
								addressData: addressData,
								setAddressData: setAddressData,
								asPopup: true,
								showAddressPopup: setShowEditAddressModal,
							},
						}}
					/>
				}
				onClose={() => setShowEditAddressModal(false)}
			/>
			<TopFeedbackBar
				feedbackNature={feedbackNature}
				setShow={setShowFeedback}
				show={showFeedback}
				text={feedbackText}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "white",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
});
export default SavedAddressScreen;
