import React from "react";
import { StyleSheet, View, Image } from "react-native";

//assets
import logo from "../../assets/Images/ic_launcher-playstore.png";

const PlainHeader = () => {
	return (
		<View style={styles.top}>
			<View
				style={{
					height: 40,
					width: 80,
				}}>
				<Image
					source={logo}
					style={{
						height: "100%",
						width: "100%",
						overflow: "hidden",
						borderRadius: 200,
					}}
					resizeMode='cover'
				/>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	top: {
		flexDirection: "row",
		alignItems: "center",
		paddingHorizontal: 10,
		height: 50,
		borderBottomWidth: 1,
		borderBottomColor: "#ccc",
		backgroundColor: "#fff",
	},
});

export default PlainHeader;
