import React from "react";
import { Image, View } from "react-native";
import def_img from "../../assets/Defaults/DCS_no_image.png";
import { RMIUploader } from "react-multiple-image-uploader";

const MultiImageCollector = ({ onUpload, uploadedImages }) => {
	return (
		// the styles are added to hide the right section which is buggy from library side
		<View
			style={{
				height: 320,
				width: 350,
				overflow: "hidden",
			}}>
			<View
				style={{
					width: 800,
					position: "absolute",
					left: -5,
					top: -20,
				}}>
				<RMIUploader onUpload={onUpload} dataSources={uploadedImages} />
			</View>
		</View>
	);
};

export default MultiImageCollector;
