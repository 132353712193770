import React, { useState, useEffect, useRef } from "react";
import {
	StyleSheet,
	Text,
	View,
	ScrollView,
	Dimensions,
	Image,
	Platform,
	ToastAndroid,
	Alert,
	TouchableOpacity,
} from "react-native";
// import Share from "react-native-share";

//components
import BackFab from "../../components/Common/BackFab";
import RatingFiveStar from "../../components/Utils/RatingFiveStar";
import CommentBox from "../../components/Utils/CommentBox";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils";

//assets
import no_image from "../../assets/Defaults/DCS_no_image.png";
// import share_ic from "../../assets/Icons/DCS_share_ic.png";
import check_ic from "../../assets/Icons/DCS_round_bordered_check.png";
import arrow_ic from "../../assets/Icons/DCS_arrow_head_ic.png";
import PlaceHolderShimmer from "../../components/Common/PlaceHolderShimmer.web";

const SpecificServiceDetailScreen = ({ route, navigation }) => {
	const { serviceID, serviceObj, companyID } = route.params;
	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));
	const scrollView = useRef();

	const [servicesData, setServicesData] = useState(null);
	//possible states, "loading", "error", "done"
	const [loadingState, setLoadingState] = useState("loading");

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	useEffect(() => {
		setLoadingState("loading");
		axiosInstance({
			method: "get",
			url: `auto-care/services/${serviceID}/`,
		})
			.then((response) => {
				try {
					if (response.status >= 200 && response.status < 300) {
						handleServiceDetailsResponse(response.data);
					} else {
						setLoadingState("error");
					}
				} catch (error) {
					setLoadingState("error");
					console.log(error);
				}
			})
			.catch((onrejected) => {
				setLoadingState("error");
				Utils.handleFailedResponse(
					"getServicesByID",
					onrejected.response,
					onrejected.response.status,
					navigation
				);
			});
	}, []);

	const calculateAverageRating = (array) => {
		let sumOfRating = 0.0;
		array.forEach((review) => {
			sumOfRating += review.rating;
		});
		return (sumOfRating / array.length).toFixed(2);
	};

	const handleServiceDetailsResponse = (response) => {
		setServicesData(response);
		setLoadingState("done");
	};

	return (
		<View style={styles.container}>
			<ScrollView
				showsVerticalScrollIndicator={false}
				contentContainerStyle={{
					flexGrow: 1,
					paddingBottom: window.width > 500 ? 60 : "40%",
					width: window.width < 800 ? window.width : "70%",
					maxWidth: 900,
					alignSelf: "center",
					backgroundColor: "#fff",
					paddingHorizontal: 20,
				}}>
				{/* Top image section */}
				<View
					style={{
						flexDirection:
							window.width < 800 ? "column-reverse" : "row",
						paddingVertical: 20,
						alignItems: "center",
					}}>
					{loadingState !== "done" ? (
						<View style={{ marginStart: 25, marginVertical: 20 }}>
							{loadingState == "error" ? (
								<Text>---</Text>
							) : (
								<View>
									<PlaceHolderShimmer
										height={20}
										width={140}
										radius={5}
									/>
									<PlaceHolderShimmer
										height={18}
										width={120}
										radius={5}
									/>
									<PlaceHolderShimmer
										height={18}
										width={80}
										radius={5}
									/>
									<View style={{ height: 40 }} />
									<PlaceHolderShimmer
										height={18}
										width={200}
										radius={5}
									/>
								</View>
							)}
						</View>
					) : (
						<View
							style={{
								backgroundColor: "white",
								justifyContent: "center",
								flex: 1,
							}}>
							<View
								style={{
									marginStart: 25,
									marginTop: 20,
								}}>
								<Text
									style={{
										fontSize: 20,
										fontFamily: "Gilroy-Regular",
									}}>
									{servicesData.name}
								</Text>
								{servicesData.display_price !== null &&
								servicesData.display_price !== "" ? (
									<View>
										<Text
											style={{
												fontSize: 15,
												color: "#585C74",
												marginTop: 7,
												fontFamily: "Gilroy-Regular",
											}}>
											{"By " +
												servicesData.service_centers[0]
													.name}
										</Text>
										<Text
											style={{
												fontSize: 15,
												marginTop: 7,
												fontFamily: "Gilroy-Regular",
											}}>
											{" " + servicesData.display_price}
										</Text>
									</View>
								) : null}
							</View>
							<View
								style={{
									marginStart: 25,
									marginTop: 20,
								}}>
								<Text
									style={{
										fontSize: 14,
										color: "#585C74",
										fontFamily: "Gilroy-Regular",
									}}>
									{servicesData.description}
								</Text>
							</View>
						</View>
					)}
					<View
						style={{
							flexDirection: "row",
							height: 200,
							width: 350,
						}}>
						<Image
							source={
								loadingState !== "done" ||
								servicesData.image == null
									? no_image
									: { uri: servicesData.image + "" }
							}
							resizeMode={
								loadingState !== "done" ||
								servicesData.image == null
									? "contain"
									: "cover"
							}
							style={{
								height: "100%",
								width: "100%",
								borderRadius: 7,
								overflow: "hidden",
							}}
						/>
					</View>
				</View>
				<View
					style={{
						flexDirection: window.width < 800 ? "column" : "row",
					}}>
					<View style={{ flex: 1 }}>
						<View
							style={{
								backgroundColor: "white",
								paddingStart: 25,
								paddingTop: 10,
								paddingBottom: 20,
							}}>
							<Text
								style={{
									fontSize: 18,
									fontFamily: "Gilroy-Regular",
								}}>
								Details
							</Text>
							{loadingState == "done" ? (
								<View>
									{servicesData.features
										.replace("- ", "")
										.split("\n")
										.map((item, i) => {
											return (
												<View
													key={i}
													style={{ marginTop: 8 }}>
													<View
														style={{
															flexDirection:
																"row",
														}}>
														<Image
															source={check_ic}
															style={{
																height: 20,
																width: 20,
															}}
															resizeMode='contain'
														/>
														<Text
															style={{
																fontSize: 15,
																marginStart: 10,
																color: "#585C74",
															}}>
															{item}
														</Text>
													</View>
												</View>
											);
										})}
								</View>
							) : (
								<PlaceHolderShimmer
									height={18}
									width={200}
									radius={5}
								/>
							)}
						</View>
						<View
							style={{
								backgroundColor: "white",
								paddingStart: 25,
								paddingTop: 10,
								paddingBottom: 20,
							}}>
							<Text
								style={{
									fontSize: 18,
									fontFamily: "Gilroy-Regular",
								}}>
								Terms and Conditions
							</Text>
							{loadingState == "done" ? (
								<View>
									{servicesData.terms != null &&
										servicesData.terms
											.replace("- ", "")
											.split("\n")
											.map((item, i) => {
												return (
													<View
														key={i}
														style={{
															marginTop: 8,
														}}>
														<View>
															<Text
																style={{
																	fontSize: 14,
																	marginStart: 10,
																	color: "#585C74",
																	fontFamily:
																		"Gilroy-Regular",
																}}>
																{`\u2022 ${item}`}
															</Text>
														</View>
													</View>
												);
											})}
								</View>
							) : (
								<View>
									<PlaceHolderShimmer
										height={18}
										width={200}
										radius={5}
									/>
									<PlaceHolderShimmer
										height={18}
										width={200}
										radius={5}
									/>
								</View>
							)}
						</View>
						<View
							style={{
								backgroundColor: "white",
								paddingStart: 25,
								paddingTop: 10,
								paddingBottom: 20,
								flex: 1,
							}}>
							<Text
								style={{
									fontSize: 18,
									fontFamily: "Gilroy-Regular",
								}}>
								Customer Reviews
							</Text>
							{loadingState !== "done" ? (
								<View>
									{loadingState == "error" ? (
										<Text>---</Text>
									) : (
										<PlaceHolderShimmer
											height={40}
											width={180}
											radius={5}
										/>
									)}
								</View>
							) : servicesData.reviews.length == 0 ? (
								<View style={{ marginTop: 10 }}>
									<Text
										style={{
											color: "#bbb",
											fontFamily: "Gilroy-Regular",
										}}>
										{"No Reviews :("}
									</Text>
								</View>
							) : (
								<View>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}>
										<RatingFiveStar
											styles={{ marginVertical: 8 }}
											rating={calculateAverageRating(
												servicesData.reviews
											)}
										/>
										<Text
											style={{
												fontSize: 16,
												color: "#7E98ED",
												fontFamily: "Gilroy-SemiBold",
												marginLeft: 8,
											}}>
											{calculateAverageRating(
												servicesData.reviews
											)}
										</Text>
										{/* <Text
											style={{
												fontSize: 14,
												color: "#7E98ED",
												marginStart: 3,
											}}>
											(10,000+ verified buyers)
										</Text> */}
									</View>
									{servicesData.reviews.map((review, i) => {
										return (
											<CommentBox
												key={i}
												rating={review.rating}
												styles={{ marginVertical: 5 }}
												title={review.title}
												body={review.comment}
												name={review.first_name}
												date={Utils.getLocalTimeFromUTC(
													review.created,
													"ddd Do MMM"
												)}
											/>
										);
									})}
								</View>
							)}
						</View>
					</View>
				</View>
			</ScrollView>
			{/* {loadingState !== "done" ? null : (
				<ProceedBarButton
					onPress={() =>
						selectedCenter == -1
							? notifyMessage("Please select a service center")
							: navigation.navigate("ServiceBookingScreen", {
									serviceObj: serviceObj,
									locationData: locationData,
									updateAddress: updateAddress,
									companyID:
										selectedCenter == -1
											? companyID
											: selectedCenter,
									isFastOrder: false,
							  })
					}
					proceedText={
						<View>
							<Text style={{ color: "white", fontSize: 26 }}>
								Place Order
							</Text>
						</View>
					}
				/>
			)} */}
			{loadingState == "done" && servicesData != null ? (
				<TouchableOpacity
					style={[
						{
							width: 200,
							borderRadius: 50,
							// maxWidth: 900,
							height: 70,
							position: "absolute",
							alignSelf: "flex-end",
							margin: 10,
							bottom: windowSize.width < 800 ? 120 : 70,
							// marginLeft: -10,
							backgroundColor: "#001A45",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							paddingHorizontal: 20,
						},
					]}
					onPress={() => {
						navigation.navigate("bookservice", {
							serviceID: serviceID,
							companyID: companyID,
							opts: false,
						});
					}}>
					<Text
						style={{
							width: "90%",
							color: "white",
							fontSize: 26,
						}}>
						Book it!
					</Text>
					<Image
						source={arrow_ic}
						style={{
							height: 25,
							width: 25,
							tintColor: "white",
							transform: [{ rotate: "-90deg" }],
						}}
						resizeMode='contain'
					/>
				</TouchableOpacity>
			) : null}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// backgroundColor: "white",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
});
export default SpecificServiceDetailScreen;
