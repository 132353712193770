import React, { useEffect, useState } from "react";
import { StyleSheet, Image, View, Text } from "react-native";
import { Link } from "@react-navigation/native";
import BorderedButton from "../components/Buttons/NonFilledButton";

import error_image from "../assets/Images/DCS_error_vector.png";
import logo from "../assets/Images/ic_launcher-playstore.png";

const ErrorPage = () => {
	return (
		<View style={styles.container}>
			<View style={styles.top}>
				<View
					style={{
						height: 40,
						width: 80,
					}}>
					<Image
						source={logo}
						style={{
							height: "100%",
							width: "100%",
							overflow: "hidden",
							borderRadius: 200,
						}}
						resizeMode='cover'
					/>
				</View>
			</View>
			<View style={{ flex: 1, alignItems: "center" }}>
				<Image
					source={error_image}
					style={{
						height: "100%",
						width: "80%",
						flex: 3,
						maxWidth: 400,
						maxHeight: 400,
					}}
					resizeMode='contain'
				/>
				<View
					style={{
						flex: 1,
						marginVertical: 20,
						alignItems: "center",
						width: "90%",
					}}>
					<Text
						style={{
							fontSize: 35,
							fontWeight: "500",
						}}>
						Page not found!
					</Text>
					<Text style={{ marginTop: 20, fontSize: 20 }}>
						Looks like you took a wrong turn. You can try going back
						home.
					</Text>
					<Link to='/home' style={{ marginTop: 20 }}>
						<BorderedButton title={"Go back home"} />
					</Link>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
	},
	top: {
		flexDirection: "row",
		alignItems: "center",
		paddingHorizontal: 10,
		height: 50,
		borderBottomWidth: 1,
		borderBottomColor: "#ccc",
	},
});

export default ErrorPage;
