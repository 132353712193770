import React, { useState } from "react";
import {
	StyleSheet,
	Text,
	View,
	Image,
	TouchableOpacity,
	Platform,
} from "react-native";
import FImage from "../Common/ImageInterface";

const FloatingCard = ({ onPress, icon, titleText, bodyText, isUrl }) => {
	return (
		<TouchableOpacity onPress={onPress} style={styles.cardEffect}>
			<FImage
				source={isUrl ? { uri: icon + "", priority: "high" } : icon}
				style={{
					width: "100%",
					height: "100%",
				}}
				resizeMode='cover'
			/>
			<View
				style={{
					position: "absolute",
					bottom: 0,
					backgroundColor: "white",
					width: "100%",
					height: 70,
				}}>
				<Text style={{ marginTop: 7, marginStart: 20 }}>
					{titleText}
				</Text>
				<Text
					ellipsizeMode='tail'
					numberOfLines={2}
					style={{
						marginTop: 5,
						marginStart: 20,
						fontSize: 12,
						color: "#888",
					}}>
					{bodyText}
				</Text>
			</View>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	cardEffect: {
		height: 200,
		width: 300,
		marginHorizontal: 5,
		marginVertical: 10,
		overflow: "hidden",
		borderRadius: 5,
		backgroundColor: "white",
		borderWidth: 1,
		borderColor: "#ddd",
		elevation: 3,
	},
});

export default FloatingCard;
