import React, { useState, useRef, useEffect } from "react";
import {
	StyleSheet,
	Text,
	View,
	TextInput,
	Image,
	ToastAndroid,
	Platform,
	Alert,
	TouchableOpacity,
	Dimensions,
} from "react-native";
// import CheckBox from "@react-native-community/checkbox";
import { CheckBox } from "react-native-web";
import axiosInstance from "../../Utils/axiosGlobal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Utils from "../../Utils/Utils.web";
import PlainHeader from "../../components/WebOnly/PlainHeader";
import LoaderOverlay from "../../components/Common/LoaderOverlay";
import FeedbackBar from "../../components/WebOnly/TopFeedbackBar";

//assets
import SubmitButtonDark from "../../components/Buttons/SubmitButtonDark";
import googleIc from "../../assets/Misc/google-48.png";
import eye_close_ic from "../../assets/Icons/DCS_eye_closed_ic.png";
import eye_open_ic from "../../assets/Icons/DCS_eye_open_ic.png";
import BackFab from "../../components/Common/BackFab";
import themes from "../../Styles/themes";
import { Link, useLinkTo } from "@react-navigation/native";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import FirebaseWebInstance from "../../Utils/FirebaseWebInstance.web";

const Login = ({ route, navigation }) => {
	//input fields
	const [userNameValue, setUserNameValue] = useState("");
	const [passwordValue, setPasswordValue] = useState("");
	const [rememberMe, setRememberMe] = useState(false);
	//input field refs
	const passFieldref = useRef();
	const usernameRef = useRef();
	const analytics = FirebaseWebInstance.getInstance();

	const linkTo = useLinkTo();

	const [next, setNext] = useState(
		typeof route.params == "undefined" ? "home" : route.params.next
	);

	const [landingError, setLandingError] = useState(
		typeof route.params == "undefined" ? "" : route.params.error
	);

	const [commonErrorText, setCommonErrorText] = useState(null);

	const [securePassword, setSecurePassword] = useState(true);

	//error texts
	const [errorText, setErrorText] = useState({
		username: "",
		password: "",
	});
	//error text refs
	const errorPassFieldref = useRef();
	const errorUsernameRef = useRef();

	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));

	const [loginInProgress, setLoginInProgress] = useState(false);

	const [box1IsFocus, setBox1Focus] = useState(false);
	const [box2IsFocus, setBox2Focus] = useState(false);

	const [feedbackText, setFeedbackText] = useState("");
	const [feedbackType, setFeedbackType] = useState("none");
	const [showFeedback, setShowFeedback] = useState(false);

	const errorCodeMap = new Map([
		["guest-booking", "You need to be logged in to place an order"],
	]);

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	const updateParam = (text, fieldName) => {
		switch (fieldName) {
			case "email":
				setUserNameValue(text);
				break;

			case "password":
				setPasswordValue(text);
				break;
		}
	};

	const sendGAEvent = (eventName, eventObject) => {
		logEvent(analytics, eventName, eventObject);
	};

	const callLoginApi = () => {
		setLoginInProgress(true);
		axiosInstance({
			method: "post",
			url: "accounts/dj-rest-auth/login/",
			data: {
				//written as username but should be an email
				username: userNameValue,
				password: passwordValue,
			},
		})
			.then((response) => {
				if (
					response.status >= 200 &&
					response.status < 300 &&
					typeof response.data.key !== "undefined"
				) {
					console.log("successful login, key = " + response.data.key);
					//save response.data.key locally for future api calls
					AsyncStorage.setItem("auth-key", response.data.key);
					AsyncStorage.setItem("userName", userNameValue);
					AsyncStorage.setItem("guestUser", "false");
					//set Authorization in header to fetched key
					axiosInstance.defaults.headers.common["Authorization"] =
						"Token " + response.data.key;

					let decodedNext = atob(next);
					setUserId(analytics, userNameValue);
					try {
						//Only need to check for params if we landed on login page due to an error
						if (decodedNext.trim() != "" && landingError != "") {
							//Navigate using window object because linkTo doesn't work properly
							let routeName = decodedNext?.split("?")[0];
							let queryObj = JSON.parse(
								decodedNext?.split("?")[1]
							);
							let queryAsString = "?";
							let currentIndex = 0;
							for (let key in queryObj) {
								queryAsString += `${key}=${queryObj[key]}`;
								if (
									currentIndex !=
									Object.keys(queryObj).length - 1
								) {
									queryAsString += "&";
								}
								currentIndex++;
							}
							sendGAEvent("login_success");

							window.location.href = window.location.href
								.split("?")[0]
								.replace(
									"/login",
									"/" + routeName + queryAsString
								);
						} else {
							if (next == "home") {
								sendGAEvent("login_success");
							}
							navigation.reset({
								index: 0,
								routes: [
									{
										name: next,
									},
								],
							});
						}
					} catch (error) {
						console.error(error);
						navigation.reset({
							index: 0,
							routes: [
								{
									name: next,
								},
							],
						});
					}
				} else {
					setLoginInProgress(false);
					setShowFeedback(true);
					setFeedbackType("error");
					handleErrorResponse(response?.data);
					sendGAEvent("login_failure");
				}
			})
			.catch((onrejected) => {
				setLoginInProgress(false);
				handleErrorResponse(onrejected?.response?.data);
				setShowFeedback(true);
				setFeedbackType("error");
				Utils.handleFailedResponse(
					"postLogin",
					onrejected?.response,
					onrejected?.response?.status,
					navigation,
					setFeedbackText
				);
				if (
					onrejected.request?._response?.indexOf("not verified") > -1
				) {
					navigation.navigate("verifymail", {
						email: userNameValue,
					});
				}
				sendGAEvent("login_failure");
			});
	};

	const handleErrorResponse = (error) => {
		if (!error) return;
		setErrorText({
			email: "username" in error ? error.username : "",
			password: "password" in error ? error.password : "",
		});

		//update email field
		if ("username" in error) {
			usernameRef.current.setNativeProps({
				style: { borderColor: "#FF5151" },
			});

			errorUsernameRef.current.setNativeProps({
				style: { height: 20 },
			});
		} else {
			usernameRef.current.setNativeProps({
				style: { borderColor: "#D1FFA0" },
			});

			errorUsernameRef.current.setNativeProps({
				style: { height: 0 },
			});
		}

		//update password field
		if ("password" in error) {
			passFieldref.current.setNativeProps({
				style: { borderColor: "#FF5151" },
			});

			errorPassFieldref.current.setNativeProps({
				style: { height: 20 },
			});
		} else {
			passFieldref.current.setNativeProps({
				style: { borderColor: "#D1FFA0" },
			});

			errorPassFieldref.current.setNativeProps({
				style: { height: 0 },
			});
		}

		if ("non_field_errors" in error) {
			usernameRef.current.setNativeProps({
				style: { borderColor: "#FF5151" },
			});
			passFieldref.current.setNativeProps({
				style: { borderColor: "#FF5151" },
			});
			setCommonErrorText(error.non_field_errors[0]);
		}
	};

	const InputField = ({
		title,
		placeHolder,
		innerRef,
		param,
		errorText,
		errorInnerRef,
	}) => {
		return (
			<View style={{ flexWrap: "wrap" }}>
				<Text style={styles.textInputTitle}>{title}</Text>
				<TextInput
					ref={innerRef}
					style={styles.textInput}
					placeholder={placeHolder}
					placeholderTextColor='#858189'
					color='#000'
					onChangeText={(text) => {
						updateParam(text, param);
					}}
					autoCorrect={false}
					spellCheck={false}
					secureTextEntry={param.endsWith("password")}
					keyboardType={
						param == "email" ? "email-address" : "default"
					}
				/>
				<Text
					style={{ color: "#FF5151", height: 0 }}
					ref={errorInnerRef}>
					{errorText}
				</Text>
			</View>
		);
	};

	const FormBody = () => {
		return (
			<View
				style={[
					styles.innerContainer,
					{
						paddingBottom: screen.height < 700 ? 50 : 0,
						maxWidth: screen.width > 500 ? 400 : "80%",
					},
				]}>
				{/* title */}
				<Text style={styles.titleFont}>Login</Text>
				{/* Sign in with Google Button */}
				{/* <TouchableOpacity
					style={[styles.googleSignInTextCont, styles.centerify]}
					onPress={() => {
						// navigation.navigate("VehicleSelectionForm");
						// navigation.navigate("Main");
						// navigation.reset({
						// 	index: 0,
						// 	routes: [{ name: "Main" }],
						// });
					}}>
					<Image
						source={googleIc}
						style={{
							height: 20,
							width: 20,
						}}
					/>
					<Text style={styles.googleSignInText}>
						Sign Up with Google
					</Text>
				</TouchableOpacity> */}
				{/* partitioning title text */}
				{/* <View
					style={[
						styles.centerHorizontal,
						{
							marginTop: 10,
							flexDirection: "row",
							alignContent: "space-around",
						},
					]}>
					<Text
						style={{
							backgroundColor: "#858189",
							flex: 1,
							maxHeight: 2,
							opacity: 0.5,
							marginHorizontal: 5,
						}}></Text>
					<Text style={styles.signInOptionText}>
						Or Sign in with email
					</Text>
					<Text
						style={{
							backgroundColor: "#858189",
							flex: 1,
							maxHeight: 2,
							opacity: 0.5,
							marginHorizontal: 5,
						}}></Text>
				</View> */}
				{/* Inputfields */}
				{/* Username inputfield */}
				<View style={{ marginTop: 55 }}>
					<Text style={styles.textInputTitle}>Email</Text>
					<TextInput
						ref={usernameRef}
						style={[
							styles.textInput,
							{
								outlineColor: box1IsFocus
									? themes.primarySkyBlue
									: "#C4C4C4",
							},
						]}
						placeholder={"Enter your email"}
						placeholderTextColor='#858189'
						color='#000'
						onChangeText={(text) => {
							updateParam(text, "email");
						}}
						onFocus={() => {
							setBox1Focus(true);
						}}
						onBlur={() => setBox1Focus(false)}
						textContentType='emailAddress'
						autoCapitalize='none'
						value={userNameValue}
						autoCorrect={false}
						spellCheck={false}
					/>
					<Text
						style={{
							color: "#FF5151",
							height: 0,
							fontFamily: "Gilroy-Regular",
						}}
						ref={errorUsernameRef}>
						{errorText.username}
					</Text>
					{/* <InputField
						innerRef={usernameRef}
						errorInnerRef={errorUsernameRef}
						title={"Email"}
						placeHolder='Enter your email'
						errorText={errorText.email}
						param='email'
						value={userNameValue}
					/> */}
					{/* Password inputfield */}
					<View style={{ marginTop: 20 }}>
						<Text style={styles.textInputTitle}>Password</Text>
						<TextInput
							ref={passFieldref}
							style={[
								styles.textInput,
								{
									outlineColor: box2IsFocus
										? themes.primarySkyBlue
										: "#C4C4C4",
									fontFamily: "Gilroy-Regular",
								},
							]}
							placeholder={"Enter your password"}
							placeholderTextColor='#858189'
							color='#000'
							onChangeText={(text) => {
								updateParam(text, "password");
							}}
							onFocus={() => setBox2Focus(true)}
							onBlur={() => setBox2Focus(false)}
							value={passwordValue}
							autoCorrect={false}
							spellCheck={false}
							secureTextEntry={securePassword}
						/>
						<TouchableOpacity
							style={{ position: "absolute", right: 20, top: 25 }}
							onPress={() => setSecurePassword((prev) => !prev)}>
							<Image
								source={
									securePassword ? eye_close_ic : eye_open_ic
								}
								style={{
									height: 25,
									width: 25,
									tintColor: !securePassword
										? "#ccc"
										: "#7E98ED",
								}}
							/>
						</TouchableOpacity>
						<Text
							style={{
								color: "#FF5151",
								height: 0,
								fontFamily: "Gilroy-Regular",
							}}
							ref={errorPassFieldref}>
							{errorText.password}
						</Text>

						{/* <InputField
							innerRef={passFieldref}
							errorInnerRef={errorPassFieldref}
							title={"Password"}
							placeHolder='Enter your password'
							errorText={errorText.password}
							param='password'
						/> */}
					</View>
				</View>
				{/* Remember me and Forgot password options */}
				<View
					style={{
						flexDirection: screen.width < 300 ? "column" : "row",
						justifyContent: "flex-end",
						alignItems:
							screen.width < 300 ? "flex-start" : "center",
						marginVertical: 20,
					}}>
					{/* <View
						style={{
							flexDirection: "row",
							alignItems: "center",
						}}>
						<CheckBox
							boxType='square'
							// onCheckColor='#fff'
							color='#001A45'
							// tintColors={{ true: "#001A45" }}
							value={rememberMe}
							onChange={() => {
								setRememberMe((prev) => !prev);
							}}
						/>
						<Text
							style={{
								fontSize: 14,
								marginLeft: 7,
								fontFamily: "Gilroy-Regular",
							}}>
							Remember me
						</Text>
					</View> */}
					<Text
						onPress={() => navigation.navigate("forgotpassword")}
						style={{
							color: "#001A45",
							fontSize: 14,
							fontFamily: "Gilroy-SemiBold",
						}}>
						Forgot Password?
					</Text>
				</View>
				{/* Login/Submit Button */}
				<SubmitButtonDark
					fontStyle={{ fontFamily: "Gilroy-SemiBold" }}
					onPress={() => callLoginApi()}
					title={"Login"}
				/>
				{/* Create an Account Option */}
				<Link to='/register'>
					<View
						style={{
							flexDirection: "row",
							marginTop: 45,
							justifyContent: "center",
							fontFamily: "Gilroy-Regular",
						}}>
						<Text>Not yet Registered?</Text>
						<Text
							style={{
								color: "#00417C",
								fontFamily: "Gilroy-SemiBold",
								marginStart: 5,
							}}
							onPress={() => {}}>
							Create an Account
						</Text>
					</View>
				</Link>
				<View style={{ alignItems: "center" }}>
					<Text style={{ marginTop: 20, color: "#ccc" }}>OR</Text>
					<Link to={"/home"}>
						<TouchableOpacity
							onPress={() => {
								AsyncStorage.setItem("guestUser", "true");
								// navigation.navigate("HomeScreen", {
								// 	isGuestUser: true,
								// });
								sendGAEvent("guest_login");
							}}>
							<Text
								style={{
									fontSize: 18,
									fontWeight: "500",
									color: "#7E98ED",
									marginTop: 20,
									fontFamily: "AvenirNextLTPro-Regular",
								}}>
								Proceed as Guest
							</Text>
						</TouchableOpacity>
					</Link>
				</View>
			</View>
		);
	};

	return (
		<View style={styles.container}>
			<PlainHeader />
			<View style={{ alignSelf: "center", marginTop: 20 }}>
				<Text style={{ color: "red" }}>
					{commonErrorText || errorCodeMap.get(landingError)}
				</Text>
			</View>
			<FeedbackBar
				feedbackNature={feedbackType}
				show={showFeedback}
				setShow={setShowFeedback}
				text={feedbackText}
			/>
			<View style={{ alignItems: "center" }}>{FormBody()}</View>
			{loginInProgress ? (
				<LoaderOverlay
					bgColor={"#ffffff50"}
					isVisible={loginInProgress}
				/>
			) : null}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
	},
	innerContainer: {
		flex: 1,
		flexDirection: "column",
		paddingTop: 35,
		backgroundColor: "#fff",
	},
	titleFont: {
		fontSize: 25,
		color: "#120632",
		fontFamily: "AvenirNextLTPro-Bold",
	},
	googleSignInText: {
		fontSize: 15,
		color: "#001A45",
		marginStart: 20,
	},
	googleSignInTextCont: {
		height: 45,
		width: 300,
		maxWidth: "100%",
		flexDirection: "row",
		marginTop: 25,
		marginBottom: 16,
		borderWidth: 1,
		borderColor: "#001A45",
		borderRadius: 15,
		padding: 5,
	},
	centerify: {
		alignItems: "center",
		justifyContent: "center",
	},
	centerHorizontal: {
		alignItems: "center",
	},
	signInOptionText: {
		fontSize: 12,
		color: "#858189",
	},
	textInputTitle: {
		marginStart: 7,
		color: "#120632",
		fontSize: 16,
		fontFamily: "Gilroy-Regular",
	},
	textInput: {
		borderRadius: 18,
		borderWidth: 2,
		paddingHorizontal: 15,
		height: 40,
		width: "100%",
		borderColor: "#C4c4c4",
		fontFamily: "Gilroy-Regular",
	},
	backArrow: {
		position: "absolute",
		left: 30,
		top: 25,
		zIndex: 1,
	},
});

export default Login;
