import React from "react";
import { StyleSheet, Image, View } from "react-native";
import star_filled_ic from "../../assets/Misc/DCS_star_filled_ic.png";
import star_half_filled_ic from "../../assets/Misc/DCS_star_half_filled_ic.png";
import star_empty from "../../assets/Misc/DCS_star_empty_ic.png";

const RatingFiveStar = ({ rating, styles }) => {
	const starArray = [];
	const renderStars = () => {
		if (rating == 0) {
			for (let i = 0; i < 5; i++) {
				starArray.push(
					<Image
						key={i}
						source={star_empty}
						style={{ height: 20, width: 20, marginRight: 4 }}
						resizeMode='contain'
					/>
				);
			}
		} else {
			for (let i = 0; i < Math.ceil(rating); i++) {
				if (i < rating && i + 1 > rating) {
					starArray.push(
						<Image
							key={i}
							source={star_half_filled_ic}
							style={{ height: 20, width: 20, marginRight: 4 }}
							resizeMode='contain'
						/>
					);
				} else {
					starArray.push(
						<Image
							key={i}
							source={star_filled_ic}
							style={{ height: 20, width: 20, marginRight: 4 }}
							resizeMode='contain'
						/>
					);
				}
			}
			for (let i = 0; i < Math.floor(5 - rating); i++) {
				starArray.push(
					<Image
						key={rating + i}
						source={star_empty}
						style={{ height: 20, width: 20, marginRight: 4 }}
						resizeMode='contain'
					/>
				);
			}
		}
	};
	renderStars();
	return <View style={[{ flexDirection: "row" }, styles]}>{starArray}</View>;
};

export default RatingFiveStar;
