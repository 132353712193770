import React from "react";
import { Text, View, TouchableOpacity, Modal } from "react-native";

//components
import SubmitButton from "../Buttons/SubmitButtonDark";

const PopupDialogBox = ({
	titleText,
	messageText,
	positiveOptionText,
	negativeOptionText,
	onPositiveSelection,
	onNegativeSelection,
	visibility,
	extraComponents,
	showButtons,
}) => {
	return (
		<Modal
			visible={visibility}
			transparent
			animationType='fade'
			children={
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#00000050",
					}}>
					<View
						style={{
							width: "80%",
							maxWidth: 330,
							maxHeight: 700,
							backgroundColor: "white",
							borderRadius: 10,
							elevation: 10,
							paddingVertical: 10,
							paddingHorizontal: 20,
							justifyContent: "space-between",
							borderWidth: 1,
							borderColor: "#ccc",
						}}>
						<View>
							<Text
								style={{
									fontSize: 22,
									fontWeight: "500",
								}}>
								{titleText}
							</Text>
							<Text style={{ marginTop: 30 }}>{messageText}</Text>
							{extraComponents}
						</View>
						{showButtons ? (
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
									padding: 10,
									marginTop: 60,
								}}>
								{/* Negative button */}
								<TouchableOpacity onPress={onNegativeSelection}>
									<Text
										style={{
											color: "#7E98ED",
											fontSize: 20,
											fontWeight: "500",
										}}>
										{negativeOptionText}
									</Text>
								</TouchableOpacity>
								{/* Positive Button */}
								<SubmitButton
									onPress={onPositiveSelection}
									title={positiveOptionText}
									style={{
										backgroundColor: "#e3342b",
										width: 90,
										paddingHorizontal: 0,
										elevation: 3,
									}}
								/>
							</View>
						) : null}
					</View>
				</View>
			}></Modal>
	);
};

export default PopupDialogBox;
