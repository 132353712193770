import React, { useRef, useEffect, useState } from "react";
import {
	StyleSheet,
	View,
	TouchableOpacity,
	Text,
	Dimensions,
	Image,
} from "react-native";
import {
	Link,
	NavigationContainer,
	useNavigationContainerRef,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Utils from "./Utils/Utils.web";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getAnalytics, logEvent } from "firebase/analytics";

//screens
import InitSplash from "./Screens/Setup screens/InitSplash";
import Login from "./Screens/Setup screens/Login";
import Register from "./Screens/Setup screens/Register";
import HomeScreen from "./Screens/Main screens/HomeScreen";
import VerifyEmailScreen from "./Screens/Setup screens/VerifyEmailScreen";
import SettingsScreen from "./Screens/Main screens/SettingsScreen";
import ServiceDetailScreen from "./Screens/Main screens/ServiceDetailScreen";
import CompanyServiceScreen from "./Screens/Main screens/CompanyServiceScreen";
import DealershipScreen from "./Screens/Main screens/DealershipScreen";
import SpecificServiceDetailScreen from "./Screens/Main screens/SpecificServiceDetailScreen";
import OptionListScreen from "./Screens/Main screens/OptionListScreen";
import ServiceBookingScreen from "./Screens/Main screens/ServiceBookingScreen";
import VehicleSelectionForm from "./Screens/Setup screens/VehicleSelectionForm.web";
import PaymentScreen from "./Screens/Main screens/PaymentScreen.web";
import OrderTrackingScreen from "./Screens/Main screens/OrderTrackingScreen.web";
import MyOrdersScreen from "./Screens/Main screens/MyOrdersScreen.web";
import ErrorPage from "./Screens/ErrorPage";
import GatepassScreen from "./Screens/Main screens/GatePassScreen.web";
import EditUserProfileScreen from "./Screens/Main screens/EditUserProfileScreen.web";
import NotificationScreen from "./Screens/Main screens/NotificationScreen.web";
import ShowroomScreen from "./Screens/Main screens/ShowroomScreen.web";
import ForgotPasswordScreen from "./Screens/Setup screens/ForgotPasswordScreen.web";
import ResetCredentialScreen from "./Screens/Setup screens/ResetCredentialScreen.web";
import PromoDetailScreen from "./Screens/Main screens/PromoDetailScreen.web";
import SavedAddressScreen from "./Screens/Main screens/SavedAddressScreen.web";
import SpecificShowroomScreen from "./Screens/Main screens/SpecificShowroomScreen.web";

//components
import themes from "./Styles/themes";
import SearchBar from "./components/Common/GenericSearchBar";
import "./Styles/externalStyles.module.css";

//assets
import profile_ic from "./assets/svgs/profile_ic.svg";
import notifications_ic from "./assets/Icons/DCS_bell_ic.png";
import edit_ic from "./assets/Icons/DCS_edit_ic.png";
import coupon_ic from "./assets/Icons/DCS_coupon_ic.png";
import log_out_ic from "./assets/Icons/DCS_log_out_ic.png";
import home_ic from "./assets/Icons/DCS_home_tab_ic.png";
import cart_ic from "./assets/Icons/DCS_cart_ic.png";
import axiosInstance from "./Utils/axiosGlobal";
import FirebaseWebInstance from "./Utils/FirebaseWebInstance.web";
import ShowroomVehiclesScreen from "./Screens/Main screens/ShowroomVehiclesScreen";
import QuotationScreen from "./Screens/Main screens/QuotationScreen.web";
import GatePassByTokenScreen from "./Screens/Main screens/GatePassByTokenScreen.web";

const Stack = createNativeStackNavigator();

//app entry
const App = () => {
	const windowSize = Dimensions.get("window");
	const screenSize = Dimensions.get("screen");
	const [dimensions, setDimensions] = useState({ windowSize, screenSize });
	const [currentRouteName, setCurrentRouteName] = useState(null);

	const [showNewIndicator, setShowNewIndicator] = useState(false);

	const [isLoggedUser, setAsLoggedUser] = useState(
		typeof axiosInstance.defaults.headers.common["Authorization"] !=
			"undefined" &&
			axiosInstance.defaults.headers.common["Authorization"].startsWith(
				"Token"
			)
	);

	const _no_navbar_screens = new Set([
		"/splash",
		"/login",
		"/register",
		"/verifymail",
		"/estimatedpayment",
		"/paymentstatus",
		"/forgotpassword",
		"/resetpassword",
	]);

	const [dropdownOneVisible, setDropdownOneVisible] = useState(false);
	const [dropdownTwoVisible, setDropdownTwoVisible] = useState(false);
	const [q, setQuery] = useState("");

	// const config = {
	// 	screens: {
	// 		notfound: "*",
	// 		splash: "splash",
	// 		login: "login",
	// 		register: "register",
	// 		home: "home",
	// 		verifymail: "verifymail",
	// 		settings: "settings",
	// 		service: "service",
	// 		servicesbyprovider: "servicesbyprovider",
	// 		providedservice: "providedservice",
	// 		dealerbrands: "dealerbrands",
	// 		exploreoptions: "exploreoptions",
	// 		bookservice: {
	// 			path: "bookservice/:serviceID/:companyID/:opts?",
	// 			parse: {
	// 				serviceID: (serviceID) => `${serviceID}`,
	// 				companyID: (companyID) => `${companyID}`,
	// 				opts: (opts) => (opts == "true" ? "1" : "0"),
	// 			},
	// 			stringify: {
	// 				serviceID: (serviceID) => `${serviceID}`,
	// 				companyID: (companyID) => `${companyID}`,
	// 				opts: (opts) => (opts == "1" ? "true" : "false"),
	// 			},
	// 		},
	// 		managevehicles: "managevehicles",
	// 		estimatedpayment: "estimatedpayment",
	// 		trackorder: "trackorder",
	// 		myorders: "myorders",
	// 		gatepass: "gatepass",
	// 		editprofile: "editprofile",
	// 		showrooms: "showrooms",
	// 		forgotpassword: "forgotpassword",
	// 		resetpassword: "resetpassword",
	// 		promodetails: "promodetails",
	// 	},
	// };
	const config = {
		screens: {
			// notfound: "*",
			splash: "splash",
			login: "login",
			register: "register",
			home: "home",
			verifymail: "verifymail",
			settings: "settings",
			service: "service",
			servicesbyprovider: "servicesbyprovider",
			providedservice: "providedservice",
			dealership: {
				path: ":slug",
				parse: {
					slug: (slug) => `${slug}`,
				},
				stringify: {
					slug: (slug) => `${slug}`,
				},
			},
			exploreoptions: "exploreoptions",
			bookservice: "bookservice",
			managevehicles: "managevehicles",
			estimatedpayment: "estimatedpayment",
			trackorder: "trackorder",
			myorders: "myorders",
			gatepass: "gatepass",
			gatepassbytoken: {
				path: "gatepass/:token",
				parse: {
					token: (token) => `${token}`,
				},
				stringify: {
					token: (token) => `${token}`,
				},
			},
			editprofile: "editprofile",
			showrooms: "showrooms",
			forgotpassword: "forgotpassword",
			resetpassword: "resetpassword",
			promodetails: "promodetails",
			savedaddresses: "savedaddresses",
			showroomsbybrand: "showroomsbybrand",
			showroomvehicles: "showroomvehicles",
			quotations: {
				path: "quotations/:token",
				parse: {
					token: (token) => `${token}`,
				},
				stringify: {
					token: (token) => `${token}`,
				},
			},
		},
	};
	const linking = {
		prefixes: ["https://acc.accessai.co/", "accessai://"],
		config,
	};
	const navigationRef = useNavigationContainerRef();
	const routeNameRef = useRef();

	const analytics = FirebaseWebInstance.getInstance();

	useEffect(() => {
		/**
		 * This disables the scroll on web page, we need this because we want our navbar to be
		 * stationary and only main body content to scroll
		 */
		document.body.style.overflow = "hidden";
	}, []);

	useEffect(() => {
		//update logged user state
		AsyncStorage.getItem("guestUser").then((result) => {
			setAsLoggedUser(result != "true");
		});
	}, [axiosInstance.defaults.headers.common["Authorization"]]);

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setDimensions({ window, screen });
			}
		);
		return () => subscription?.remove();
	}, []);

	useEffect(() => {
		AsyncStorage.getItem("notifications").then((result) => {
			let resultJson = JSON.parse(result);
			if (resultJson != null && resultJson.length > 0) {
				setShowNewIndicator(true);
			}
		});
		AsyncStorage.getItem("query").then((result) => {
			setQuery(result);
		});
	}, []);

	const OptionComponent = ({ icon, optionName, onPress }) => {
		return (
			<View>
				<TouchableOpacity
					style={{
						flexDirection: "row",
						alignItems: "center",
						padding: 4,
					}}
					onPress={onPress}>
					<Image
						source={icon}
						style={{ height: 13, width: 13, tintColor: "#000" }}
						resizeMode='contain'
					/>
					<Text
						style={{
							marginLeft: 17,
							fontFamily: "Gilroy-Regular",
						}}>
						{optionName}
					</Text>
				</TouchableOpacity>
			</View>
		);
	};

	const QuickNavButtons = ({ onPress, onFocusCondition, text }) => {
		return (
			<View>
				<TouchableOpacity onPress={onPress}>
					<Text
						style={{
							color: onFocusCondition
								? themes.primarySkyBlue
								: "#aaa",
							fontFamily: onFocusCondition
								? "Gilroy-SemiBold"
								: "Gilroy-Regular",
							fontSize: windowSize.width < 860 ? 19 : 16,
						}}>
						{text}
					</Text>
				</TouchableOpacity>
			</View>
		);
	};

	return (
		<View
			style={[
				styles.container,
				{
					paddingTop:
						_no_navbar_screens.has("/" + currentRouteName) ||
						(currentRouteName != null &&
							currentRouteName == "notfound")
							? 0
							: windowSize.width < 800
							? 120
							: 70,
				},
			]}>
			{_no_navbar_screens.has("/" + currentRouteName) ||
			(currentRouteName != null &&
				currentRouteName == "notfound") ? null : (
				<View
					style={{
						height: "10vh",
						position: "absolute",
						top: 0,
						right: 0,
						left: 0,
						zIndex: 999,
					}}>
					<View
						style={{
							height: windowSize.width < 800 ? 120 : 70,
							borderBottomWidth: 1,
							borderBottomColor: "#ccc",
							backgroundColor: "#fff",
						}}>
						<View
							style={{
								height: "100%",
								width: "100%",
								maxWidth: 1000,
								flexDirection: "row",
								alignItems: "center",
								alignSelf: "center",
								paddingHorizontal: 20,
							}}>
							<View
								style={{
									flexDirection:
										windowSize.width < 800
											? "column-reverse"
											: "row",
									alignItems: "center",
									flex: 3,
								}}>
								<View
									style={{
										flexDirection: "row",
										flex: 1,
										justifyContent: "space-evenly",
										alignSelf:
											windowSize.width < 800
												? "stretch"
												: null,
										maxWidth:
											windowSize.width < 800 ? 800 : 350,
										marginTop:
											windowSize.width < 800 ? 10 : 0,
									}}>
									<QuickNavButtons
										onPress={() => {
											if (currentRouteName != "home") {
												setDropdownOneVisible(false);
												navigationRef.current.navigate(
													"home"
												);
											}
										}}
										onFocusCondition={
											currentRouteName == "home" ||
											currentRouteName == "dealership"
										}
										text={"HOME"}
									/>
									<QuickNavButtons
										onPress={() => {
											if (
												currentRouteName != "settings"
											) {
												setDropdownOneVisible(false);
												navigationRef.current.navigate(
													"settings"
												);
											}
										}}
										onFocusCondition={
											currentRouteName == "settings"
										}
										text={"SETTINGS"}
									/>
									{/* <QuickNavButtons
										onPress={() => {
											if (
												currentRouteName != "exchange"
											) {
												setDropdownOneVisible(false);
												navigationRef.current.navigate(
													"exchange"
												);
											}
										}}
										onFocusCondition={
											currentRouteName == "exchange"
										}
										text={"EXCHANGE"}
									/> */}
									{/* <QuickNavButtons
										onPress={() => {
											if (
												currentRouteName != "showrooms"
											) {
												setDropdownOneVisible(false);
												navigationRef.current.navigate(
													"showrooms"
												);
											}
										}}
										onFocusCondition={
											currentRouteName == "showrooms"
										}
										text={"SHOWROOMS"}
									/> */}
								</View>
								{currentRouteName != null &&
								currentRouteName.startsWith("home") ? (
									<SearchBar
										placeholderText={
											"What are you looking for?"
										}
										textInputStyle={{
											backgroundColor: "#eee",
											width:
												windowSize.width < 800
													? 250
													: 300,
											fontFamily: "Gilroy-Regular",
										}}
										containerStyle={{
											height: 60,
										}}
										onTouchEnter={(text) => {
											AsyncStorage.setItem("query", text);
											// navigationRef.current.navigate("home", {
											// 	q: text,
											// });
											window.location = "home?q=" + text;
										}}
										onChangeText={(t) => {
											if (t == "") {
												AsyncStorage.setItem(
													"query",
													""
												).then(() => {
													// navigationRef.current.navigate(
													// 	"home",
													// 	{
													// 		q: "",
													// 	}
													// );
													window.location = "home";
												});
											}
											setQuery(t);
										}}
										value={q || ""}
									/>
								) : null}
							</View>
							<View
								style={{
									flexDirection: "row",
									flex: 1,
									justifyContent: "space-evenly",
									maxWidth: 200,
								}}>
								<TouchableOpacity
									onPress={() => {
										setDropdownOneVisible((prev) => !prev);
										setDropdownTwoVisible(false);
									}}
									style={{ height: 30, width: 30 }}>
									<Image
										source={profile_ic}
										style={{
											height: "100%",
											width: "100%",
											tintColor: dropdownOneVisible
												? themes.primarySkyBlue
												: "#000",
										}}
										resizeMode='contain'
									/>
									{dropdownOneVisible ? (
										<View
											style={{
												width: 150,
												backgroundColor: "#fff",
												borderWidth: 1,
												borderColor: "#ddd",
												borderRadius: 5,
												padding: 7,
												position: "absolute",
												right: 0,
												top:
													windowSize.width < 800
														? 90
														: 60,
											}}>
											{isLoggedUser ? (
												<View>
													<OptionComponent
														icon={edit_ic}
														optionName={
															"Edit Profile"
														}
														onPress={() => {
															navigationRef.current.navigate(
																"editprofile"
															);
														}}
													/>
													<OptionComponent
														icon={home_ic}
														optionName={
															"Saved Addresses"
														}
														onPress={() => {
															navigationRef.current.navigate(
																"savedaddresses",
																{}
															);
														}}
													/>
													{/* <OptionComponent
														icon={coupon_ic}
														optionName={"Coupons"}
														onPress={() => {}}
													/> */}
													<OptionComponent
														icon={cart_ic}
														optionName={"My Orders"}
														onPress={() => {
															navigationRef.current.navigate(
																"myorders",
																{
																	filterCode:
																		"none",
																}
															);
														}}
													/>
												</View>
											) : (
												<Text
													style={{
														color: "#bbb",
														marginLeft: 6,
													}}>
													Guest User
												</Text>
											)}
											<OptionComponent
												icon={log_out_ic}
												optionName={
													isLoggedUser
														? "Log Out"
														: "Log In"
												}
												onPress={() => {
													Utils.handleLogOut(
														navigationRef.current,
														() => {
															logEvent(
																analytics,
																"logout_success"
															);
														}
													);
												}}
											/>
										</View>
									) : null}
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() => {
										setDropdownTwoVisible((prev) => !prev);
										setDropdownOneVisible(false);
									}}
									style={{ height: 30, width: 30 }}>
									{showNewIndicator ? (
										<View
											style={{
												backgroundColor: "#7E98ED",
												height: 12,
												width: 12,
												borderRadius: 12,
												position: "absolute",
												right: 0,
											}}
										/>
									) : null}
									<Image
										source={notifications_ic}
										style={{
											height: "100%",
											width: "100%",
											tintColor: dropdownTwoVisible
												? "#7E98ED"
												: "#000",
										}}
										resizeMode='contain'
									/>
									{dropdownTwoVisible ? (
										<View
											style={{
												width:
													windowSize.width < 600
														? 300
														: 600,
												backgroundColor: "#fff",
												borderWidth: 1,
												borderColor: "#ddd",
												borderRadius: 5,
												padding: 7,
												position: "absolute",
												right: 0,
												top:
													windowSize.width < 800
														? 90
														: 60,
											}}>
											{isLoggedUser ? (
												<NotificationScreen
													navigation={
														navigationRef.current
													}
												/>
											) : (
												<View>
													<Text
														style={{
															color: "#bbb",
															marginLeft: 6,
														}}>
														Guest User
													</Text>
													<OptionComponent
														icon={log_out_ic}
														optionName={
															isLoggedUser
																? "Log Out"
																: "Log In"
														}
														onPress={() => {
															Utils.handleLogOut(
																navigationRef.current
															);
														}}
													/>
												</View>
											)}
										</View>
									) : null}
								</TouchableOpacity>
							</View>
						</View>
					</View>
				</View>
			)}
			<View style={{ height: "100vh", width: "100vw" }}>
				<NavigationContainer
					linking={linking}
					ref={navigationRef}
					onReady={async () => {
						routeNameRef.current =
							navigationRef.current.getCurrentRoute().name;
						setCurrentRouteName(
							navigationRef.current.getCurrentRoute().name
						);
						//SEND GA FOR WEB WHEN PAGE IS READY (LOADED FROM SEARCH)
						logEvent(analytics, routeNameRef.current, {
							screen_name: routeNameRef.current,
							params: JSON.stringify(
								navigationRef.current.getCurrentRoute().params
							),
						});
					}}
					onStateChange={async () => {
						setDropdownOneVisible(false);
						setDropdownTwoVisible(false);
						const previousRouteName = routeNameRef.current;
						setCurrentRouteName(
							navigationRef.current.getCurrentRoute().name
						);
						const currentRouteName =
							navigationRef.current.getCurrentRoute().name;

						if (previousRouteName !== currentRouteName) {
							console.log(
								`sending ga: screen_class:${currentRouteName}`
							);
							console.log(
								`sending ga: prev screen_class:${previousRouteName}`
							);

							//SEND GA FOR WEB WHEN PAGE CHANGES WITHIN THE SCOPE OF APP
							logEvent(analytics, currentRouteName, {
								screen_name: currentRouteName,
								params: JSON.stringify(
									navigationRef.current.getCurrentRoute()
										.params
								),
							});
						}
					}}>
					<Stack.Navigator
						screenOptions={({ route }) => ({
							title: "Auto Cares",
						})}>
						{/* <Stack.Screen
						name='splash'
						component={SplashScreen}
						options={{ headerShown: false }}
					/> */}
						<Stack.Screen
							name='home'
							component={HomeScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='splash'
							component={InitSplash}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='login'
							component={Login}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='register'
							component={Register}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='verifymail'
							component={VerifyEmailScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='settings'
							component={SettingsScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='service'
							component={ServiceDetailScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='servicesbyprovider'
							component={CompanyServiceScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='providedservice'
							component={SpecificServiceDetailScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='dealership'
							component={DealershipScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='exploreoptions'
							component={OptionListScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='bookservice'
							component={ServiceBookingScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='managevehicles'
							component={VehicleSelectionForm}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='estimatedpayment'
							component={PaymentScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='trackorder'
							component={OrderTrackingScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='myorders'
							component={MyOrdersScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='gatepass'
							component={GatepassScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='gatepassbytoken'
							component={GatePassByTokenScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='editprofile'
							component={EditUserProfileScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='showrooms'
							component={ShowroomScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='forgotpassword'
							component={ForgotPasswordScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='resetpassword'
							component={ResetCredentialScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='promodetails'
							component={PromoDetailScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='savedaddresses'
							component={SavedAddressScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='showroomsbybrand'
							component={SpecificShowroomScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='showroomvehicles'
							component={ShowroomVehiclesScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='quotations'
							component={QuotationScreen}
							options={{ headerShown: false }}
						/>
						<Stack.Screen
							name='notfound'
							component={ErrorPage}
							options={{ headerShown: false }}
						/>
					</Stack.Navigator>
				</NavigationContainer>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "white",
	},
});

export default App;
