import React, { useState, useEffect } from "react";
import {
	StyleSheet,
	View,
	Dimensions,
	Text,
	Image,
	Platform,
	ScrollView,
	FlatList,
} from "react-native";

//components
import SearchBar from "../../components/Common/GenericSearchBar";
import FloatingCardBlank from "../../components/Cards/FloatingCardBlank";
import OptionComponent from "../../components/WebOnly/OptionComponent";

//assets
import noImage from "../../assets/Defaults/DCS_no_image.png";
// import { FlatList } from "react-native-web";

const ShowroomScreen = ({ route, navigation }) => {
	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));
	const [dimensions, setDimensions] = useState({ window, screen });

	const [containerWidth, setContainerWidth] = useState();

	const [showRooms, SetShowRooms] = useState([
		{
			showroomId: 1,
			showRoomName: "Tata motors showrooms",
			extraText: "3 showrooms near you",
			image: noImage,
		},
		{
			showroomId: 1,
			showRoomName: "Honda motors showrooms",
			extraText: "3 showrooms near you",
			image: noImage,
		},
		{
			showroomId: 1,
			showRoomName: "Mercedes showrooms",
			extraText: "3 showrooms near you",
			image: noImage,
		},
	]);

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	const showRoomCard = ({ item, i }) => {
		return (
			<OptionComponent
				key={i}
				imageUrl={item.image}
				children={
					<View
						style={{
							flexDirection: "column",
							justifyContent: "space-between",
						}}>
						<Text>{item.showRoomName}</Text>
					</View>
				}
				imageOccupancyPercent={60}
				onCardPress={() => {
					navigation.navigate("showroomsbybrand");
				}}
			/>
		);
	};

	return (
		<View style={styles.container}>
			<View
				style={{
					backgroundColor: "#fff",
					borderRadius: 20,
					width: windowSize.width < 800 ? windowSize.width : "70%",
					maxWidth: 900,
					alignSelf: "center",
					marginVertical: 10,
					marginHorizontal: windowSize.width < 800 ? 10 : 0,
				}}>
				<View
					style={{
						width: "70%",
						maxWidth: 400,
						height: 60,
						alignSelf: "center",
					}}>
					<SearchBar
						placeholderText={"Search "}
						onChangeText={(t) => {
							// onChangeTextHandler(t);
						}}
					/>
				</View>
			</View>
			<FlatList
				key={containerWidth + Math.random() * 1000}
				data={showRooms}
				contentContainerStyle={{
					flexGrow: 1,
					width: windowSize.width < 800 ? windowSize.width : "70%",
					maxWidth: 900,
					alignSelf: "center",
					backgroundColor: "#fff",
					paddingHorizontal: 20,
					paddingTop: 20,
					paddingBottom: 50,
					borderTopRightRadius: 7,
					borderTopLeftRadius: 7,
				}}
				onLayout={(e) => {
					var { x, y, width, height } = e.nativeEvent.layout;
					setContainerWidth(width);
				}}
				numColumns={Math.floor(containerWidth / 310)}
				renderItem={showRoomCard}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// backgroundColor: "#fff",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
});

export default ShowroomScreen;
