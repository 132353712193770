import React from "react";
import ReactDOM from "react-dom";
import {
	StyleSheet,
	TouchableOpacity,
	View,
	Text,
	findNodeHandle,
	Image,
} from "react-native";

const RadioContext = React.createContext({});
const batch = ReactDOM.unstable_batchedUpdates;

export function RadioGroup({ children, labelID, value, onChange, ...props }) {
	const [focusIndex, setFocusIndex] = React.useState(-1);

	const buttons = [];
	const newChildren = React.Children.map(children, (child) => {
		if (child.type === RadioButton) {
			buttons.push(child);
			return React.cloneElement(child, { index: buttons.length - 1 });
		}
		return child;
	});

	const childCount = buttons.length;

	function focusNext() {
		const nextFocusIndex = (focusIndex + 1) % childCount;
		setFocusIndex(nextFocusIndex);
		onChange(buttons[nextFocusIndex].props.value);
	}

	function focusPrev() {
		const prevFocusIndex =
			focusIndex === 0 ? childCount - 1 : focusIndex - 1;
		setFocusIndex(prevFocusIndex);
		onChange(buttons[prevFocusIndex].props.value);
	}

	if (typeof labelID !== "string") {
		throw new Error(
			`RadioGroup should be given ID to its label in order to be accessible.`
		);
	}

	return (
		<RadioContext.Provider
			value={{
				currentValue: value,
				currentFocusIndex: focusIndex,
				setFocusIndex,
				focusNext,
				focusPrev,
				onChange,
			}}>
			<View
				accessibilityRole='radiogroup'
				aria-labelledby={labelID}
				onBlur={() => setFocusIndex(-1)}
				{...props}>
				{newChildren}
			</View>
		</RadioContext.Provider>
	);
}

const KeyCode = {
	RETURN: 13,
	SPACE: 32,
	END: 35,
	HOME: 36,
	LEFT: 37,
	UP: 38,
	RIGHT: 39,
	DOWN: 40,
};

export function RadioButton({
	value,
	label,
	index,
	containerStyle,
	ic,
	ButtonComponent = DefaultButtonComponent,
}) {
	const containerRef = React.useRef(null);
	const {
		currentValue,
		currentFocusIndex,
		setFocusIndex,
		focusNext,
		focusPrev,
		onChange,
	} = React.useContext(RadioContext);
	const isFocused = currentFocusIndex === index;

	React.useEffect(() => {
		function handleKeydown(e) {
			batch(() => {
				let handled = false;
				switch (e.keyCode) {
					case KeyCode.SPACE:
					case KeyCode.RETURN:
						onChange(value);
						handled = true;
						break;
					case KeyCode.UP:
						focusPrev();
						handled = true;
						break;
					case KeyCode.DOWN:
						focusNext();
						handled = true;
						break;
					case KeyCode.LEFT:
						focusPrev();
						handled = true;
						break;
					case KeyCode.RIGHT:
						focusNext();
						handled = true;
						break;
					default:
						break;
				}
				if (handled) {
					e.stopPropagation();
					e.preventDefault();
				}
			});
		}

		const node = containerRef.current;
		node.addEventListener("keydown", handleKeydown);

		return () => {
			node.removeEventListener("keydown", handleKeydown);
		};
	}, [focusNext, focusPrev, value, onChange]);

	React.useEffect(() => {
		const rafId = requestAnimationFrame(() => {
			if (isFocused === true) {
				const node = containerRef.current;
				node.focus();
			}
		});
		return () => {
			cancelAnimationFrame(rafId);
		};
	}, [isFocused]);

	const isSelected = currentValue === value;
	return (
		<TouchableOpacity
			ref={(ref) => (containerRef.current = findNodeHandle(ref))}
			onPress={() => {
				setFocusIndex(index);
				onChange(value);
			}}
			onFocus={() => setFocusIndex(index)}
			accessibilityRole='radio'
			aria-checked={isSelected}
			accessible={isFocused || isSelected}
			style={[radioStyles.optionContainer, containerStyle]}>
			<ButtonComponent
				label={label}
				isFocused={isFocused}
				isSelected={isSelected}
			/>
			<Image
				style={{
					height: 20,
					width: 20,
					marginHorizontal: 7,
				}}
				source={ic}
				resizeMode='contain'
			/>
		</TouchableOpacity>
	);
}

function DefaultButtonComponent({ isSelected, label }) {
	return (
		<>
			<View style={radioStyles.button} />
			{isSelected && (
				<View
					style={[StyleSheet.absoluteFill, radioStyles.buttonInner]}
				/>
			)}
			<Text style={radioStyles.label}>{label}</Text>
		</>
	);
}

const radioStyles = StyleSheet.create({
	optionContainer: {
		flexDirection: "row",
		alignItems: "center",
	},
	button: {
		position: "absolute",
		top: 2,
		left: 0,
		width: 14,
		height: 14,
		borderRadius: 14,
		borderWidth: 1,
	},
	buttonInner: {
		position: "absolute",
		top: 2,
		left: 0,
		width: 14,
		height: 14,
		borderRadius: 14,
		backgroundColor: "#7E98ED",
		transform: [{ scale: 0.6 }],
	},
	label: {
		marginLeft: 20,
		fontFamily: "Gilroy-Regular",
	},
});
