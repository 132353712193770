import React, { useState, useEffect, useRef } from "react";
import {
	StyleSheet,
	Text,
	View,
	ScrollView,
	Dimensions,
	Image,
	Platform,
	ToastAndroid,
	Alert,
	TouchableOpacity,
} from "react-native";

//components
import BackFab from "../../components/Common/BackFab";
import RatingFiveStar from "../../components/Utils/RatingFiveStar";
import CommentBox from "../../components/Utils/CommentBox";
import ProceedBarButton from "../../components/Buttons/ProceedBarButton";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils.web";
import Clipboard from "@react-native-clipboard/clipboard";
import BorderedButton from "../../components/Buttons/NonFilledButton";
import TopFeedbackBar from "../../components/WebOnly/TopFeedbackBar";

//assets
import no_image from "../../assets/Defaults/DCS_no_image.png";
import share_ic from "../../assets/Icons/DCS_share_ic.png";
import check_ic from "../../assets/Icons/DCS_round_bordered_check.png";
import PlaceHolderShimmerWeb from "../../components/Common/PlaceHolderShimmer.web";
import FirebaseWebInstance from "../../Utils/FirebaseWebInstance.web";
import { logEvent } from "@firebase/analytics";

const ServiceDetailScreen = ({ route, navigation }) => {
	const { serviceID } = route.params;
	const [window, setWindow] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));
	const [sliderState, setSliderState] = useState({ currentPage: 0 });
	const scrollView = useRef();

	const [servicesData, setServicesData] = useState(null);
	const [fetchingData, setFetchingData] = useState(true);
	const [selectedCenter, setSelectedCenter] = useState(-1);
	//possible states, "loading", "error", "done"
	const [loadingState, setLoadingState] = useState("loading");

	const [feedbackText, setFeedbackText] = useState("");
	const [feedbackNature, setFeedbackNature] = useState("");
	const [feedbackVisiblity, setFeedbackVisibility] = useState(false);

	const analytics = FirebaseWebInstance.getInstance();

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setWindow(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	const notifyMessage = (message) => {
		if (Platform.OS === "android") {
			ToastAndroid.show(message, ToastAndroid.SHORT);
		} else {
			Alert.alert(message);
		}
	};

	useEffect(() => {
		setLoadingState("loading");
		axiosInstance({
			method: "get",
			url: `auto-care/service-types/${serviceID}/`,
		})
			.then((response) => {
				try {
					if (response.status >= 200 && response.status < 300) {
						handleServiceDetailsResponse(response.data);
					} else {
						notifyMessage(
							"Error connecting to our servers. Try again later."
						);
						setLoadingState("error");
					}
				} catch (error) {
					setLoadingState("error");
					console.log(error);
					notifyMessage(
						"Something went wrong, processing your request. Try again later."
					);
				}
			})
			.catch((onrejected) => {
				setLoadingState("error");
				setFeedbackNature("error");
				setFeedbackVisibility(true);
				Utils.handleFailedResponse(
					"getServicesByID",
					onrejected.response,
					onrejected.response.status,
					navigation,
					setFeedbackText
				);
			});
	}, []);

	const calculateAverageRating = (array) => {
		let sumOfRating = 0.0;
		array.forEach((review) => {
			sumOfRating += review.rating;
		});
		return (sumOfRating / array.length).toFixed(2);
	};

	const handleServiceDetailsResponse = (response) => {
		console.log(response);
		setServicesData(response);
		setLoadingState("done");
	};

	return (
		<View style={styles.container}>
			<TopFeedbackBar
				feedbackNature={feedbackNature}
				setShow={setFeedbackVisibility}
				show={feedbackVisiblity}
				text={feedbackText}
			/>
			<ScrollView
				showsVerticalScrollIndicator={false}
				contentContainerStyle={{
					flexGrow: 1,
					paddingBottom: window.width > 500 ? 60 : "40%",
					width: window.width < 800 ? window.width : "70%",
					maxWidth: 900,
					alignSelf: "center",
					backgroundColor: "#fff",
					paddingHorizontal: 20,
				}}>
				<View
					style={{
						flexDirection:
							window.width < 800 ? "column-reverse" : "row",
						paddingVertical: 20,
						alignItems: "center",
					}}>
					{loadingState !== "done" ? (
						<View style={{ marginStart: 25, marginVertical: 20 }}>
							{loadingState == "error" ? (
								<Text>---</Text>
							) : (
								<View>
									<PlaceHolderShimmerWeb
										height={20}
										width={130}
										radius={5}
									/>
									<PlaceHolderShimmerWeb
										height={16}
										width={150}
										radius={5}
									/>
									<PlaceHolderShimmerWeb
										height={16}
										width={170}
										radius={5}
									/>
								</View>
							)}
						</View>
					) : (
						<View
							style={{
								backgroundColor: "white",
								justifyContent: "center",
								flex: 1,
							}}>
							<View
								style={{
									marginStart: 25,
									marginTop: 20,
								}}>
								<Text
									style={{
										fontSize: 20,
										fontFamily: "Gilroy-Regular",
									}}>
									{servicesData.name}
								</Text>
								{servicesData.display_price !== null &&
								servicesData.display_price !== "" ? (
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}>
										<Text
											style={{
												fontSize: 14,
												color: "#585C74",
												marginTop: 3,
												fontFamily: "Gilroy-Regular",
											}}>
											Starting from
										</Text>
										<Text
											style={{
												fontSize: 15,
												fontFamily: "Gilroy-SemiBold",
											}}>
											{" " + servicesData.display_price}
										</Text>
									</View>
								) : null}
							</View>
							<View
								style={{
									marginStart: 25,
									marginVertical: 10,
								}}>
								<Text
									style={{
										fontSize: 13,
										color: "#585C74",
										fontFamily: "Gilroy-Regular",
									}}>
									{servicesData.description}
								</Text>
							</View>
						</View>
					)}
					<View
						style={{
							flexDirection: "row",
							height: 200,
							width: 350,
						}}>
						<Image
							source={
								loadingState !== "done" ||
								servicesData.image == null
									? no_image
									: { uri: servicesData.image + "" }
							}
							resizeMode={
								loadingState !== "done" ||
								servicesData.image == null
									? "contain"
									: "cover"
							}
							style={{
								height: "100%",
								width: "100%",
								borderRadius: 7,
								overflow: "hidden",
							}}
						/>
					</View>
				</View>
				<View
					style={{
						flexDirection: window.width < 800 ? "column" : "row",
					}}>
					<View style={{ flex: 1 }}>
						<View
							style={{
								backgroundColor: "white",
								paddingStart: 25,
								paddingTop: 10,
								paddingBottom: 20,
							}}>
							<Text
								style={{
									fontSize: 18,
									fontFamily: "Gilroy-Regular",
								}}>
								Details
							</Text>
							{loadingState == "done" ? (
								<View>
									{servicesData.features
										.replace("- ", "")
										.split("\n")
										.map((item, i) => {
											return (
												<View
													key={i}
													style={{ marginTop: 8 }}>
													<View
														style={{
															flexDirection:
																"row",
														}}>
														<Image
															source={check_ic}
															style={{
																height: 20,
																width: 20,
															}}
															resizeMode='contain'
														/>
														<Text
															style={{
																fontSize: 15,
																marginStart: 10,
																color: "#585C74",
																fontFamily:
																	"Gilroy-Regular",
															}}>
															{item}
														</Text>
													</View>
												</View>
											);
										})}
								</View>
							) : (
								<PlaceHolderShimmerWeb
									height={16}
									width={150}
									radius={5}
								/>
							)}
						</View>
						<View
							style={{
								backgroundColor: "white",
								paddingStart: 25,
								paddingTop: 10,
								paddingBottom: 20,
							}}>
							<Text
								style={{
									fontSize: 18,
									fontFamily: "Gilroy-Regular",
								}}>
								Terms and Conditions
							</Text>
							{loadingState == "done" ? (
								<View>
									{servicesData.terms != null &&
										servicesData.terms
											.replace("- ", "")
											.split("\n")
											.map((item, i) => {
												return (
													<View
														style={{ marginTop: 8 }}
														key={i}>
														<View>
															<Text
																style={{
																	fontSize: 12,
																	marginStart: 10,
																	color: "#585C74",
																	fontFamily:
																		"Gilroy-Regular",
																}}>
																{`\u2022 ${item}`}
															</Text>
														</View>
													</View>
												);
											})}
								</View>
							) : (
								<View>
									<PlaceHolderShimmerWeb
										height={16}
										width={150}
										radius={5}
									/>
									<PlaceHolderShimmerWeb
										height={16}
										width={150}
										radius={5}
									/>
								</View>
							)}
						</View>
						<View
							style={{
								backgroundColor: "white",
								paddingStart: 25,
								paddingTop: 10,
								paddingBottom: 20,
								flex: 1,
							}}>
							<Text
								style={{
									fontSize: 18,
									fontFamily: "Gilroy-Regular",
								}}>
								Customer Reviews
							</Text>
							{loadingState !== "done" ? (
								<View>
									{loadingState == "error" ? (
										<Text>---</Text>
									) : (
										<PlaceHolderShimmerWeb
											height={40}
											width={180}
											radius={5}
										/>
									)}
								</View>
							) : servicesData.reviews.length == 0 ? (
								<View style={{ marginTop: 10 }}>
									<Text
										style={{
											color: "#bbb",
											fontFamily: "Gilroy-Regular",
										}}>
										{"No Reviews :("}
									</Text>
								</View>
							) : (
								<View>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}>
										<RatingFiveStar
											styles={{ marginVertical: 8 }}
											rating={calculateAverageRating(
												servicesData.reviews
											)}
										/>
										<Text
											style={{
												fontSize: 16,
												color: "#7E98ED",
												fontFamily: "Gilroy-SemiBold",
											}}>
											{calculateAverageRating(
												servicesData.reviews
											)}
										</Text>
										<Text
											style={{
												fontSize: 14,
												color: "#7E98ED",
												marginStart: 3,
												fontFamily: "Gilroy-Regular",
											}}>
											(10,000+ verified buyers)
										</Text>
									</View>
									{servicesData.reviews.map((review, i) => {
										return (
											<CommentBox
												key={i}
												rating={review.rating}
												styles={{ marginVertical: 10 }}
												title={review.title}
												body={review.comment}
												name={review.first_name}
												date={Utils.getLocalTimeFromUTC(
													review.created,
													"dddd Do MMM"
												)}
											/>
										);
									})}
								</View>
							)}
						</View>
					</View>
					{window.width < 800 ? null : (
						<View
							style={{
								height: 400,
								width: 1,
								marginHorizontal: 7,
								backgroundColor: "#eee",
								alignSelf: "center",
							}}
						/>
					)}
					<View
						style={{
							paddingTop: 10,
							paddingBottom: 20,
						}}>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
								marginTop: 10,
							}}>
							<Text
								style={{
									fontSize: 18,
									paddingLeft: 20,
									fontFamily: "Gilroy-Regular",
								}}>
								Service Providers
							</Text>
							{loadingState == "done" &&
							servicesData.service_centers.length > 0 ? (
								<BorderedButton
									title={"See more"}
									style={{
										height: 35,
										alignItems: "center",
										marginRight: 20,
									}}
									fontStyle={{ fontSize: 12 }}
									onPress={() => {
										navigation.navigate("exploreoptions", {
											display: "centers",
											show: `service_type_as_${servicesData.id}`,
										});
									}}
								/>
							) : null}
						</View>
						{loadingState != "done" ? (
							<View>
								<PlaceHolderShimmerWeb
									height={60}
									width={320}
									radius={5}
								/>
								<PlaceHolderShimmerWeb
									height={60}
									width={320}
									radius={5}
								/>
							</View>
						) : servicesData.service_centers.length > 0 ? (
							<View style={{ alignItems: "center" }}>
								{servicesData.service_centers
									.slice(0, 3)
									.map((item, i) => {
										return (
											<TouchableOpacity
												key={i}
												onPress={() => {
													logEvent(
														analytics,
														"service_provider_for_service",
														{
															serviceID:
																servicesData
																	?.services[
																	i
																],
															companyID: item.id,
														}
													);
													navigation.navigate(
														"bookservice",
														{
															companyID: item.id,
															serviceID:
																servicesData
																	?.services[
																	i
																],
														}
													);
												}}
												style={{
													height: 150,
													maxHeight: 190,
													width: "100%",
													maxWidth:
														window.width > 400
															? 450
															: 350,
													marginTop: 10,
													borderColor: "#ddd",
													borderWidth: 1,
													borderRadius: 7,
													flexDirection: "row",
													overflow: "hidden",
												}}>
												<View
													style={{
														flex: 6,
														padding: 5,
														paddingLeft: 20,
													}}>
													<View
														style={{
															flexDirection:
																"row",
															alignItems:
																"center",
															justifyContent:
																"space-between",
														}}>
														<Text
															style={{
																fontFamily:
																	"Gilroy-SemiBold",
																fontSize: 17,
															}}>
															{
																item.services[0]
																	.display_price
															}
														</Text>
														<Text
															style={{
																backgroundColor:
																	"#4CAF50",
																borderRadius: 7,
																padding: 3,
																fontSize: 14,
																color: "#fff",
																padding: 5,
																fontFamily:
																	"Gilroy-SemiBold",
															}}>
															{item.rating}
														</Text>
													</View>
													<Text
														style={{
															fontSize: 15,
															color: "#262A38",
															fontFamily:
																"Gilroy-SemiBold",
															marginTop: 5,
														}}>
														{item.name}
													</Text>
													<Text
														style={{
															fontSize: 14,
															color: "#52566C",
															marginTop: 5,
															fontFamily:
																"Gilroy-Regular",
														}}>
														{item.location.address}
													</Text>
												</View>
												<View style={{ flex: 4 }}>
													<Image
														source={
															item.image == null
																? no_image
																: {
																		uri: item.image,
																  }
														}
														style={{
															height: "100%",
															width: "100%",
														}}
														resizeMode='cover'
													/>
												</View>
											</TouchableOpacity>
										);
									})}
							</View>
						) : (
							<Text
								style={{
									color: "#bbb",
									marginTop: 10,
									marginLeft: 20,
									fontFamily: "Gilroy-Regular",
								}}>
								No other providers...
							</Text>
						)}
					</View>
				</View>
			</ScrollView>
			{/* {loadingState !== "done" ? null : (
				<ProceedBarButton
					onPress={() =>
						selectedCenter == -1
							? notifyMessage("Please select a service center")
							: navigation.navigate("ServiceBookingScreen", {
									serviceObj: serviceObj,
									locationData: locationData,
									updateAddress: updateAddress,
									companyID:
										selectedCenter == -1
											? companyID
											: selectedCenter,
									isFastOrder: false,
							  })
					}
					proceedText={
						<View>
							<Text style={{ color: "white", fontSize: 26 }}>
								Place Order
							</Text>
						</View>
					}
				/>
			)} */}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// backgroundColor: "white",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
});
export default ServiceDetailScreen;
