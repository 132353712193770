import React, { useState, useEffect } from "react";
import {
	StyleSheet,
	Text,
	View,
	Platform,
	ScrollView,
	Dimensions,
	TextInput,
	Switch,
	TouchableOpacity,
	Image,
} from "react-native";
// import RadioForm, {
// 	RadioButton,
// 	RadioButtonInput,
// 	RadioButtonLabel,
// } from "react-native-simple-radio-button";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils.web";
import { RadioGroup, RadioButton } from "../../components/WebOnly/RadioButton";
import WebMap from "../../components/WebOnly/WebMap";
// import { PERMISSIONS } from "react-native-permissions";

//components
import BackFab from "../../components/Common/BackFab";
import BandButton from "../../components/Buttons/BandButton";
import AddressBox from "../../components/Utils/AddressBox";
import NonFilledButton from "../../components/Buttons/NonFilledButton";
import SubmitButtonDark from "../../components/Buttons/SubmitButtonDark";

//assets
import curr_location_ic from "../../assets/Icons/DCS_curr_location_ic.png";

const EditAddressScreen = ({ route, navigation }) => {
	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));
	const [dimensions, setDimensions] = useState({ window, screen });

	const [errorTextName, setErrorTextName] = useState("");
	const [errorTextAddress, setErrorTextAddress] = useState("");
	const [errorTextCity, setErrorTextCity] = useState("");
	const [errorTextState, setErrorTextState] = useState("");
	const [errorTextPIN, setErrorTextPIN] = useState("");
	const [showMap, setShowMap] = useState(false);

	const [selectedAddress, setSelectedAddress] = useState(null);
	const {
		address,
		updateAddress,
		addressData,
		setAddressData,
		asPopup,
		showAddressPopup,
	} = route.params;
	const radio_props = [
		{
			label: "Home",
			value: 0,
		},
		{
			label: "Work",
			value: 1,
		},
	];
	const [buttonState, setButtonState] = useState(0);
	const [defaultAddress, setDefaultAddress] = useState(false);
	const toggleSwitch = () =>
		setDefaultAddress((previousState) => !previousState);
	const [errorText, setErrorText] = useState("");

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	const validateInput = () => {
		let validInput = true;
		if (selectedAddress.line1 == "" && selectedAddress.line2 == "") {
			setErrorTextAddress("This field cannot be empty");
			validInput = false;
		} else {
			setErrorTextAddress("");
		}

		if (selectedAddress.name == "") {
			setErrorTextName("This field cannot be empty");
			validInput = false;
		} else {
			setErrorTextName("");
		}

		if (selectedAddress.pin_code == "") {
			setErrorTextPIN("This field cannot be empty");
			validInput = false;
		} else if (
			!selectedAddress.pin_code.match(/^[0-9]+$/) ||
			selectedAddress.pin_code.length < 6
		) {
			setErrorTextPIN("This field can only be a 6 digit number");
			validInput = false;
		} else {
			setErrorTextPIN("");
		}

		if (selectedAddress.city == "") {
			setErrorTextCity("This field cannot be empty");
			validInput = false;
		} else {
			setErrorTextCity("");
		}

		if (selectedAddress.state == "") {
			setErrorTextState("This field cannot be empty");
			validInput = false;
		} else {
			setErrorTextState("");
		}

		return validInput;
	};

	const updateAddressData = (customAddressObj) => {
		address != null
			? axiosInstance({
					method: "patch",
					url: `auto-care/locations/${address.id}/`,
					data: {
						name: selectedAddress.name,
						line1: selectedAddress.line1,
						line2: selectedAddress.line2,
						location_type: buttonState == 0 ? "HOME" : "WORK",
						is_default: defaultAddress,
						pin_code: selectedAddress.pin_code,
						state: selectedAddress.state,
					},
			  })
					.then((response) => {
						if (response.status >= 200 && response.status < 300) {
							let temp = addressData.map((address) => {
								if (address.id == response.data.id) {
									return response.data;
								} else {
									address = { ...address, is_default: false };
									return address;
								}
							});
							setAddressData(temp);
							showAddressPopup(false);
							// updateAddress(temp);
							// navigateBack();
							// Utils.notifyMessage(
							// 	"Address updated successfully!"
							// );
						} else {
							setErrorText(
								"There was an error processing your request."
							);
							// Utils.notifyMessage(
							// 	"There was an error processing your request."
							// );
						}
					})
					.catch((onrejected) => {
						console.log(onrejected);
						Utils.handleFailedResponse(
							"patchLocationByID",
							onrejected.response,
							onrejected.response.status,
							navigation
						);
					})
			: axiosInstance({
					method: "post",
					url: `auto-care/locations/`,
					data: customAddressObj
						? {
								name: "New Address",
								latitude: customAddressObj.latitude,
								longitude: customAddressObj.longitude,
								line1: customAddressObj.line1,
								line2: customAddressObj.line2,
								pin_code: customAddressObj.pin_code,
								city: customAddressObj.city,
								is_service_center_location: false,
								state: customAddressObj.state,
								is_default: defaultAddress,
								location_type: "HOME",
						  }
						: {
								name: selectedAddress.name,
								latitude: 0,
								longitude: 0,
								line1: selectedAddress.line1,
								line2: selectedAddress.line2,
								pin_code: selectedAddress.pin_code,
								city: selectedAddress.city,
								is_service_center_location: false,
								state: selectedAddress.state,
								is_default: defaultAddress,
								location_type:
									buttonState == 0 ? "HOME" : "WORK",
						  },
			  })
					.then((response) => {
						if (response.status >= 200 && response.status < 300) {
							let temp = addressData.concat([response.data]);
							setAddressData(temp);
							showAddressPopup(false);
						} else {
							setErrorText(
								"There was an error processing your request."
							);
						}
					})
					.catch((onrejected) => {
						setErrorText(
							"There was an error processing your request"
						);
					});
	};

	useEffect(() => {
		address != null
			? setSelectedAddress({
					name: address.name != null ? address.name : "",
					line1: address?.line1,
					line2: address?.line2,
					id: address.id != null ? address.id : "",
					pin_code: address.pin_code,
					state: address.state,
					city: address.city,
			  })
			: setSelectedAddress({
					name: "",
					line1: "",
					line2: "",
					id: -1,
					pin_code: "",
					state: "",
					city: "",
			  });
		address != null && address.location_type != null
			? setButtonState(address.location_type == "HOME" ? 0 : 1)
			: setButtonState(0);
		setDefaultAddress(
			address != null && address.is_default != null && address.is_default
		);
	}, []);

	return (
		<View style={styles.container}>
			{asPopup || asPopup == "true" ? (
				<View
					style={[
						{
							width: "100%",
							height:
								windowSize.width > 400
									? (windowSize.height * 10) / 100
									: 70,
							borderBottomWidth: 1,
							borderBottomColor: "#ccc",
							flexDirection: "row",
							backgroundColor: "white",
						},
					]}>
					{/* Action Bar */}
					<View style={{ alignSelf: "center", marginStart: 20 }}>
						<Text
							style={{
								fontSize: 26,
								color: "#0E1B46",
								fontFamily: "Gilroy-SemiBold",
							}}>
							Edit Address
						</Text>
					</View>
				</View>
			) : null}
			{/* Body */}
			<ScrollView
				contentContainerStyle={{
					flexGrow: 1,
					maxWidth: 900,
				}}>
				{!showMap ? (
					<View
						style={{
							width: windowSize.width < 800 ? "100%" : "70%",
							alignSelf: "center",
							backgroundColor: "#f0ecfc",
						}}>
						<View style={styles.whiteBox}>
							<Text
								style={{
									marginStart: "5%",
									color: "#585C74",
									fontFamily: "Gilroy-Regular",
								}}>
								Name
							</Text>
							<TextInput
								color='#000'
								style={styles.textInput}
								placeholder='Enter your name'
								placeholderTextColor={"#bbb"}
								defaultValue={
									address != null ? address.name : ""
								}
								onChangeText={(t) =>
									setSelectedAddress({
										...selectedAddress,
										name: t,
									})
								}
							/>
							<Text
								style={{
									marginStart: "5%",
									color: "red",
									fontSize: 12,
									fontFamily: "Gilroy-Regular",
								}}>
								{errorTextName}
							</Text>
						</View>
						<View style={styles.whiteBox}>
							<Text
								style={{
									marginStart: "5%",
									color: "#585C74",
									fontFamily: "Gilroy-Regular",
								}}>
								Address line 1
							</Text>
							<TextInput
								color='#000'
								style={[styles.textInput, { height: 60 }]}
								placeholder='Enter the first line of your address'
								placeholderTextColor={"#bbb"}
								defaultValue={
									address != null ? address.line1 : ""
								}
								onChangeText={(t) =>
									setSelectedAddress({
										...selectedAddress,
										line1: t,
									})
								}
								multiline={true}
							/>
							<Text
								style={{
									marginStart: "5%",
									color: "red",
									fontSize: 12,
									fontFamily: "Gilroy-Regular",
								}}>
								{errorTextAddress}
							</Text>
							<Text
								style={{
									marginStart: "5%",
									color: "#585C74",
									fontFamily: "Gilroy-Regular",
								}}>
								Address line 2
							</Text>
							<TextInput
								color='#000'
								style={[styles.textInput, { height: 60 }]}
								placeholder='Enter the remainder of your address'
								placeholderTextColor={"#bbb"}
								defaultValue={
									address != null ? address.line2 : ""
								}
								onChangeText={(t) =>
									setSelectedAddress({
										...selectedAddress,
										line2: t,
									})
								}
								multiline={true}
							/>
							<View
								style={{
									flexDirection: "row",
									marginStart: "5%",
									marginTop: 10,
									color: "#585C74",
								}}>
								<View style={{ flex: 1 }}>
									<Text
										style={{
											color: "#585C74",
											fontFamily: "Gilroy-Regular",
										}}>
										City
									</Text>
									<TextInput
										color='#000'
										style={[
											styles.textInput,
											{ alignSelf: "flex-start" },
										]}
										placeholder='Enter your City'
										placeholderTextColor={"#bbb"}
										defaultValue={
											address != null ? address.city : ""
										}
										onChangeText={(t) =>
											setSelectedAddress({
												...selectedAddress,
												city: t,
											})
										}
									/>
									<Text
										style={{
											marginStart: "5%",
											color: "red",
											fontSize: 12,
											fontFamily: "Gilroy-Regular",
										}}>
										{errorTextCity}
									</Text>
								</View>
								<View style={{ flex: 1 }}>
									<Text
										style={{
											color: "#585C74",
											fontFamily: "Gilroy-Regular",
										}}>
										Pin
									</Text>
									<TextInput
										color='#000'
										style={[
											styles.textInput,
											{ alignSelf: "flex-start" },
										]}
										placeholder='Enter your PIN code'
										maxLength={6}
										textContentType='postalCode'
										placeholderTextColor={"#bbb"}
										defaultValue={
											address != null
												? address.pin_code
												: ""
										}
										onChangeText={(t) =>
											setSelectedAddress({
												...selectedAddress,
												pin_code: t,
											})
										}
										keyboardType='number-pad'
									/>
									<Text
										style={{
											marginStart: "5%",
											color: "red",
											fontSize: 12,
											fontFamily: "Gilroy-Regular",
										}}>
										{errorTextPIN}
									</Text>
								</View>
							</View>
							<View>
								<Text
									style={{
										marginStart: "5%",
										marginTop: 10,
										color: "#585C74",
										fontFamily: "Gilroy-Regular",
									}}>
									State
								</Text>
								<TextInput
									color='#000'
									style={styles.textInput}
									placeholder='Enter your State'
									placeholderTextColor={"#bbb"}
									defaultValue={
										address != null ? address.state : ""
									}
									onChangeText={(t) =>
										setSelectedAddress({
											...selectedAddress,
											state: t,
										})
									}
								/>
								<Text
									style={{
										marginStart: "5%",
										color: "red",
										fontSize: 12,
										fontFamily: "Gilroy-Regular",
									}}>
									{errorTextState}
								</Text>
							</View>
							{/* <Text
						style={{
							marginStart: "5%",
							marginTop: 10,
							color: "#585C74",
						}}>
						Landmark
					</Text>
					<TextInput
						style={styles.textInput}
						placeholder='Enter the closest Landmark to you'
						placeholderTextColor={"#bbb"}
						multiline={true}
					/> */}
						</View>
						<View style={styles.whiteBox}>
							<Text
								style={{
									marginStart: "5%",
									marginTop: 10,
									color: "#585C74",
									fontFamily: "Gilroy-Regular",
								}}>
								Type of Address
							</Text>
							<RadioGroup
								value={buttonState}
								labelID={"label"}
								onChange={(id) => {
									setButtonState(id);
								}}
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									width: 250,
									marginStart: "5%",
									marginTop: 16,
								}}>
								{radio_props.map((item, i) => (
									<RadioButton key={i} {...item} />
								))}
							</RadioGroup>
							{address == null ? (
								<View>
									<Text style={{ alignSelf: "center" }}>
										OR
									</Text>
									<View
										style={[
											styles.whiteBox,
											{ marginLeft: 12 },
										]}>
										<TouchableOpacity
											onPress={() => {
												setShowMap(true);
											}}
											style={{
												marginLeft: 20,
												margin: 5,
												flexDirection: "row",
											}}>
											<Image
												source={curr_location_ic}
												style={{
													height: 20,
													width: 20,
													marginRight: 5,
												}}
												resizeMode='contain'
											/>
											<Text
												style={{
													color: "#7E98ED",
													fontFamily:
														"Gilroy-SemiBold",
												}}>
												Locate on Map
											</Text>
										</TouchableOpacity>
									</View>
								</View>
							) : null}
							{/* <RadioForm
						style={{
							flexDirection: "row",
							marginStart: "5%",
							marginTop: 16,
						}}>
						{radio_props.map((item, i) => {
							return (
								<RadioButton
									labelHorizontal={true}
									key={i}
									style={{
										alignItems: "center",
										marginEnd: 20,
									}}
									onPress={() => setButtonState(i)}>
									<RadioButtonInput
										obj={item}
										index={i}
										isSelected={i == buttonState}
										onPress={() => setButtonState(i)}
										borderWidth={2}
										buttonInnerColor={"#7E98ED"}
										buttonOuterColor={updateRimColor(i)}
										buttonSize={6}
										buttonOuterSize={16}
									/>
									<RadioButtonLabel
										obj={item}
										index={i}
										labelHorizontal={true}
										onPress={() => setButtonState(i)}
										labelStyle={{
											fontSize: 14,
										}}
									/>
								</RadioButton>
							);
						})}
					</RadioForm> */}
						</View>
					</View>
				) : (
					<WebMap
						showMap={setShowMap}
						setNewAddress={updateAddressData}
					/>
				)}
			</ScrollView>
			<View
				style={{
					height: 138,
					width: "100%",
					backgroundColor: "#fff",
					position: "absolute",
					bottom: 0,
					borderTopWidth: 1,
					borderTopColor: "#ddd",
					padding: 10,
				}}>
				{errorText != "" ? (
					<Text
						style={{
							color: "red",
							alignSelf: "center",
							margin: 5,
							fontFamily: "Gilroy-Regular",
						}}>
						{errorText}
					</Text>
				) : null}
				<View style={{ flexDirection: "row", alignItems: "center" }}>
					<Switch
						trackColor={{
							false: "#767577",
							true: "#81b0ff",
						}}
						thumbColor={"#eee"}
						onValueChange={toggleSwitch}
						value={defaultAddress}
					/>
					<Text
						style={{
							marginStart: 15,
							fontSize: 16,
							fontFamily: "Gilroy-Regular",
						}}>
						Set this default address?
					</Text>
				</View>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						paddingHorizontal: 10,
						position: "absolute",
						bottom: Platform.OS == "ios" ? 40 : 20,
						right: 10,
						left: 10,
					}}>
					<NonFilledButton
						title={"Cancel"}
						onPress={() => showAddressPopup(false)}
						style={{ borderColor: "#1D3688" }}
					/>
					<SubmitButtonDark
						title={"Save details"}
						onPress={() => {
							if (validateInput()) {
								updateAddressData();
							}
						}}
					/>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#f0ecfc",
		paddingBottom: 140,
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	textInput: {
		borderBottomWidth: 1,
		borderBottomColor: "#ddd",
		borderRadius: 6,
		width: "90%",
		padding: 7,
		alignSelf: "center",
		outlineColor: "#7e98ed",
		backgroundColor: "#f5faff",
		fontFamily: "Gilroy-Regular",
	},
	whiteBox: {
		backgroundColor: "#fff",
		paddingVertical: 20,
		marginVertical: 5,
		borderBottomWidth: 1,
		borderBottomColor: "#ddd",
	},
});
export default EditAddressScreen;
