import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebaseConfig from "../firebase-web-config.json";

export default class FirebaseWebInstance {
	static fbInstance = null;
	static getInstance() {
		if (FirebaseWebInstance.fbInstance == null) {
			// Initialize Firebase
			const app = initializeApp(firebaseConfig);
			// Initialize Analytics and get a reference to the service
			const analytics = getAnalytics(app);
			FirebaseWebInstance.fbInstance = analytics;
		}
		return this.fbInstance;
	}
}
