import {
	View,
	Button,
	StyleSheet,
	TouchableHighlight,
	Text,
	Image,
} from "react-native";
import React from "react";

//assets
import add_ic from "../../assets/Icons/DCS_add_ic.png";

const BandButton = ({ onPress, text, style }) => (
	<TouchableHighlight
		activeOpacity={0.5}
		underlayColor='#6886e8'
		onPress={onPress}
		style={[styles.appButtonContainer, style]}>
		<View
			style={{
				flexDirection: "row",
				alignItems: "center",
				paddingStart: 10,
			}}>
			<Image
				style={{ height: 30, width: 30, tintColor: "#fff" }}
				source={add_ic}
				resizeMode='contain'
			/>
			<Text style={styles.appButtonText}>{text}</Text>
		</View>
	</TouchableHighlight>
);

const styles = StyleSheet.create({
	appButtonContainer: {
		paddingVertical: 7,
		paddingHorizontal: 7,
		backgroundColor: "#7E98ED",
	},
	appButtonText: {
		fontSize: 16,
		color: "#fff",
	},
});

export default BandButton;
