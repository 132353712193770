import React, { useState, useEffect } from "react";
import { View, Dimensions, Image, TouchableOpacity, Modal } from "react-native";

//assets
import cross_ic from "../../assets/Icons/DCS_add_ic.png";
import { Text } from "react-native";

const FloatingModal = ({
	isVisible,
	containerStyle,
	innerContainerStyle,
	contentStyle,
	onClose,
	children,
	height,
	modalTitle,
}) => {
	return (
		<Modal visible={isVisible} transparent animationType={"fade"}>
			<View
				style={[
					{
						width: "100%",
						height: "100%",
						backgroundColor: "#00000050",
						justifyContent: "center",
					},
					containerStyle,
				]}>
				<View
					style={[
						{
							alignSelf: "center",
							borderRadius: 20,
							borderWidth: 1,
							borderColor: "#ccc",
							height: height || 800,
							width: "100%",
							maxWidth: 1000,
							backgroundColor: "#fff",
							position: "absolute",
						},
						innerContainerStyle,
					]}>
					{/* close modal */}
					<TouchableOpacity
						onPress={onClose}
						style={{
							flexDirection: "row",
							width: "100%",
							alignItems: "center",
						}}>
						<Text
							style={{
								flex: 4,
								textAlign: "center",
								fontFamily: "Gilroy-SemiBold",
								fontSize: 18,
							}}>
							{modalTitle || ""}
						</Text>
						<View style={{ flex: 1 }}>
							<Image
								source={cross_ic}
								style={{
									height: 35,
									width: 35,
									transform: [{ rotate: "45deg" }],
									alignSelf: "flex-end",
									margin: 5,
								}}
								resizeMode='contain'
							/>
						</View>
					</TouchableOpacity>
					<View style={[{ flex: 1 }, contentStyle]}>{children}</View>
				</View>
			</View>
		</Modal>
	);
};

export default FloatingModal;
