import React, { useState, useEffect, useRef, useMemo } from "react";
import {
	StyleSheet,
	Text,
	View,
	Image,
	ScrollView,
	Dimensions,
} from "react-native";
//todo replace individual images with these combined
import splash1Image from "../../assets/Images/DCS_splash_1.png";
import splash2Image from "../../assets/Images/DCS_splash_2.png";
import splash3Image from "../../assets/Images/DCS_splash_3.png";
import logo from "../../assets/Images/ic_launcher-playstore.png";
import BorderedButton from "../../components/Buttons/NonFilledButton";
import FilledButton from "../../components/Buttons/SubmitButtonDark";

import { Link } from "@react-navigation/native";

import themes from "../../Styles/themes";
import Utils from "../../Utils/Utils.web";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Splash = ({ navigation }) => {
	const window = Dimensions.get("window");
	const screen = Dimensions.get("screen");
	const [dimensions, setDimensions] = useState({ window, screen });
	const [modHeadingFontSize, setModHeadFontSize] = useState(10);
	const [modBodyFontSize, setModBodyFontSize] = useState(7);

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setDimensions({ window, screen });
			}
		);
		return () => subscription?.remove();
	}, []);

	useEffect(() => {
		var newHeadingFontSize = Math.floor((6.95 * screen.width) / 100);
		var newBodyFontSize = Math.floor((5 * screen.width) / 100);
		setModHeadFontSize(newHeadingFontSize >= 30 ? 30 : newHeadingFontSize);
		setModBodyFontSize(newBodyFontSize >= 20 ? 20 : newBodyFontSize);
	}, [screen]);

	const StockImage = ({ stockImage }) => {
		const memoizedComp = useMemo(() => {
			return (
				<View
					style={{
						width: "30vw",
						minWidth: 170,
						maxWidth: 220,
						aspectRatio: 1 / 1,
						alignSelf: "center",
						paddingTop: 20,
					}}>
					<Image
						source={stockImage}
						style={{
							height: "100%",
							width: "100%",
						}}
						resizeMode='contain'
					/>
				</View>
			);
		}, []);
		return <View style={{ flex: 1 }}>{memoizedComp}</View>;
	};

	const ContentPassage = ({ titleText, bodyText }) => {
		return (
			<View
				style={{
					flex: 1,
					paddingStart: 20,
				}}>
				<Text
					style={{
						flex: 1,
						color: "#fff",
						fontSize: modHeadingFontSize,
					}}>
					{titleText}
				</Text>
				{/* body textview */}
				<View
					style={{
						flex: 2,
					}}>
					<Text
						style={{
							color: "#fff",
							fontSize: modBodyFontSize,
						}}>
						{bodyText}
					</Text>
				</View>
			</View>
		);
	};

	return (
		<View style={styles.splashContainer}>
			{/* toppest layer, display logo and skip button to skip intro */}
			<View style={styles.top}>
				<View
					style={{
						height: 40,
						width: 80,
					}}>
					<Image
						source={logo}
						style={{
							height: "100%",
							width: "100%",
							overflow: "hidden",
							borderRadius: 200,
						}}
						resizeMode='cover'
					/>
				</View>
			</View>
			{/* scrollview to scroll between pages of splash intros, only images and text will be scrolled */}
			<ScrollView
				contentContainerStyle={{
					flex: 1,
					width: "90vw",
					maxWidth: 950,
					alignSelf: "center",
					alignItems: "center",
				}}>
				<View
					style={{
						flex: 1,
						backgroundColor: themes.secondaryColor,
						flexDirection: screen.width < 840 ? "column" : "row",
						alignItems: "center",
					}}>
					{/* parent for splash page 1 containing images and text*/}
					<View
						style={{
							flex: 1,
							minHeight: "60vh",
							backgroundColor: themes.primaryColor,
							padding: 10,
							margin: 10,
							borderRadius: 10,
							marginTop: screen.width < 840 ? 10 : 30,
							maxWidth: 320,
						}}>
						<StockImage stockImage={splash1Image} />
						<ContentPassage
							titleText={"Authorized Auto Service Centers"}
							bodyText={
								"Discover top rated authorized service centers for your Car and Bike"
							}
						/>
					</View>
					{/* parent for splash page 2 containing images and text*/}
					<View
						style={{
							flex: 1,
							minHeight: "60vh",
							backgroundColor: themes.primaryColor,
							padding: 10,
							margin: 10,
							borderRadius: 10,
							maxWidth: 320,
						}}>
						<StockImage stockImage={splash2Image} />
						<ContentPassage
							titleText={"Services & Offers"}
							bodyText={
								"Discover service offerings and promotional offers at your nearest service centers"
							}
						/>
					</View>
					{/* parent for splash page 3 containing images and text*/}
					<View
						style={{
							flex: 1,
							minHeight: "60vh",
							backgroundColor: themes.primaryColor,
							padding: 10,
							margin: 10,
							borderRadius: 10,
							marginBottom: 40,
							maxWidth: 320,
						}}>
						<StockImage stockImage={splash3Image} />
						<ContentPassage
							titleText={"Keep an eye"}
							bodyText={
								"Track status of your vehicle while it is being serviced at service center"
							}
						/>
					</View>
				</View>
			</ScrollView>
			<View
				style={[
					{
						width: "100%",
						backgroundColor: "#fff",
						alignItems: "center",
					},
					screen.width < 840
						? {
								borderTopWidth: 1,
								borderTopColor: "#ccc",
								paddingBottom: 60,
						  }
						: null,
				]}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "center",
						backgroundColor: "#fff",
						margin: 20,
					}}>
					<Link to='/login'>
						<FilledButton
							onPress={() => {}}
							title={"LOG IN"}
							style={{ width: 140, marginRight: 5 }}
						/>
					</Link>
					<Link to='/register'>
						<BorderedButton
							onPress={() => {}}
							title={"SIGN UP"}
							style={{
								width: 140,
								justifyContent: "center",
								marginLeft: 5,
							}}
						/>
					</Link>
				</View>
				<Text style={{ marginBottom: 10, color: "#bababa" }}>OR</Text>
				<Link
					style={{ marginBottom: 30 }}
					to='/home'
					onPress={() => {
						AsyncStorage.setItem("guestUser", "true").then(() => {
							axiosInstance.defaults.headers.common[
								"Authorization"
							] = "";
							AsyncStorage.removeItem("auth-key");
						});
					}}>
					<Text
						style={{ color: themes.primarySkyBlue, fontSize: 18 }}>
						PROCEED AS GUEST
					</Text>
				</Link>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	splashContainer: {
		height: "100vh",
		width: "100%",
		backgroundColor: themes.secondaryColor,
	},
	top: {
		flexDirection: "row",
		alignItems: "center",
		paddingHorizontal: 10,
		height: 50,
		borderBottomWidth: 1,
		borderBottomColor: "#ccc",
	},
	paginationWrapper: {
		position: "absolute",
		bottom: 50,
		left: 0,
		right: 0,
		alignItems: "center",
		flexDirection: "row",
	},
	paginationDots: {
		height: 10,
		width: 10,
		borderRadius: 10 / 2,
		marginLeft: 10,
	},
});

export default Splash;
