import React, { useState } from "react";
import {
	StyleSheet,
	Text,
	View,
	TouchableOpacity,
	Platform,
	Image,
	Pressable,
} from "react-native";
import FImage from "../Common/ImageInterface";

const ClickableCard = ({
	onPress,
	icon,
	iconHeight,
	iconWidth,
	borderColor,
	text,
	isDisabled,
	isUrl,
	extraStyle,
	extraOuterStyle,
}) => {
	const [hovered, setHovered] = useState(false);
	return (
		<Pressable
			onHoverIn={() => setHovered(true)}
			onHoverOut={() => setHovered(false)}
			onPress={onPress}
			disabled={isDisabled}
			style={[
				{
					alignItems: "center",
					width: 110,
					margin: 5,
				},
				extraOuterStyle,
			]}>
			<View
				style={[
					styles.buttonContainer,
					{
						borderColor: Platform.select({
							web: hovered ? borderColor : "#fff",
							android: borderColor,
							ios: borderColor,
						}),
						borderRadius: 10,
						borderWidth: 2,
					},
					extraStyle,
				]}>
				<FImage
					source={isUrl ? { uri: icon } : icon}
					style={{ width: iconWidth, height: iconHeight }}
					resizeMode='cover'
				/>
			</View>
			<Text
				style={{
					marginTop: 7,
					fontFamily: "Gilroy-Regular",
					color: hovered ? "#7E98ED" : "#000",
				}}>
				{text}
			</Text>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	buttonContainer: {
		alignItems: "center",
		overflow: "hidden",
	},
});

export default ClickableCard;
