import React, { useEffect, useState } from "react";
import {
	Text,
	View,
	TouchableOpacity,
	FlatList,
	Pressable,
} from "react-native";
// import { FlatList } from "react-native-gesture-handler";
import fontStyle from "../../Styles/typography";

//components
import ClickableCard from "../../components/Cards/ClickableCard";
import PlainBlockButton from "../Buttons/PlainBlockButton";

//assets
import no_image_ic from "../../assets/Defaults/DCS_no_image.png";

const GenericServiceComponent = ({ data, props }) => {
	const [dataObj, setObj] = useState([]);

	useEffect(() => {
		let tempArr = data.items.slice();
		if (props.windowDim.width < 540) {
			tempArr.splice(3, tempArr.length - 4);
		} else if (props.windowDim.width > 380) {
			let rowFloat =
				tempArr.length /
				Math.floor((props.windowDim.width * 0.7) / 135);
			let widgetOffset =
				(((rowFloat - Math.floor(rowFloat)) / 2) *
					100 *
					props.windowDim.width) /
				100;
			let extraWidgets =
				Math.floor(widgetOffset / 135) + (Math.ceil(rowFloat) - 2);
			if (rowFloat >= 2) {
				tempArr.splice(tempArr.length - 1 - extraWidgets, extraWidgets);
			}
		}
		setObj(tempArr);
	}, []);

	const renderCard = ({ item }) => {
		return item.name != "extra" ? (
			<ClickableCard
				borderColor='#7E98ED'
				icon={item.image == null ? no_image_ic : item.image}
				iconHeight={92}
				iconWidth={120}
				onPress={() => {
					props.navigation.navigate("service", {
						serviceID: item.id,
					});
				}}
				text={item.name}
				isUrl={item.image != null}
				extraOuterStyle={{
					margin: 10,
				}}
			/>
		) : (
			<PlainBlockButton
				onPress={() => {
					props.navigation.navigate("exploreoptions", {
						display: "services",
					});
				}}
				text={"Explore Services"}
			/>
		);
	};

	return (
		<View style={{ width: "100%" }}>
			{data.items.length > 0 ? (
				<View>
					<Text
						style={[
							fontStyle.fontTitle,
							// fontStyle.fontFamilyAvenir,
							{
								marginLeft: 10,
								marginTop: 10,
								fontFamily: "Gilroy-SemiBold",
							},
						]}>
						{data.name}
					</Text>
					<View
						style={{
							paddingHorizontal: 10,
							marginTop: 10,
							alignItems: "center",
							// height: animHeight,
						}}>
						{console.log(props)}
						<FlatList
							data={dataObj}
							renderItem={renderCard}
							showsHorizontalScrollIndicator={false}
							showsVerticalScrollIndicator={false}
							numColumns={
								props.windowDim.width < 380
									? 2
									: Math.floor(
											(props.windowDim.width * 0.6) / 135
									  ) //count how many items of 135 width would fit in the 60% of available window width, top level container is 70% so it can't more than that
							}
							keyExtractor={(item) => item.id}
							scrollEnabled={false}
						/>
						{/* {popularServiceData.items.length /
							Math.floor(screen.width / 125) >=
						2 ? (
							<View
								style={{
									height: 30,
									bottom: 0,
									backgroundColor: "#fff",
									marginHorizontal: 5,
									borderWidth: 1,
									borderColor: "#ddd",
									borderRadius: 7,
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "row",
								}}
								onTouchEnd={() => {
									isCollapsed
										? AnimateHeight("collapse")
										: AnimateHeight("expand");

									setIsCollapsed((prev) => !prev);
								}}>
								<Text style={{ color: "#585C74" }}>
									{!isCollapsed ? "See more" : "See less"}
								</Text>
								<View
									style={{
										height: 15,
										width: 15,
										marginStart: 10,
										borderWidth: 1,
										borderRadius: 15,
										borderColor: "#585C74",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<Animated.Image
										source={arrow_head_ic}
										style={{
											height: "80%",
											width: "80%",
											transform: [{ rotate: spin }],
										}}
										resizeMode='contain'
									/>
								</View>
							</View>
						) : null} */}
					</View>
				</View>
			) : null}
		</View>
	);
};

export default GenericServiceComponent;
