import {
	View,
	Button,
	StyleSheet,
	TouchableHighlight,
	Text,
} from "react-native";
import React from "react";

const NonFilledButton = ({ onPress, title, style, fontStyle }) => (
	<TouchableHighlight
		activeOpacity={0.5}
		underlayColor='#7E98ED'
		onPress={onPress}
		style={[styles.appButtonContainer, style]}>
		<Text style={[styles.appButtonText, fontStyle]}>{title}</Text>
	</TouchableHighlight>
);

const styles = StyleSheet.create({
	appButtonContainer: {
		borderRadius: 10,
		paddingVertical: 7,
		paddingHorizontal: 7,
		borderWidth: 2,
		borderColor: "#7E98ED",
	},
	appButtonText: {
		fontSize: 18,
		color: "#1D3688",
		alignSelf: "center",
		fontFamily: "Gilroy-Regular",
	},
});

export default NonFilledButton;
