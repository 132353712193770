import React, { useState, useEffect, useMemo } from "react";
import {
	StyleSheet,
	Text,
	View,
	Platform,
	ScrollView,
	Dimensions,
	Image,
	TouchableOpacity,
	TouchableHighlight,
	FlatList,
	Linking,
	TextInput,
} from "react-native";
//components
import BackFab from "../../components/Common/BackFab";
import NonFilledButton from "../../components/Buttons/NonFilledButton";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils";
import BottomPopUpModal from "../../components/Common/BottomPopUpModal";
import PopupDialogBox from "../../components/Common/PopupDialogBox.web";
import LoaderOverlay from "../../components/Common/LoaderOverlay.web";
import TopFeedbackBar from "../../components/WebOnly/TopFeedbackBar";

//assets
import headphone_ic from "../../assets/Icons/DCS_help_ic.png";
import default_img from "../../assets/Defaults/DCS_no_image.png";
import call_ic from "../../assets/Icons/DCS_call_ic.png";
import arrow_ic from "../../assets/Icons/DCS_arrow_head_ic.png";
// import { FlatList } from "react-native-gesture-handler";

const OrderTrackingScreen = ({ route, navigation }) => {
	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));
	const { orderID } = route.params;
	const [dimensions, setDimensions] = useState({ window, screen });

	const [orderProgressData, setOrderProgressData] = useState([]);
	const [serviceData, setServiceData] = useState(null);
	const [fetchingData, setFetchingData] = useState(true);
	const [showModal, setShowModal] = useState(false);

	const [showCancelPopup, setCancelPopup] = useState(false);
	const [cancelationReason, setCancelationReason] = useState("");

	const [showFeedback, setShowFeedback] = useState(false);
	const [feedbackNature, setFeedbackNature] = useState("warn");
	const [feedbackText, setFeedbackText] = useState("");

	useEffect(() => {
		/**
		 * On web version we need to check for user session validity on page refresh as the axios default headers don't
		 * persist page resfresh, so this is an additional step and needs to be done on all pages.
		 */
		Utils.isGuestOrUser(navigation).then((result) => {
			if (
				result == null ||
				typeof result == "undefined" ||
				result == "guest"
			) {
				hitApis();
			} else {
				axiosInstance.defaults.headers.common["Authorization"] = result;
				hitApis();
			}
		});
	}, []);

	const hitApis = () => {
		axiosInstance({
			method: "get",
			url: `auto-care/service-bookings/${orderID}/status/`,
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					handleOrderProgressResponse(response.data);
				} else {
					setShowFeedback(true);
					setFeedbackText(
						"Error connecting to our servers. Try again later."
					);
					setFeedbackNature("error");
				}
			})
			.catch((onrejected) => {
				setShowFeedback(true);
				setFeedbackText(
					"Your request could not be completed at this moment, Please try again later."
				);
				setFeedbackNature("error");
				// Utils.handleFailedResponse(
				// 	"getStatusByID",
				// 	onrejected.response,
				// 	onrejected.response.status,
				// 	navigation
				// );
			});
		axiosInstance({
			method: "get",
			url: `auto-care/service-bookings/${orderID}`,
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					setServiceData(response.data);
				} else {
					setShowFeedback(true);
					setFeedbackText(
						"Error connecting to our servers. Try again later."
					);
					setFeedbackNature("error");
				}
			})
			.catch((onrejected) => {
				setShowFeedback(true);
				setFeedbackText(
					"Your request could not be completed at this moment, Please try again later."
				);
				setFeedbackNature("error");
				// Utils.handleFailedResponse(
				// 	"getStatusByID",
				// 	onrejected.response,
				// 	onrejected.response.status,
				// 	navigation
				// );
			});
	};

	const cancelService = () => {
		setCancelPopup(false);
		axiosInstance({
			method: "post",
			url: `auto-care/service-bookings/${orderID}/status/`,
			data: { status: -1, notes: cancelationReason },
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					console.log(response.data);
					setShowFeedback(true);
					setFeedbackText(
						`Your service order #${orderID} has been cancelled successfully!`
					);
					setFeedbackNature("success");
					navigation.navigate("myorders");
				} else {
					setShowFeedback(true);
					setFeedbackText(
						"Error connecting to our servers. Try again later."
					);
					setFeedbackNature("error");
				}
			})
			.catch((onrejected) => {
				setShowFeedback(true);
				setFeedbackText(
					"Your request could not be completed at this moment, Please try again later."
				);
				setFeedbackNature("error");
				// Utils.handleFailedResponse(
				// 	"getStatusByID",
				// 	onrejected.response,
				// 	onrejected.response.status,
				// 	navigation
				// );
			});
	};

	const handleOrderProgressResponse = (response) => {
		setOrderProgressData(response);
		setFetchingData(false);
	};

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	const renderBlock = ({ item }) => {
		return (
			<View>
				<View style={{ flexDirection: "row" }}>
					<View>
						<Image
							source={
								item.icon == null
									? default_img
									: { uri: item.icon, priority: "high" }
							}
							style={{
								height: 40,
								width: 40,
							}}
							resizeMode='contain'
						/>
					</View>
					<View style={{ marginStart: 10 }}>
						<Text
							style={{
								fontFamily: "Gilroy-SemiBold",
								fontSize: 15,
							}}>
							{item.status_str}
						</Text>
						<Text
							style={{
								color: "#52566C",
								fontSize: 12,
								fontFamily: "Gilroy-Regular",
							}}>
							{Utils.getLocalTimeFromUTC(
								item.created,
								"dddd Do MMM"
							)}
						</Text>
					</View>
				</View>
				{item.status != 20 && orderProgressData.length != 1 ? (
					<View style={{ marginLeft: 20 }}>
						<View style={styles.dash} />
						<View style={[styles.dash, { height: 7 }]} />
						<View style={styles.dash} />
					</View>
				) : null}
			</View>
		);
	};

	return (
		<View style={{ flex: 1, flexDirection: "row" }}>
			<ScrollView
				contentContainerStyle={{
					flexGrow: 1,
					width: windowSize.width < 800 ? "100%" : "70%",
					maxWidth: 900,
					alignSelf: "center",
					// alignItems: "center",
					justifyContent: "center",
					backgroundColor: "#fff",
					paddingHorizontal: 20,
					paddingBottom: 120,
				}}>
				<View
					style={{
						backgroundColor: "#fff",
						marginTop: 10,
						borderBottomWidth: 1,
						borderBottomColor: "#ddd",
					}}>
					<View
						style={{
							flexDirection: "row",
							alignSelf: "center",
							alignItems: "center",
							marginVertical: 10,
							maxWidth: 350,
						}}>
						<Text
							style={{
								color: "#121212",
								fontSize: 18,
								fontFamily: "Gilroy-SemiBold",
								marginRight: 20,
							}}>
							Order Details
						</Text>
						<NonFilledButton
							title={"Invoice"}
							style={{
								height: 30,
								marginRight: 20,
								paddingVertical: 0,
							}}
						/>
						<TouchableOpacity
							onPress={() => {
								setShowModal((prev) => !prev);
							}}
							style={{
								flexDirection: "row",
								alignItems: "center",
							}}>
							<Image
								source={headphone_ic}
								style={{
									height: 30,
									width: 30,
									tintColor: "#7E98ED",
								}}
								resizeMode='contain'
							/>
							<Text
								style={{
									color: "#7E98ED",
									fontSize: 16,
									marginStart: 5,
									fontFamily: "Gilroy-Regular",
								}}>
								Help
							</Text>
						</TouchableOpacity>
					</View>
					<View
						style={{
							flexDirection: "row",
							width: "40%",
							maxWidth: 300,
							alignSelf: "center",
						}}>
						<View
							style={{
								flex: 1,
								alignItems: "center",
								paddingVertical: 10,
								marginRight: 12,
							}}>
							<View
								style={{
									overflow: "hidden",
									borderRadius: 50,
									borderWidth: 1,
									borderColor: "#ccc",
									height: 80,
									width: 80,
								}}>
								<Image
									source={
										serviceData == null
											? default_img
											: { uri: serviceData.service.image }
									}
									style={{
										height: "100%",
										width: "100%",
									}}
									resizeMode='cover'
								/>
							</View>
						</View>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								flex: 1,
							}}>
							<View style={{ flexDirection: "row" }}>
								<Text style={{ fontFamily: "Gilroy-Regular" }}>
									Order ID
								</Text>
								<Text
									style={{
										fontFamily: "Gilroy-SemiBold",
										marginLeft: 5,
									}}>
									{"#" + orderID}
								</Text>
							</View>
							<View style={{ flexDirection: "row" }}>
								<Text style={{ fontFamily: "Gilroy-Regular" }}>
									Service
								</Text>
								<Text
									style={{
										fontFamily: "Gilroy-SemiBold",
										marginLeft: 5,
									}}>
									{serviceData == null
										? "UNKNOWN"
										: serviceData.service.name || "UNKNOWN"}
								</Text>
							</View>
						</View>
					</View>
					{serviceData != null &&
					serviceData.status.status != -1 &&
					serviceData.status.status != 20 ? (
						<TouchableOpacity
							onPress={() => {
								setCancelPopup(true);
							}}
							style={{
								backgroundColor: "red",
								width: 120,
								padding: 5,
								borderRadius: 6,
								alignItems: "center",
								alignSelf: "center",
								marginVertical: 9,
							}}>
							<Text
								style={{
									color: "#fff",
									fontFamily: "Gilroy-SemiBold",
								}}>
								Cancel Service
							</Text>
						</TouchableOpacity>
					) : null}
				</View>
				<FlatList
					data={orderProgressData}
					renderItem={renderBlock}
					style={{
						backgroundColor: "#fff",
					}}
					contentContainerStyle={{
						alignSelf: "center",
						marginTop: 20,
						paddingBottom: 60,
					}}
				/>
			</ScrollView>
			<TopFeedbackBar
				feedbackNature={feedbackNature}
				setShow={setShowFeedback}
				show={showFeedback}
				text={feedbackText}
			/>
			<PopupDialogBox
				visibility={showCancelPopup}
				titleText={"Cancel Service?"}
				messageText={
					"Are you sure you want to cancel this service order?"
				}
				extraComponents={
					<View style={{ marginTop: 20 }}>
						<TextInput
							style={{
								backgroundColor: "#eee",
								borderRadius: 5,
								padding: 7,
								height: 90,
								fontFamily: "Gilroy-Regular",
								outlineColor: "#7E98ED",
							}}
							onChangeText={(t) => {
								setCancelationReason(t);
							}}
							autoCapitalize={"none"}
							autoCorrect={false}
							multiline={true}
							placeholder='Please provide a reason for your cancellation of this order'
						/>
					</View>
				}
				positiveOptionText={"Yes"}
				negativeOptionText={"No"}
				onPositiveSelection={() => {
					if (
						cancelationReason == null ||
						cancelationReason.trim().length == 0
					) {
						setShowFeedback(true);
						setFeedbackNature("warn");
						setFeedbackText("Please provide a cancellation reason");
					} else {
						cancelService();
					}
				}}
				onNegativeSelection={() => {
					setCancelPopup(false);
				}}
				showButtons={true}
			/>
			{serviceData != null &&
			serviceData.status.status == "20" &&
			// serviceData.payment_completed &&
			serviceData.gatepass_code != null ? (
				<TouchableHighlight
					onPress={() => {
						navigation.navigate("gatepass", {
							gatepassCode: serviceData.gatepass_code,
						});
					}}
					style={{
						width: 280,
						borderRadius: 50,
						// maxWidth: 900,
						height: 70,
						position: "absolute",
						alignSelf: "flex-end",
						margin: 10,
						bottom: windowSize.width < 800 ? 120 : 70,
						right: 10,
						// marginLeft: -10,
						backgroundColor: "#001A45",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						paddingHorizontal: "5%",
					}}>
					<Text
						style={{
							color: "#fff",
							fontSize: 22,
							fontFamily: "Gilroy-SemiBold",
						}}>
						Get Gate Pass
					</Text>
				</TouchableHighlight>
			) : serviceData != null &&
			  serviceData.status.status == 20 &&
			  !serviceData.payment_completed ? (
				<View>
					<TouchableOpacity
						style={[
							{
								width: 250,
								borderRadius: 50,
								// maxWidth: 900,
								height: 70,
								position: "absolute",
								alignSelf: "flex-end",
								margin: 10,
								bottom: windowSize.width < 800 ? 120 : 70,
								// marginLeft: -10,
								backgroundColor: "#001A45",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
								paddingHorizontal: "5%",
							},
						]}
						onPress={() => {
							navigation.navigate("estimatedpayment", {
								postId: serviceData.id,
							});
						}}>
						<Text
							style={{
								width: "90%",
								color: "white",
								fontSize: 26,
								marginLeft: 20,
								fontFamily: "Gilroy-Regular",
							}}>
							Proceed to pay
						</Text>
						<Image
							source={arrow_ic}
							style={{
								height: 25,
								width: 25,
								marginRight: 20,
								tintColor: "white",
								transform: [{ rotate: "-90deg" }],
							}}
							resizeMode='contain'
						/>
					</TouchableOpacity>
				</View>
			) : null}
			<BottomPopUpModal
				isVisible={showModal}
				height={"20%"}
				onClose={() => setShowModal(false)}
				children={
					<View style={{ alignSelf: "center" }}>
						<TouchableOpacity
							onPress={() => {
								if (
									serviceData != null &&
									serviceData.service_center.email != null
								) {
									Linking.openURL(
										`mailto:${serviceData.service_center.email}?subject=SendMail&body=Description`
									);
								}
							}}>
							<View style={{ flexDirection: "row" }}>
								<Image
									source={headphone_ic}
									style={{ height: 30, width: 30 }}
									resizeMode='contain'
								/>
								<Text
									style={{
										color: "#7E98ED",
										fontWeight: "500",
										fontSize: 18,
										marginLeft: 10,
										fontFamily: "Gilroy-Regular",
									}}>
									{serviceData != null &&
									serviceData.service_center.email != null
										? serviceData.service_center.email
										: "Unavailable"}
								</Text>
							</View>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() => {
								let number = "";
								if (
									serviceData != null &&
									serviceData.service_center.phone != null
								) {
									if (Platform.OS === "ios") {
										number = `telprompt:${serviceData.service_center.phone}`;
									} else {
										number = `tel:${serviceData.service_center.phone}`;
									}
									Linking.openURL(number);
								}
							}}>
							<View
								style={{
									flexDirection: "row",
									marginTop: 10,
								}}>
								<Image
									source={call_ic}
									style={{ height: 30, width: 30 }}
									resizeMode='contain'
								/>
								<Text
									style={{
										color: "#4d4d4d",
										fontFamily: "Gilroy-Regular",
										fontSize: 18,
										marginLeft: 10,
									}}>
									{serviceData != null &&
									serviceData.service_center.phone != null
										? serviceData.service_center.phone
										: "Unavailable"}
								</Text>
							</View>
						</TouchableOpacity>
					</View>
				}
			/>
			<LoaderOverlay bgColor={"#fff"} isVisible={fetchingData} />
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "white",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	dash: {
		height: 5,
		borderColor: "#585C74",
		borderWidth: 1,
		width: 1,
		borderRadius: 10,
		marginTop: 2,
	},
});

export default OrderTrackingScreen;
