import React, { useState, useRef } from "react";
import {
	StyleSheet,
	View,
	TextInput,
	Image,
	Text,
	FlatList,
	TouchableOpacity,
} from "react-native";

//assets
import search_ic from "../../assets/Icons/DCS_search_bar.png";
import themes from "../../Styles/themes";

const GenericSearchBar = ({
	placeholderText,
	onChangeText,
	onFocus,
	textInputStyle,
	value,
	onTouchEnter,
	containerStyle,
	valueRef,
}) => {
	const [tiValue, setTIValue] = useState("");
	const tiRef = useRef();

	return (
		<View style={[styles.container, containerStyle]}>
			<TextInput
				ref={tiRef}
				onFocus={onFocus}
				placeholder={placeholderText}
				placeholderTextColor='#858189'
				color='#000'
				onChangeText={(t) => {
					onChangeText ? onChangeText(t) : null;
					setTIValue(t);
				}}
				style={[
					styles.textInput,
					textInputStyle,
					{
						flex: 1,
						outlineColor: themes.primarySkyBlue,
						fontFamily: "Gilroy-Regular",
					},
				]}
				onSubmitEditing={() => onTouchEnter(tiValue || value)}
				value={value || valueRef?.current}
			/>
			<TouchableOpacity
				disabled={!value && value?.length == 0 && tiValue.length == 0}
				style={{ position: "absolute", right: 10 }}
				onPress={() => {
					onTouchEnter(tiValue || value);
				}}>
				<View
					style={{
						backgroundColor:
							(value && value.length > 0) || tiValue.length > 0
								? "#7E98ED"
								: null,
						padding: 6,
						borderRadius: 12,
					}}>
					<Image
						source={search_ic}
						style={{
							height: 20,
							width: 20,
							tintColor:
								(value && value.length > 0) ||
								tiValue.length > 0
									? "#fff"
									: "#ccc",
						}}
					/>
				</View>
			</TouchableOpacity>
		</View>
	);
};

const styles = StyleSheet.create({
	textInput: {
		height: 40,
		width: "100%",
		minWidth: 200,
		marginVertical: 10,
		backgroundColor: "#fff",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		alignSelf: "center",
		borderWidth: 2,
		borderColor: "#E8E8E8",
		paddingLeft: 15,
		paddingRight: 50,
		borderRadius: 15,
	},
	container: {
		justifyContent: "center",
		height: 60,
	},
});

export default GenericSearchBar;
