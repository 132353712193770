import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView, Dimensions } from "react-native";

import BackFab from "../../components/Common/BackFab";
import SubmitButtonDark from "../../components/Buttons/SubmitButtonDark";
import RetryButton from "../../components/Buttons/NonFilledButton";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils";
import PlainHeader from "../../components/WebOnly/PlainHeader";
import { Link } from "@react-navigation/native";

const VerifyEmailScreen = ({ navigation, route }) => {
	const window = Dimensions.get("window");
	const screen = Dimensions.get("screen");

	const { email } = route.params;

	const [dimensions, setDimensions] = useState({ window, screen });
	// 3 possible states => hidden, retry
	const [showRetry, setShowRetry] = useState(false);
	const [counter, setCounter] = useState(10);
	var timerID = 0;

	useEffect(() => {
		countDown(10);
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setDimensions({ window, screen });
			}
		);
		return () => subscription?.remove();
	}, []);

	function countDown(i) {
		setShowRetry(false);
		timerID = setInterval(function () {
			--i || clearInterval(timerID);
			if (i > 0) {
				setCounter((prev) => (prev -= 1));
			} else {
				setShowRetry(true);
				setCounter(10);
			}
		}, 1000);
	}

	const hitResendApi = () => {
		axiosInstance({
			method: "post",
			url: "accounts/account-resend-verify-email/",
			data: {
				email: email,
			},
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					try {
						console.log(response.data);
						setShowRetry(false);
						countDown(10);
					} catch (error) {
						console.log(error);
					}
				} else {
					notifyMessage(
						"Error connecting to our servers. Try again later."
					);
				}
			})
			.catch((onrejected) => {
				Utils.handleFailedResponse(
					"postRetryVerification",
					onrejected.response,
					onrejected.response.status,
					navigation
				);
			});
	};

	return (
		<View>
			<PlainHeader />
			<View style={styles.container}>
				<View style={styles.innerContainer}>
					{/* title */}
					<Text style={styles.titleFont}>Verification</Text>
					<Text style={{ marginTop: 50 }}>
						A verification link has been sent to your email
					</Text>
					<Text style={{ marginTop: 5, color: "#7E98ED" }}>
						{email}
					</Text>
					{showRetry ? (
						<View>
							<Text
								style={{ marginTop: 100, alignSelf: "center" }}>
								Did not receive an email?
							</Text>
							<RetryButton
								title={"RETRY"}
								style={{
									width: 100,
									alignSelf: "center",
									marginTop: 10,
								}}
								onPress={() => {
									hitResendApi();
								}}
							/>
						</View>
					) : (
						<Text style={{ marginTop: 100, alignSelf: "center" }}>
							Try again in {counter} seconds
						</Text>
					)}
					<Link
						to='/login'
						style={{ alignSelf: "center", marginTop: 60 }}>
						<SubmitButtonDark
							title={"Login"}
							style={{
								width: 150,
							}}
							onPress={() => {
								clearInterval(timerID);
							}}
						/>
					</Link>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		height: "80%",
		backgroundColor: "#fff",
		alignItems: "center",
	},
	innerContainer: {
		flex: 1,
		paddingTop: 35,
		flexDirection: "column",
		maxWidth: "80%",
	},
	backArrow: {
		marginTop: 35,
		marginStart: 40,
	},
	titleFont: {
		fontSize: 25,
		color: "#120632",
		fontWeight: "bold",
	},
	backArrow: {
		position: "absolute",
		left: 30,
		top: 25,
		zIndex: 1,
	},
});

export default VerifyEmailScreen;
