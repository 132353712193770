import React from "react";
import {
	Text,
	TouchableOpacity,
	View,
	Platform,
	Image,
	ActivityIndicator,
} from "react-native";
// import { FlatList } from "react-native-gesture-handler";
import fontStyle from "../../Styles/typography";
import FImage from "./ImageInterface";
import Utils from "../../Utils/Utils";
// import Carousel from "react-native-snap-carousel";
import Carousel from "react-native-x-carousel";

//components
import ButtonDark from "../Buttons/SubmitButtonDark";

//assets
import person_ic from "../../assets/Icons/DCS_person_with_bg_ic.png";
import refresh_ic from "../../assets/Icons/DCS_refresh_ic.png";

const GenericServiceBookingComponent = ({ data, props }) => {
	const screen = props.screen;

	const RenderOnGoingService = ({ item }) => {
		return (
			<View
				key={item.id}
				style={{
					width:
						screen.width > 700
							? screen.height - 40
							: screen.width - 40,
					marginHorizontal: 20,
					marginTop: 10,
				}}>
				<View
					style={{
						borderRadius: 12,
						borderWidth: 2,
						borderColor: "#7E98ED",
						padding: 16,
						width: screen.width > 400 ? 450 : screen.width - 40,
						alignSelf: "center",
					}}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
						}}>
						<Text style={{ fontFamily: "Gilroy-Regular" }}>
							{"Service: " +
								(item.service.name == null
									? "Custom"
									: item.service.name)}
						</Text>
						<ButtonDark
							fontStyle={{ fontSize: 12 }}
							title='Check more'
							style={{
								paddingHorizontal: 10,
								paddingVertical: 5,
							}}
							onPress={() => {
								props.navigation.navigate("trackorder", {
									orderID: item.id,
								});
							}}
						/>
					</View>
					<View
						style={{
							flexDirection: "row",
							marginTop: 5,
						}}>
						<FImage
							source={
								item.status != null && item.status.icon != null
									? {
											uri: item.status.icon,
											// priority: FImage.priority.high,
									  }
									: person_ic
							}
							style={{ height: 40, width: 40 }}
							resizeMode='contain'
						/>
						<View style={{ marginLeft: 16 }}>
							<Text style={{ fontFamily: "Gilroy-SemiBold" }}>
								{item.status == null
									? "UNKNOWN"
									: item.status.status_str}
							</Text>
							<Text style={{ fontFamily: "Gilroy-SemiBold" }}>
								{"On " +
									Utils.getNumToMonth(
										item.updated.substring(5, 7) - 1
									) +
									" " +
									item.updated.substring(8, 10)}
							</Text>
						</View>
					</View>
				</View>
			</View>
		);
	};

	const SafeCarousel = ({ data }) => {
		try {
			return (
				<Carousel
					ref={props.ongoingServiceSVRef}
					data={data}
					renderItem={(item) => (
						<RenderOnGoingService item={item} key={item.id} />
					)}
					loop={true}
					autoplay={true}
					autoplayInterval={3000}
					scrollEventThrottle={0}
					handleScrollEnd={() => {}}
				/>
			);
		} catch (error) {
			return null;
		}
	};

	return (
		<View>
			{data.length > 0 ? (
				<View>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
						}}>
						<Text
							style={[
								fontStyle.fontTitle,
								{
									marginLeft: 20,
									marginTop: 10,
									fontFamily: "Gilroy-SemiBold",
								},
							]}>
							{props.name}
						</Text>
						<TouchableOpacity
							onPress={() => {
								props.onRefreshClick();
							}}
							style={{
								justifyContent: "center",
								marginRight: 20,
							}}>
							<View>
								<FImage
									source={refresh_ic}
									style={{
										height: 25,
										width: 25,
									}}
									resizeMode='contain'
								/>
							</View>
						</TouchableOpacity>
					</View>
					{props.isLoading ? (
						<View style={{ height: 117, justifyContent: "center" }}>
							<ActivityIndicator
								size={"large"}
								color='#7E98ED'
								hidesWhenStopped={true}
								animating={props.isLoading}
							/>
						</View>
					) : (
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
							}}>
							<SafeCarousel data={data} />
						</View>
					)}
				</View>
			) : null}
		</View>
	);
};

export default GenericServiceBookingComponent;
