import React, { useState, useEffect } from "react";
import {
	View,
	Dimensions,
	Image,
	ActivityIndicator,
	Text,
	TouchableOpacity,
	Platform,
	ScrollView,
} from "react-native";

import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils.web";
import TopFeedbackBar from "../../components/WebOnly/TopFeedbackBar";
import NonFilledButton from "../../components/Buttons/NonFilledButton";
import defaultImg from "../../assets/Defaults/DCS_no_image.png";
import NavigatableTextPrompt from "../../components/Common/NavigatableTextPrompt";
import FirebaseWebInstance from "../../Utils/FirebaseWebInstance.web";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Link } from "@react-navigation/native";

const QuotationScreen = ({ route, navigation }) => {
	const { token } = route.params;

	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));

	const [imageUrl, setImageUrl] = useState();
	const [pdfUrl, setPdfUrl] = useState();
	const [bannerText, setBannerText] = useState("");
	const [bannerNature, setBannerNature] = useState("warn");
	const [showBanner, setShowBanner] = useState(false);
	const [loadingState, setLoadingState] = useState("loading");

	const [dealershipName, setDealershipName] = useState();
	const [dealershipPhone, setDealershipPhone] = useState();
	const [dealershipMail, setDealershipMail] = useState();
	const [imageHeight, setImageHeight] = useState(null);
	const [imageWidth, setImageWidth] = useState(null);

	const analytics = FirebaseWebInstance.getInstance();

	useEffect(() => {
		hitApis();
	}, []);

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	const hitApis = () => {
		axiosInstance({
			method: "get",
			url: `auto-care/quotations/token/${token}`,
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					setLoadingState("done");
					handleQuotationResponse(response.data);
				} else {
					notifyMessage(
						"Error connecting to our servers. Try again later."
					);
					setLoadingState("error");
				}
			})
			.catch((onrejected) => {
				Utils.handleFailedResponse(
					"getQuotationByToken",
					onrejected.response,
					onrejected.response.status,
					navigation,
					setBannerText
				);
				setLoadingState("error");
			});
	};

	const notifyMessage = (mssg) => {
		setBannerText(mssg);
		setBannerNature("error");
		setShowBanner(true);
	};

	const handleQuotationResponse = (data) => {
		console.log(data);
		if (data.image_url) {
			setImageUrl(data.image_url);
			getImageSizes(data.image_url);
		} else {
			setPdfUrl(data.pdf_url);
			window.open(data.pdf_url);
		}
		setDealershipName(data.dealership_name);
		setDealershipPhone(data.phone);
		setDealershipMail(data.email);
		logEvent(analytics, "quotation_access", {
			screen_name: "quotations",
			params: token,
			dealership_name: data.dealership_name,
		});
	};

	const getImageSizes = (imageUrl) => {
		Image.getSize(imageUrl, (height, width) => {
			console.log(height, width);
			setImageHeight(height);
			setImageWidth(width);
		});
	};

	return (
		<View style={{ flex: 1, backgroundColor: "#fff" }}>
			<ScrollView
				scrollEnabled={true}
				showsVerticalScrollIndicator={true}
				contentContainerStyle={{
					flexGrow: 1,
					alignItems: "center",
					paddingBottom: 150,
				}}>
				{showBanner ? (
					<TopFeedbackBar
						feedbackNature={bannerNature}
						setShow={setShowBanner}
						show={showBanner}
						text={bannerText}
					/>
				) : null}
				{loadingState == "done" ? (
					<View
						style={{
							flex: 1,
							width: "90%",
							maxWidth: 380,
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "column",
								alignItems: "flex-start",
								justifyContent: "center",
								paddingTop: 20,
							}}>
							<Text
								style={{
									fontFamily: "Gilroy-Regular",
									fontSize: 22,
								}}>
								{dealershipName}
							</Text>
							{dealershipPhone ? (
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
									}}>
									<Text
										style={{
											fontFamily: "Gilroy-Regular",
											fontSize: 17,
										}}>
										Phone:
									</Text>
									<NavigatableTextPrompt
										value={dealershipPhone}
										typeOf={"phone"}
									/>
								</View>
							) : null}
							{dealershipMail ? (
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
									}}>
									<Text
										style={{
											fontFamily: "Gilroy-Regular",
											fontSize: 17,
										}}>
										Email:
									</Text>
									<NavigatableTextPrompt
										value={dealershipMail}
										typeOf={"mail"}
									/>
								</View>
							) : null}
						</View>
						<View style={{ flex: 8 }}>
							{pdfUrl ? (
								<TouchableOpacity
									style={{ alignSelf: "center" }}
									onPress={() => {
										window.open(pdfUrl);
									}}>
									<Text
										style={{
											color: "#7E98ED",
											fontFamily: "Gilroy-SemiBold",
											fontSize: 25,
										}}>
										Open Quotation
									</Text>
								</TouchableOpacity>
							) : (
								<View style={{ flex: 1 }}>
									<Text
										style={{
											color: "#aaa",
											fontFamily: "Gilroy-Regular",
											fontSize: 20,
											alignSelf: "center",
											marginBottom: 12,
										}}>
										Your Quotation
									</Text>
									<Image
										source={imageUrl}
										style={{
											aspectRatio:
												imageHeight / imageWidth || 1.5,
											borderWidth: 1,
											borderColor: "#ddd",
											borderRadius: 9,
										}}
										progressiveRenderingEnabled={true}
										resizeMode='contain'
									/>
								</View>
							)}
						</View>
					</View>
				) : loadingState == "loading" ? (
					<ActivityIndicator
						style={{ marginTop: 160 }}
						size={"large"}
					/>
				) : (
					<View
						style={{
							flexDirection: "column",
							justifyContent: "center",
						}}>
						<Image
							source={defaultImg}
							style={{
								height: 200,
								width: 200,
							}}
							resizeMode='contain'
						/>
						<Text style={{ fontFamily: "Gilroy-Regular" }}>
							Your Quotation was not found.
						</Text>
						<NonFilledButton
							title={"HOME"}
							style={{ marginTop: 50 }}
							onPress={() => {
								navigation.reset({
									index: 0,
									routes: [
										{
											name: "home",
										},
									],
								});
							}}
						/>
					</View>
				)}
			</ScrollView>
		</View>
	);
};

export default QuotationScreen;
