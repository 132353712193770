import React, { useState } from "react";
import {
	StyleSheet,
	Text,
	View,
	Dimensions,
	TextInput,
	Image,
	TouchableOpacity,
} from "react-native";
import BackFab from "../../components/Common/BackFab";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils.web";
import SubmitButton from "../../components/Buttons/SubmitButtonDark";
import PlainHeader from "../../components/WebOnly/PlainHeader";
import TopFeedbackBar from "../../components/WebOnly/TopFeedbackBar";

import eye_close_ic from "../../assets/Icons/DCS_eye_closed_ic.png";
import eye_open_ic from "../../assets/Icons/DCS_eye_open_ic.png";

const ResetCredentialScreen = ({ navigation, route }) => {
	const window = Dimensions.get("window");
	const screen = Dimensions.get("screen");
	const [usernameValue, setUserNameValue] = useState("");
	const [passValue, setPassValue] = useState("");
	const [repassValue, setRepassValue] = useState("");
	const [tokenValue, setTokenValue] = useState("");

	const [securePassword1, setSecurePassword1] = useState(true);
	const [securePassword2, setSecurePassword2] = useState(true);

	const [feedbackText, setFeedbackText] = useState();
	const [feedbackType, setFeedbackType] = useState("warn");
	const [showFeedback, setShowFeedback] = useState(false);

	const { email } = route.params;

	const resetPassword = () => {
		axiosInstance({
			method: "post",
			url: "accounts/dj-rest-auth/password/reset/confirm/",
			data: {
				username: email,
				new_password1: passValue,
				new_password2: repassValue,
				token: tokenValue,
			},
		})
			.then((response) => {
				try {
					if (response.status >= 200 && response.status < 300) {
						console.log(response.data);
						navigation.reset({
							index: 0,
							routes: [{ name: "login" }],
						});
					} else {
						setFeedbackType("error");
						setFeedbackText(
							"Error connecting to our servers. Try again later."
						);
						setShowFeedback(true);
					}
				} catch (error) {
					console.log(error);
				}
			})
			.catch((onrejected) => {
				setFeedbackType("error");
				Utils.handleFailedResponse(
					"postResetPassword",
					onrejected.response,
					onrejected.response.status,
					navigation,
					setFeedbackText
				);
				setShowFeedback(true);
			});
	};

	return (
		<View style={{ flex: 1 }}>
			<PlainHeader />
			<View style={styles.container}>
				<TopFeedbackBar
					feedbackNature={feedbackType}
					setShow={setShowFeedback}
					show={showFeedback}
					text={feedbackText}
				/>
				<View
					style={{
						width: "100%",
						maxWidth: 350,
						alignSelf: "center",
						marginTop: 80,
					}}>
					<Text>Reset password email sent for</Text>
					<Text style={{ color: "#7E98ED", fontSize: 18 }}>
						{email}
					</Text>
					{/* <TextInput
						style={styles.textInput}
						placeholder={"Enter your email"}
						placeholderTextColor='#858189'
						color='#000'
						onChangeText={(text) => {
							setUserNameValue(text);
						}}
						textContentType='emailAddress'
						autoCapitalize='none'
						value={usernameValue}
						autoCorrect={false}
						spellCheck={false}
					/> */}
					<View style={{ marginTop: 20 }}>
						<Text style={styles.textInputTitle}>Password</Text>

						<TextInput
							style={styles.textInput}
							placeholder={"Enter your password"}
							placeholderTextColor='#858189'
							color='#000'
							onChangeText={(text) => {
								setPassValue(text);
							}}
							value={passValue}
							autoCorrect={false}
							spellCheck={false}
							secureTextEntry={securePassword1}
						/>
						<TouchableOpacity
							style={{ position: "absolute", right: 20, top: 25 }}
							onPress={() => setSecurePassword1((prev) => !prev)}>
							<Image
								source={
									securePassword1 ? eye_close_ic : eye_open_ic
								}
								style={{
									height: 25,
									width: 25,
									tintColor: !securePassword1
										? "#ccc"
										: "#7E98ED",
								}}
							/>
						</TouchableOpacity>
					</View>
					<View style={{ marginTop: 20 }}>
						<Text style={styles.textInputTitle}>
							Re-Enter Password
						</Text>

						<TextInput
							style={styles.textInput}
							placeholder={"Re-Enter your password"}
							placeholderTextColor='#858189'
							color='#000'
							onChangeText={(text) => {
								setRepassValue(text);
							}}
							value={repassValue}
							autoCorrect={false}
							spellCheck={false}
							secureTextEntry={securePassword2}
						/>
						<TouchableOpacity
							style={{ position: "absolute", right: 20, top: 25 }}
							onPress={() => setSecurePassword2((prev) => !prev)}>
							<Image
								source={
									securePassword2 ? eye_close_ic : eye_open_ic
								}
								style={{
									height: 25,
									width: 25,
									tintColor: !securePassword2
										? "#ccc"
										: "#7E98ED",
								}}
							/>
						</TouchableOpacity>
					</View>
					<View style={{ marginTop: 20 }}>
						<Text style={styles.textInputTitle}>
							Verification Token
						</Text>
						<TextInput
							style={styles.textInput}
							placeholder={"Paste the Token from Email"}
							placeholderTextColor='#858189'
							color='#000'
							onChangeText={(text) => {
								setTokenValue(text);
							}}
							textContentType='emailAddress'
							autoCapitalize='none'
							value={tokenValue}
							autoCorrect={false}
							spellCheck={false}
						/>
					</View>
					<SubmitButton
						style={{ marginTop: 40 }}
						title={"Reset Password"}
						onPress={() => {
							if (
								tokenValue.length == 0 ||
								passValue.length == 0 ||
								repassValue.length == 0
							) {
								setFeedbackType("warn");
								setFeedbackText(
									"Please don't leave any fields empty."
								);
								setShowFeedback(true);
							} else if (passValue != repassValue) {
								setFeedbackType("warn");
								setFeedbackText("Passwords don't match.");
								setShowFeedback(true);
							} else {
								resetPassword();
							}
						}}
					/>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
	},
	backArrow: {
		marginTop: 35,
		marginStart: 40,
	},
	textInputTitle: {
		marginStart: 7,
		color: "#120632",
		fontSize: 16,
	},
	textInput: {
		borderRadius: 18,
		borderWidth: 2,
		borderColor: "#C4C4C4",
		paddingHorizontal: 15,
		height: 40,
		width: "100%",
		outlineColor: "#7E98ED",
	},
});

export default ResetCredentialScreen;
