const themes = (function () {
	let themes = {};
	themes.primaryColor = "#0E1B46";
	themes.primarySkyBlue = "#7E98ED";
	themes.secondaryColor = "#fff";

	return themes;
})();

export default themes;
