import React, { useState } from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";

const ClickableCard2 = ({
	onPress,
	icon,
	borderColor,
	titleText,
	additionalText,
}) => {
	return (
		<TouchableOpacity
			onPress={onPress}
			style={{
				width: 180,
				height: 210,
				margin: 5,
				overflow: "hidden",
				borderColor: borderColor,
				borderRadius: 10,
				borderWidth: 2,
			}}>
			<View style={styles.buttonContainer}>
				<Image
					source={icon}
					style={{ width: 180, height: 130 }}
					resizeMode='contain'
				/>
				<Text style={{ marginTop: 7 }}>{titleText}</Text>
				<View
					style={{
						backgroundColor: "#7E98ED",
						width: 80,
						height: 1,
						opacity: 0.4,
						margin: 5,
					}}
				/>
				<Text style={{ marginTop: 5, fontSize: 12, color: "#888" }}>
					{additionalText}
				</Text>
			</View>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	buttonContainer: {
		alignItems: "center",
		flex: 1,
	},
});

export default ClickableCard2;
