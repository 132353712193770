import React from "react";
import { Text, View, ScrollView, TouchableOpacity } from "react-native";
import FImage from "./ImageInterface";
import fontStyle from "../../Styles/typography";
import no_image_ic from "../../assets/Defaults/DCS_no_image.png";
import { Link } from "@react-navigation/native";

const GenericDealershipComponent = ({ data, props }) => {
	return (
		<View>
			{data.items.length > 0 ? (
				<View>
					<Text
						style={[
							fontStyle.fontTitle,
							{ marginLeft: 20, marginTop: 10 },
						]}>
						{data.name}
					</Text>
					<ScrollView horizontal={true} style={{ marginTop: 10 }}>
						{data.items.map((item, i) => {
							return (
								<TouchableOpacity
									key={i}
									onPress={() => props.onPress(item.slug)}
									style={{
										marginHorizontal: 15,
										marginVertical: 7,
										alignItems: "center",
									}}>
									<FImage
										source={
											item.logo == null
												? no_image_ic
												: {
														uri: item.logo,
														priority: "high",
												  }
										}
										style={{
											height: 100,
											width: 100,
											borderWidth: 1,
											borderColor: "#ccc",
											borderRadius: 7,
										}}
										resizeMode='contain'
									/>
									<Text
										style={{
											width: 100,
											fontSize: 16,
											color: "#282C3F",
											fontFamily: "Gilroy-Regular",
											marginTop: 5,
										}}
										numberOfLines={2}
										ellipsizeMode='tail'>
										{item.name}
									</Text>
								</TouchableOpacity>
							);
						})}
					</ScrollView>
				</View>
			) : null}
		</View>
	);
};

export default GenericDealershipComponent;
