import React, { useState, useEffect } from "react";
import {
	StyleSheet,
	Text,
	View,
	StatusBar,
	ScrollView,
	Dimensions,
	TextInput,
	Switch,
	Image,
	FlatList,
	Platform,
} from "react-native";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils.web";
import AsyncStorage from "@react-native-async-storage/async-storage";

//components
import BackFab from "../../components/Common/BackFab";

//assets
import def_img from "../../assets/Defaults/DCS_no_image.png";
import no_notification_img from "../../assets/Images/DCS_error_vector.png";

const NotificationScreen = ({ navigation }) => {
	const window = Dimensions.get("window");
	const screen = Dimensions.get("screen");
	const [dimensions, setDimensions] = useState({ window, screen });

	const [notifications, setNotifications] = useState([]);
	const [fetchingData, setFetchingData] = useState(false);

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setDimensions({ window, screen });
			}
		);
		return () => subscription?.remove();
	}, []);

	useEffect(() => {
		getStoredNotifications();
	}, []);

	const getStoredNotifications = () => {
		//Replace with API call
		// let notifications = [
		// 	{
		// 		title: "50% off on all deals",
		// 		body: "Offer valid on till yesterday",
		// 		image: "https://picsum.photos/200",
		// 	},
		// 	{
		// 		title: "100% off on all deals",
		// 		body: "Offer valid on till yesterday",
		// 		image: "https://picsum.photos/200",
		// 	},
		// ];
		AsyncStorage.getItem("notifications").then((result) => {
			let resultJson = JSON.parse(result);
			if (
				resultJson !== null &&
				typeof resultJson !== "undefined" &&
				compareJSON(notifications, resultJson)
			) {
				// console.log("updating notifications to : " + resultJson);
				AsyncStorage.setItem(
					"notifications",
					JSON.stringify(notifications)
				);
				setNotifications(notifications);
			} else {
				// console.log("no change in notifications : " + resultJson);
				setNotifications(resultJson);
			}
		});
	};

	const compareJSON = (otherJson, newJson) => {
		for (var key in otherJson) {
			if (otherJson[key] != newJson[key]) {
				return false;
			}
		}
		return true;
	};

	const NoNotificationBlock = () => {
		return (
			<View
				style={{
					marginTop:
						screen.width > 400 ? (screen.height * 10) / 100 : 80,
					backgroundColor: "#fff",
					alignItems: "center",
				}}>
				<Image
					source={no_notification_img}
					style={{ height: 300, width: 300 }}
					resizeMode='contain'
				/>
				<Text
					style={{
						fontWeight: "500",
						color: "#282C3F",
						marginBottom: 40,
						fontFamily: "Gilroy-SemiBold",
					}}>
					No New Notification
				</Text>
			</View>
		);
	};

	const renderItem = ({ item }) => {
		return (
			<View
				style={{
					flexDirection: "row",
					backgroundColor: "#f8f8f8",
					marginHorizontal: 10,
					marginVertical: 5,
					borderRadius: 7,
					padding: 14,
				}}>
				<Image
					source={
						item.image == "" || item.image == null
							? def_img
							: item.image
					}
					style={{
						height: 80,
						width: 80,
						borderWidth: 1,
						borderColor: "#ddd",
					}}
					resizeMode='contain'
				/>
				<View
					style={{
						marginLeft: 10,
						justifyContent: "space-evenly",
						flex: 1,
					}}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
						}}>
						<Text style={{ fontFamily: "Gilroy-SemiBold" }}>
							{item.title}
						</Text>
						{/* <Text style={{ fontSize: 10, color: "#585C74" }}>
							{Utils.timeAgotext(item.sentTime)}
						</Text> */}
					</View>
					<Text
						style={{
							maxWidth: screen.width - 130,
							fontSize: 12,
							fontFamily: "Gilroy-Regular",
						}}>
						{item.body}
					</Text>
					{/* <Text style={{ color: "red" }}>USE: {item.offerCode}</Text> */}
				</View>
			</View>
		);
	};

	return (
		<View style={styles.container}>
			{fetchingData ? (
				<View
					style={{
						flex: 1,
						alignItems: "center",
					}}>
					<Text
						style={{
							fontFamily: "Gilroy-SemiBold",
							color: "#ccc",
						}}>
						Loading...
					</Text>
				</View>
			) : notifications.length > 0 ? (
				<FlatList data={notifications} renderItem={renderItem} />
			) : (
				<NoNotificationBlock />
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
		maxHeight: "90%",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
});

export default NotificationScreen;
