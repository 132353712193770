import React, { useState } from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";

const FloatingServiceCard = ({
	onCardPress,
	onButtonPress,
	icon,
	titleText,
	priceText,
	cardWidth,
	tagText,
	tagColor,
	featureList,
	includeButton,
	isUrl,
	extraStyle,
	textOnButton,
	activeOpacity,
}) => {
	const features = featureList;

	return (
		<TouchableOpacity
			activeOpacity={activeOpacity}
			onPress={onCardPress}
			style={[
				styles.cardEffect,
				{ width: cardWidth },
				extraStyle,
				styles.iosShadowDown,
			]}>
			<View style={{ flex: 1 }}>
				{tagText != null ? (
					<View
						style={{
							backgroundColor: tagColor,
							height: 18,
							width: 70,
							position: "absolute",
							alignItems: "center",
							borderRadius: 5,
							marginStart: "20%",
						}}>
						<Text
							style={{
								color: "white",
								fontFamily: "Gilroy-Regular",
							}}>
							{tagText}
						</Text>
					</View>
				) : null}
				<Text
					style={{
						marginTop: 27,
						marginStart: 20,
						fontFamily: "Gilroy-SemiBold",
					}}>
					{titleText}
				</Text>
				<View style={styles.textView}>
					{features.map((feature, i) => (
						<View key={i}>
							<Text
								style={{
									fontSize: 12,
									fontFamily: "Gilroy-Regular",
								}}>
								{"\u2022 " + feature}
							</Text>
						</View>
					))}
				</View>
				<View style={styles.textView}>
					<Text style={{ fontFamily: "Gilroy-SemiBold" }}>
						{priceText}
					</Text>
				</View>
			</View>
			<View
				style={{
					flex: 1,
					flexDirection: "column",
				}}>
				<Image
					source={isUrl ? { uri: icon + "" } : icon}
					style={{
						width: "100%",
						height: "100%",
						borderTopRightRadius: 10,
						borderBottomRightRadius: 10,
					}}
					resizeMode='cover'
				/>
				{includeButton ? (
					<TouchableOpacity
						style={{
							marginEnd: (parseInt(cardWidth) * 50) / 100,
							bottom: 20,
							height: 30,
							width: 90,
							backgroundColor: "#1D3688",
							borderRadius: 7,
							borderColor: "#7E98ED",
							borderWidth: 1,
							alignSelf: "flex-end",
							alignItems: "center",
							justifyContent: "center",
						}}
						onPress={onButtonPress}>
						<Text
							style={{
								color: "white",
								fontSize: 12,
								fontFamily: "Gilroy-Regular",
							}}>
							{typeof textOnButton !== "undefined"
								? textOnButton
								: "Add Service"}
						</Text>
					</TouchableOpacity>
				) : null}
			</View>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	cardEffect: {
		flex: 1,
		height: 50,
		margin: 5,
		marginVertical: 10,
		borderRadius: 10,
		backgroundColor: "white",
		elevation: 5,
		flexDirection: "row",
	},
	iosShadowDown: {
		shadowColor: "#222",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.17,
		shadowRadius: 2,
	},
	textView: { marginTop: 10, marginStart: 20 },
});

export default FloatingServiceCard;
