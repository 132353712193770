import React, { useRef, useState } from "react";
import {
	Text,
	View,
	ScrollView,
	TouchableOpacity,
	Platform,
} from "react-native";
import FImage from "./ImageInterface";
import Flipper from "../WebOnly/Flipper";
import FirebaseWebInstance from "../../Utils/FirebaseWebInstance.web";
import { logEvent } from "@firebase/analytics";
import { isBrowser, BrowserView } from "react-device-detect";

const GenericPromoComponent = ({ data, props }) => {
	const screen = props.screen;
	const scrollRef = useRef();
	const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
	const [showTopFlipper, setShowTopFlipper] = useState(false);
	const [showBottomFlipper, setShowBottomFlipper] = useState(true);
	const [containerWidth, setContainerWidth] = useState(0);
	const [contentWidth, setContentWidth] = useState(0);

	const isCloseToBottom = (e) => {
		return (
			e.layoutMeasurement.width + e.contentOffset.x >=
			e.contentSize.width - 20 - 20
		);
	};

	const analytics = FirebaseWebInstance.getInstance();

	const ifCloseToTop = (e) => {
		return e.contentOffset.x == 0;
	};

	return (
		<View>
			{data &&
			data.items &&
			data.items.length > 0 &&
			contentWidth >= containerWidth &&
			typeof scrollRef != "undefined" &&
			showBottomFlipper ? (
				<BrowserView>
					<Flipper
						onPress={() => {
							scrollRef.current.scrollTo({
								x: currentScrollPosition + 300,
								y: 0,
								animated: true,
							});
						}}
						gradientStart={"#fff"}
						gradientEnd={"#fff"}
						rightFlipper={true}
						customHeight={140}
						customOffsetTop={0}
					/>
				</BrowserView>
			) : null}

			{data && data.items && data.items.length > 0 && showTopFlipper ? (
				<BrowserView>
					<Flipper
						onPress={() => {
							scrollRef.current.scrollTo({
								x: currentScrollPosition - 300,
								y: 0,
								animated: true,
							});
						}}
						gradientStart={"#fff"}
						gradientEnd={"#fff"}
						rightFlipper={false}
						customHeight={140}
						customOffsetTop={0}
					/>
				</BrowserView>
			) : null}
			{data.items.length > 0 ? (
				<ScrollView
					ref={scrollRef}
					onScroll={(e) => {
						if (isBrowser) {
							setShowTopFlipper(!ifCloseToTop(e.nativeEvent));
							setShowBottomFlipper(
								!isCloseToBottom(e.nativeEvent)
							);
							setCurrentScrollPosition(
								e.nativeEvent.contentOffset.x
							);
						}
					}}
					onLayout={(e) => {
						if (isBrowser) {
							var { x, y, width, height } = e.nativeEvent.layout;
							setContainerWidth(width);
						}
					}}
					horizontal={true}
					nestedScrollEnabled={true}
					showsHorizontalScrollIndicator={false}>
					<View
						onLayout={(e) => {
							if (isBrowser) {
								var { x, y, width, height } =
									e.nativeEvent.layout;
								setContentWidth(width);
							}
						}}
						style={{ flexDirection: "row", margin: 7 }}>
						{data.items.map((item) => {
							return (
								<TouchableOpacity
									onPress={() => {
										if (Platform.OS == "web") {
											// logEvent(analytics, "onClick", {
											// 	widget: "Promo",
											// 	item_id: item.id,
											// 	service_center_id:
											// 		item.service_center,
											// 	promo_name: item.name,
											// });
											props.navigation.navigate(
												"promodetails",
												{ promoID: item.id }
											);
										} else {
											props.navigation.navigate(
												"PromoDetailScreen",
												{ promoID: item.id }
											);
										}
									}}
									key={item.id}>
									<FImage
										source={{
											uri: item.image,
											priority: "high",
										}}
										style={{
											height: 110,
											width: 316.8,
											margin: 5,
										}}
									/>
									<Text
										style={{
											fontSize: screen.fontScale * 20,
											fontFamily: "Gilroy-Regular",
											position: "absolute",
											width: "40%",
											color: "#fff",
											top: 20,
											left: 20,
										}}>
										{item.name}
									</Text>
									<Text
										style={{
											fontSize: screen.fontScale * 12,
											position: "absolute",
											width: "40%",
											color: "#fff",
											bottom: 20,
											left: 20,
											fontFamily: "Gilroy-Regular",
										}}>
										{item.description}
									</Text>
								</TouchableOpacity>
							);
						})}
					</View>
				</ScrollView>
			) : null}
		</View>
	);
};

export default GenericPromoComponent;
