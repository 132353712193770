import React, { useEffect, useState, useCallback } from "react";
import { StyleSheet, View, Text, TextInput, Dimensions } from "react-native";
import Geolocation from "@react-native-community/geolocation";
import LoaderOverlay from "../Common/LoaderOverlay.web";
import SearchBar from "../../components/Utils/SearchBar.web";
import EmptyButton from "../../components/Buttons/NonFilledButton";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils.web";

const WebMap = ({ setNewAddress, showMap }) => {
	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	//inputs
	const [currentLocationTxt, setCurrentLocationText] = useState("");
	const [cityName, setCityName] = useState("");
	const [pinCode, setPinCode] = useState("");

	//geolocated address data
	const [currentCoords, setCurrentCoords] = useState();
	const [currentLocationObj, setCurrentLocationObj] = useState();
	//user selected address data
	const [selectedResult, setSelectedResult] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [selectedResultObj, setSelectedResultObj] = useState();

	//UI states
	const [isMapLoaded, setMapLoaded] = useState(false);
	const [map, setMap] = useState();
	const [mainMarker, setMainMarker] = useState();

	const [errorText, setErrorText] = useState("");

	// GoogleMap loading with @react-google-maps/api
	// const { isLoaded } = useJsApiLoader({
	// 	id: "google-map-script",
	// 	googleMapsApiKey: "AIzaSyB1IsDFQf1Oy11U-xB3eUozxqhcGQK-xdI",
	// });

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	useEffect(() => {
		if (selectedResult != "") {
			setCurrentLocationText("");
		}
	}, [selectedResult]);

	function geocode(request) {
		let geocoder = new google.maps.Geocoder();
		geocoder
			.geocode(request)
			.then((result) => {
				const { results } = result;
				if (results.length != 0) {
					createMarker(results[0]);
					setCityName(results[0].address_components[5].long_name);
					let temp;
					let pin;
					let state;
					if (results[0].address_components.length == 10) {
						pin = results[9].long_name;
						state = results[7].long_name;
					} else {
						temp = results[0].formatted_address.split(",");
						pin = temp[temp.length - 2].trim().split(" ")[1];
						state = temp[temp.length - 2].trim().split(" ")[0];
					}
					setPinCode(isNaN(pin) ? "" : pin);
					let finalObj = {
						name: "Home",
						latitude: results[0].geometry.location.lat(),
						longitude: results[0].geometry.location.lng(),
						line1: results[0].formatted_address,
						pin_code: isNaN(pin) ? "" : pin,
						city: temp[temp.length - 3].trim(),
						is_service_center_location: false,
						state: state,
						map_url: `https://www.google.com/maps/search/?api=1&query=${results[0].geometry.location.lat()}%2C${results[0].geometry.location.lng()}`,
					};
					setSelectedResultObj(finalObj);
					if (request.hasOwnProperty("address")) {
						setCurrentLocationObj(finalObj);
						setCurrentLocationText(results[0].formatted_address);
					} else {
						setCurrentLocationText("");
						setSelectedResult(results[0].formatted_address);
					}
				}
				return results;
			})
			.catch((e) => {
				console.log(
					"Geocode was not successful for the following reason: " + e,
					request
				);
			});
	}

	const initMapWithCurrentLocation = (map) => {
		return Geolocation.getCurrentPosition(
			(position) => {
				let marker = new google.maps.Marker({
					map: map,
					position: {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					},
				});
				setMainMarker(marker);
				setCurrentCoords({
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				});
				setMapLoaded(true);

				geocode({
					address: `${position.coords.latitude},${position.coords.longitude}`,
				});
			},
			(error) => {
				console.log(error.message);
			},
			{
				showLocationDialog: true,
				enableHighAccuracy: false,
				timeout: 10000,
			}
		);
	};

	const postNewAddress = () => {
		console.log(selectedResultObj);
		axiosInstance({
			method: "post",
			url: `/auto-care/locations/`,
			data: selectedResultObj,
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					setErrorText("");
					setNewAddress(response.data);
					showMap(false);
				} else {
					Utils.notifyMessage(
						"Error connecting to our servers. Try again later."
					);
				}
			})
			.catch((onrejected) => {
				Utils.handleFailedResponse(
					"postLocations",
					onrejected.response,
					onrejected.response.status,
					navigation,
					setErrorText
				);
			});
	};

	const onLoad = useCallback(function callback(mapObj) {
		setMap(mapObj);
		initMapWithCurrentLocation(mapObj);
	}, []);

	const getLocationfromQuery = (query, successHandler) => {
		const request = {
			query: query,
			fields: ["name", "geometry", "formatted_address"],
		};
		if (map) {
			let service = new google.maps.places.PlacesService(map);
			service.findPlaceFromQuery(request, (results, status) => {
				if (
					status === google.maps.places.PlacesServiceStatus.OK &&
					results
				) {
					successHandler(results);
				}
			});
		} else {
			console.log("map not set");
		}
	};

	function createMarker(place) {
		if (!place.geometry || !place.geometry.location) return;

		clearMarker();

		let marker = new google.maps.Marker({
			map,
			position: place.geometry.location,
		});

		setMainMarker(marker);
	}

	const clearMarker = () => {
		try {
			mainMarker.setMap(null);
		} catch (e) {}
	};

	return (
		<View style={{ flex: 1 }}>
			<SearchBar
				placeholderText={"Search for location"}
				extraStyles={{
					marginTop: 0,
					marginBottom: 0,
					position: "absolute",
					top: 10,
					zIndex: 1,
					width: "70%",
					maxWidth: 360,
					alignSelf: "center",
				}}
				onChangeText={(t) => {
					if (t == "") {
						setSearchResults([]);
					} else {
						getLocationfromQuery(t, (result) => {
							setSearchResults(result);
						});
					}
				}}
				results={searchResults}
				setSelectedResult={setSelectedResult}
				setSelectedResultObj={setSelectedResultObj}
				selectedCoords={{}}
			/>
			{isMapLoaded ? null : (
				<LoaderOverlay bgColor={"#fff"} isVisible={true} />
			)}
			{/* <div id='map' style={{ flex: 1 }} /> */}
			<GoogleMap
				mapContainerStyle={{
					flex: 1,
				}}
				center={currentCoords}
				zoom={18}
				onLoad={onLoad}
				onClick={(e) => {
					geocode({ location: e.latLng });
				}}
			/>

			<View
				style={{
					backgroundColor: "#1D368899",
					padding: 10,
					borderRadius: 20,
					position: "absolute",
					bottom: 10,
					left: 10,
					width: "50%",
					alignSelf: "flex-start",
				}}>
				<View
					style={{
						width: "100%",
						alignSelf: "center",
						paddingTop: 15,
						paddingHorizontal: 15,
					}}>
					<Text
						style={{
							fontSize: 12,
							color: "#fff",
							marginBottom: 10,
						}}>
						{selectedResult == ""
							? "Current location according to GPS"
							: "You selected..."}
					</Text>
					<View
						style={{
							flexDirection:
								windowSize.width > 500 ? "row" : "column",
							justifyContent: "space-between",
							marginVertical: 10,
						}}>
						<View>
							<Text
								style={{
									fontSize: 12,
									color: "#fff",
									marginBottom: 5,
								}}>
								City
							</Text>
							<TextInput
								placeholder='City Name'
								style={{
									borderWidth: 1,
									borderColor: "#ddd",
									backgroundColor: "#ffffff60",
									borderRadius: 5,
									padding: 5,
									outlineColor: "#FFF",
									borderColor:
										errorText != "" ? "#d62a24" : "#FFF",
								}}
								placeholderTextColor={"#f1f1f1"}
								value={cityName}
								onChangeText={(t) => {
									setCityName(t);
								}}
							/>
						</View>
						<View>
							<Text
								style={{
									fontSize: 12,
									color: "#fff",
									marginBottom: 5,
								}}>
								Pin Code
							</Text>
							<TextInput
								placeholder='Your Pin Code'
								style={{
									borderWidth: 1,
									borderColor: "#ddd",
									backgroundColor: "#ffffff60",
									borderRadius: 5,
									padding: 5,
									outlineColor: "#FFF",
									borderColor:
										errorText != "" ? "#d62a24" : "#FFF",
								}}
								placeholderTextColor={"#f1f1f1"}
								value={pinCode}
								onChangeText={(t) => setPinCode(pinCode)}
							/>
						</View>
					</View>
					<Text
						style={{
							fontSize: 12,
							color: "#fff",
							marginBottom: 5,
						}}>
						Address
					</Text>
					<TextInput
						placeholder='Your Address'
						style={{
							borderWidth: 1,
							borderColor: "#ddd",
							backgroundColor: "#ffffff60",
							borderRadius: 5,
							padding: 5,
							marginBottom: 20,
							outlineColor: "#FFF",
							borderColor: errorText != "" ? "#d62a24" : "#FFF",
						}}
						multiline={true}
						numberOfLines={3}
						placeholderTextColor={"#f1f1f1"}
						value={currentLocationTxt || selectedResult}
						onChangeText={(t) => {
							setSelectedResultObj((prev) => ({
								...prev,
								line1: t,
							}));
							setCurrentLocationText(t);
						}}
					/>
					<Text
						style={{
							fontSize: 12,
							color: "#ffa9a6",
							fontWeight: "500",
							marginBottom: 10,
						}}>
						{errorText}
					</Text>
					<View
						style={{
							flexDirection:
								windowSize.width > 500 ? "row" : "column",
							justifyContent: "space-between",
						}}>
						<EmptyButton
							title={"Clear selection"}
							style={{
								backgroundColor: "#fff",
								justifyContent: "center",
								borderWidth: 2,
								marginVertical: 3,
							}}
							onPress={() => {
								setSelectedResult("");
								setCurrentLocationText(
									currentLocationObj &&
										currentLocationObj.address
										? currentLocationObj.address
										: ""
								);
								mainMarker.setMap(null);
								setSelectedResultObj(currentLocationObj);
								setPinCode(
									currentLocationObj &&
										currentLocationObj.pin_code
										? currentLocationObj.pin_code
										: ""
								);
								setCityName(
									currentLocationObj &&
										currentLocationObj.city
										? currentLocationObj.city
										: ""
								);
								map.setCenter(currentCoords);
							}}
							fontStyle={{
								fontSize: 14,
								fontWeight: "500",
							}}
						/>
						<EmptyButton
							title={"Confirm location"}
							style={{
								backgroundColor: "#fff",
								justifyContent: "center",
								borderWidth: 2,
								marginVertical: 3,
							}}
							onPress={() => {
								postNewAddress();
							}}
							fontStyle={{
								fontSize: 14,
								fontWeight: "500",
							}}
						/>
						{/* <EmptyButton
							title={"Search location"}
							style={{
								justifyContent: "center",
							}}
							onPress={() => {}}
							fontStyle={{
								fontSize: 14,
								fontWeight: "500",
								color: "#fff",
							}}
						/> */}
					</View>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({});
export default WebMap;
