import React from "react";
import { View } from "react-native";

const PlaceHolderShimmer = ({ height, width, radius, extraStyles }) => {
	return (
		<View
			style={[
				extraStyles,
				{
					margin: 5,
					height: height || 20,
					width: width || 100,
					backgroundColor: "#e3e3e3",
					borderRadius: radius || 5,
				},
			]}
		/>
	);
};
export default PlaceHolderShimmer;
