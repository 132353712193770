import { Link } from "@react-navigation/native";
import React, { useState, useEffect, Children } from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";

const OptionComponent = ({
	imageUrl,
	onCardPress,
	onButtonPress,
	tag,
	tagStyle,
	children,
	enableButton,
	isSelected,
	imageOccupancyPercent,
	buttonText,
}) => {
	const [componentHeight, setComponentHeight] = useState(0);

	return (
		<TouchableOpacity
			style={{
				width: 200,
				height: 290,
				borderWidth: isSelected ? 2 : 1,
				borderColor: isSelected ? "#7e98ed" : "#ddd",
				borderRadius: 5,
				overflow: "hidden",
				margin: 5,
			}}
			onPress={onCardPress}>
			{tag != null ? (
				<View
					style={[
						{
							position: "absolute",
							top: 0,
							zIndex: 1,
							backgroundColor: "#FFCB3E",
							paddingVertical: 3,
							paddingHorizontal: 6,
							borderBottomRightRadius: 5,
						},
						tagStyle,
					]}>
					<Text style={{ color: "#555" }}>{tag.toUpperCase()}</Text>
				</View>
			) : null}
			<Image
				source={imageUrl}
				style={{
					height: imageOccupancyPercent
						? (imageOccupancyPercent * 290) / 100
						: 120,
					width: "100%",
				}}
			/>
			<View
				style={{
					height: 170,
					padding: 7,
					backgroundColor: "#fff",
					paddingBottom: 70,
				}}>
				<View
					onLayout={(e) => {
						var { x, y, width, height } = e.nativeEvent.layout;
						setComponentHeight(height);
					}}
					style={{
						paddingHorizontal: 10,
					}}>
					{children}
				</View>
				{componentHeight > 87 ? (
					<Text style={{ alignSelf: "center", color: "#2693ff" }}>
						AND MORE!
					</Text>
				) : null}
			</View>
			{enableButton ? (
				<TouchableOpacity
					onPress={onButtonPress}
					style={{
						alignItems: "center",
						backgroundColor: "#1D3688",
						width: 110,
						padding: 5,
						borderRadius: 5,
						position: "absolute",
						bottom: 10,
						alignSelf: "center",
					}}>
					<Text style={{ color: "#fff" }}>{buttonText}</Text>
				</TouchableOpacity>
			) : null}
		</TouchableOpacity>
	);
};

export default OptionComponent;
