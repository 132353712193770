import React from "react";
// import WebView from "react-native-webview";
import { View } from "react-native";
import PropTypes from "prop-types";

const YTPlayer = ({ extraStyles, videoId }) => {
	const YoutubeEmbed = (embedId) => (
		<iframe
			width='100%'
			height='100%'
			frameBorder={0}
			src={`https://www.youtube-nocookie.com/embed/${embedId}`}
			allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;'
			allowFullScreen={false}
			title='Promo'
		/>
	);

	YoutubeEmbed.propTypes = {
		embedId: PropTypes.string.isRequired,
	};

	return <View style={extraStyles}>{YoutubeEmbed(videoId)}</View>;
};

export default YTPlayer;
