import React, { useState, useEffect, useRef, useMemo } from "react";
import {
	StyleSheet,
	Text,
	View,
	ScrollView,
	Image,
	Dimensions,
	TouchableOpacity,
	TouchableHighlight,
	Platform,
	FlatList,
	ActivityIndicator,
} from "react-native";
import fontStyle from "../../Styles/typography";
import MemoImage from "../../components/Common/MemoizedImage";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils.web";

//components
import GenericDealershipComponent from "../../components/Common/GenericDealershipComponent";
import GenericPromoComponent from "../../components/Common/GenericPromoComponent";
import GenericServiceComponent from "../../components/Common/GenericServiceComponent";
import GenericServiceBookingComponent from "../../components/Common/GenericServiceBookingComponent";
import GenericServiceCenterComponent from "../../components/Common/GenericServiceCenterComponent";
import GenericYTVideoComponent from "../../components/Common/GenericYTVideoComponent";
import GenericSearchBar from "../../components/Common/GenericSearchBar";
import EmptyButton from "../../components/Buttons/NonFilledButton";

//assets
import no_image_ic from "../../assets/Defaults/DCS_no_image.png";

const DealershipScreen = ({ navigation, route }) => {
	const [window, setWindow] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));

	const { slug } = route?.params;

	//possible states are: loading, done, error, fetching
	const [loadingState, setLoadingState] = useState("loading");

	const [isGuestUserState, setGuestUserState] = useState(true);

	const [brandedHomeData, setBrandedHomeData] = useState([]);
	const [brandingInfo, setBrandingInfo] = useState({});

	const [ongoingServicesData, setOngoingServicesData] = useState([]);
	const ongoingServiceSVRef = useRef();
	const [updatingOngoingServices, setUpdatingOngoingServices] =
		useState(false);

	const [errorText, setErrorText] = useState("");
	const [errorType, setErrorType] = useState("warn");

	useEffect(() => {
		/**
		 * On web version we need to check for user session validity on page refresh as the axios default headers don't
		 * persist page resfresh, so this is an additional step and needs to be done on all pages.
		 */
		Utils.isGuestOrUser(navigation).then((result) => {
			console.log(result);
			if (
				result == null ||
				typeof result == "undefined" ||
				result == "guest"
			) {
				setGuestUserState(true);
				hitApis();
			} else {
				axiosInstance.defaults.headers.common["Authorization"] = result;
				hitApis();
			}
		});
	}, []);

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setWindow(window);
				// setDimensions({ window, screen });
			}
		);
		return () => subscription?.remove();
	}, []);

	const hitApis = () => {
		getDealershipData(slug, "");
	};

	const handleHomeResponse = (data) => {
		try {
			if (brandingInfo.id !== data.id) {
				let brandInfo = {
					id: data.id,
					image: data.image,
					logo: data.logo,
					name: data.name,
					legal_name: data.legal_name,
					code: data.code,
					website: data.website,
					automobile_brands: data.automobile_brands,
				};
				setBrandingInfo(brandInfo);
			}
			data = data.sections;

			data.forEach((obj) => {
				if (obj["type"] == "service" && obj.items.length > 0) {
					if (
						obj.items.length / Math.floor(screen.width / 125) > 2 ||
						obj.items.length > 5
					) {
						obj.items.splice(2, obj.items.length - 3);
					}
					obj.items.push({ name: "extra" });
				}
				if (obj["type"] == "service_center" && obj.items.length > 0) {
					obj.items.push({ name: "extra" });
				}
				if (obj["type"] == "service_booking" && obj.items.length > 0) {
					handleOngoingServicesUpdate(obj.items);
				}
				return;
			});
		} catch (error) {
			console.log(error);
		}
		setBrandedHomeData(data);
		setLoadingState("done");
	};

	const getDealershipData = (id, query) => {
		setLoadingState("loading");
		axiosInstance({
			method: "get",
			url: `auto-care/dealer-brands/${id}/${query ? `?q=${query}` : ""}`,
		})
			.then((response) => {
				try {
					if (response.status >= 200 && response.status < 300) {
						handleHomeResponse(response.data, true);
					} else {
						Utils.notifyMessage(
							"Error connecting to our servers. Try again later."
						);
					}
				} catch (error) {
					console.log(error);
					setLoadingState("error");
				}
			})
			.catch((onrejected) => {
				setLoadingState("error");
				Utils.handleFailedResponse(
					"getDealership",
					onrejected.response,
					onrejected.response.status,
					navigation,
					setErrorText
				);
			});
	};

	const handleOngoingServicesUpdate = (response) => {
		let tempArr = [];

		//get completed services
		response.forEach((service) => {
			if (service.completed) {
				tempArr.push(service);
			}
		});
		//if more than one services completed
		if (tempArr.length > 0) {
			setOngoingServicesData(tempArr);
		} else {
			setOngoingServicesData(response);
		}
		setUpdatingOngoingServices(false);
	};

	const updateOngoingServices = () => {
		setUpdatingOngoingServices(true);
		axiosInstance({
			method: "get",
			url: `auto-care/service-bookings?dealership=${slug}`,
		})
			.then((response) => {
				try {
					if (response.status >= 200 && response.status < 300) {
						handleOngoingServicesUpdate(response.data.results);
					} else {
						Utils.notifyMessage(
							"Error connecting to our servers. Try again later."
						);
					}
				} catch (error) {
					console.log(error);
					setUpdatingOngoingServices(false);
				}
			})
			.catch((onrejected) => {
				setUpdatingOngoingServices(false);
				Utils.handleFailedResponse(
					"getServices",
					onrejected.response,
					onrejected.response.status,
					navigation
				);
			});
	};

	const getHomeScreenData = (item) => {
		return item.map((view) => {
			switch (view.type) {
				case "promo":
					return (
						<GenericPromoComponent
							data={view}
							props={{ screen, navigation }}
						/>
					);
				case "service_booking":
					return (
						<GenericServiceBookingComponent
							data={ongoingServicesData}
							props={{
								name: view.name,
								screen,
								navigation,
								ongoingServiceSVRef,
								onRefreshClick: () => {
									updateOngoingServices();
								},
								isLoading: updatingOngoingServices,
							}}
						/>
					);
				case "service":
					return (
						<GenericServiceComponent
							data={view}
							props={{
								windowDim: window,
								navigation,
							}}
						/>
					);
				case "service_center":
					return (
						<GenericServiceCenterComponent
							data={view}
							props={{
								navigation,
								isGuestUserState,
							}}
						/>
					);
				case "ad":
					return <GenericAdBannerComponent data={view} />;
				case "dealership":
					return (
						<GenericDealershipComponent
							data={view}
							props={{
								navigation,
								onPress: getDealershipData,
							}}
						/>
					);
				case "video":
					return (
						<GenericYTVideoComponent
							data={view}
							props={{ windowDim: window }}
						/>
					);

				default:
					return null;
			}
		});
	};

	const renderErrorScreen = (errorText) => {
		return (
			<View
				style={{
					height: window.height,
					justifyContent: "center",
					alignItems: "center",
					paddingBottom: 0,
				}}>
				<Text style={{ fontFamily: "Gilroy-Regular" }}>
					{errorText}
				</Text>
				<EmptyButton
					title={"Retry"}
					onPress={() => {
						hitApis();
					}}
					style={{ width: 80, marginTop: 10 }}
				/>
			</View>
		);
	};

	return (
		<ScrollView scrollEnabled={true}>
			<View
				style={{
					flex: 1,
					backgroundColor: "white",
					width: screen.width < 800 ? screen.width : "70%",
					maxWidth: 900,
					alignSelf: "center",
					paddingHorizontal: 10,
				}}>
				{loadingState == "error" ? (
					renderErrorScreen("Something went wrong, Try again later")
				) : loadingState == "loading" ? (
					<View
						style={{
							height: window.height,
							justifyContent: "center",
							alignItems: "center",
							paddingBottom: 0,
						}}>
						<ActivityIndicator
							animating={loadingState != "done"}
							size={"large"}
							color={"#7E98ED"}
						/>
						<Text style={{ marginVertical: 10, color: "#bbb" }}>
							Loading, please wait...
						</Text>
					</View>
				) : (
					<View>
						<View
							style={{
								paddingHorizontal: 20,
								paddingVertical: 10,
								margin: 7,
								marginBottom: 0,
								backgroundColor: "#fafcfc",
								borderRadius: 12,
							}}>
							<View
								style={{
									flexDirection: "row",
									width: "100%",
									maxWidth: 700,
									alignSelf: "center",
								}}>
								<View style={{ flex: 1 }}>
									<Image
										source={
											brandingInfo.logo == null
												? no_image_ic
												: {
														uri: brandingInfo.logo,
												  }
										}
										style={{
											width:
												screen.width > 400 ? 110 : 70,
											height:
												screen.width > 400 ? 110 : 70,
											borderWidth: 1,
											borderColor: "#eee",
											borderRadius: 50,
										}}
										resizeMode='contain'
									/>
								</View>
								<View style={{ flex: 3, marginLeft: 30 }}>
									<Text
										style={{
											fontSize: 18,
											fontFamily: "Gilroy-SemiBold",
										}}>
										{brandingInfo.name}
									</Text>
									<Text
										style={{
											fontFamily: "Gilroy-Regular",
										}}>
										Welcome to {brandingInfo.name}
									</Text>
								</View>
							</View>
							<GenericSearchBar
								placeholderText={"What are you looking for?"}
								containerStyle={{
									marginBottom: 0,
									width: "80%",
									maxWidth: 600,
									alignSelf: "center",
								}}
								onTouchEnter={(t) => {
									// getDealershipData(brandingInfo.id, t);
									navigation.navigate("dealership", {
										slug: brandingInfo.id,
										query: t,
									});
								}}
							/>
						</View>
						<FlatList
							scrollEnabled={true}
							contentContainerStyle={{
								backgroundColor: "white",
								paddingBottom: screen.width > 600 ? 140 : 450,
							}}
							showsVerticalScrollIndicator={false}
							data={getHomeScreenData(brandedHomeData)}
							renderItem={({ item }) => {
								return (
									<View key={Math.random() * 100}>
										{item}
									</View>
								);
							}}
						/>
					</View>
				)}
			</View>
		</ScrollView>
	);
};

export default DealershipScreen;
