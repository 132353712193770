import React, { useState, useEffect, useContext } from "react";
import {
	StyleSheet,
	Text,
	View,
	ScrollView,
	Dimensions,
	TextInput,
	ToastAndroid,
	Platform,
	Alert,
	Image,
	TouchableOpacity,
	ActivityIndicator,
} from "react-native";
import BackFab from "../../components/Common/BackFab";
import Utils from "../../Utils/Utils";
import themes from "../../Styles/themes";
import GuestContext from "../../GuestContext";
import DarkButton from "../../components/Buttons/SubmitButtonDark";

//assets
import no_image from "../../assets/Defaults/DCS_no_image.png";
import axiosInstance from "../../Utils/axiosGlobal";
// import Spinner from "react-native-spinkit";
import login_error_vector from "../../assets/Images/DCS_login_error_vector.png";
import PlaceHolderShimmer from "../../components/Common/PlaceHolderShimmer.web";

const PromoDetailScreen = ({ route, navigation }) => {
	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));

	const { promoID } = route.params;

	const [loadingState, setLoadingState] = useState("loading");
	const [promoData, setPromoData] = useState();
	const [serviceCenterData, setServiceCenterData] = useState(null);
	const [dealershipData, setDealershipData] = useState(null);

	const [isGuestUser, setGuestUserState] = useState(false);

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	const notifyMessage = (message) => {
		if (Platform.OS === "android") {
			ToastAndroid.show(message, ToastAndroid.SHORT);
		} else {
			Alert.alert(message);
		}
	};

	const fetchProviderDetails = (sc_id, dealership_id) => {
		// axiosInstance({
		// 	method: "get",
		// 	url: `auto-care/service-centers/${sc_id}/`,
		// })
		// 	.then((response) => {
		// 		if (response.status >= 200 && response.status < 300) {
		// 			setServiceCenterData(response.data);
		// 		} else {
		// 			setServiceCenterData("error");
		// 			Utils.notifyMessage(
		// 				"Error connecting to our servers. Try again later."
		// 			);
		// 		}
		// 	})
		// 	.catch((onrejected) => {
		// 		setServiceCenterData("error");
		// 		Utils.handleFailedResponse(
		// 			"getServiceCenterByID",
		// 			onrejected.response,
		// 			onrejected.response.status,
		// 			navigation
		// 		);
		// 	});

		axiosInstance({
			method: "get",
			url: `auto-care/dealer-brands/${dealership_id}/`,
		})
			.then((response) => {
				try {
					if (response.status >= 200 && response.status < 300) {
						setDealershipData(response.data);
					} else {
						setDealershipData("error");
						Utils.notifyMessage(
							"Error connecting to our servers. Try again later."
						);
					}
				} catch (error) {
					setDealershipData("error");
					console.log(error);
				}
			})
			.catch((onrejected) => {
				setDealershipData("error");
				Utils.handleFailedResponse(
					"getDealership",
					onrejected.response,
					onrejected.response.status,
					navigation
				);
			});
	};

	useEffect(() => {
		hitApis();
	}, []);

	const hitApis = () => {
		setLoadingState("loading");
		axiosInstance({
			method: "get",
			url: `/auto-care/promos/${promoID}/`,
		})
			.then((response) => {
				try {
					if (response.status >= 200 && response.status < 300) {
						setLoadingState("done");
						fetchProviderDetails(
							response.data.service_center,
							response.data.dealer_brand
						);
						setPromoData(response.data);
					}
				} catch (error) {
					setLoadingState("error");
					notifyMessage(
						"Could not fetch your details, Go back home and try again."
					);
					console.log(error);
					Utils.handleFailedResponse(
						"getPromoDetails",
						response,
						response.status,
						navigation
					);
				}
			})
			.catch((onrejected) => {
				console.log(onrejected);
				setLoadingState("error");
				notifyMessage(
					"Could not fetch your details, Go back home and try again."
				);
				Utils.handleFailedResponse(
					"getPromoDetails",
					onrejected.response,
					onrejected.response.status,
					navigation
				);
			});
	};

	return (
		<View
			style={[
				styles.container,
				{
					justifyContent:
						loadingState == "loading" ? "center" : "flex-start",
				},
			]}>
			{/* header */}
			{loadingState == "loading" ? (
				<View
					style={{
						flexGrow: 1,
						width: windowSize.width < 800 ? "100%" : "70%",
						maxWidth: 900,
						alignSelf: "center",
						paddingHorizontal: 20,
						backgroundColor: "#fff",
						justifyContent: "center",
					}}>
					<ActivityIndicator
						size={"large"}
						color='#7E98ED'
						hidesWhenStopped={true}
						animating={true}
						style={{
							alignSelf: "center",
						}}
					/>
				</View>
			) : (
				<ScrollView
					endFillColor={"#fff"}
					showsVerticalScrollIndicator={false}
					contentContainerStyle={{
						flexGrow: 1,
						width: windowSize.width < 800 ? "100%" : "70%",
						maxWidth: 900,
						alignSelf: "center",
						paddingHorizontal: 20,
						backgroundColor: "#fff",
						paddingBottom: 200,
					}}>
					<View
						style={{
							flexDirection:
								windowSize.width > 800
									? "row"
									: "column-reverse",
							justifyContent: "space-between",
							paddingTop: 10,
							height: windowSize.width > 800 ? 200 : 350,
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								alignItems: "center",
								maxWidth: 400,
								marginVertical: 7,
							}}>
							<View style={{ flex: 1, alignItems: "center" }}>
								<Image
									source={
										loadingState == "done" &&
										dealershipData &&
										dealershipData.logo != null
											? { uri: dealershipData.logo }
											: no_image
									}
									style={{
										height: 80,
										width: 80,
										borderRadius: 50,
										borderWidth: 1,
										borderColor: "#eee",
									}}
								/>
							</View>
							<Text
								style={[
									styles.title,
									{ flex: 3, fontSize: 25, marginLeft: 20 },
								]}>
								{loadingState == "done" &&
								dealershipData &&
								dealershipData.name ? (
									"Offer by " + dealershipData.name
								) : (
									<View>
										<PlaceHolderShimmer
											height={20}
											width={200}
											radius={5}
										/>
										<PlaceHolderShimmer
											height={20}
											width={160}
											radius={5}
										/>
									</View>
								)}
							</Text>
						</View>
						{/* Image */}
						<Image
							source={
								loadingState == "loading" ||
								promoData == null ||
								promoData.image == null
									? no_image
									: { uri: promoData.image }
							}
							style={{
								flex: 1,
								borderRadius: 12,
							}}
							resizeMode='cover'
						/>
					</View>
					{/* Main text container */}
					<View style={{ padding: 14 }}>
						<View style={styles.section}>
							{/* Description title */}
							{promoData && promoData.description != null ? (
								<Text style={styles.title}>
									{promoData.description}
								</Text>
							) : null}
							{/* description body */}
							{promoData && promoData.long_description != null ? (
								<Text
									style={{
										paddingLeft: 7,
										marginTop: 5,
										fontFamily: "Gilroy-Regular",
									}}>
									{promoData.long_description}
								</Text>
							) : null}
						</View>
						{/* Offer Vailidity */}
						{promoData &&
						promoData.start_date != null &&
						promoData.end_date ? (
							<View style={styles.section}>
								<Text style={styles.title}>Offer validity</Text>
								<Text
									style={{
										marginTop: 7,
										paddingLeft: 7,
										fontFamily: "Gilroy-Regular",
									}}>
									{"Offer Valid from " +
										Utils.getLocalTimeFromUTC(
											promoData.start_date,
											"Do MMM"
										) +
										" - " +
										Utils.getLocalTimeFromUTC(
											promoData.end_date,
											"Do MMM"
										)}
								</Text>
							</View>
						) : null}
						{/* Offer details */}
						{promoData && promoData.offer_details != null ? (
							<View style={styles.section}>
								<Text style={styles.title}>Offer Details</Text>
								<View style={{ paddingLeft: 7 }}>
									{promoData.offer_details
										.split("\r\n")
										.map((item, i) => (
											<Text
												key={i}
												style={{
													marginVertical: 3,
													fontFamily:
														"Gilroy-Regular",
												}}>
												{"\u2022 " + item}
											</Text>
										))}
								</View>
							</View>
						) : null}
						{/* Terms and Conditions section */}
						{promoData && promoData.offer_details != null ? (
							<View
								style={[
									styles.section,
									{ borderBottomWidth: 0 },
								]}>
								<Text style={styles.title}>
									Terms and Conditions
								</Text>
								<View style={{ paddingLeft: 7 }}>
									{promoData.terms
										.split("\r\n")
										.map((item, i) => (
											<Text
												key={i}
												style={{
													marginVertical: 3,
													fontFamily:
														"Gilroy-Regular",
												}}>
												{"\u2022 " + item}
											</Text>
										))}
								</View>
							</View>
						) : null}
					</View>
				</ScrollView>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	title: { fontSize: 16, fontFamily: "Gilroy-SemiBold", color: "#161616" },
	section: {
		borderBottomColor: "#ddd",
		borderBottomWidth: 1,
		paddingBottom: 10,
		marginTop: 7,
	},
});

export default PromoDetailScreen;
