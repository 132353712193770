import { StyleSheet } from "react-native";

export default StyleSheet.create({
	fontSmallRegular: {
		fontSize: 10,
	},
	fontSmallBold: {
		fontSize: 10,
		fontWeight: "500",
	},
	fontSmallBoldTitle: {
		fontSize: 12,
		fontWeight: "500",
	},
	fontSmallRegularTitle: {
		fontSize: 12,
	},

	fontTitle: {
		fontSize: 20,
		color: "#454249",
		fontWeight: "500",
	},

	fontHeading: {
		fontSize: 26,
		color: "#0E1B46",
		fontWeight: "500",
	},

	fontProceedText: { color: "white", fontSize: 23 },
});
