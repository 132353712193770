import React from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";

//assets
import edit_ic from "../../assets/Icons/DCS_edit_ic.png";
import delete_ic from "../../assets/Icons/DCS_delete_ic.png";

const AddressBox = ({
	onPressDelete,
	onPressEdit,
	addTypeIcon,
	name,
	address,
	id,
}) => {
	return (
		<View style={styles.container} key={id}>
			<View
				style={{
					flexDirection: "row",
					width: "100%",
					paddingVertical: 10,
				}}>
				<Image
					style={{
						height: 20,
						width: 20,
						tintColor: "#858189",
						flex: 0.5,
					}}
					source={addTypeIcon}
					resizeMode='contain'
				/>
				<View
					style={{
						flex: 2,
						paddingStart: 10,
					}}>
					<View>
						<Text style={{ fontFamily: "Gilroy-SemiBold" }}>
							{name}
						</Text>
						<Text style={styles.secondaryText} numberOfLines={3}>
							{address}
						</Text>
						{/* <Text style={styles.secondaryText}>
							Mobile - {phNum}
						</Text> */}
					</View>
				</View>
				<TouchableOpacity
					onPress={onPressEdit}
					style={{
						flex: 0.5,
						alignItems: "center",
					}}>
					<Image
						style={{
							height: 20,
							width: 20,
							tintColor: "#7E98ED",
						}}
						source={edit_ic}
						resizeMode='contain'
					/>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={onPressDelete}
					style={{
						flex: 0.5,
						alignItems: "center",
					}}>
					<Image
						style={{ height: 20, width: 20 }}
						source={delete_ic}
						resizeMode='contain'
					/>
				</TouchableOpacity>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		backgroundColor: "#fff",
		borderBottomWidth: 1,
		borderBottomColor: "#ddd",
	},
	secondaryText: {
		color: "#585C74",
		fontSize: 12,
		fontFamily: "Gilroy-Regular",
	},
});

export default AddressBox;
