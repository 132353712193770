import React from "react";
import { View, Text, TouchableOpacity, Linking, Platform } from "react-native";

const NavigatableTextPrompt = ({ extraStyle, value, typeOf }) => {
	return (
		<TouchableOpacity
			onPress={() => {
				let action;
				console.log(action);
				if (typeOf == "email") {
					action = `mailto:${value}?subject=Query&body=Description`;
				} else if (typeOf == "phone") {
					if (Platform.OS === "ios") {
						action = `telprompt:${value}`;
					} else {
						action = `tel:${value}`;
					}
				} else if (typeOf == "url") {
					if (Linking.canOpenURL(value)) {
						action = value;
					}
				} else {
					console.log("No effect");
				}
				if (value) {
					Linking.openURL(action);
				}
			}}>
			<Text
				style={[
					{
						color: value ? "#7E98ED" : "#4d4d4d",
						fontFamily: "Gilroy-Regular",
						fontSize: 18,
						marginLeft: 10,
					},
					extraStyle,
				]}>
				{value != null ? value : "Unavailable"}
			</Text>
		</TouchableOpacity>
	);
};

export default NavigatableTextPrompt;
