import React, { useState, useEffect } from "react";
import {
	StyleSheet,
	Text,
	View,
	Dimensions,
	Image,
	TouchableOpacity,
	ScrollView,
} from "react-native";

//components
import BackFab from "../../components/Common/BackFab";
import NonFilledButton from "../../components/Buttons/NonFilledButton";
import DarkButton from "../../components/Buttons/SubmitButtonDark";

//assets
import default_success from "../../assets/Defaults/DCS_dummy_success.png";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Utils from "../../Utils/Utils.web";
import { useLinkTo } from "@react-navigation/native";

const PaymentStatusScreen = ({ route, navigation, props }) => {
	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));
	const [dimensions, setDimensions] = useState({ window, screen });

	const linkTo = useLinkTo();

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	const TableRow = (rowKey, rowValue) => {
		return (
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
				}}>
				<Text
					style={{ color: "#2A292E", fontFamily: "Gilroy-Regular" }}>
					{rowKey}
				</Text>
				<Text
					style={{ color: "#2A292E", fontFamily: "Gilroy-Regular" }}>
					{rowValue}
				</Text>
			</View>
		);
	};

	return (
		<View style={[styles.container, { alignItems: "center" }]}>
			<View
				style={{
					width: windowSize.width < 800 ? "100%" : "70%",
					alignItems: "center",
				}}>
				<View
					style={[
						{
							flexDirection: "row",
							marginTop: 80,
							zIndex: 1,
						},
					]}>
					{/* floating back button */}
					{/* <View style={styles.backArrow}>
					<BackFab
						onPress={() => {
							navigateBack();
						}}
					/>
				</View> */}
				</View>
				<ScrollView
					contentContainerStyle={{
						maxWidth: 500,
						alignItems: "center",
					}}>
					<Text
						style={{
							fontFamily: "Gilroy-SemiBold",
							fontSize: 18,
							color: "#0E1B46",
						}}>
						Service Request Submitted
					</Text>
					<Text
						style={{
							maxWidth: 300,
							color: "#585C74",
							textAlign: "center",
							marginTop: 10,
							fontFamily: "Gilroy-Regular",
						}}>
						Our representatives will review your request and get
						back to you soon.
					</Text>
					<Image
						source={default_success}
						style={{ height: 200, width: 200 }}
						resizeMode='contain'
					/>
					<NonFilledButton
						title={"Explore more services"}
						style={{ marginTop: 15 }}
						onPress={() => linkTo("/home")}
					/>
					<View
						style={{
							padding: 20,
							backgroundColor: "#F0F3FB",
							borderRadius: 12,
							width: "80%",
							maxWidth: 500,
							marginTop: 30,
							marginBottom: 50,
						}}>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
							<Text
								style={{
									fontSize: 14,
									fontFamily: "Gilroy-SemiBold",
									color: "#001A45",
								}}>
								Service Summary
							</Text>
							{/* <DarkButton
							title={"Check Invoice"}
							style={{
								paddingHorizontal: 10,
								paddingVertical: 7,
								backgroundColor: "#1D3688",
							}}
							fontStyle={{ fontSize: 12 }}
						/> */}
						</View>
						<View
							style={{
								width: "100%",
								height: 2,
								backgroundColor: "#fff",
								marginVertical: 10,
							}}
						/>
						{/* table */}
						<View>
							{/* rows */}
							{TableRow("Service", props.sname)}
							{TableRow("Booked at", props.timing)}
							{TableRow(
								"Mode",
								props.mode == "true" ? "Pick up" : "Self drop"
							)}
						</View>
					</View>
				</ScrollView>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	row: {
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: "10%",
		fontSize: 16,
		marginVertical: 5,
	},
});

export default PaymentStatusScreen;
