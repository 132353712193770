import React, { useState, useEffect, useRef } from "react";
import {
	StyleSheet,
	Text,
	View,
	Platform,
	ToastAndroid,
	Alert,
	ScrollView,
	Dimensions,
	Image,
	FlatList,
	Linking,
	TouchableOpacity,
} from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils";
import Clipboard from "@react-native-clipboard/clipboard";
import BottomPopUpModal from "../../components/Common/BottomPopUpModal.web";
// import Share from "react-native-share";

//components
import BackFab from "../../components/Common/BackFab";
import IconButton from "../../components/ClickableIcons/ClickableIcon";
import FloatingServiceCard from "../../components/Cards/FloatingServiceCard";

//assets
import no_image from "../../assets/Defaults/DCS_no_image.png";
import call_ic from "../../assets/Icons/DCS_call_ic.png";
import help_ic from "../../assets/Icons/DCS_help_ic.png";
import location_ic from "../../assets/Icons/DCS_location_ic.png";
import cross_ic from "../../assets/Icons/DCS_add_ic.png";
import share_ic from "../../assets/Icons/DCS_share_ic.png";
import OptionComponent from "../../components/WebOnly/OptionComponent";

const ShowroomVehiclesScreen = ({navigation, route})=>{
const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
const [screen, setScreen] = useState(Dimensions.get("screen"));


useEffect(() => {
	const subscription = Dimensions.addEventListener(
		"change",
		({ window, screen }) => {
			setScreen(screen);
			setwindowSize(window);
		}
	);
	return () => subscription?.remove();
}, []);

	return (
		<View style={{ flex: 1 }}>
			<ScrollView
				showsVerticalScrollIndicator={false}
				contentContainerStyle={{
					flexGrow: 1,
					width: windowSize.width < 800 ? "100%" : "70%",
					maxWidth: 900,
					alignSelf: "center",
					paddingHorizontal: 20,
					backgroundColor: "#fff",
					paddingBottom: 200,
				}}>
					
				</ScrollView>
		</View>
	);
}
const styles = StyleSheet.create({
	
})

export default ShowroomVehiclesScreen