import React, { useRef, useState } from "react";
import {
	Text,
	View,
	ScrollView,
	TouchableOpacity,
	StyleSheet,
	Image,
} from "react-native";
import LinearGradient from "react-native-web-linear-gradient";
import arrow_ic from "../../assets/Icons/DCS_arrow_head_ic.png";

const Flipper = ({
	onPress,
	gradientStart,
	gradientEnd,
	rightFlipper,
	extraStyles,
	customHeight,
	customOffsetTop,
}) => {
	return (
		<LinearGradient
			start={rightFlipper ? { x: 1, y: 0 } : { x: 0, y: 1 }}
			end={rightFlipper ? { x: 0, y: 0 } : { x: 1, y: 1 }}
			colors={[gradientStart, gradientEnd]}
			style={[
				{
					height: customHeight || 220,
					width: 40,
					position: "absolute",
					top:
						customOffsetTop == null ||
						typeof customOffsetTop == "undefined"
							? 45
							: customOffsetTop,
					zIndex: 99,
				},
				rightFlipper ? { right: 0 } : { left: 0 },
				extraStyles,
			]}>
			<TouchableOpacity
				style={{
					height: "100%",
					width: "100%",
					justifyContent: "center",
					// alignItems: rightFlipper ? "flex-end" : "flex-start",
					alignItems: "center",
				}}
				onPress={onPress}>
				<Image
					source={arrow_ic}
					style={{
						height: 30,
						width: 30,
						transform: [
							{ rotate: rightFlipper ? "270deg" : "90deg" },
						],
					}}
				/>
			</TouchableOpacity>
		</LinearGradient>
	);
};

export default Flipper;
