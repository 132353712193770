import React, { useState, useEffect } from "react";
import {
	StyleSheet,
	Text,
	View,
	Platform,
	ScrollView,
	Dimensions,
	TextInput,
	Switch,
	Image,
	Picker,
	ActivityIndicator,
	Modal,
} from "react-native";

import { TouchableOpacity } from "react-native-web";

//components
import NonFilledButton from "../../components/Buttons/NonFilledButton";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils.web";
import ClickableCard from "../../components/Cards/ClickableCard";
import DarkButton from "../../components/Buttons/SubmitButtonDark";
import Option from "../../components/WebOnly/OptionComponent";
import BottomModal from "../../components/Common/BottomPopUpModal.web";
import MultiImageCollector from "../../components/WebOnly/MultiImageCollector";
// import Map from "./Map";
import EditAddressScreen from "../Main screens/EditAddressScreen";
import TopFeedbackBar from "../../components/WebOnly/TopFeedbackBar";
import VehicleSelectionForm from "../Setup screens/VehicleSelectionForm.web";
import FloatingCardBlank from "../../components/Cards/FloatingCardBlank";
import LoaderOverlay from "../../components/Common/LoaderOverlay.web";
import Map from "../Main screens/Map.web";

//assets
import no_image from "../../assets/Defaults/DCS_no_image.png";
import location_ic from "../../assets/Icons/DCS_location_ic.png";
import login_error_vector from "../../assets/Images/DCS_login_error_vector.png";
import arrow_ic from "../../assets/Icons/DCS_arrow_head_ic.png";
import attach_ic from "../../assets/Icons/DCS_attach_ic.png";
import cross_ic from "../../assets/Icons/DCS_add_ic.png";
import WebMap from "../../components/WebOnly/WebMap";

const ServiceBookingScreen = ({ route, navigation }) => {
	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));

	const [isGuestUserState, setGuestUserState] = useState(true);

	const { serviceID, companyID, opts } = route.params;
	const [serviceObj, setServiceObj] = useState(null);
	const [isPickupSelected, setIsPickupSelected] = useState(false);
	const [selectedDate, setSelectedDate] = useState(0);
	const [selectedTime, setSelectedTime] = useState(0);
	const toggleSwitch = () =>
		setIsPickupSelected((previousState) => !previousState);
	const [availablePickupDates, setAvailablePickupDates] = useState([]);
	const [availablePickupTimes, setAvailablePickupTimes] = useState([]);
	const [availableDropDates, setAvailableDropDates] = useState([]);
	const [availableDropTimes, setAvailableDropTimes] = useState([]);

	const [selectedPickupDateText, setSelectedPickupDateText] = useState("");
	const [selectedPickupTimeText, setSelectedPickupTimeText] = useState("");
	const [selectedDropDateText, setSelectedDropDateText] = useState("");
	const [selecteDropTimeText, setSelectedDropTimeText] = useState("");
	const [selectedVehicle, setSelectedVehicle] = useState(-1);
	const [selectedService, setSelectedService] = useState(-1);
	const [selectedCenter, setSelectedCenter] = useState(-1);

	const [additionalNotesText, setAdditionalNotesText] = useState("");
	const [selectedAddress, setSelectedAddress] = useState();
	const [locationData, setLocationData] = useState(null);

	const [showError, setShowError] = useState(false);
	const [errorText, setErrorText] = useState(null);

	//Dropdown states
	const [value, setValue] = useState(null);
	const [isFocus, setIsFocus] = useState(false);

	const [doRefresh, setDoRefresh] = useState(false);
	const [vehicleData, setVehicleData] = useState([]);
	const [serviceData, setServiceData] = useState([]);
	const [centerData, setCenterData] = useState([]);
	const [fetchingData, setFetchingData] = useState(true);
	const [showVehicleForm, setShowVehicleForm] = useState(false);
	const [showMap, setShowMap] = useState(false);

	const [uploadableMedia, setUploadableMedia] = useState({ media: [] });
	const [showUploader, setShowUploader] = useState(false);

	const [postingInProgress, setPostingInProgress] = useState(false);

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	useEffect(() => {
		/**
		 * On web version we need to check for user session validity on page refresh as the axios default headers don't
		 * persist page resfresh, so this is an additional step and needs to be done on all pages.
		 */
		Utils.isGuestOrUser(navigation).then((result) => {
			if (
				result == null ||
				typeof result == "undefined" ||
				result == "guest"
			) {
				// setGuestUserState(true);
				// hitApis(true);
				// Utils.handleLogOut(navigation);
				console.log(route);
				let encodedUri = btoa(
					route.name + "?" + JSON.stringify(route?.params)
				);
				navigation.navigate("login", {
					next: encodedUri,
					error: "guest-booking",
				});
			} else {
				axiosInstance.defaults.headers.common["Authorization"] = result;
				hitApis(false);
				setGuestUserState(false);
			}
		});
	}, []);

	const hitApis = (isGuest) => {
		if (!isGuest) {
			axiosInstance({
				method: "get",
				url: `auto-care/vehicles/`,
			})
				.then((response) => {
					if (response.status >= 200 && response.status < 300) {
						handleVehicleResponse(response.data);
					} else {
						Utils.notifyMessage(
							"Error connecting to our servers. Try again later."
						);
					}
				})
				.catch((onrejected) => {
					Utils.handleFailedResponse(
						"getVehicle",
						onrejected.response,
						onrejected.response.status,
						navigation
					);
				});
			axiosInstance({
				method: "get",
				url: `/auto-care/forms/service-bookings/`,
			})
				.then((response) => {
					if (response.status >= 200 && response.status < 300) {
						handleFormOptionsResponse(response.data);
					} else {
						Utils.notifyMessage(
							"Error connecting to our servers. Try again later."
						);
					}
				})
				.catch((onrejected) => {
					Utils.handleFailedResponse(
						"getBookings",
						onrejected.response,
						onrejected.response.status,
						navigation
					);
				});

			axiosInstance({
				method: "get",
				url: `auto-care/locations/`,
			})
				.then((response) => {
					if (response.status >= 200 && response.status < 300) {
						handleLocationResponse(response.data);
					} else {
						Utils.notifyMessage(
							"Error connecting to our servers. Try again later."
						);
					}
				})
				.catch((onrejected) => {
					Utils.handleFailedResponse(
						"getBookings",
						onrejected.response,
						onrejected.response.status,
						navigation
					);
				});

			if (opts == "true") {
				axiosInstance({
					method: "get",
					url: `/auto-care/services/`,
				})
					.then((response) => {
						if (response.status >= 200 && response.status < 300) {
							setServiceData(response.data.results);
							setSelectedService(response.data.results[0].id);
						} else {
							Utils.notifyMessage(
								"Error connecting to our servers. Try again later."
							);
						}
					})
					.catch((onrejected) => {
						Utils.handleFailedResponse(
							"getServices",
							onrejected.response,
							onrejected.response.status,
							navigation
						);
					});
				axiosInstance({
					method: "get",
					url: `/auto-care/service-centers/`,
				})
					.then((response) => {
						if (response.status >= 200 && response.status < 300) {
							setCenterData(response.data.results);
							setSelectedCenter(response.data.results[0].id);
						} else {
							Utils.notifyMessage(
								"Error connecting to our servers. Try again later."
							);
						}
					})
					.catch((onrejected) => {
						Utils.handleFailedResponse(
							"getServiceCenters",
							onrejected.response,
							onrejected.response.status,
							navigation
						);
					});
			} else {
				axiosInstance({
					method: "get",
					url: `/auto-care/services/${serviceID}/`,
				})
					.then((response) => {
						if (response.status >= 200 && response.status < 300) {
							setServiceObj(response.data);
							setSelectedService(response.data);
						} else {
							Utils.notifyMessage(
								"Error connecting to our servers. Try again later."
							);
						}
					})
					.catch((onrejected) => {
						Utils.handleFailedResponse(
							"getServices",
							onrejected.response,
							onrejected.response.status,
							navigation
						);
					});
			}
		}
	};

	const handleLocationResponse = (response) => {
		if (response.length > 0) {
			let defaultAddress = "";
			response.forEach((addressObj) => {
				if (addressObj.is_default) {
					defaultAddress = addressObj.address;
				}
			});
			setSelectedAddress(defaultAddress);
			setLocationData(response);
		} else {
			setSelectedAddress("");
		}
	};

	const handleVehicleResponse = (response) => {
		if (response != null && response.length > 0) {
			setVehicleData(response);
			setSelectedVehicle(response[0].id);
		}
		setFetchingData(false);
	};

	const handleFormOptionsResponse = (response) => {
		setAvailablePickupDates(response.pickup_date_options);
		setAvailablePickupTimes(response.pickup_time_options);
		setAvailableDropDates(response.drop_date_options);
		setAvailableDropTimes(response.drop_time_options);
		setSelectedPickupDateText(response.pickup_date_options[0]);
		setSelectedPickupTimeText(response.pickup_time_options[0]);
		setSelectedDropDateText(response.drop_date_options[0]);
		setSelectedDropTimeText(response.drop_time_options[0]);
	};

	const postCreateBooking = () => {
		setPostingInProgress(true);
		try {
			axiosInstance({
				method: "post",
				url: `/auto-care/service-bookings/`,
				data: {
					vehicle_id: selectedVehicle,
					service_center_id:
						opts == "true" ? selectedCenter : companyID,
					service_id:
						opts == "true" ? selectedService : serviceObj.id,
					issue_description: additionalNotesText,
					pickup: isPickupSelected,
					pickup_location_id: isPickupSelected ? selectedAddress : "",
					pickup_date_time: isPickupSelected
						? selectedPickupDateText +
						  " " +
						  Utils.convert12Hto24HFormat(selectedPickupTimeText) +
						  ":00"
						: null,
					drop_date_time: !isPickupSelected
						? selectedDropDateText +
						  " " +
						  Utils.convert12Hto24HFormat(selecteDropTimeText) +
						  ":00"
						: null,
				},
			})
				.then((response) => {
					if (response.status >= 200 && response.status < 300) {
						console.log("booking creation successful!!");
						console.log(response.data.id);
						if (uploadableMedia.media.length > 0) {
							console.log("uploading image now");
							uploadableMedia.media.forEach((media, i) => {
								axiosInstance({
									method: "post",
									url: `/auto-care/service-bookings/${response.data.id}/media/`,
									data: {
										booking_id: response.data.id,
										type: "IMAGE",
										file: media.dataURL,
									},
								})
									.then(() => {
										if (
											i ==
											uploadableMedia.media.length - 1
										) {
											setPostingInProgress(false);
											// linkTo(`/estimatedpayment/${response.data.id}`);
											navigation.navigate(
												"estimatedpayment",
												{
													preId: response.data.id,
												}
											);
										} else {
											console.log("upload in progress");
										}
									})
									.catch((error) => {
										console.log(error);
										setPostingInProgress(false);
										Utils.notifyMessage(
											"Failed to upload all Images"
										);
									});
							});
						} else {
							setPostingInProgress(false);
							navigation.navigate("estimatedpayment", {
								preId: response.data.id,
							});
						}
					} else {
						Utils.notifyMessage(
							"Error connecting to our servers. Try again later."
						);
						setPostingInProgress(false);
					}
				})
				.catch((onrejected) => {
					console.log(onrejected);
					setPostingInProgress(false);
					setShowError(true);
					Utils.handleFailedResponse(
						"postBookings",
						onrejected.response,
						onrejected.response.status,
						navigation,
						setErrorText
					);
				});
		} catch (error) {
			console.log(error);
			setErrorText(error);
			setShowError(true);
			setPostingInProgress(false);
		}
	};

	const DayChip = (day, month, key, fullDate) => {
		return (
			<TouchableOpacity
				style={{
					height: 60,
					width: 80,
					borderColor: selectedDate == key ? "#1D3688" : "#7e98ed",
					backgroundColor: selectedDate == key ? "#1D3688" : "white",
					borderWidth: 2,
					borderRadius: 10,
					alignItems: "center",
					justifyContent: "center",
					marginHorizontal: 3,
				}}
				key={key}
				onPress={() => {
					setSelectedDate(key);
					isPickupSelected
						? setSelectedPickupDateText(fullDate)
						: setSelectedDropDateText(fullDate);
				}}>
				<Text
					style={{
						fontSize: 18,
						fontFamily: "Gilroy-SemiBold",
						color: selectedDate == key ? "white" : "black",
					}}>
					{day}
				</Text>
				<Text
					style={{
						fontSize: 18,
						fontFamily: "Gilroy-Regular",
						color: selectedDate == key ? "white" : "black",
					}}>
					{month}
				</Text>
			</TouchableOpacity>
		);
	};

	const TimeChip = (time, key) => {
		return (
			<TouchableOpacity
				style={{
					height: 37,
					width: 90,
					borderColor: selectedTime == key ? "#1D3688" : "#7e98ed",
					backgroundColor: selectedTime == key ? "#1D3688" : "white",
					borderWidth: 2,
					borderRadius: 10,
					alignItems: "center",
					justifyContent: "center",
					marginHorizontal: 3,
				}}
				key={key}
				onPress={() => {
					setSelectedTime(key);
					isPickupSelected
						? setSelectedPickupTimeText(time)
						: setSelectedDropTimeText(time);
				}}>
				<Text
					style={{
						fontSize: 18,
						fontFamily: "Gilroy-Regular",
						color: selectedTime == key ? "white" : "black",
					}}>
					{time}
				</Text>
			</TouchableOpacity>
		);
	};

	const ListOfDayOptions = (type) => {
		let temp = [];
		if (type == "pickup") {
			temp = availablePickupDates.concat(temp);
		} else {
			temp = availableDropDates.concat(temp);
		}
		return temp.map((date, index) => {
			return DayChip(
				date.split("-")[2],
				Utils.getNumToMonth(date.split("-")[1] - 1),
				index,
				date
			);
		});
	};

	const ListOfTimeOptions = (type) => {
		let temp = [];
		if (type == "pickup") {
			temp = availablePickupTimes.concat(temp);
		} else {
			temp = availableDropTimes.concat(temp);
		}
		return temp.map((time, index) => {
			return TimeChip(time, index);
		});
	};

	useEffect(() => {
		if (vehicleData.length > 0) {
			setSelectedVehicle(vehicleData[0].id);
		}
	}, [vehicleData]);

	const addAddress = (newAddress) => {
		console.log(newAddress);
		setLocationData((prev) => [...prev.items, newAddress]);
		setSelectedAddress(newAddress.id);
	};

	return (
		// Parent Body
		<View style={styles.container}>
			{fetchingData || postingInProgress ? (
				<View
					style={{
						flexGrow: 1,
						width: windowSize.width < 800 ? "100%" : "70%",
						maxWidth: 900,
						alignSelf: "center",
						alignItems: "center",
						justifyContent: "center",
						backgroundColor: "#fff",
						paddingHorizontal: 20,
						paddingBottom: 120,
					}}>
					<View>
						<ActivityIndicator
							animating={true}
							size={"large"}
							color={"#7E98ED"}
						/>
					</View>
				</View>
			) : !isGuestUserState ? (
				// Scrollable Main body
				<ScrollView
					contentContainerStyle={{
						flexGrow: 1,
						width: windowSize.width < 800 ? "100%" : "70%",
						maxWidth: 900,
						alignSelf: "center",
						backgroundColor: "#fff",
						paddingHorizontal: 20,
						paddingBottom: 220,
					}}>
					{/* Upper body before pickup switch */}
					<View
						style={{
							marginTop: 10,
							paddingStart: 25,
						}}>
						{/* Selected Vehicle section with button adjacent */}
						<Text
							style={{
								color: "#454249",
								fontFamily: "Gilroy-SemiBold",
								fontSize: 20,
							}}>
							Selected Vehicle
						</Text>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
								paddingEnd: 10,
								alignItems: "center",
							}}>
							<View style={{ width: "85%" }}>
								{fetchingData ? (
									<Text style={styles.vehicleText}>
										{"Loading..."}
									</Text>
								) : vehicleData != null &&
								  vehicleData.length > 0 ? (
									<ScrollView
										horizontal={true}
										showsHorizontalScrollIndicator={true}>
										{vehicleData.map((vehicle, i) => {
											return (
												<TouchableOpacity
													key={i}
													style={{
														width: 80,
														alignItems: "center",
														backgroundColor:
															"#efefef",
														borderWidth:
															vehicle.id ==
															selectedVehicle
																? 2
																: 1,
														borderColor:
															vehicle.id ==
															selectedVehicle
																? "#7E98ED"
																: "#eee",
														borderRadius: 7,
														overflow: "hidden",
														margin: 5,
														elevation:
															vehicle.id ==
															selectedVehicle
																? 0
																: 3,
													}}
													onPress={() =>
														setSelectedVehicle(
															vehicle.id
														)
													}>
													<Image
														style={{
															height: 80,
															width: 80,
														}}
														resizeMode='contain'
														source={
															vehicle == null ||
															!vehicle.hasOwnProperty(
																"vehicle_photos"
															) ||
															vehicle.vehicle_photos ==
																null ||
															vehicle
																.vehicle_photos
																.length == 0
																? no_image
																: {
																		uri:
																			vehicle
																				.vehicle_photos[0]
																				.file +
																			"",
																  }
														}
													/>
													<Text
														style={
															styles.vehicleText
														}>
														{vehicle.name}
													</Text>
												</TouchableOpacity>
											);
										})}
									</ScrollView>
								) : (
									<Text style={styles.vehicleText}>
										{"No vehicle found, please add"}
									</Text>
								)}
							</View>
							<View>
								<NonFilledButton
									title={"Add"}
									onPress={() => {
										// navigation.navigate("managevehicles", {
										// 	vehicleData: vehicleData,
										// 	setVehicleData: setVehicleData,
										// });
										setShowVehicleForm(true);
									}}
									fontStyle={{ fontSize: 12 }}
								/>
							</View>
						</View>
						{/* Selected Plan section to display plan selected on previous screen */}
						{opts == "true" ? (
							serviceData.length > 0 ? (
								<View>
									<Text
										style={{
											color: "#454249",
											fontFamily: "Gilroy-SemiBold",
											fontSize: 20,
											marginTop: 10,
										}}>
										Select a Plan
									</Text>
									<View style={{ flexDirection: "row" }}>
										<ClickableCard
											borderColor='#7E98ED'
											icon={
												serviceData[0].image == null
													? no_image
													: serviceData[0].image
											}
											iconHeight={72}
											iconWidth={100}
											onPress={() => {
												setSelectedService(
													serviceData[0].id
												);
											}}
											text={serviceData[0].name}
											isUrl={serviceData[0].image != null}
											extraStyle={{ borderWidth: 0 }}
											extraOuterStyle={[
												styles.serviceCard,
												{
													borderWidth:
														selectedService ==
														serviceData[0].id
															? 2
															: 1,
													borderColor:
														selectedService ==
														serviceData[0].id
															? "#7E98ED"
															: "#eee",
													elevation:
														selectedService ==
														serviceData[0].id
															? 0
															: 3,
												},
											]}
										/>
										<ClickableCard
											borderColor='#7E98ED'
											icon={
												serviceData[1].image == null
													? no_image
													: serviceData[1].image
											}
											iconHeight={72}
											iconWidth={100}
											onPress={() => {
												setSelectedService(
													serviceData[1].id
												);
											}}
											text={serviceData[1].name}
											isUrl={serviceData[1].image != null}
											extraStyle={{ borderWidth: 0 }}
											extraOuterStyle={[
												styles.serviceCard,
												{
													borderWidth:
														selectedService ==
														serviceData[1].id
															? 2
															: 1,
													borderColor:
														selectedService ==
														serviceData[1].id
															? "#7E98ED"
															: "#eee",
													elevation:
														selectedService ==
														serviceData[1].id
															? 0
															: 3,
												},
											]}
										/>
										<View
											style={{
												flex: 1,
												height: 125,
												maxWidth: 100,
												backgroundColor: "#7E98ED",
												borderRadius: 7,
												margin: 5,
												alignItems: "center",
												justifyContent: "center",
											}}
											onTouchEnd={() => {
												navigation.navigate(
													"OptionListScreen",
													{
														typeOfList: "services",
														setSelectedService:
															setSelectedService,
														extraData: null,
													}
												);
											}}>
											<Text
												style={{
													color: "#fff",
													fontFamily:
														"Gilroy-SemiBold",
												}}>
												See more Services
											</Text>
										</View>
									</View>
								</View>
							) : (
								<View>
									<Text
										style={{
											color: "#bbb",
											fontFamily: "Gilroy-Regular",
										}}>
										Loading...
									</Text>
								</View>
							)
						) : (
							<View>
								<Text
									style={{
										color: "#454249",
										fontFamily: "Gilroy-SemiBold",
										fontSize: 20,
										marginTop: 10,
									}}>
									Selected Plan
								</Text>
								{serviceObj !== null ? (
									<View>
										<Option
											isSelected={true}
											children={
												<View>
													<Text
														style={{
															fontFamily:
																"Gilroy-SemiBold",
															fontSize: 16,
														}}>
														{serviceObj.name}
													</Text>
													{serviceObj.description
														.split("\n")
														.map((text, i) => {
															return (
																<Text
																	key={i}
																	style={{
																		color: "#2A292E",
																		fontFamily:
																			"Gilroy-Regular",
																	}}>
																	{"\u2022 " +
																		text}
																</Text>
															);
														})}
												</View>
											}
											tag={serviceObj.tag}
											imageUrl={
												serviceObj.image == null
													? no_image
													: serviceObj.image
											}
											linkTo={""}
										/>
									</View>
								) : null}
							</View>
						)}
						{/*Company options ONLY SHOWN WHEN SCREEN IS FAST ORDER */}
						{opts == "true" ? (
							centerData.length > 0 ? (
								<View style={{ marginRight: 15 }}>
									<Text
										style={{
											color: "#454249",
											fontFamily: "Gilroy-SemiBold",
											fontSize: 20,
											marginTop: 10,
										}}>
										Select a Provider
									</Text>
									<View
										style={{
											flexDirection: "row",
										}}>
										<FloatingCardBlank
											Content={
												<View>
													<Image
														source={
															centerData[0]
																.image != null
																? {
																		uri:
																			centerData[0]
																				.image +
																			"",
																  }
																: no_image
														}
														style={{
															width: "100%",
															height: "100%",
														}}
														resizeMode='cover'
													/>
													<View
														style={{
															position:
																"absolute",
															height: "100%",
															width: "100%",
															backgroundColor:
																"#121212",
															opacity: 0.5,
														}}
													/>
													<View
														style={{
															position:
																"absolute",
															height: "100%",
															width: "100%",
															zIndex: 100,
															justifyContent:
																"center",
															alignItems:
																"center",
														}}>
														<Text
															style={{
																color: "#fff",
																fontFamily:
																	"Gilroy-SemiBold",
																color:
																	Platform.OS ==
																	"ios"
																		? selectedCenter ==
																		  centerData[0]
																				.id
																			? "#fff"
																			: "#c9c7c7"
																		: "#fff",
															}}>
															{centerData[0].name}
														</Text>
														<Text
															style={{
																marginTop: 5,
																marginStart: 10,
																fontSize: 12,
																color:
																	Platform.OS ==
																	"ios"
																		? selectedCenter ==
																		  centerData[0]
																				.id
																			? "#dedcdc"
																			: "#c9c7c7"
																		: "#dedcdc",
																fontFamily:
																	"Gilroy-Regular",
															}}>
															{centerData[0]
																.location
																.address +
																"\n" +
																centerData[0]
																	.location
																	.city}
														</Text>
													</View>
												</View>
											}
											extraStyle={{
												maxWidth: 150,
												overflow: "hidden",
												flex: 1,
												borderWidth:
													selectedCenter ==
													centerData[0].id
														? 2
														: 0,
												borderColor:
													selectedCenter ==
													centerData[0].id
														? "#7E98ED"
														: "#eee",
											}}
											activeOpacity={0.8}
											onPress={() => {
												setSelectedCenter(
													centerData[0].id
												);
											}}
										/>
										<FloatingCardBlank
											Content={
												<View>
													<Image
														source={
															centerData[1]
																.image != null
																? {
																		uri:
																			centerData[1]
																				.image +
																			"",
																  }
																: no_image
														}
														style={{
															width: "100%",
															height: "100%",
														}}
														resizeMode='cover'
													/>
													<View
														style={{
															position:
																"absolute",
															height: "100%",
															width: "100%",
															backgroundColor:
																"#121212",
															opacity: 0.5,
														}}
													/>
													<View
														style={{
															position:
																"absolute",
															height: "100%",
															width: "100%",
															zIndex: 100,
															justifyContent:
																"center",
															alignItems:
																"center",
														}}>
														<Text
															style={{
																color:
																	Platform.OS ==
																	"ios"
																		? selectedCenter ==
																		  centerData[1]
																				.id
																			? "#fff"
																			: "#c9c7c7"
																		: "#fff",
																fontFamily:
																	"Gilroy-SemiBold",
															}}>
															{centerData[1].name}
														</Text>
														<Text
															style={{
																marginTop: 5,
																marginStart: 10,
																fontSize: 12,
																color:
																	Platform.OS ==
																	"ios"
																		? selectedCenter ==
																		  centerData[1]
																				.id
																			? "#dedcdc"
																			: "#c9c7c7"
																		: "#dedcdc",
																fontFamily:
																	"Gilroy-Regular",
															}}>
															{centerData[1]
																.location
																.address +
																"\n" +
																centerData[1]
																	.location
																	.city}
														</Text>
													</View>
												</View>
											}
											extraStyle={{
												maxWidth: 150,
												overflow: "hidden",
												flex: 1,
												borderWidth:
													selectedCenter ==
													centerData[1].id
														? 2
														: 0,
												borderColor:
													selectedCenter ==
													centerData[1].id
														? "#7E98ED"
														: "#eee",
											}}
											activeOpacity={0.8}
											onPress={() => {
												setSelectedCenter(
													centerData[1].id
												);
											}}
										/>
										<View
											onTouchEnd={() => {
												navigation.navigate(
													"OptionListScreen",
													{
														typeOfList: "centers",
														setSelectedService:
															setSelectedService,
														setSelectedCenter:
															setSelectedCenter,
														extraData: null,
													}
												);
											}}
											style={{
												flex: 1,
												height: 160,
												maxWidth: 80,
												backgroundColor: "#7E98ED",
												borderRadius: 7,
												margin: 5,
												marginVertical: 10,
												alignItems: "center",
												justifyContent: "center",
											}}>
											<Text
												style={{
													color: "#fff",
													fontFamily:
														"Gilroy-SemiBold",
												}}>
												See more Providers
											</Text>
										</View>
									</View>
								</View>
							) : (
								<Text
									style={{
										color: "#bbb",
										fontFamily: "Gilroy-Regular",
									}}>
									Loading...
								</Text>
							)
						) : null}
						{/* Additional Notes section with textinput */}
						<Text
							style={{
								color: "#454249",
								fontFamily: "Gilroy-SemiBold",
								fontSize: 20,
								marginTop: 10,
							}}>
							Additional Notes
						</Text>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								marginTop: 10,
							}}>
							<Text
								style={{
									color: "#7E98ED",
									borderWidth: 1,
									borderColor: "#7E98ED",
									borderRadius: 20,
									height: 15,
									width: 15,
									textAlign: "center",
									fontSize: 10,
									fontFamily: "Gilroy-Regular",
								}}>
								i
							</Text>
							<Text
								style={{
									fontSize: 12,
									fontFamily: "Gilroy-Regular",
									marginLeft: 7,
								}}>
								You can also upload image or video about your
								problems!
							</Text>
						</View>
						<View>
							<TextInput
								style={styles.textInput}
								placeholder='You can mention any additional problem in your Vehicle here.'
								placeholderTextColor='#858189'
								color='#000'
								multiline={true}
								onChangeText={(t) => {
									setAdditionalNotesText(t);
								}}
							/>
							<View
								style={{
									borderBottomRightRadius: 9,
									borderBottomLeftRadius: 9,
									borderWidth: 1.6,
									borderTopWidth: 0,
									borderColor: "#ddd",
									marginEnd: 10,
									padding: 10,
								}}>
								<View style={{ flexDirection: "row" }}>
									<TouchableOpacity
										style={{
											backgroundColor: showUploader
												? "#7E98ED"
												: "#fff",
											height: 30,
											width: 30,
											borderRadius: 15,
											padding: 2,
										}}
										onPress={() =>
											setShowUploader((prev) => !prev)
										}>
										<Image
											source={attach_ic}
											style={{
												flex: 1,
												tintColor: !showUploader
													? "#7E98ED"
													: "#fff",
											}}
											resizeMode='contain'
										/>
									</TouchableOpacity>
									{uploadableMedia.media.map((media, i) => {
										return (
											<View
												style={{ marginHorizontal: 4 }}>
												<Image
													key={i}
													source={{
														uri: media.dataURL,
													}}
													style={{
														height: 80,
														width: 80,
													}}
													resizeMode='contain'
												/>
												<TouchableOpacity
													onPress={() => {
														setUploadableMedia(
															(prev) => ({
																media: prev.media.filter(
																	(
																		value,
																		index
																	) =>
																		index !=
																		i
																),
															})
														);
													}}
													style={{
														position: "absolute",
														right: -5,
														top: -5,
														borderRadius: 30,
														overflow: "hidden",
													}}>
													<Image
														key={i}
														source={cross_ic}
														style={{
															height: 25,
															width: 25,
															backgroundColor:
																"red",
															tintColor: "#fff",
															transform: [
																{
																	rotate: "45deg",
																},
															],
														}}
														resizeMode='contain'
													/>
												</TouchableOpacity>
											</View>
										);
									})}
								</View>
								{showUploader ? (
									<View
										style={{
											width: "100%",
											alignItems: "center",
											marginTop: 20,
										}}>
										<MultiImageCollector
											onUpload={(items) => {
												if (
													uploadableMedia.media
														.length < 5 &&
													items.length +
														uploadableMedia.media
															.length <=
														5
												) {
													items.forEach((item) => {
														setUploadableMedia(
															(prev) => ({
																media: [
																	...prev.media,
																	item,
																],
															})
														);
													});
												} else {
													setErrorText(
														"Cannot upload more than 5 Images."
													);
													setShowError(true);
												}
											}}
											uploadedImages={
												uploadableMedia.media
											}
										/>
									</View>
								) : null}
							</View>
						</View>
					</View>
					{/* Pickup switch, also divides the screen in two section */}
					<View
						style={{
							width: "100%",
							height: 44,
							backgroundColor: "#7E98ED",
							justifyContent: "center",
							marginTop: 20,
						}}>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
								paddingHorizontal: 20,
							}}>
							<Text
								style={{
									color: "white",
									fontSize: 16,
									fontFamily: "Gilroy-Regular",
								}}>
								Want us to pickup & drop?
							</Text>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
								}}>
								<Text
									style={{
										color: "white",
										fontFamily: "Gilroy-Regular",
									}}>
									No
								</Text>
								<Switch
									trackColor={{
										false: "#767577",
										true: "#81b0ff",
									}}
									thumbColor={"#eee"}
									ios_backgroundColor='#808080'
									onValueChange={toggleSwitch}
									value={isPickupSelected}
									style={{
										marginHorizontal: 7,
									}}
								/>
								<Text
									style={{
										color: "white",
										fontFamily: "Gilroy-Regular",
									}}>
									Yes
								</Text>
							</View>
						</View>
					</View>
					{/* Only display if isPickUp switch was selected above */}
					{isPickupSelected ? (
						<View>
							<Text
								style={{
									color: "#454249",
									fontFamily: "Gilroy-SemiBold",
									fontSize: 20,
									marginTop: 15,
									paddingStart: 24,
								}}>
								Pick Up Address
							</Text>
							<View
								style={{
									flexDirection: "row",
									height: 80,
									width: "100%",
									alignItems: "center",
									paddingStart: 24,
									marginTop: 5,
								}}>
								{fetchingData ||
								locationData == null ||
								locationData.length == 0 ? (
									<View
										style={{
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "center",
											flex: 1,
											paddingHorizontal: 10,
										}}>
										<Text
											style={{
												fontFamily: "Gilroy-Regular",
											}}>
											No location info, add now
										</Text>
										<NonFilledButton
											title={"Add"}
											onPress={() => {
												//Add location
												setShowMap(true);
											}}
										/>
									</View>
								) : (
									<View style={{ width: "80%" }}>
										{/* {console.log(locationData)} */}
										<View
											style={{
												flexDirection: "row",
												alignItems: "center",
												marginTop: 10,
											}}>
											<View
												style={{
													height: 26,
													width: 26,
													marginRight: 7,
												}}>
												<Image
													source={location_ic}
													style={{
														height: "100%",
														width: "100%",
													}}
													resizeMode='contain'
												/>
											</View>
											<Picker
												style={[styles.dropdown]}
												onValueChange={(item) => {
													setValue(item.value);
													setIsFocus(false);
													setSelectedAddress(item.id);
												}}
												selectedValue={selectedAddress}>
												{locationData.map((item, i) => {
													return (
														<Picker.Item
															key={i}
															itemStyle={{
																maxWidth: 100,
															}}
															label={item.address}
															value={item.address}
														/>
													);
												})}
											</Picker>
											{/* <Dropdown
												style={[
													styles.dropdown,
													isFocus && {
														borderColor: "#7e98ed",
														borderWidth: 2,
													},
												]}
												onFocus={() => setIsFocus(true)}
												onBlur={() => setIsFocus(false)}
												onChange={(item) => {
													setValue(item.value);
													setIsFocus(false);
													setSelectedAddress(item.id);
												}}
												placeholder={
													"Set Pick up address"
												}
												maxHeight={300}
												data={locationData}
												labelField='address'
												valueField='id'
												selectedTextProps={{
													numberOfLines: 2,
													ellipsizeMode: "tail",
												}}
												selectedTextStyle={{
													fontSize: 14,
												}}
											/> */}
										</View>
										<NonFilledButton
											title={"Add new address"}
											style={{
												width: 150,
												marginTop: 5,
												alignSelf: "flex-end",
											}}
											fontStyle={{
												fontSize: 12,
												fontWeight: "500",
											}}
											onPress={() => {
												setShowMap(true);
											}}
										/>
									</View>
								)}
							</View>
						</View>
					) : null}
					{/* bottom half of screen */}
					<View style={{ paddingStart: 24 }}>
						<Text
							style={{
								color: "#454249",
								fontFamily: "Gilroy-SemiBold",
								fontSize: 20,
								marginTop: 20,
							}}>
							{isPickupSelected
								? "Pick a date you would like for us to pick up your vehicle"
								: "Pick a date when you will be dropping your vehicle at the center"}
						</Text>
						{/* Scrollable date options */}
						{fetchingData ? (
							<View>
								<Text
									style={{
										color: "#bbb",
										fontFamily: "Gilroy-Regular",
									}}>
									Loading...
								</Text>
							</View>
						) : (
							<ScrollView
								horizontal={true}
								nestedScrollEnabled={true}
								showsHorizontalScrollIndicator={false}
								style={{ marginTop: 10, width: "90%" }}>
								{isPickupSelected
									? ListOfDayOptions("pickup")
									: ListOfDayOptions("drop")}
							</ScrollView>
						)}
						<Text
							style={{
								color: "#454249",
								fontFamily: "Gilroy-SemiBold",
								fontSize: 20,
								marginTop: 20,
							}}>
							{isPickupSelected
								? "Pick your prefered time slot for pickup"
								: "Pick the time at which you will drop by"}
						</Text>
						{/* Scrollable time options */}
						{fetchingData ? (
							<View>
								<Text
									style={{
										color: "#bbb",
										fontFamily: "Gilroy-Regular",
									}}>
									Loading...
								</Text>
							</View>
						) : (
							<ScrollView
								horizontal={true}
								nestedScrollEnabled={true}
								showsHorizontalScrollIndicator={false}
								style={{ marginTop: 10, width: "90%" }}>
								{isPickupSelected
									? ListOfTimeOptions("pickup")
									: ListOfTimeOptions("drop")}
							</ScrollView>
						)}
					</View>
					<BottomModal
						isVisible={showVehicleForm}
						height={"90%"}
						children={
							<VehicleSelectionForm
								navigation={navigation}
								route={{
									params: {
										vehicleData: vehicleData,
										setVehicleData: setVehicleData,
										setSelectedVehicle: setSelectedVehicle,
										showVehicleForm: setShowVehicleForm,
									},
								}}
							/>
						}
						onClose={() => setShowVehicleForm(false)}
					/>
					<BottomModal
						isVisible={showMap}
						height={"90%"}
						children={
							<WebMap
								setNewAddress={addAddress}
								showMap={setShowMap}
							/>
						}
						onClose={() => setShowMap(false)}
					/>
				</ScrollView>
			) : (
				//Error overlay
				<View
					style={{
						flexGrow: 1,
						width: windowSize.width < 800 ? "100%" : "70%",
						maxWidth: 900,
						alignSelf: "center",
						backgroundColor: "#fff",
						paddingHorizontal: 20,
						paddingBottom: 120,
					}}>
					<Image
						source={login_error_vector}
						style={{ height: 240, width: 240 }}
						resizeMode='contain'
					/>
					<Text style={{ marginVertical: 10, fontWeight: "500" }}>
						Please Log in to book a service
					</Text>
					<DarkButton
						title={"Log In"}
						onPress={() => {
							navigation.reset({
								index: 0,
								routes: [{ name: "Login" }],
							});
						}}
						style={{ marginTop: 20 }}
					/>
				</View>
			)}
			{!isGuestUserState ? (
				<TouchableOpacity
					style={[
						{
							width: 200,
							borderRadius: 50,
							// maxWidth: 900,
							height: 70,
							position: "absolute",
							alignSelf: "flex-end",
							margin: 10,
							bottom: windowSize.width < 800 ? 120 : 70,
							// marginLeft: -10,
							backgroundColor: "#001A45",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							paddingHorizontal: 20,
						},
					]}
					onPress={() => postCreateBooking()}>
					<Text
						style={{
							width: "90%",
							color: "white",
							fontSize: 26,
							fontFamily: "Gilroy-Regular",
						}}>
						Next
					</Text>
					<Image
						source={arrow_ic}
						style={{
							height: 25,
							width: 25,
							tintColor: "white",
							transform: [{ rotate: "-90deg" }],
						}}
						resizeMode='contain'
					/>
				</TouchableOpacity>
			) : null}
			<TopFeedbackBar
				feedbackNature={"error"}
				text={errorText}
				show={showError}
				setShow={setShowError}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// backgroundColor: "white",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	textInput: {
		borderTopRightRadius: 9,
		borderTopLeftRadius: 9,
		borderWidth: 1.6,
		borderColor: "#ddd",
		paddingHorizontal: 15,
		height: 80,
		marginEnd: 10,
		marginTop: 5,
		outlineColor: "#7e98ed",
		padding: 7,
		fontFamily: "Gilroy-Regular",
	},
	vehicleText: {
		color: "#52566C",
		fontSize: 14,
		marginTop: 5,
		fontFamily: "Gilroy-Regular",
	},
	dropdown: {
		height: 40,
		width: "100%",
		borderColor: "#ddd",
		borderWidth: 1.3,
		borderRadius: 8,
		paddingHorizontal: 8,
		marginTop: 5,
		outlineColor: "#7e98ed",
	},
	serviceCard: {
		flex: 1,
		height: 125,
		maxWidth: 110,
		justifyContent: "space-between",
		padding: 5,
		backgroundColor: "#efefef",
		borderRadius: 7,
		overflow: "hidden",
		margin: 5,
	},
});
export default ServiceBookingScreen;
