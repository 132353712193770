import React, { useState, useRef } from "react";
import YTPlayer from "../Utils/YTPlayer";
import { Dimensions, View, Text, FlatList } from "react-native";
import fontStyle from "../../Styles/typography";

import Carousel from "react-native-x-carousel";
import Flipper from "../WebOnly/Flipper";
import {
	BrowserView,
	MobileView,
	isBrowser,
	isMobile,
} from "react-device-detect";

const GenericYTVideoComponent = ({ data, props }) => {
	const [index, setIndex] = useState(0);
	const scrollableRef = useRef();
	const [currentScrollPosition, setCurrentScrollPosition] = useState(0);

	return (
		<View>
			{data.items.length > 0 ? (
				<View
					style={{
						marginBottom: 10,
					}}>
					<Text
						style={[
							fontStyle.fontTitle,
							{ marginLeft: 20, marginTop: 10, marginBottom: 20 },
						]}>
						{data.name}
					</Text>
					<BrowserView>
						{index != 0 ? (
							<Flipper
								gradientStart={"#00000000"}
								gradientEnd={"#00000000"}
								rightFlipper={false}
								onPress={() => {
									scrollableRef.current.scrollView.current.scrollTo(
										{
											x:
												currentScrollPosition -
												scrollableRef.current.scrollView
													.current.offsetWidth,
											y: 0,
											animated: true,
										}
									);
								}}
								extraStyles={{ top: 120 }}
							/>
						) : null}
						{data.items.length > 1 &&
						index != data.items.length - 1 ? (
							<Flipper
								gradientStart={"#00000000"}
								gradientEnd={"#00000000"}
								rightFlipper={true}
								onPress={() => {
									scrollableRef.current.scrollView.current.scrollTo(
										{
											x:
												currentScrollPosition +
												scrollableRef.current.scrollView
													.current.offsetWidth,
											y: 0,
											animated: true,
										}
									);
								}}
								extraStyles={{ top: 120 }}
							/>
						) : null}
					</BrowserView>
					<View style={{ alignItems: "center" }}>
						<Carousel
							ref={scrollableRef}
							data={data.items}
							renderItem={(item, i) => (
								<YTPlayer
									key={i}
									extraStyles={
										props.windowDim.width < 500
											? {
													minWidth:
														0.8 *
														props.windowDim.width, //80% of window width
													aspectRatio: 1.77, //maintain AR of 1920/1080
													alignSelf: "center",
													borderRadius: 7,
													overflow: "hidden",
											  }
											: {
													minWidth:
														0.5 *
														props.windowDim.width, //50% of window width because max width of top level container on full screen is 70%
													aspectRatio: 1.77, //maintain AR of 1920/1080
													alignSelf: "center",
													borderRadius: 7,
													overflow: "hidden",
											  }
									}
									videoId={item.url.replace(
										"https://youtu.be/",
										""
									)}
								/>
							)}
							onPage={({ current }) => {
								setIndex(current - 1);
							}}
							onScroll={(e) => {
								if (isBrowser) {
									setCurrentScrollPosition(
										e.nativeEvent.contentOffset.x
									);
								}
							}}
						/>
					</View>
					{data.items.length > 1 ? (
						<View
							style={{
								flexDirection: "row",
								justifyContent: "center",
							}}>
							{data.items.map((item, i) => {
								return (
									<View
										key={i}
										style={{
											height: 10,
											width: 10,
											backgroundColor:
												index == i ? "#7E98ED" : "#ccc",
											marginVertical: 8,
											marginHorizontal: 4,
											borderRadius: 20,
										}}
									/>
								);
							})}
						</View>
					) : null}
				</View>
			) : null}
		</View>
	);
};

export default GenericYTVideoComponent;
