import React, { useMemo, useRef, useState } from "react";
import {
	Text,
	View,
	ScrollView,
	TouchableOpacity,
	StyleSheet,
	Platform,
	FlatList,
} from "react-native";
import fontStyle from "../../Styles/typography";
import {
	BrowserView,
	MobileView,
	isBrowser,
	isMobile,
} from "react-device-detect";

//components
import FloatingCard from "../Cards/FloatingCard";
import Flipper from "../WebOnly/Flipper";

//assets
import no_image_ic from "../../assets/Defaults/DCS_no_image.png";
import { Link } from "@react-navigation/native";
import PlainBlockButton from "../Buttons/PlainBlockButton";

const GenericServiceCenterComponent = ({ data, props }) => {
	const { navigation } = props;
	const scrollRef = useRef();
	const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
	const [showTopFlipper, setShowTopFlipper] = useState(false);
	const [showBottomFlipper, setShowBottomFlipper] = useState(true);
	const [containerWidth, setContainerWidth] = useState(0);
	const [contentWidth, setContentWidth] = useState(0);

	const isCloseToBottom = (e) => {
		return (
			e.layoutMeasurement.width + e.contentOffset.x >=
			e.contentSize.width - 20 - 20
		);
	};

	const ifCloseToTop = (e) => {
		return e.contentOffset.x == 0;
	};

	const ServiceCenterItem = ({ item }) => {
		return (
			<View key={Math.random() * 100}>
				{item.name == "extra" ? (
					<PlainBlockButton
						onPress={() => {
							navigation.navigate("exploreoptions", {
								display: "centers",
								show: item?.filter?.replace("=", "_as_"),
							});
						}}
						extraStyles={{
							width: 120,
							height: 200,
						}}
						text={"Explore more"}
					/>
				) : (
					<View>
						{item.rating != null && item.rating != 0 ? (
							<View
								key={item.id}
								style={{
									position: "absolute",
									padding: 5,
									backgroundColor: "#4CAF50",
									borderRadius: 10,
									zIndex: 100,
									elevation: 5,
									right: 20,
									top: 20,
								}}>
								<Text
									style={{
										color: "white",
										fontFamily: "Gilroy-Regular",
									}}>
									{item.rating}
								</Text>
							</View>
						) : null}
						<FloatingCard
							icon={item.image == null ? no_image_ic : item.image}
							onPress={() => {
								navigation.navigate("servicesbyprovider", {
									companyID: item.id,
								});
							}}
							titleText={item.name}
							bodyText={item.location.address.replace("\n", "")}
							isUrl={item.image != null}
						/>
					</View>
				)}
			</View>
		);
	};

	const flatListMemo = useMemo(() => {
		return (
			<FlatList
				data={data.items}
				renderItem={ServiceCenterItem}
				horizontal={true}
			/>
		);
	}, [data.items]);

	return (
		<View>
			{data.items.length > 0 ? (
				<View>
					<Text
						style={[
							fontStyle.fontTitle,
							fontStyle.fontFamilyAvenir,
							{
								marginLeft: 20,
								marginTop: 10,
								fontFamily: "Gilroy-SemiBold",
							},
						]}>
						{data.name}
					</Text>
					{contentWidth >= containerWidth &&
					typeof scrollRef != "undefined" &&
					showBottomFlipper ? (
						<BrowserView>
							<Flipper
								onPress={() => {
									scrollRef.current.scrollTo({
										x:
											currentScrollPosition +
											containerWidth,
										y: 0,
										animated: true,
									});
								}}
								gradientStart={"#fff"}
								gradientEnd={"#fff"}
								rightFlipper={true}
							/>
						</BrowserView>
					) : null}

					{showTopFlipper ? (
						<BrowserView>
							<Flipper
								onPress={() => {
									scrollRef.current.scrollTo({
										x:
											currentScrollPosition -
											containerWidth,
										y: 0,
										animated: true,
									});
								}}
								gradientStart={"#fff"}
								gradientEnd={"#fff"}
								rightFlipper={false}
							/>
						</BrowserView>
					) : null}
					<ScrollView
						ref={scrollRef}
						horizontal={true}
						scrollEventThrottle={0}
						onLayout={(e) => {
							var { x, y, width, height } = e.nativeEvent.layout;
							setContainerWidth(width);
						}}
						nestedScrollEnabled={true}
						showsHorizontalScrollIndicator={false}
						contentContainerStyle={{
							alignItems: "center",
						}}
						onScroll={(e) => {
							if (isBrowser) {
								setShowTopFlipper(!ifCloseToTop(e.nativeEvent));
								setShowBottomFlipper(
									!isCloseToBottom(e.nativeEvent)
								);
								setCurrentScrollPosition(
									e.nativeEvent.contentOffset.x
								);
							}
						}}
						style={[
							Platform.OS == "ios" ? styles.iosShadowDown : null,
							{ padding: 10 },
						]}>
						<View
							style={{ flexDirection: "row" }}
							onLayout={(e) => {
								var { x, y, width, height } =
									e.nativeEvent.layout;
								setContentWidth(width);
							}}>
							{flatListMemo}
						</View>
					</ScrollView>
				</View>
			) : null}
		</View>
	);
};

const styles = StyleSheet.create({
	iosShadowDown: {
		shadowColor: "#222",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.17,
		shadowRadius: 2,
	},
	iosShadowUp: {
		shadowColor: "#222",
		shadowOffset: {
			width: 0,
			height: -2,
		},
		shadowOpacity: 0.1,
		shadowRadius: 2,
	},
});

export default GenericServiceCenterComponent;
