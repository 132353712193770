import React, { useState, useEffect, useCallback } from "react";
import {
	StyleSheet,
	Text,
	View,
	ScrollView,
	Dimensions,
	TextInput,
	ToastAndroid,
	Platform,
	Alert,
	Image,
	TouchableOpacity,
} from "react-native";
// import { launchCamera, launchImageLibrary } from "react-native-image-picker";

//components
import BackFab from "../../components/Common/BackFab";
import SubmitButtonDark from "../../components/Buttons/SubmitButtonDark";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils.web";

//assets
import def_bg from "../../assets/Defaults/DCS_def_profile_bg.png";
import def_user_img from "../../assets/Defaults/DCS_profile_ic.png";
import edit_ic from "../../assets/Icons/DCS_edit_ic.png";
import ImageCollector from "../../components/WebOnly/ImageCollector";
import { ActivityIndicator } from "react-native-web";
import TopFeedbackBar from "../../components/WebOnly/TopFeedbackBar";

const EditUserProfileScreen = ({ route, navigation }) => {
	const window = Dimensions.get("window");
	const screen = Dimensions.get("screen");
	const [dimensions, setDimensions] = useState({ window, screen });

	const [newUserInfo, setNewUserInfo] = useState({
		id: "",
		firstName: "",
		lastName: "",
		email: "",
		phNum: "",
		photo: null,
	});

	const [profileImage, setProfileImage] = useState(null);
	const [updateInProgress, setUpdateInProgress] = useState(false);

	// const [profileData, setProfileData] = useState({});
	// const [fetchingData, setFetchingData] = useState(true);

	const [isFirstNameValid, setIsFirstNameValid] = useState(true);
	const [isLastNameValid, setIsLastNameValid] = useState(true);
	const [isEmailValid, setIsEmailValid] = useState(true);
	const [isPhNumberValid, setIsPhNumValid] = useState(true);

	const [feedbacktext, setFeedbackText] = useState("Loading...");
	const [showFeedback, setShowFeedback] = useState(false);
	const [feedbackType, setfeedbackType] = useState("success");

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setDimensions({ window, screen });
			}
		);
		return () => subscription?.remove();
	}, []);

	useEffect(() => {
		/**
		 * On web version we need to check for user session validity on page refresh as the axios default headers don't
		 * persist page resfresh, so this is an additional step and needs to be done on all pages.
		 */
		Utils.isGuestOrUser(navigation).then((result) => {
			console.log(result);
			if (
				result == null ||
				typeof result == "undefined" ||
				result == "guest"
			) {
				navigation.reset({
					index: 0,
					routes: [{ name: "home" }],
				});
				// hitApi();
			} else {
				axiosInstance.defaults.headers.common["Authorization"] = result;
				hitApi();
			}
		});
	}, []);

	const hitApi = () => {
		axiosInstance({
			method: "get",
			url: "/accounts/dj-rest-auth/user",
		})
			.then((response) => {
				try {
					if (response.status >= 200 && response.status < 300) {
						setNewUserInfo((prev) => ({
							...prev,
							firstName: response.data.first_name,
							lastName: response.data.last_name,
							email: response.data.email,
						}));
					} else {
						setfeedbackType("error");
						setFeedbackText(
							"Could not fetch your details, try refreshing the page."
						);
						setShowFeedback(true);
						Utils.handleFailedResponse(
							"getUser",
							response,
							response.status,
							navigation,
							setFeedbackText
						);
					}
				} catch (error) {
					console.log(error);
					setfeedbackType("error");
					setFeedbackText(
						"Could not fetch your details, try refreshing the page."
					);
					setShowFeedback(true);
					Utils.handleFailedResponse(
						"getUser",
						response,
						response.status,
						navigation,
						setFeedbackText
					);
				}
			})
			.catch((onrejected) => {
				setfeedbackType("error");
				setFeedbackText(
					"Could not fetch your details, try refreshing the page."
				);
				setShowFeedback(true);
				Utils.handleFailedResponse(
					"getUser",
					onrejected.response,
					onrejected.response.status,
					navigation,
					setFeedbackText
				);
			});

		axiosInstance({
			method: "get",
			url: "/accounts/user/profile",
		})
			.then((response) => {
				try {
					if (response.status >= 200 && response.status < 300) {
						setNewUserInfo((prev) => ({
							...prev,
							phNum: response.data[0].phone,
							id: response.data[0].id,
							photo: response.data[0].photo,
						}));
					} else {
						setfeedbackType("error");
						setFeedbackText(
							"Could not fetch your details, try refreshing the page."
						);
						setShowFeedback(true);
						Utils.handleFailedResponse(
							"getProfile",
							response,
							response.status,
							navigation,
							setFeedbackText
						);
					}
				} catch (error) {
					console.log(error);
					setfeedbackType("error");
					setFeedbackText(
						"Could not fetch your details, try refreshing the page."
					);
					setShowFeedback(true);
					Utils.handleFailedResponse(
						"getProfile",
						response,
						response.status,
						navigation,
						setFeedbackText
					);
				}
			})
			.catch((onrejected) => {
				setfeedbackType("error");
				setFeedbackText(
					"Could not fetch your details, try refreshing the page."
				);
				setShowFeedback(true);
				Utils.handleFailedResponse(
					"getProfile",
					onrejected.response,
					onrejected.response.status,
					navigation,
					setFeedbackText
				);
			});
	};

	const updateParam = (text, fieldName) => {
		switch (fieldName) {
			case "first":
				setNewUserInfo({ ...newUserInfo, firstName: text });
				break;
			case "last":
				setNewUserInfo({ ...newUserInfo, lastName: text });
				break;

			case "email":
				setNewUserInfo({ ...newUserInfo, email: text });
				break;

			case "phNum":
				setNewUserInfo({ ...newUserInfo, phNum: text });
				break;
		}
		checkDataValidity(text, fieldName);
	};

	const checkDataValidity = (data, fieldName) => {
		switch (fieldName) {
			case "first": {
				setIsFirstNameValid(data.search(/[0-9]/) == -1);
				break;
			}
			case "last": {
				setIsLastNameValid(data.search(/[0-9]/) == -1);
				break;
			}

			case "email":
				setIsEmailValid(
					data.search(
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					) != -1
				);
				break;

			case "phNum":
				setIsPhNumValid(
					data.search(/[0-9]/) != -1 &&
						data != null &&
						data.length == 10
				);
				break;
		}
	};

	const ErrorMessageText = (message) => {
		return (
			<Text
				style={{
					color: "red",
					paddingHorizontal: 5,
					fontFamily: "Gilroy-SemiBold",
				}}>
				{message}
			</Text>
		);
	};

	const notifyMessage = (message) => {
		if (Platform.OS === "android") {
			ToastAndroid.show(message, ToastAndroid.SHORT);
		} else {
			Alert.alert(message);
		}
	};

	const hitProfileApi = (requestBody) => {
		if (
			newUserInfo.firstName != newUserInfo.firstName ||
			newUserInfo.lastName != newUserInfo.lastName ||
			newUserInfo.phNum != newUserInfo.phone ||
			profileImage != null
		) {
			setUpdateInProgress(true);
			axiosInstance({
				method: "patch",
				url: `accounts/user/profile/${newUserInfo.id}/`,
				data: requestBody,
			})
				.then((response) => {
					try {
						if (response.status >= 200 && response.status < 300) {
							setUpdateInProgress(false);
							setfeedbackType("success");
							setFeedbackText("Your changes have been saved.");
							setShowFeedback(true);
							setUpdateInProgress(false);
							// Utils.notifyMessage("Successfully updated profile");
						} else {
							Utils.notifyMessage(
								"Error connecting to our servers. Try again later."
							);
						}
					} catch (error) {
						setUpdateInProgress(false);
						console.log(error);
					}
				})
				.catch((onrejected) => {
					setUpdateInProgress(false);
					Utils.handleFailedResponse(
						"patchProfile",
						onrejected.response,
						onrejected.response.status,
						navigation
					);
				});
		} else {
			setUpdateInProgress(false);
			setfeedbackType("error");
			setFeedbackText(
				"Somethign went wrong posting your request, Please try again later."
			);
			setShowFeedback(true);
			setUpdateInProgress(false);
		}
	};

	const updateProfileData = () => {
		//prepare default request body
		var requestBody = {
			user: {
				first_name: newUserInfo.firstName,
				last_name: newUserInfo.lastName,
			},
			phone: newUserInfo.phNum,
		};

		//append new image if it was selected
		profileImage != null
			? (requestBody.photo = profileImage)
			: newUserInfo.photo;

		//if email was changed patch email first
		if (newUserInfo.email != "") {
			setUpdateInProgress(true);
			axiosInstance({
				method: "patch",
				url: "accounts/dj-rest-auth/user/",
				data: {
					email: newUserInfo.email,
				},
			})
				.then((response) => {
					//check if profile values were changed then patch profile
					try {
						if (response.status >= 200 && response.status < 300) {
							hitProfileApi(requestBody);
						} else {
							Utils.notifyMessage(
								"Error connecting to our servers. Try again later."
							);
						}
					} catch (error) {
						setUpdateInProgress(false);
						console.log(error);
					}
				})
				.catch((onrejected) => {
					setUpdateInProgress(false);
					Utils.handleFailedResponse(
						"patchUser",
						onrejected.response,
						onrejected.response.status,
						navigation
					);
				});
		} else {
			//else just patch profile if it was changed
			hitProfileApi(requestBody);
		}
	};

	return (
		<View style={styles.container}>
			<TopFeedbackBar
				feedbackNature={feedbackType}
				show={showFeedback}
				setShow={setShowFeedback}
				text={feedbacktext}
			/>
			<ScrollView
				contentContainerStyle={{
					flex: 1,
				}}>
				<View
					style={{
						height: window.width < 800 ? "100%" : 700,
						backgroundColor: "white",
						width: window.width < 800 ? "100%" : 500,
						maxWidth: 900,
						borderWidth: window.width < 800 ? 0 : 1,
						borderColor: "#ccc",
						borderRadius: window.width < 800 ? 0 : 8,
						overflow: "hidden",
						alignSelf: "center",
						marginTop: window.width < 800 ? 0 : 20,
					}}>
					{/* Profile Section */}
					<View
						style={{
							width: "100%",
							height:
								screen.width > 400
									? (screen.height * 20) / 100
									: 140,
							borderBottomWidth: 1,
							borderBottomColor: "#ccc",
							alignItems: "center",
							justifyContent: "center",
							zIndex: 1,
						}}>
						{/* Profile Background */}
						<Image
							style={{
								height: "100%",
								width: "100%",
							}}
							source={def_bg}
							resizeMode='cover'
						/>
						<View
							style={{
								width: 120,
								position: "absolute",
							}}>
							<ImageCollector
								currentImage={profileImage || newUserInfo.photo}
								setImage={(img) => {
									setProfileImage(img);
								}}
							/>
						</View>
					</View>
					<View
						style={{
							paddingTop: 30,
							width: "80%",
							alignSelf: "center",
							alignItems: "center",
						}}>
						{/* First Name inputfield */}
						<View
							style={{
								width: screen.width > 400 ? "70%" : "100%",
								marginVertical: 8,
							}}>
							<Text style={styles.textInputTitle}>
								First Name
							</Text>
							<TextInput
								style={styles.textInput}
								placeholder='Your First Name'
								placeholderTextColor='#858189'
								color='#000'
								onChangeText={(text) =>
									updateParam(text, "first")
								}
								defaultValue={newUserInfo.firstName || ""}
							/>
							{!isFirstNameValid
								? ErrorMessageText(
										"Name should only contain alpahabets"
								  )
								: null}
						</View>
						{/* Last Name inputfield */}
						<View
							style={{
								width: screen.width > 400 ? "70%" : "100%",
								marginVertical: 8,
							}}>
							<Text style={styles.textInputTitle}>Last Name</Text>
							<TextInput
								style={styles.textInput}
								placeholder='Your Last Name'
								placeholderTextColor='#858189'
								color='#000'
								onChangeText={(text) =>
									updateParam(text, "last")
								}
								defaultValue={newUserInfo.lastName || ""}
							/>
							{!isFirstNameValid
								? ErrorMessageText(
										"Name should only contain alpahabets"
								  )
								: null}
						</View>
						{/* New Email inputfield */}
						<View
							style={{
								width: screen.width > 400 ? "70%" : "100%",
								marginVertical: 8,
							}}>
							<Text style={styles.textInputTitle}>Email</Text>
							<TextInput
								style={styles.textInput}
								placeholder='YourEmail@mail.com'
								placeholderTextColor='#858189'
								color='#000'
								onChangeText={(text) =>
									updateParam(text, "email")
								}
								defaultValue={newUserInfo.email || ""}
							/>
							{!isEmailValid
								? ErrorMessageText("Please Enter a valid Email")
								: null}
						</View>
						{/* New PhNum inputfield */}
						<View
							style={{
								width: screen.width > 400 ? "70%" : "100%",
								marginVertical: 8,
							}}>
							<Text style={styles.textInputTitle}>
								Phone Number
							</Text>
							<TextInput
								style={styles.textInput}
								placeholder='(000) 000 0000'
								placeholderTextColor='#858189'
								color='#000'
								onChangeText={(text) =>
									updateParam(text, "phNum")
								}
								defaultValue={newUserInfo.phNum}
							/>
							{!isPhNumberValid
								? ErrorMessageText(
										"A phone number should be a 10 digit number"
								  )
								: null}
						</View>
						<SubmitButtonDark
							style={{
								width: 220,
								alignSelf: "center",
								marginVertical: 30,
							}}
							onPress={() => {
								if (
									newUserInfo.firstName != "" &&
									newUserInfo.lastName != "" &&
									newUserInfo.email != "" &&
									newUserInfo.phNum != ""
								) {
									//Save data and go back
									updateProfileData();
								} else {
									notifyMessage(
										"Please do not leave any fields empty"
									);
								}
							}}
							title='Save details'
						/>
					</View>
				</View>
			</ScrollView>
			{updateInProgress ? (
				<View
					style={{
						height: "100%",
						width: "100%",
						zIndex: 100,
						elevation: 100,
						position: "absolute",
						justifyContent: "center",
					}}>
					<View
						style={{
							height: screen.height,
							width: screen.width,
							backgroundColor: "black",
							opacity: 0.5,
						}}
					/>
					{/* <Spinner
						isVisible={updateInProgress}
						size={40}
						type='Circle'
						color={"#7E98ED"}
						style={{
							alignSelf: "center",
							marginTop: 50,
							position: "absolute",
							zIndex: 100,
							elevation: 100,
						}}
					/> */}
					<ActivityIndicator
						style={{
							zIndex: 100,
							alignSelf: "center",
							position: "absolute",
							backgroundColor: "white",
							padding: 10,
							borderRadius: 50,
						}}
						animating={true}
						size={"large"}
						color={"#7E98ED"}
					/>
				</View>
			) : null}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// backgroundColor: "#fff",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	floating: {
		position: "absolute",
		top: 10,
		left: 0,
		zIndex: 1,
	},
	textInput: {
		borderRadius: 18,
		borderWidth: 2,
		borderColor: "#C4C4C4",
		paddingHorizontal: 15,
		height: 38,
		fontFamily: "Gilroy-Regular",
	},
	textInputTitle: {
		marginStart: 7,
		color: "#120632",
		fontSize: 16,
		fontFamily: "Gilroy-Regular",
	},
});

export default EditUserProfileScreen;
