import React, { useState } from "react";
import { Text, View, Pressable } from "react-native";

const PlainBlockButton = ({ extraStyles, onPress, text }) => {
	const [isFocused, setFocused] = useState(false);
	return (
		<Pressable
			onHoverIn={() => setFocused(true)}
			onHoverOut={() => setFocused(false)}
			onPress={onPress}
			style={[
				{
					height: 90,
					width: 120,
					maxWidth: 100,
					borderRadius: 12,
					backgroundColor: isFocused ? "#7E98ED" : "#6b88e8",
					alignItems: "center",
					justifyContent: "center",
					padding: 10,
					marginTop: 10,
					marginLeft: 5,
				},
				extraStyles,
			]}>
			<Text style={{ color: "#fff", fontFamily: "Gilroy-SemiBold" }}>
				{text}
			</Text>
		</Pressable>
	);
};

export default PlainBlockButton;
