import React, { useState, useEffect, useRef } from "react";
import {
	StyleSheet,
	Text,
	View,
	Dimensions,
	Image,
	FlatList,
	ScrollView,
	TouchableOpacity,
} from "react-native";
import Utils from "../../Utils/Utils.web";
//components
import BackFab from "../../components/Common/BackFab";
import FloatingServiceCard from "../../components/Cards/FloatingServiceCard";
import axiosInstance from "../../Utils/axiosGlobal";
import SearchBar from "../../components/Common/GenericSearchBar";
import BottomPopUpModal from "../../components/Common/BottomPopUpModal.web";
import BoldButton from "../../components/Buttons/SubmitButtonDark";

//assets
import no_image from "../../assets/Defaults/DCS_no_image.png";
import sort_ic from "../../assets/Icons/DCS_sort_ic.png";
import location_ic from "../../assets/Icons/DCS_location_ic.png";
import arrow_ic from "../../assets/Icons/DCS_arrow_head_ic.png";
import FloatingCardBlank from "../../components/Cards/FloatingCardBlank";
import RatingFiveStar from "../../components/Utils/RatingFiveStar";
import Option from "../../components/WebOnly/OptionComponent";
import { ActivityIndicator } from "react-native-web";

const OptionListScreen = ({ navigation, route }) => {
	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));

	const [optionList, setOptionList] = useState([]);
	const [optionListCopy, setOptionListCopy] = useState([]);
	const [searchedQuery, setSearchedQuery] = useState("");

	const [containerWidth, setContainerWidth] = useState();
	const [loadingState, setLoadingState] = useState("loading");

	const timeout = React.useRef(null);

	const { display, setSelectedService, setSelectedCenter, serviceID, show } =
		route.params;

	const [filterMap, setFilterMap] = useState([]);
	const [sortOptionsMap, setSortOptionsMap] = useState([]);
	const [selectedFilter, setSelectedFilter] = useState(null);
	const [selectedSort, setSelectedSort] = useState(null);
	const [showSortModal, setShowSortModal] = useState(false);

	useEffect(() => {
		show?.replace("_as_", "=");
		hitApis(false, "", show?.replace("_as_", "="));
	}, []);

	const onChangeTextHandler = (value) => {
		clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			setSearchedQuery(value);
			hitApis(false, value, [filterMap[selectedFilter], selectedSort]);
		}, 700);
	};

	const handleOptionsAPI = (response, append) => {
		if (append) {
			let newObj = {
				count: optionList.count,
				next: response.next,
				prev: optionList.next,
				results: optionList.results.concat(response.results),
			};
			setOptionList(newObj);
		} else {
			setOptionList(response);
			// setOptionListCopy(response);
		}
		setLoadingState("done");
	};

	const hitApis = (append, query, filter) => {
		setLoadingState("loading");
		let filterArr = [];
		if (typeof filter == "string" || typeof filter == "undefined") {
			filterArr.push(filter);
		} else {
			filterArr = filter.slice(0);
		}
		console.log(filterArr);
		if (display == "services") {
			axiosInstance({
				method: "get",
				url: append
					? optionList.next.substring(
							optionList.next.indexOf("auto-care/service-types/"),
							optionList.next.length
					  )
					: Utils.getConstructedUrl(
							"auto-care/service-types/",
							[query],
							filterArr
					  ),
			})
				.then((response) => {
					if (response.status >= 200 && response.status < 300) {
						handleOptionsAPI(response.data, append);
					} else {
						Utils.notifyMessage(
							"Error connecting to our servers. Try again later."
						);
					}
				})
				.catch((onrejected) => {
					console.log(onrejected);
					// Utils.handleFailedResponse(
					// 	"getServices",
					// 	onrejected.response,
					// 	onrejected.response.status,
					// 	navigation
					// );
				});
		} else if (display == "centers") {
			axiosInstance({
				method: "get",
				url: append
					? optionList.next.substring(
							optionList.next.indexOf(
								"auto-care/service-centers/"
							),
							optionList.next.length
					  )
					: Utils.getConstructedUrl(
							"auto-care/service-centers/",
							[query],
							filterArr
					  ),
			})
				.then((response) => {
					if (response.status >= 200 && response.status < 300) {
						handleOptionsAPI(response.data, append);
					} else {
						Utils.notifyMessage(
							"Error connecting to our servers. Try again later."
						);
					}
				})
				.catch((onrejected) => {
					// Utils.handleFailedResponse(
					// 	"getServiceCenters",
					// 	onrejected.response,
					// 	onrejected.response.status,
					// 	navigation
					// );
				});
			axiosInstance({
				method: "get",
				url: `auto-care/filters/service-centers/`,
			})
				.then((response) => {
					if (response.status >= 200 && response.status < 300) {
						let tempShow = show?.replace("_as_", "=");
						if (
							tempShow &&
							selectedSort == null &&
							selectedFilter == null
						) {
							if (!tempShow?.includes("type=")) {
								setSelectedSort(
									Object.keys(
										response.data.sort_filters[0]
									).find(
										(key) =>
											tempShow
												?.replace("ordering=", "")
												?.replace("-", "") ===
											response.data.sort_filters[0][key]
									)
								);
							} else {
								if (tempShow) {
									Object.keys(
										response.data.search_filters
									).forEach((key) => {
										if (
											key.toLowerCase() ==
											tempShow
												?.replace("type=", "")
												?.toLowerCase()
										) {
											setSelectedFilter(key);
										}
									});
								}
							}
						}

						setFilterMap(response.data.search_filters);
						setSortOptionsMap(response.data.sort_filters[0]);
					} else {
						Utils.notifyMessage(
							"Error connecting to our servers. Try again later."
						);
					}
				})
				.catch((onrejected) => {
					console.log(onrejected);
				});
		}
	};

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	const renderCard = ({ item, i }) => {
		if (display == "services") {
			return (
				<View style={{ alignSelf: "center" }}>
					<Option
						key={i}
						imageUrl={item.image}
						onCardPress={() => {
							navigation.navigate("service", {
								serviceID: item.id,
							});
						}}
						enableButton={false}
						onButtonPress={() => {
							// navigation.navigate("bookservice", {
							// 	serviceID: item.id,
							// 	companyID: companyID,
							// 	opts: false,
							// });
						}}
						tag={item.tag || null}
						children={
							<View style={{ maxHeight: 88, overflow: "hidden" }}>
								<Text
									style={{ fontWeight: "500", fontSize: 16 }}>
									{item.name}
								</Text>
								{item.description.split("\n").map((text, i) => {
									return (
										<Text
											key={i}
											style={{ color: "#2A292E" }}>
											{"\u2022 " + text}
										</Text>
									);
								})}
							</View>
						}
						imageOccupancyPercent={50}
					/>
				</View>
			);
		} else if (display == "centers") {
			return (
				<View>
					<TouchableOpacity
						key={item.id}
						style={{
							height: 275,
							// width: 350,
							width: "100%",
							maxWidth: 500,
							marginVertical: 5,
							overflow: "hidden",
							marginHorizontal: 5,
							marginVertical: 10,
							borderRadius: 5,
							backgroundColor: "white",
							borderWidth: 1,
							borderColor: "#ddd",
							alignSelf: "center",
						}}
						onPress={() => {
							if (
								typeof setSelectedCenter !== "undefined" &&
								setSelectedCenter != null
							) {
								setSelectedCenter(item.id);
							} else {
								navigation.navigate("servicesbyprovider", {
									companyID: item.id,
								});
							}
						}}>
						<View style={{ flex: 1 }}>
							<View style={{ height: 190, width: "100%" }}>
								<Image
									source={
										item.image == null
											? no_image
											: { uri: item.image }
									}
									style={{
										height: "100%",
										width: "100%",
									}}
									resizeMode='cover'
								/>
								{/* <View
										style={{
											backgroundColor: "#fff",
											padding: 5,
											borderRadius: 20,
											borderWidth: 1,
											borderColor: "#ddd",
											position: "absolute",
											right: 10,
											top: 10,
										}}>
										<Image
											source={bookmark_off_ic}
											style={{
												height: 16,
												width: 16,
											}}
											resizeMode='contain'
										/>
										</View> */}
								{/* <View
										style={{
											position: "absolute",
											right: 10,
											bottom: 10,
											backgroundColor: "#fff",
											padding: 5,
											borderRadius: 7,
										}}>
										<Text
											style={{ color: "#003399", fontSize: 10 }}>
											24 mins
										</Text>
									</View> */}
								<View
									style={{
										position: "absolute",
										right: 0,
										bottom: 10,
										backgroundColor: "#4CAF50",
										borderRadius: 7,
										padding: 3,
										marginRight: 15,
									}}>
									<Text
										style={{
											fontSize: 14,
											color: "#fff",
											fontWeight: "500",
										}}>
										{item.rating}
									</Text>
									{/* <Text
										style={{ fontSize: 8, color: "#fff" }}>
										RATING
									</Text> */}
								</View>
							</View>
							<View
								style={{
									paddingLeft: 20,
									height: 80,
								}}>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										marginTop: 6,
									}}>
									<Text
										style={{
											fontSize: 15,
											fontWeight: "500",
										}}>
										{item.name}
									</Text>
								</View>
								<Text
									numberOfLines={2}
									style={{
										fontSize: 10,
										color: "#444C66",
									}}>
									{item.location.address}
								</Text>
							</View>
						</View>
					</TouchableOpacity>
				</View>
			);
		}
	};

	const renderChips = ({ item }) => {
		return (
			<TouchableOpacity
				onPress={() => {
					selectedFilter == item
						? setSelectedFilter("")
						: setSelectedFilter(item);
					hitApis(false, searchedQuery, [
						selectedSort,
						selectedFilter == item ? "" : filterMap[item],
					]);
				}}
				style={{
					backgroundColor:
						selectedFilter == item ? "#7E98ED" : "#F0F3FB",
					paddingVertical: 5,
					borderRadius: 20,
					margin: 5,
					width: 100,
				}}>
				<Text
					style={{
						color: selectedFilter == item ? "#fff" : "#9197AA",
						textAlign: "center",
						fontSize: 14,
					}}>
					{item}
				</Text>
			</TouchableOpacity>
		);
	};

	const [chipContainerWidth, setChipContainerWidth] = useState(0);

	return (
		<View style={styles.container}>
			<View
				style={{
					backgroundColor: "#fff",
					borderRadius: 20,
					width: windowSize.width < 800 ? windowSize.width : "70%",
					maxWidth: 900,
					alignSelf: "center",
					marginVertical: 10,
					marginHorizontal: windowSize.width < 800 ? 10 : 0,
				}}>
				<View
					style={{
						width: "70%",
						maxWidth: 400,
						height: 60,
						alignSelf: "center",
					}}>
					<SearchBar
						placeholderText={
							"Search " +
							(display == "services" ? "Services" : "Providers")
						}
						onChangeText={(t) => {
							onChangeTextHandler(t);
						}}
					/>
				</View>
				{display != "services" ? (
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							paddingHorizontal: 50,
						}}>
						{Object.keys(filterMap).length != 0 ? (
							<View
								onLayout={(e) => {
									var { x, y, width, height } =
										e.nativeEvent.layout;
									setChipContainerWidth(width);
								}}
								style={{
									flex: 3,
									flexDirection: "row",
									marginBottom: 7,
								}}>
								<FlatList
									key={Math.random() * 1000}
									data={Object.keys(filterMap)}
									renderItem={renderChips}
									showsHorizontalScrollIndicator={false}
									showsVerticalScrollIndicator={false}
									numColumns={Math.floor(
										chipContainerWidth / 110
									)}
									scrollEnabled={false}
								/>
							</View>
						) : null}
						<View
							style={{
								flex: 1,
								alignItems: "flex-end",
								marginLeft: 10,
								marginBottom: 7,
							}}>
							<TouchableOpacity
								onPress={() => setShowSortModal(true)}
								style={{
									flexDirection: "row",
									alignItems: "center",
								}}>
								<Text
									style={{ fontSize: 16, color: "#9197AA" }}>
									{selectedSort ? "Sorted" : "Sort"}
								</Text>
								<Image
									source={sort_ic}
									style={{
										height: 30,
										width: 30,
										tintColor: "#7E98ED",
									}}
									resizeMode='contain'
								/>
							</TouchableOpacity>
							{selectedSort != "" && selectedSort != null ? (
								<View
									style={{
										flexDirection: "row",
									}}>
									<TouchableOpacity
										onPress={() => {
											setSelectedSort("");
											hitApis(false, searchedQuery, [
												filterMap[selectedFilter],
												show !==
												filterMap[selectedFilter]
													? show
													: null,
											]);
										}}
										style={{
											backgroundColor: "#7E98ED",
											paddingHorizontal: 10,
											paddingVertical: 5,
											borderRadius: 20,
											paddingRight: 40,
											height: 27,
										}}>
										<Text
											style={{
												color: "#fff",
											}}>
											{selectedSort?.split(" : ")[0]}
										</Text>
									</TouchableOpacity>
									<Image
										source={arrow_ic}
										style={{
											height: 20,
											width: 20,
											transform: [
												{
													rotate: selectedSort?.endsWith(
														"High"
													)
														? "180deg"
														: "0deg",
												},
											],
											tintColor: "#fff",
											marginTop: 5,
											position: "absolute",
											right: 10,
										}}
									/>
								</View>
							) : null}
						</View>
					</View>
				) : null}
			</View>
			<ScrollView
				contentContainerStyle={{
					flexGrow: 1,
					width: windowSize.width < 800 ? windowSize.width : "70%",
					maxWidth: 900,
					alignSelf: "center",
					backgroundColor: "#fff",
					paddingHorizontal: 20,
					paddingTop: 20,
					paddingBottom: 50,
					borderTopRightRadius: 7,
					borderTopLeftRadius: 7,
				}}>
				{loadingState == "loading" ? (
					<View
						style={{
							flex: 1,
							justifyContent: "center",
						}}>
						<ActivityIndicator
							animating={loadingState != "done"}
							size={"large"}
							color={"#7E98ED"}
						/>
						<Text
							style={{
								justifyContent: "center",
								alignSelf: "center",
								color: "#ccc",
							}}>
							Loading...
						</Text>
					</View>
				) : optionList.results.length == 0 ? (
					<Text
						style={{
							marginTop:
								windowSize.width > 400
									? (windowSize.height * 10) / 100
									: 70,
							justifyContent: "center",
							alignSelf: "center",
						}}>
						No {display} Found
					</Text>
				) : (
					<FlatList
						scrollEnabled={false}
						key={containerWidth + Math.random() * 1000}
						contentContainerStyle={{
							paddingBottom:
								windowSize.width > 450
									? (windowSize.height * 10) / 100
									: 200,
							alignItems:
								display == "centers" ? "stretch" : "center",
						}}
						data={optionList.results}
						onLayout={(e) => {
							var { x, y, width, height } = e.nativeEvent.layout;
							setContainerWidth(width);
						}}
						numColumns={
							display == "centers"
								? 1
								: Math.floor(containerWidth / 210)
						}
						renderItem={renderCard}
						onEndReached={() => {
							optionList.next != null &&
							optionList.results.length < optionList.count
								? hitApis(true, "", "")
								: null;
						}}
						onEndReachedThreshold={0.5}
					/>
				)}
			</ScrollView>
			<BottomPopUpModal
				isVisible={showSortModal}
				onClose={() => {
					setShowSortModal(false);
				}}
				height={300}
				children={
					<View>
						<View
							style={{
								position: "absolute",
								top: -35,
								left: 20,
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
								width: "90%",
								padding: 6,
								borderBottomWidth: 1,
								borderBottomColor: "#ddd",
							}}>
							<Text style={{ fontSize: 20 }}>Sort by</Text>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
								}}>
								<TouchableOpacity
									onPress={() => {
										setSelectedSort("");
										setShowSortModal(false);
										hitApis(
											false,
											searchedQuery,
											filterMap[selectedFilter]
										);
									}}>
									<Text
										style={{
											fontSize: 20,
											fontWeight: "500",
											color: "red",
											marginRight: 15,
										}}>
										Clear All
									</Text>
								</TouchableOpacity>
								<BoldButton
									title={"Apply"}
									style={{ width: 100 }}
									onPress={() => {
										let tempFilter =
											sortOptionsMap[
												selectedSort.split(" : ")[0]
											];
										hitApis(false, searchedQuery, [
											"ordering=" +
												(selectedSort.endsWith("Low")
													? "-" + tempFilter
													: tempFilter),
											filterMap[selectedFilter],
										]);
										setShowSortModal(false);
									}}
								/>
							</View>
						</View>
						<View style={{ marginTop: 30, paddingHorizontal: 20 }}>
							{Object.keys(sortOptionsMap).map((item, i) => {
								return (
									<View key={i}>
										<TouchableOpacity
											style={{
												padding: 5,
												backgroundColor:
													item + " : High to Low" ==
													selectedSort
														? "#7E98ED50"
														: "#fff",
												borderRadius: 3,
												alignItems: "center",
											}}
											onPress={() => {
												selectedSort ==
												item + " : High to Low"
													? setSelectedSort("")
													: setSelectedSort(
															item +
																" : High to Low"
													  );
											}}>
											<Text
												style={{
													fontSize: 16,
													color:
														item +
															" : High to Low" ==
														selectedSort
															? "#1f1f1f"
															: "#585C74",
												}}>
												{item + " : High to Low"}
											</Text>
										</TouchableOpacity>
										<TouchableOpacity
											key={i + 10}
											style={{
												padding: 5,
												backgroundColor:
													item + " : Low to High" ==
													selectedSort
														? "#7E98ED50"
														: "#fff",
												borderRadius: 3,
												alignItems: "center",
											}}
											onPress={() => {
												selectedSort ==
												item + " : Low to High"
													? setSelectedSort("")
													: setSelectedSort(
															item +
																" : Low to High"
													  );
											}}>
											<Text
												style={{
													fontSize: 16,
													color:
														item == selectedSort
															? "#1f1f1f"
															: "#585C74",
												}}>
												{item + " : Low to High"}
											</Text>
										</TouchableOpacity>
									</View>
								);
							})}
						</View>
					</View>
				}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// backgroundColor: "white",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
});

export default OptionListScreen;
