import React from "react";
import { View, Dimensions, ActivityIndicator } from "react-native";

const LoaderOverlay = ({ isVisible, bgColor, extraComponent }) => {
	const window = Dimensions.get("window");

	return isVisible ? (
		<View
			style={{
				height: window.height,
				width: "100%",
				zIndex: 100,
				elevation: 100,
				position: "absolute",
				justifyContent: "center",
			}}>
			<View
				style={{
					height: "100%",
					width: "100%",
					backgroundColor: bgColor,
				}}
			/>
			<View
				style={{
					alignSelf: "center",
					position: "absolute",
					zIndex: 100,
					elevation: 100,
					padding: 10,
					backgroundColor: "#fff",
					borderRadius: 50,
				}}>
				<ActivityIndicator
					animating={isVisible}
					size={"large"}
					color={"#7E98ED"}
				/>
			</View>
			{extraComponent}
		</View>
	) : null;
};

export default LoaderOverlay;
