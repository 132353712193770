import React, { useState, useEffect } from "react";
import {
	StyleSheet,
	View,
	Dimensions,
	Text,
	Image,
	Platform,
	ScrollView,
	FlatList,
	TouchableOpacity,
} from "react-native";

//components
import SearchBar from "../../components/Common/GenericSearchBar";
import FloatingCardBlank from "../../components/Cards/FloatingCardBlank";
import OptionComponent from "../../components/WebOnly/OptionComponent";

//assets
import noImage from "../../assets/Defaults/DCS_no_image.png";
// import { FlatList } from "react-native-web";

const SpecificShowroomScreen = ({ route, navigation }) => {
	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));
	const [dimensions, setDimensions] = useState({ window, screen });

	const [containerWidth, setContainerWidth] = useState();

	const [showRooms, SetShowRooms] = useState([
		{
			showroomId: 1,
			showRoomName: "Tata motors showrooms",
			extraText: "3 showrooms near you",
			image: noImage,
			rating: 4,
		},
		{
			showroomId: 1,
			showRoomName: "Tata motors showrooms",
			extraText: "3 showrooms near you",
			image: noImage,
			rating: 4,
		},
		{
			showroomId: 1,
			showRoomName: "Tata motors showrooms",
			extraText: "3 showrooms near you",
			image: noImage,
			rating: 4,
		},
	]);

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	const showRoomCard = ({ item, i }) => {
		return (
			<TouchableOpacity
				key={i}
				style={{
					height: 275,
					width: 300,
					marginVertical: 5,
					overflow: "hidden",
					marginHorizontal: 5,
					marginVertical: 10,
					borderRadius: 5,
					backgroundColor: "white",
					borderWidth: 1,
					borderColor: "#ddd",
					alignSelf: "center",
				}}
				onPress={() => {
					navigation.navigate("showroomvehicles");
				}}>
				<View style={{ flex: 1 }}>
					<View style={{ height: 190, width: "100%" }}>
						<Image
							source={
								item.image == null
									? noImage
									: { uri: item.image }
							}
							style={{
								height: "100%",
								width: "100%",
							}}
							resizeMode='cover'
						/>
						{/* <View
										style={{
											backgroundColor: "#fff",
											padding: 5,
											borderRadius: 20,
											borderWidth: 1,
											borderColor: "#ddd",
											position: "absolute",
											right: 10,
											top: 10,
										}}>
										<Image
											source={bookmark_off_ic}
											style={{
												height: 16,
												width: 16,
											}}
											resizeMode='contain'
										/>
										</View> */}
						{/* <View
										style={{
											position: "absolute",
											right: 10,
											bottom: 10,
											backgroundColor: "#fff",
											padding: 5,
											borderRadius: 7,
										}}>
										<Text
											style={{ color: "#003399", fontSize: 10 }}>
											24 mins
										</Text>
									</View> */}
						<View
							style={{
								position: "absolute",
								right: 0,
								bottom: 10,
								backgroundColor: "#4CAF50",
								borderRadius: 7,
								padding: 3,
								marginRight: 15,
							}}>
							<Text
								style={{
									fontSize: 14,
									color: "#fff",
									fontWeight: "500",
								}}>
								{item.rating}
							</Text>
							{/* <Text
										style={{ fontSize: 8, color: "#fff" }}>
										RATING
									</Text> */}
						</View>
					</View>
					<View
						style={{
							paddingLeft: 20,
							height: 80,
						}}>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
								marginTop: 6,
							}}>
							<Text
								style={{
									fontSize: 15,
									fontWeight: "500",
								}}>
								{item.showRoomName}
							</Text>
						</View>
						<Text
							numberOfLines={2}
							style={{
								fontSize: 10,
								color: "#444C66",
							}}>
							{item.extraText}
						</Text>
					</View>
				</View>
			</TouchableOpacity>
		);
	};

	return (
		<View style={styles.container}>
			<View
				style={{
					backgroundColor: "#fff",
					borderRadius: 20,
					width: windowSize.width < 800 ? windowSize.width : "70%",
					maxWidth: 900,
					alignSelf: "center",
					marginVertical: 10,
					marginHorizontal: windowSize.width < 800 ? 10 : 0,
				}}>
				<Text
					style={{
						fontFamily: "Gilroy-Regular",
						fontSize: 28,
						paddingTop: 10,
						paddingLeft: "8%",
					}}>
					TATA SHOWROOMS
				</Text>
				<View
					style={{
						width: "70%",
						maxWidth: 400,
						height: 60,
						alignSelf: "center",
					}}>
					<SearchBar
						placeholderText={"Search "}
						onChangeText={(t) => {
							// onChangeTextHandler(t);
						}}
					/>
				</View>
			</View>
			<ScrollView
				contentContainerStyle={{
					flexGrow: 1,
					width: windowSize.width < 800 ? windowSize.width : "70%",
					maxWidth: 900,
					alignSelf: "center",
					backgroundColor: "#fff",
					paddingHorizontal: 20,
					paddingTop: 20,
					paddingBottom: 50,
					borderTopRightRadius: 7,
					borderTopLeftRadius: 7,
				}}>
				<FlatList
					key={containerWidth + Math.random() * 1000}
					data={showRooms}
					contentContainerStyle={{
						alignSelf: "center",
						paddingBottom: 50,
						borderTopRightRadius: 7,
						borderTopLeftRadius: 7,
					}}
					onLayout={(e) => {
						var { x, y, width, height } = e.nativeEvent.layout;
						setContainerWidth(width);
					}}
					numColumns={Math.floor(containerWidth / 320)}
					renderItem={showRoomCard}
				/>
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// backgroundColor: "#fff",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
});

export default SpecificShowroomScreen;
