import React, { useState, useEffect } from "react";
import {
	StyleSheet,
	Text,
	View,
	Platform,
	ScrollView,
	Dimensions,
	TextInput,
	Switch,
	Image,
	TouchableOpacity,
	Linking,
} from "react-native";
import axiosInstance from "../../Utils/axiosGlobal";
import Utils from "../../Utils/Utils.web";
import PopupDialogBox from "../../components/Common/PopupDialogBox";

//components
import BackFab from "../../components/Common/BackFab";
import NonFilledButton from "../../components/Buttons/NonFilledButton";
import LoaderOverlay from "../../components/Common/LoaderOverlay.web";
import TopFeedbackBar from "../../components/WebOnly/TopFeedbackBar";

//assets
import def_bg from "../../assets/Defaults/DCS_def_profile_bg.png";
import email_ic from "../../assets/Icons/DCS_email_ic.png";
import message_ic from "../../assets/Icons/DCS_message_ic.png";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Link } from "@react-navigation/native";

const SettingsScreen = ({ route, navigation }) => {
	const window = Dimensions.get("window");
	const screen = Dimensions.get("screen");
	const [dimensions, setDimensions] = useState({ window, screen });
	const [allowNotification, setAllowNotification] = useState(true);
	const [allowPromotionalMsg, setAllowPromotionalMsg] = useState(true);

	const [fetchingData, setFetchingData] = useState(true);
	const [subscriptionInfo, setSubscriptionInfo] = useState([]);
	const [toggleState, setToggleState] = useState({});
	const [isPopUpVisible, setPopUpVisible] = useState(false);
	const [confirmationText, setConfirmationText] = useState("");

	const [rootNav, setRootNav] = useState(navigation);
	const [isGuestUserState, setGuestUserState] = useState(true);
	const [loadingState, setLoadingState] = useState("loading");

	const [feedbackType, setFeedbackType] = useState("success");
	const [feedbacktext, setFeedbackText] = useState("");
	const [showFeedback, setShowFeedback] = useState(false);

	// const { userData, profileData, updateUserProfile } = route.params;

	useEffect(() => {
		setLoadingState("loading");
		/**
		 * On web version we need to check for user session validity on page refresh as the axios default headers don't
		 * persist page resfresh, so this is an additional step and needs to be done on all pages.
		 */
		Utils.isGuestOrUser(navigation).then((result) => {
			console.log(result);
			if (
				result == null ||
				typeof result == "undefined" ||
				result == "guest"
			) {
				setGuestUserState(true);
				setLoadingState("done");
				// hitApis();
			} else {
				axiosInstance.defaults.headers.common["Authorization"] = result;
				hitApis();
			}
		});
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setDimensions({ window, screen });
			}
		);
		return () => subscription?.remove();
	}, []);

	const hitApis = () => {
		setLoadingState("loading");
		if (isGuestUserState) {
			axiosInstance({
				method: "get",
				url: `messaging/forms/notification-topics/`,
			})
				.then((response) => {
					try {
						if (response.status >= 200 && response.status < 300) {
							console.log(response.data.topics);
							setSubscriptionInfo(response.data.topics);
							let states = {};
							for (
								let i = 0;
								i < response.data.topics.length;
								i++
							) {
								states[response.data.topics[i].key] =
									response.data.topics[i].subscribed;
							}
							setToggleState(states);
							setLoadingState("done");
							setGuestUserState(false);
						} else {
							setLoadingState("error");
						}
					} catch (error) {
						setLoadingState("error");
						setFeedbackType("error");
						setFeedbackText("Unable to fetch data at this moment.");
						setShowFeedback(true);
						console.log(error);
					}
				})
				.catch((onrejected) => {
					setLoadingState("error");
					setFeedbackType("error");
					console.log("in fail block with error:" + onrejected);
					Utils.handleFailedResponse(
						"getTopics",
						onrejected.response,
						onrejected.response.status,
						navigation,
						setFeedbackText
					);
					setShowFeedback(true);
				});
		}
	};

	const updateSubscription = (key) => {
		axiosInstance({
			method: "post",
			url: `messaging/notification-topics/subscribe/`,
			data: {
				topic: key,
			},
		})
			.then((response) => {
				try {
					if (response.status >= 200 && response.status < 300) {
						setToggleState({
							...toggleState,
							[key]: !toggleState[key],
						});
						setFeedbackType("success");
						setFeedbackText("Successfully saved your changes.");
						setShowFeedback(true);
					} else {
						setFeedbackType("error");
						setFeedbackText("Unable to fetch data at this moment.");
						setShowFeedback(true);
					}
				} catch (error) {
					setFeedbackType("error");
					setFeedbackText(
						"Unable to process your request at this moment."
					);
					setShowFeedback(true);
					console.log(error);
				}
			})
			.catch((onrejected) => {
				console.log("in fail block with error:" + onrejected);
				Utils.handleFailedResponse(
					"postSubscription",
					onrejected.response,
					onrejected.response.status,
					navigation,
					setFeedbackText
				);
				setFeedbackType("error");
				setShowFeedback(true);
			});
	};

	const handleDeletion = () => {
		axiosInstance({
			method: "delete",
			url: `accounts/dj-rest-auth/user-account/`,
		})
			.then((response) => {
				try {
					if (response.status >= 200 && response.status < 300) {
						AsyncStorage.removeItem("notifications");
						Utils.handleLogOut(navigation);
					} else {
						setFeedbackType("error");
						setFeedbackText("Unable to fetch data at this moment.");
						setShowFeedback(true);
					}
				} catch (error) {
					console.log(error);
				}
			})
			.catch((onrejected) => {
				console.log("in fail block with error:" + onrejected);
				Utils.handleFailedResponse(
					"deleteAccount",
					onrejected.response,
					onrejected.response.status,
					navigation,
					setFeedbackText
				);
				setFeedbackType("error");
				setShowFeedback(true);
			});
	};

	const CustomSwitch = (key) => {
		return (
			<Switch
				trackColor={{
					false: "#767577",
					true: "#81b0ff",
				}}
				style={{
					transform:
						Platform.OS == "ios"
							? [{ scaleX: 0.7 }, { scaleY: 0.7 }]
							: [{ scaleX: 1.1 }, { scaleY: 1.1 }],
				}}
				thumbColor={"#eee"}
				onValueChange={() => updateSubscription(key)}
				value={toggleState[key]}
			/>
		);
	};

	const SubscribableNotifications = ({ data }) => {
		return (
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					marginTop: 12,
				}}>
				<Text style={styles.settingText}>{data.value}</Text>
				{CustomSwitch(data.key)}
			</View>
		);
	};

	return (
		<View
			style={{
				flex: 1,
				width: screen.width < 800 ? screen.width : "70%",
				maxWidth: 900,
				alignSelf: "center",
				backgroundColor: "#fff",
			}}>
			<TopFeedbackBar
				feedbackNature={feedbackType}
				setShow={setShowFeedback}
				show={showFeedback}
				text={feedbacktext}
			/>
			<LoaderOverlay
				bgColor={"#fff"}
				isVisible={loadingState == "loading"}
			/>
			{/* Body */}
			<View style={{ paddingHorizontal: 20 }}>
				<View style={styles.settingContaner}>
					<Text style={styles.settingTitle}>
						{isGuestUserState
							? "You're browsing as a Guest"
							: "Edit Profile"}
					</Text>
					{isGuestUserState ? null : (
						<NonFilledButton
							style={{ width: 70 }}
							title={"Edit"}
							onPress={() => {
								navigation.navigate("editprofile");
							}}
						/>
					)}
				</View>
				{isGuestUserState ? null : (
					<View
						style={{
							padding: 15,
							borderBottomWidth: 1,
							borderBottomColor: "#ccc",
						}}>
						<Text style={styles.settingTitle}>Notifications</Text>
						{loadingState != "loading" ? (
							subscriptionInfo.map((item) => {
								return (
									<SubscribableNotifications
										data={item}
										key={Math.random() * 100}
									/>
								);
							})
						) : (
							<Text>Loading...</Text>
						)}
					</View>
				)}
				<View
					style={{
						padding: 15,
						borderBottomWidth: 1,
						borderBottomColor: "#ccc",
					}}>
					<Text style={styles.settingTitle}>
						Complaints and Feedbacks
					</Text>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							marginTop: 7,
						}}>
						<Text style={styles.settingText}>Email</Text>
						<Image
							source={email_ic}
							style={{ height: 20, width: 20 }}
							resizeMode='contain'
						/>
					</View>
					<View style={{ marginStart: 10 }}>
						<Text>For queries and feedback reach out to us at</Text>
						<TouchableOpacity
							onPress={() =>
								Linking.openURL(
									"mailto:support@accessai.co?subject=Query&body=Description"
								)
							}>
							<Text style={{ color: "#7E98ED" }}>
								support@accessai.co
							</Text>
						</TouchableOpacity>
					</View>
				</View>
				<View style={{ padding: 15 }}>
					<Text style={styles.settingTitle}>Account Settings</Text>
					{isGuestUserState ? (
						<Link to={"/login"}>
							<TouchableOpacity onPress={() => {}}>
								<Text
									style={[
										styles.settingText,
										{
											color: "blue",
											fontWeight: "600",
											marginTop: 10,
										},
									]}>
									Log In
								</Text>
							</TouchableOpacity>
						</Link>
					) : (
						<TouchableOpacity onPress={() => setPopUpVisible(true)}>
							<Text
								style={[
									styles.settingText,
									{
										color: "red",
										fontWeight: "500",
										marginTop: 10,
									},
								]}>
								Delete Account
							</Text>
						</TouchableOpacity>
					)}
				</View>
				<View>
					<PopupDialogBox
						showButtons={true}
						visibility={isPopUpVisible}
						titleText={"Delete Account?"}
						messageText={
							'This will delete your account and all your data with it. This action is not reversible. \n\n If you\'re sure to delete your account, please type "DELETE" below.'
						}
						negativeOptionText={"Cancel"}
						positiveOptionText={"DELETE"}
						onNegativeSelection={() => {
							setPopUpVisible(false);
							setConfirmationText("");
						}}
						onPositiveSelection={() => {
							if (confirmationText === "DELETE") {
								handleDeletion();
							} else {
								setConfirmationText("");
								setPopUpVisible(false);
								Utils.notifyMessage(
									"Your input didn't match requested text. Account not deleted."
								);
							}
						}}
						extraComponents={
							<TextInput
								style={{
									backgroundColor: "#eee",
									borderRadius: 12,
									width: 200,
									alignSelf: "center",
									height: 40,
									marginTop: 30,
									paddingHorizontal: 20,
								}}
								autoCapitalize={"none"}
								autoCorrect={false}
								placeholder='Type here'
								onChangeText={(t) => setConfirmationText(t)}
								value={confirmationText}
							/>
						}
					/>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "white",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	whiteBox: {
		backgroundColor: "#fff",
		paddingVertical: 20,
		marginVertical: 5,
		borderBottomWidth: 1,
		borderBottomColor: "#ddd",
	},
	floating: {
		position: "absolute",
		top: 10,
		left: 0,
		zIndex: 99,
	},
	settingTitle: {
		fontWeight: "500",
		color: "#444C66",
		fontSize: 18,
	},
	settingText: {
		color: "#52566C",
		fontSize: 16,
	},
	settingContaner: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottomWidth: 1,
		borderBottomColor: "#ccc",
		padding: 15,
	},
});
export default SettingsScreen;
