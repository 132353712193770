import React from "react";
import { Image } from "react-native";
import def_user_img from "../../assets/Defaults/DCS_profile_ic.png";

class ImageCollector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			base64Data: null,
		};
	}

	onChange = (e) => {
		// console.log("file uploaded: ", e.target.files[0]);
		let file = e.target.files[0];

		if (file) {
			const reader = new FileReader();
			reader.onload = this._handleReaderLoaded.bind(this);
			reader.readAsBinaryString(file);
		}
	};

	_handleReaderLoaded = (e) => {
		// console.log("file uploaded 2: ", e);
		let binaryString = e.target.result;
		this.props.setImage("data:image;base64," + btoa(binaryString));
		this.setState({
			base64Data: btoa(binaryString),
		});
	};

	render() {
		const { base64Data } = this.state;
		return (
			<div>
				<Image
					style={{
						height: 120,
						width: 120,
						borderRadius: 100,
						borderWidth: 2,
						borderColor: "#fff",
						marginBottom: 20,
						backgroundColor: "white",
					}}
					source={
						this.props.currentImage == null
							? def_user_img
							: this.props.currentImage
					}
					resizeMode='cover'
				/>
				<input
					type='file'
					name='image'
					id='file'
					accept='.jpg, .jpeg, .png'
					onChange={(e) => this.onChange(e)}
				/>
				<br />
			</div>
		);
	}
}

export default ImageCollector;
