import React from "react";
import { StyleSheet, TouchableHighlight, Image } from "react-native";
import backFabPng from "../../assets/Icons/DCS_back_fab.png";

const BackFab = ({ onPress }) => {
	return (
		<TouchableHighlight
			style={styles.backFab}
			onPress={onPress}
			activeOpacity={0.9}>
			<Image style={styles.backFab} source={backFabPng} />
		</TouchableHighlight>
	);
};

const styles = StyleSheet.create({
	backFab: {
		height: 40,
		width: 40,
		borderRadius: 50,
	},
});

export default BackFab;
