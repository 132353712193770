import React, { useState, useEffect } from "react";
import { View, Dimensions, Image, TouchableOpacity, Modal } from "react-native";

//assets
import cross_ic from "../../assets/Icons/DCS_add_ic.png";

const BottomPopUpModal = ({
	isVisible,
	containerStyle,
	innerContainerStyle,
	onClose,
	children,
	height,
}) => {
	return (
		<Modal visible={isVisible} transparent animationType={"fade"}>
			<View
				style={[
					{
						width: "100%",
						height: "100%",
						backgroundColor: "#00000050",
					},
					containerStyle,
				]}>
				<View
					style={[
						{
							alignSelf: "center",
							borderTopRightRadius: 20,
							borderTopLeftRadius: 20,
							borderWidth: 1,
							borderColor: "#ccc",
							height: height || 200,
							width: "100%",
							maxWidth: 800,
							backgroundColor: "#fff",
							bottom: 0,
							position: "absolute",
						},
						innerContainerStyle,
					]}>
					{/* close modal */}
					<TouchableOpacity onPress={onClose}>
						<Image
							source={cross_ic}
							style={{
								height: 35,
								width: 35,
								transform: [{ rotate: "45deg" }],
								alignSelf: "flex-end",
								margin: 5,
							}}
							resizeMode='contain'
						/>
					</TouchableOpacity>
					{children}
				</View>
			</View>
		</Modal>
	);
};

export default BottomPopUpModal;
