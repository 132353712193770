import React from "react";
import { StyleSheet, TouchableOpacity, Platform } from "react-native";

const FloatingCardBlank = ({ onPress, extraStyle, Content, activeOpacity }) => {
	return (
		<TouchableOpacity
			onPress={onPress}
			activeOpacity={activeOpacity}
			style={[
				styles.cardEffect,
				extraStyle,
				Platform.OS == "ios"
					? { borderWidth: 1, borderColor: "#eee" }
					: null,
			]}>
			{Content}
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	cardEffect: {
		flex: 1,
		width: 210,
		height: 160,
		margin: 5,
		marginVertical: 10,
		borderRadius: 10,
		backgroundColor: "white",
		elevation: 3,
		shadowColor: "#222",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.15,
		shadowRadius: 2,
	},
});

export default FloatingCardBlank;
