import React, { useRef, useState } from "react";
import { Text, View, ScrollView, TouchableOpacity, Image } from "react-native";
import FImage from "./ImageInterface";

const GenericHeroComponent = ({ data, props }) => {
	const screen = props.screen;

	const heroBannerItem = (data) => {
		return (
			<View
				style={{
					flex: 1,
					justifyContent: "center",
					alignItems: "center",
					padding: 10,
				}}>
				<Image
					source={data.items[0].bg_image}
					style={{ width: "100%", height: 300, borderRadius: 12 }}
					resizeMode='cover'
				/>
				<View
					style={{
						position: "absolute",
						flexDirection: "column",
						alignItems: "center",
						maxWidth: "70%",
					}}>
					<Text
						style={{
							fontFamily: "Gilroy-SemiBold",
							color: "#fff",
							fontSize: 30,
							marginTop: 10,
						}}>
						{data.items[0].title}
					</Text>
					<Text
						style={{
							fontFamily: "Gilroy-Regular",
							color: "#fff",
							fontSize: 16,
							marginTop: 10,
						}}>
						{data.items[0].header}
					</Text>
					<Text
						style={{
							fontFamily: "Gilroy-Regular",
							color: "#fff",
							fontSize: 16,
							marginTop: 27,
						}}>
						{data.items[0].body}
					</Text>
				</View>
			</View>
		);
	};

	return heroBannerItem(data);
};

export default GenericHeroComponent;
