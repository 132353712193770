import React, { useState, useRef, useEffect } from "react";
import {
	StyleSheet,
	Text,
	View,
	TextInput,
	Image,
	ScrollView,
	Dimensions,
	Animated,
	Easing,
	Picker,
} from "react-native";
// import { Dropdown } from "react-native-element-dropdown";
// import RadioForm, {
// 	RadioButton,
// 	RadioButtonInput,
// 	RadioButtonLabel,
// } from "react-native-simple-radio-button";
import { RadioGroup, RadioButton } from "../../components/WebOnly/RadioButton";
import axiosInstance from "../../Utils/axiosGlobal";

import BackFab from "../../components/Common/BackFab";
import Utils from "../../Utils/Utils";

//assets
import petrol_ic from "../../assets/Icons/DCS_petrol_mini_ic.png";
import diesel_ic from "../../assets/Icons/DCS_diesel_mini_ic.png";
import NonFilledButton from "../../components/Buttons/NonFilledButton";

const VehicleSelectionForm = ({ navigation, route }) => {
	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));
	const { vehicleData } = route.params;
	const [errorText, setErrorText] = useState("");

	const { showVehicleForm, setVehicleData, setSelectedVehicle } =
		route.params;

	//stores current question in focus
	const [questionInFocus, setQuestionInFocus] = useState(0);

	//This is an array of focus components, storing their each individual value.
	//the position of the value reflects the position of each question in form
	const animatedHeights = [
		useRef(new Animated.Value(60)).current,
		useRef(new Animated.Value(0)).current,
		useRef(new Animated.Value(0)).current,
		useRef(new Animated.Value(0)).current,
		useRef(new Animated.Value(0)).current,
		useRef(new Animated.Value(0)).current,
	];

	const animatedOpacities = [
		useRef(new Animated.Value(1)).current,
		useRef(new Animated.Value(0)).current,
		useRef(new Animated.Value(0)).current,
		useRef(new Animated.Value(0)).current,
		useRef(new Animated.Value(0)).current,
		useRef(new Animated.Value(0)).current,
	];

	// const [selectedVehicle, setSelectedVehicle] = useState(0);
	const [selectedFuel, setSelectedFuel] = useState(0);
	const vehicle_options = [
		{
			label: "Car",
			value: 1,
		},
		{
			label: "Bike",
			value: 0,
		},
		{
			label: "Scooty",
			value: 0,
		},
	];
	const fuel_options = [
		{
			label: "Petrol",
			value: 1,
			ic: petrol_ic,
		},
		{
			label: "Diesel",
			value: 2,
			ic: diesel_ic,
		},
	];

	const [brandOptions, setBrandOptions] = useState([]);
	const [modelOptions, setModelOptions] = useState([]);

	const [isBrandSelected, setBrandSelected] = useState(false);
	const [selectedModel, setSelectedModel] = useState("");

	const [registrationNumber, setRegistrationNumber] = useState("");
	const [manufactureYear, setManufactureYear] = useState("");

	const [value, setValue] = useState("");
	const [value2, setValue2] = useState("");
	const [isDropperFocus, setIsDropperFocus] = useState(false);
	const [isDropper2Focus, setIsDropper2Focus] = useState(false);
	const [isBrandListFetched, setBrandListFetched] = useState(false);

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	useEffect(() => {
		axiosInstance({
			method: "get",
			url: `auto-care/auto-manufacturers/`,
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					handleBrandResponse(response.data);
				} else {
					Utils.notifyMessage(
						"Error connecting to our servers. Try again later."
					);
				}
			})
			.catch((onrejected) => {
				// Utils.handleFailedResponse(
				// 	"getAutoManufacturers",
				// 	onrejected.response,
				// 	onrejected.response.status,
				// 	navigation
				// );
			});
	}, []);

	const fetchVehiclesForBrand = (id) => {
		axiosInstance({
			method: "get",
			url: `auto-care/forms/auto-manufacturers/${id}/vehicles/`,
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					handleModelResponse(response.data);
				} else {
					Utils.notifyMessage(
						"Error connecting to our servers. Try again later."
					);
				}
			})
			.catch((onrejected) => {
				// Utils.handleFailedResponse(
				// 	"getAutoManufacturersByID",
				// 	onrejected.response,
				// 	onrejected.response.status,
				// 	navigation
				// );
			});
	};

	const postVehicleData = () => {
		axiosInstance({
			method: "post",
			url: `/auto-care/vehicles/`,
			data: {
				name: selectedModel.name,
				type: selectedModel.type,
				color: "white",
				registration_number: registrationNumber,
				manufacture_year: manufactureYear,
			},
		})
			.then((response) => {
				try {
					if (response.status >= 200 && response.status < 300) {
						let temp = vehicleData;
						console.log(response);
						temp.push({
							id: response.data.id,
							name: response.data.name,
							type: selectedModel.type,
							color: "white",
							registration_number: registrationNumber,
							manufacture_year: manufactureYear,
							vehicle_photos: response.data.vehicle_photos,
						});
						setVehicleData(temp);
						setSelectedVehicle(response.data.id);
						setErrorText("");
						showVehicleForm(false);
					} else {
						// Utils.notifyMessage(
						// 	"Error connecting to our servers. Try again later."
						// );
						setErrorText("Please check all the inputs");
					}
				} catch (error) {
					console.error(error);
				}
			})
			.catch((onrejected) => {
				// Utils.handleFailedResponse(
				// 	"getVehicles",
				// 	onrejected.response,
				// 	onrejected.response.status,
				// 	navigation
				// );
				console.log(onrejected);
				setErrorText("Please check all the inputs");
			});
	};

	const handleBrandResponse = (response) => {
		setBrandOptions(response);
		if (typeof response !== "undefined") {
			fetchVehiclesForBrand(response[0].id);
		}
		setBrandListFetched(true);
	};

	const handleModelResponse = (response) => {
		setModelOptions(response.model_options);
		console.log(response);
		setSelectedModel(response.model_options[0]);
		setBrandSelected(true);
	};

	const animateFocusFx = (lastPositionInFocus, newPositionInFocus) => {
		//hide
		Animated.timing(animatedHeights[lastPositionInFocus], {
			toValue: 0,
			duration: 500,
			easing: Easing.elastic(0.8),
			useNativeDriver: false,
		}).start();
		Animated.timing(animatedOpacities[lastPositionInFocus], {
			toValue: 0,
			duration: 500,
			easing: Easing.elastic(0.8),
			useNativeDriver: false,
		}).start();

		//show
		Animated.timing(animatedHeights[newPositionInFocus], {
			toValue: 60,
			duration: 500,
			easing: Easing.elastic(0.8),
			useNativeDriver: false,
		}).start();
		Animated.timing(animatedOpacities[newPositionInFocus], {
			toValue: 1,
			duration: 500,
			easing: Easing.elastic(0.8),
			useNativeDriver: false,
		}).start();
	};

	const AnimatedBar = (num) => {
		return (
			<Animated.View
				style={{
					borderWidth: 2,
					width: 2,
					marginEnd: 10,
					height: animatedHeights[num],
					alignSelf: "center",
					borderRadius: 10,
					borderColor: "#7E98ED",
					opacity: animatedOpacities[num],
				}}
			/>
		);
	};

	return (
		<ScrollView style={styles.container}>
			<View
				style={{
					width: "100%",
					alignItems: "center",
				}}>
				{isBrandListFetched ? (
					<View>
						<View style={{ flexDirection: "row", marginTop: 20 }}>
							{AnimatedBar(0)}
							<View>
								<Text style={styles.instructionTxt}>
									Select the brand of vehicle you own
								</Text>
								<Picker
									style={[styles.dropdown]}
									onValueChange={(id) => {
										setValue(id);
										setIsDropperFocus(false);
										fetchVehiclesForBrand(id);
									}}
									onFocus={() => {
										setIsDropperFocus(true);
										setQuestionInFocus((prev) => {
											animateFocusFx(prev, 0);
											return 0;
										});
									}}
									selectedValue={value}>
									{brandOptions.map((item) => {
										return (
											<Picker.Item
												itemStyle={{
													maxWidth: 100,
												}}
												label={item.name}
												value={item.id}
											/>
										);
									})}
								</Picker>
								{/* <Dropdown
									style={[
										styles.dropdown,
										isDropperFocus && {
											borderColor: "#7E98ED",
											borderWidth: 2,
										},
									]}
									placeholderStyle={{ color: "#ccc" }}
									data={brandOptions}
									maxHeight={300}
									labelField='name'
									valueField='id'
									placeholder={"Enter a brand name"}
									onFocus={() => {
										setIsDropperFocus(true);
										setQuestionInFocus((prev) => {
											animateFocusFx(prev, 0);
											return 0;
										});
									}}
									onBlur={() => setIsDropperFocus(false)}
									onChange={(item) => {
										console.log(item);
										setValue(item.value);
										setIsDropperFocus(false);
										fetchVehiclesForBrand(item.id);
									}}
								/> */}
							</View>
						</View>
						<View style={{ flexDirection: "row", marginTop: 20 }}>
							{AnimatedBar(1)}
							<View>
								<Text style={styles.instructionTxt}>
									Select your vehicle model
								</Text>
								<Picker
									style={[styles.dropdown]}
									enabled={
										modelOptions !== null &&
										modelOptions.length > 0
									}
									onValueChange={(item) => {
										console.log(item);
										setValue2(item.value);
										setIsDropper2Focus(false);
										setSelectedModel(item);
									}}
									onFocus={() => {
										setIsDropperFocus(true);
										setQuestionInFocus((prev) => {
											animateFocusFx(prev, 1);
											return 1;
										});
									}}
									selectedValue={value}>
									{modelOptions.map((item) => {
										return (
											<Picker.Item
												itemStyle={{
													maxWidth: 100,
												}}
												label={item.name}
												value={item.id}
											/>
										);
									})}
								</Picker>
								{/* <Dropdown
									style={[
										styles.dropdown,
										isDropper2Focus && {
											borderColor: "#7E98ED",
											borderWidth: 2,
										},
									]}
									placeholderStyle={{ color: "#ccc" }}
									data={modelOptions}
									maxHeight={300}
									labelField='name'
									valueField='id'
									placeholder={
										isBrandSelected
											? "Select your vehicle model"
											: "Please select a brand first"
									}
									onFocus={() => {
										setIsDropper2Focus(true);
										setQuestionInFocus((prev) => {
											animateFocusFx(prev, 1);
											return 1;
										});
									}}
									onBlur={() => setIsDropper2Focus(false)}
									onChange={(item) => {
										console.log(item);
										setValue2(item.value);
										setIsDropper2Focus(false);
										setSelectedModel(item);
									}}
									disable={!isBrandSelected}
								/> */}
							</View>
						</View>
						<View style={{ flexDirection: "row", marginTop: 20 }}>
							{AnimatedBar(2)}
							<View>
								<Text style={styles.instructionTxt}>
									Enter registration number
								</Text>
								<TextInput
									placeholder='Registration number'
									placeholderTextColor={"#ccc"}
									style={styles.dropdown}
									onChangeText={(t) =>
										setRegistrationNumber(t)
									}
									onFocus={() => {
										setIsDropper2Focus(true);
										setQuestionInFocus((prev) => {
											animateFocusFx(prev, 2);
											return 2;
										});
									}}
								/>
							</View>
						</View>
						<View style={{ flexDirection: "row", marginTop: 20 }}>
							{AnimatedBar(3)}
							<View>
								<Text style={styles.instructionTxt}>
									Enter Manufacture year
								</Text>
								<TextInput
									placeholder='0000'
									placeholderTextColor={"#ccc"}
									style={styles.dropdown}
									maxLength={4}
									keyboardType='number-pad'
									onChangeText={(t) => setManufactureYear(t)}
									onFocus={() => {
										setIsDropper2Focus(true);
										setQuestionInFocus((prev) => {
											animateFocusFx(prev, 3);
											return 3;
										});
									}}
								/>
							</View>
						</View>
						<View style={{ flexDirection: "row", marginTop: 20 }}>
							{AnimatedBar(4)}
							<View>
								<Text style={styles.instructionTxt}>
									Select the fuel type
								</Text>
								<RadioGroup
									value={selectedFuel}
									labelID={"label"}
									onChange={(id) => {
										setSelectedFuel(id);
										setQuestionInFocus((prev) => {
											animateFocusFx(prev, 4);
											return 4;
										});
									}}
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										width: 250,
									}}>
									{fuel_options.map((item, i) => (
										<RadioButton key={i} {...item} />
									))}
								</RadioGroup>
								{/* <RadioForm
									style={{
										flexDirection: "row",
										justifyContent: "center",
									}}>
									{fuel_options.map((item, i) => {
										return (
											<View
												style={{
													flexDirection: "row",
													alignItems: "flex-start",
												}}>
												<RadioButton
													labelHorizontal={true}
													key={i}
													onPress={() =>
														setSelectedFuel(i)
													}>
													<RadioButtonInput
														obj={item}
														index={i}
														isSelected={
															i == selectedFuel
														}
														onPress={() => {
															setSelectedFuel(i);
															setQuestionInFocus(
																(prev) => {
																	animateFocusFx(
																		prev,
																		4
																	);
																	return 4;
																}
															);
														}}
														borderWidth={3}
														buttonInnerColor={
															"#7E98ED"
														}
														buttonOuterColor={
															"#7E98ED"
														}
														buttonSize={10}
														buttonOuterSize={22}
														buttonWrapStyle={{
															marginLeft: 20,
														}}
													/>
													<RadioButtonLabel
														obj={item}
														index={i}
														labelHorizontal={true}
														onPress={() =>
															setSelectedFuel(i)
														}
														labelStyle={{
															fontSize: 20,
															color: "#757A89",
														}}
													/>
												</RadioButton>
												<Image
													style={{
														height: 20,
														width: 20,
														marginHorizontal: 7,
													}}
													source={item.ic}
													resizeMode='contain'
												/>
											</View>
										);
									})}
								</RadioForm> */}
							</View>
						</View>
						{errorText != null && errorText != "" ? (
							<Text
								style={{
									color: "red",
									alignSelf: "center",
									marginTop: 20,
								}}>
								{errorText}
							</Text>
						) : null}
						<NonFilledButton
							title={"Add"}
							style={{
								width: 100,
								alignSelf: "center",
								marginTop: 50,
								marginBottom: 80,
							}}
							onPress={() => {
								postVehicleData();
							}}
						/>
					</View>
				) : (
					<Text style={{ alignSelf: "center", marginTop: 20 }}>
						Loading...
					</Text>
				)}
				{/* <View style={{ flexDirection: "row", marginTop: 20 }}>
					{AnimatedBar(0)}
					<View>
						<Text style={styles.instructionTxt}>
							Select the type of Vehicle you own
						</Text>
						<RadioForm
							style={{
								flexDirection: "row",
								justifyContent: "center",
							}}>
							{vehicle_options.map((item, i) => {
								return (
									<RadioButton
										labelHorizontal={true}
										key={i}
										onPress={() => setSelectedVehicle(i)}>
										<RadioButtonInput
											obj={item}
											index={i}
											isSelected={i == selectedVehicle}
											onPress={() => {
												setSelectedVehicle(i);
												setQuestionInFocus((prev) => {
													animateFocusFx(prev, 0);
													return 0;
												});
											}}
											borderWidth={3}
											buttonInnerColor={"#7E98ED"}
											buttonOuterColor={"#7E98ED"}
											buttonSize={10}
											buttonOuterSize={22}
											buttonWrapStyle={{ marginLeft: 20 }}
										/>
										<RadioButtonLabel
											obj={item}
											index={i}
											labelHorizontal={true}
											onPress={() =>
												setSelectedVehicle(i)
											}
											labelStyle={{
												fontSize: 20,
												color: "#757A89",
											}}
										/>
									</RadioButton>
								);
							})}
						</RadioForm>
					</View>
				</View> */}
			</View>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
	},
	backArrow: {
		position: "absolute",
		left: 30,
		top: 25,
		zIndex: 1,
	},
	dropdown: {
		height: 35,
		width: 250,
		borderColor: "#ddd",
		borderWidth: 1,
		borderRadius: 10,
		paddingHorizontal: 8,
		marginLeft: 10,
		padding: 0,
		outlineColor: "#7e98ed",
		fontFamily: "Gilroy-Regular",
	},
	instructionTxt: {
		fontSize: 18,
		fontFamily: "Gilroy-Regular",
		marginBottom: 10,
	},
});

export default VehicleSelectionForm;
