import React, { useState, useRef } from "react";
import {
	StyleSheet,
	View,
	TextInput,
	Image,
	Text,
	FlatList,
	TouchableHighlight,
	Animated,
} from "react-native";

//assets
import searchBarPng from "../../assets/Icons/DCS_search_bar.png";
import GenericSearchBar from "../Common/GenericSearchBar";

const SearchBar = ({
	placeholderText,
	extraStyles,
	onChangeText,
	results,
	setSelectedResult,
	selectedCoords,
	setSelectedResultObj,
}) => {
	const renderItems = ({ item }) => {
		return (
			<TouchableHighlight
				onPress={() => {
					setSelectedResult(item.formatted_address);
					AnimateResultBox("collapse");
					let temp;
					let pin;
					let state;
					if (item?.address_components?.length == 10) {
						pin = results[9].long_name;
						state = results[7].long_name;
					} else {
						temp = item.formatted_address.split(",");
						pin = temp[temp.length - 2].trim().split(" ")[1];
						state = temp[temp.length - 2].trim().split(" ")[0];
					}
					let finalObj = {
						name: "Home",
						latitude: item.geometry.location.lat(),
						longitude: item.geometry.location.lng(),
						address: item.formatted_address,
						pin_code: isNaN(pin) ? "" : pin,
						city: temp[temp.length - 3].trim(),
						is_service_center_location: false,
						state: state,
						map_url: `https://www.google.com/maps/search/?api=1&query=${item.geometry.location.lat()}%2C${item.geometry.location.lng()}`,
					};
					setSelectedResultObj(finalObj);
				}}
				underlayColor='#dbe9ff'
				style={{ padding: 2, borderRadius: 7 }}>
				<View>
					<Text
						style={{ fontSize: 13, fontFamily: "Gilroy-Regular" }}>
						{item.formatted_address}
					</Text>
				</View>
			</TouchableHighlight>
		);
	};

	// const [resultBoxHeight, setResultBoxHeight] = useState(0);
	const resultBoxHeight = useRef(new Animated.Value(0)).current;
	const resultBoxOpacity = useRef(new Animated.Value(0)).current;
	// const [resultBoxOpacity, setResultBoxOpacity] = useState(0);

	const AnimateResultBox = (action) => {
		if (action == "expand") {
			Animated.timing(resultBoxHeight, {
				toValue: 250,
				duration: 250,
				useNativeDriver: false,
			}).start();
			Animated.timing(resultBoxOpacity, {
				toValue: 1,
				duration: 250,
				useNativeDriver: false,
			}).start();
		} else {
			Animated.timing(resultBoxHeight, {
				toValue: 0,
				duration: 250,
				useNativeDriver: false,
			}).start();
			Animated.timing(resultBoxOpacity, {
				toValue: 0,
				duration: 250,
				useNativeDriver: false,
			}).start();
		}
	};

	return (
		<View style={extraStyles}>
			<GenericSearchBar
				onChangeText={onChangeText}
				onFocus={() => {
					try {
						AnimateResultBox("expand");
					} catch (error) {
						console.log(error);
					}
				}}
				onTouchEnter={(t) => (t ? onChangeText(t) : null)}
				placeholderText={placeholderText}
			/>
			{results.length > 0 ? (
				<Animated.View
					style={{
						maxHeight: resultBoxHeight,
						opacity: resultBoxOpacity,
					}}>
					<FlatList
						data={results}
						renderItem={renderItems}
						scrollEnabled={true}
						style={{
							backgroundColor: "#fff",
							padding: 7,
							borderRadius: 12,
							overflow: "hidden",
							maxWidth: "90%",
							alignSelf: "center",
						}}
						ItemSeparatorComponent={() => (
							<View
								style={{
									height: 1,
									backgroundColor: "#ddd",
									width: "90%",
									alignSelf: "center",
								}}
							/>
						)}
					/>
				</Animated.View>
			) : null}
		</View>
	);
};

export default SearchBar;
