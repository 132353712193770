import React, { useState, useEffect } from "react";
import {
	StyleSheet,
	Text,
	View,
	Dimensions,
	TouchableOpacity,
	Image,
} from "react-native";
import axiosInstance from "../../Utils/axiosGlobal";
import arrow_ic from "../../assets/Icons/DCS_arrow_head_ic.png";

//components
import BackFab from "../../components/Common/BackFab";
import ProceedBarButton from "../../components/Buttons/ProceedBarButton";
import Utils from "../../Utils/Utils.web";
import LoaderOverlay from "../../components/Common/LoaderOverlay.web";
// import RazorpayCheckout from "react-native-razorpay";
// import PopUpModal from "react-native-modal";
import PopupDialogBox from "../../components/Common/PopupDialogBox.web";
import BorderedButton from "../../components/Buttons/NonFilledButton";
import FilledButton from "../../components/Buttons/SubmitButtonDark";
// import FastImage from "react-native-fast-image";

//assets
import success_ic from "../../assets/Icons/DCS_tick_ic.png";
import failure_ic from "../../assets/Icons/DCS_red_warn_ic.png";
import cross_ic from "../../assets/Icons/DCS_add_ic.png";
import { useLinkTo } from "@react-navigation/native";
import PaymentStatusScreen from "./PaymentStatusScreen.web";

const PaymentScreen = ({ route, navigation }) => {
	const [windowSize, setwindowSize] = useState(Dimensions.get("window"));
	const [screen, setScreen] = useState(Dimensions.get("screen"));
	const [dimensions, setDimensions] = useState({ window, screen });

	const { preId, postId } = route.params;
	const [showLoader, setShowLoader] = useState(true);

	const [isValidForPayment, setIsValidForPayment] = useState(false);

	const [preBookingInfo, setPreBookingInfo] = useState(null);
	const [postBookingInfo, setPostBookingInfo] = useState(null);

	const [billingPrice, setBillingPrice] = useState();
	const [billingTax, setBillingTax] = useState();
	const [billingTaxTotal, setBillingTaxTotal] = useState();
	const [billingPriceTotal, setBillingPriceTotal] = useState();

	const [showBillingAlert, setShowBillingAlert] = useState("hidden");

	const [showSuccessScreen, setShowSuccessScreen] = useState(false);

	useEffect(() => {
		const subscription = Dimensions.addEventListener(
			"change",
			({ window, screen }) => {
				setScreen(screen);
				setwindowSize(window);
			}
		);
		return () => subscription?.remove();
	}, []);

	useEffect(() => {
		/**
		 * On web version we need to check for user session validity on page refresh as the axios default headers don't
		 * persist page resfresh, so this is an additional step and needs to be done on all pages.
		 */
		Utils.isGuestOrUser(navigation).then((result) => {
			console.log(result);
			if (
				result == null ||
				typeof result == "undefined" ||
				result == "guest"
			) {
				setIsValidForPayment(false);
				hitApis();
			} else {
				setIsValidForPayment(true);
				axiosInstance.defaults.headers.common["Authorization"] = result;
				hitApis();
			}
		});
	}, []);

	const hitApis = () => {
		var bookingID = typeof postId !== "undefined" ? postId : preId;
		axiosInstance({
			method: "get",
			url: `auto-care/service-bookings/${bookingID}`,
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					if (!response.data.payment_completed) {
						setIsValidForPayment(true);
						if (typeof postId !== "undefined") {
							setPostBookingInfo(response.data);
						} else {
							setPreBookingInfo(response.data);
						}
						console.log(response.data);
						setBillingPrice(response.data.billing_items[0].price);
						setBillingTax(response.data.billing_items[0].tax);
						setBillingTaxTotal(
							response.data.billing_items[0].tax_amount
						);
						setBillingPriceTotal(
							response.data.billing_items[0].total_amount
						);
					} else {
						setIsValidForPayment(false);
					}
					setShowLoader(false);
				} else {
					console.log("something went wrong");
					setShowLoader(false);
				}
			})
			.catch((onrejected) => {
				console.log(onrejected);
			});
	};

	const handleCreateOrder = async () => {
		setShowLoader(true);
		try {
			axiosInstance({
				method: "post",
				url: `/subscriptions/order/`,
				data: {
					booking_id:
						typeof postId == "undefined"
							? preBookingInfo.id
							: postBookingInfo.id,
				},
			})
				.then((response) => {
					if (response.status >= 200 && response.status < 300) {
						typeof postId != "undefined"
							? setPostBookingInfo(...postBookingInfo, {
									api_key: response.api_key,
							  })
							: setShowSuccessScreen(true);
					} else {
						Utils.notifyMessage(
							"Error connecting to our servers. Try again later."
						);
					}
					setShowLoader(false);
				})
				.catch((onrejected) => {
					setShowLoader(false);
					// Utils.handleFailedResponse(
					// 	"postCreateOrder",
					// 	onrejected.response,
					// 	onrejected.response.status,
					// 	navigation
					// );
					setShowBillingAlert("failure");
				});
		} catch (error) {
			console.log(error);
			setShowLoader(false);
			Utils.notifyMessage(
				"Error when submitting request, please recheck all parameters"
			);
		}
	};

	const handleProceedToPay = () => {
		if (typeof postId != "undefined") {
			//we are in post booking flow and still api_key is null (order not generated)
			if (postBookingInfo.api_key == null) {
				handleCreateOrder()
					.then(() => {
						razorPayCallback();
					})
					.catch((reason) => {
						console.log(reason);
						setShowBillingAlert("failure");
					});
			} else {
				//api_key exists
				razorPayCallback();
			}
		} else {
			handleCreateOrder();
		}
	};

	const razorPayCallback = () => {
		let options = {
			description: `Paying to ${
				typeof postId != "undefined"
					? postBookingInfo.service_center.name
					: preBookingInfo.service_center.name
			}`,
			image:
				typeof postId != "undefined"
					? postBookingInfo.service_center.image
					: preBookingInfo.service_center.image,
			currency: "INR",
			key:
				typeof postId != "undefined"
					? postBookingInfo.api_key
					: preBookingInfo.api_key,
			amount:
				typeof postId != "undefined"
					? postBookingInfo.billing_items[0].total_amount
					: preBookingInfo.billing_items[0].total_amount,
			name:
				typeof postId != "undefined"
					? postBookingInfo.billing_items[0]
					: preBookingInfo.billing_items[0],
			order_id:
				typeof postId != "undefined"
					? postBookingInfo.gateway_order_id
					: preBookingInfo.gateway_order_id, //Replace this with an order_id created using Orders API.
			handler: function (response) {
				console.log("success paid");
				setShowSuccessScreen(true);
			},
			// prefill: {
			// 	email: "gaurav.kumar@example.com",
			// 	contact: "9191919191",
			// 	name: "Gaurav Kumar",
			// },
			theme: { color: "#1D3688" },
		};
		console.log(options);
		var razorPayCall = new Razorpay(options);
		razorPayCall.on("payment.failed", () => {
			setShowBillingAlert("failure");
		});
		razorPayCall.open();
	};

	return (
		<View style={styles.container}>
			{showSuccessScreen ? (
				<PaymentStatusScreen
					navigation={navigation}
					props={{
						sname:
							typeof postId == "undefined"
								? preBookingInfo.service.name
								: postBookingInfo.service.name,
						timing: Utils.getLocalTimeFromUTC(
							typeof postId == "undefined"
								? preBookingInfo.status.created
								: postBookingInfo.status.created,
							"Do MMM"
						),
						mode:
							typeof postId == "undefined"
								? preBookingInfo.payment_completed
								: postBookingInfo.payment_completed,
					}}
				/>
			) : (
				<View style={styles.container}>
					<PopupDialogBox
						titleText={
							showBillingAlert == "success"
								? "PAYMENT SUCCESSFUL"
								: "PAYMENT FAILED"
						}
						visibility={showBillingAlert != "hidden"}
						showButtons={true}
						extraComponents={
							<View
								style={{
									flex: 1,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<View
									style={{
										width: "80%",
										maxWidth: 330,
										maxHeight: 400,
										backgroundColor: "white",
										borderRadius: 10,
										elevation: 10,
										padding: 20,
										alignItems: "center",
									}}>
									<Image
										source={
											showBillingAlert == "success"
												? success_ic
												: failure_ic
										}
										style={{ height: 120, width: 120 }}
									/>
									{showBillingAlert == "success" ? (
										<View
											style={{
												alignItems: "center",
												marginTop: 20,
											}}>
											<Text
												style={{
													marginTop: 10,
													fontFamily:
														"Gilroy-Regular",
												}}>
												Your payment was successful!
											</Text>
											<View
												style={{
													flexDirection: "row",
													justifyContent: "center",
													marginTop: 17,
													width: 210,
													alignSelf: "center",
												}}>
												<FilledButton
													title={"Home"}
													onPress={() => {
														navigation.reset({
															index: 0,
															routes: [
																{
																	name: "home",
																},
															],
														});
													}}
													style={{
														paddingHorizontal: 14,
													}}
												/>
											</View>
										</View>
									) : (
										<View
											style={{
												alignItems: "center",
												marginTop: 20,
											}}>
											<Text
												style={{
													marginTop: 10,
													textAlign: "center",
													fontFamily:
														"Gilroy-Regular",
												}}>
												Your payment was not processed.
												{"\n"} Please try again or
												contact our customer support.
											</Text>
										</View>
									)}
								</View>
							</View>
						}
						positiveOptionText={"Close"}
						negativeOptionText={"Try Again"}
						onPositiveSelection={() =>
							setShowBillingAlert("hidden")
						}
						onNegativeSelection={() => {
							setShowBillingAlert("hidden");
							handleProceedToPay();
						}}
					/>
					<View
						style={[
							{
								width: "100%",
								height: windowSize.width < 800 ? 120 : 70,
								borderBottomWidth: 1,
								borderBottomColor: "#ccc",
								flexDirection: "row",
								backgroundColor: "white",
								zIndex: 1,
							},
							styles.fixed,
						]}>
						<View
							style={{ alignSelf: "center", marginStart: "5%" }}>
							<Text
								style={{
									fontSize: 26,
									color: "#0E1B46",
									fontFamily: "Gilroy-SemiBold",
								}}>
								{postBookingInfo
									? "Payment Details"
									: "Payment Details - Estimated"}
							</Text>
						</View>
					</View>
					<View
						style={{
							justifyContent: "center",
							flex: 1,
							marginHorizontal: 15,
						}}>
						<View
							style={{
								width: windowSize.width < 800 ? "100%" : "70%",
								height: 300,
								maxWidth: 500,
								alignSelf: "center",
								backgroundColor: "#fff",
								justifyContent: "center",
								borderRadius: 20,
							}}>
							<View style={styles.row}>
								<Text style={{ fontFamily: "Gilroy-Regular" }}>
									Service price
								</Text>
								<Text style={{ fontFamily: "Gilroy-Regular" }}>
									Rs.
									{billingPrice || "--"}
								</Text>
							</View>
							<View style={styles.row}>
								<Text style={{ fontFamily: "Gilroy-Regular" }}>
									Tax
								</Text>
								<Text style={{ fontFamily: "Gilroy-Regular" }}>
									{billingTax
										? `(${billingTax}%) Rs.` +
										  billingTaxTotal
										: "--"}
								</Text>
							</View>
							<View
								style={{
									height: 1,
									width: "89%",
									backgroundColor: "#ddd",
									alignSelf: "center",
								}}
							/>
							<View style={styles.row}>
								<Text style={{ fontFamily: "Gilroy-Regular" }}>
									{postBookingInfo
										? "Total Payable Amount"
										: "Total Estimated Cost"}
								</Text>
								<Text style={{ fontFamily: "Gilroy-Regular" }}>
									Rs.{billingPriceTotal || "--"}
								</Text>
							</View>
							{postBookingInfo ? null : (
								<View
									style={{
										width: "85%",
										maxWidth: 400,
										marginTop: 20,
										backgroundColor: "#CBD6F8",
										borderRadius: 7,
										alignSelf: "center",
										padding: 12,
									}}>
									<Text
										style={{
											fontFamily: "Gilroy-Regular",
										}}>
										This is an estimated payable amount, You
										won't be charged yet.{"\n"}You can make
										the payment when the service is done!
									</Text>
								</View>
							)}
						</View>
					</View>
					<LoaderOverlay bgColor={"#fff"} isVisible={showLoader} />
					<TouchableOpacity
						style={[
							{
								width: postBookingInfo ? 350 : 200,
								borderRadius: 50,
								// maxWidth: 900,
								height: 70,
								position: "absolute",
								alignSelf: "flex-end",
								margin: 10,
								bottom: 0,
								// marginLeft: -10,
								backgroundColor: "#001A45",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
								paddingHorizontal: 20,
							},
						]}
						onPress={() => handleProceedToPay()}>
						<View
							style={{
								flexDirection: "row",
							}}>
							<View>
								{/* <Text style={{ color: "white", fontSize: 12 }}>
								PREMIUM
							</Text> */}
								<Text
									style={{
										color: "white",
										fontSize: 18,
										fontFamily: "Gilroy-SemiBold",
									}}>
									{postBookingInfo
										? `Pay Rs.${billingPriceTotal}`
										: "Confirm"}
								</Text>
							</View>
							{postBookingInfo ? (
								<Text
									style={{
										color: "#fff",
										fontSize: 9,
										alignSelf: "flex-end",
										padding: 3,
										fontFamily: "Gilroy-Regular",
									}}>
									Including Taxes
								</Text>
							) : null}
						</View>
						<Image
							source={arrow_ic}
							style={{
								height: 25,
								width: 25,
								tintColor: "white",
								transform: [{ rotate: "-90deg" }],
								fontFamily: "Gilroy-Regular",
							}}
							resizeMode='contain'
						/>
					</TouchableOpacity>
				</View>
			)}
			{/* <AlertComponent type={showBillingAlert} /> */}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// backgroundColor: "#fff",
	},
	backArrow: {
		marginStart: 18,
		alignSelf: "center",
	},
	fixed: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	row: {
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: "10%",
		fontSize: 16,
		marginVertical: 5,
		fontFamily: "Gilroy-Regular",
	},
});

export default PaymentScreen;
